import React, { PureComponent } from 'react';
import './textInput.scss';
import PropTypes from 'prop-types';

class TextInput extends PureComponent {
  inputRef = React.createRef();

  componentDidMount() {
    const { disableAutofocus } = this.props;
    if (!disableAutofocus) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const {
      dataname,
      placeholder,
      onChange,
      onClear,
      value,
      listitemsearchboxClass,
      isClearEnabled = true,
      className = '',
      isSearchEnabled = true,
      preChildrenJsx,
      postChildrenJsx,
      inputType = 'text',
      onKeyDown,
    } = this.props;
    let clearClass = 'text-input-clear center';
    if (!value) clearClass += ' hide';
    return (
      <div
        className={[
          'text-input-wrapper',
          listitemsearchboxClass || '',
          className,
        ].join(' ')}
        data-view-type="text-input-wrapper"
      >
        {preChildrenJsx}
        {isSearchEnabled && (
          <div className="text-input-search center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
            >
              <path
                fill="var(--manatee)"
                d="M10.963 12L12 10.963 8.593 7.556h-.56l-.181-.198c.34-.384.6-.826.782-1.325.18-.5.271-1.023.271-1.572 0-.812-.194-1.558-.584-2.239-.39-.68-.92-1.218-1.588-1.613C6.04.203 5.289 0 4.477 0c-.812 0-1.56.198-2.247.593-.685.395-1.229.935-1.63 1.621C.2 2.9 0 3.649 0 4.461c0 .812.203 1.564.61 2.255.394.67.935 1.202 1.62 1.597.686.395 1.435.592 2.247.592.538 0 1.057-.09 1.556-.271.5-.181.94-.442 1.325-.782l.198.18v.544L10.963 12zM4.477 7.556c-.56 0-1.075-.14-1.547-.42-.472-.28-.848-.656-1.128-1.128-.28-.472-.42-.987-.42-1.547 0-.56.14-1.076.42-1.547.28-.472.656-.845 1.128-1.12.472-.274.988-.411 1.547-.411.56 0 1.076.137 1.548.411.472.275.845.648 1.119 1.12.274.471.412.987.412 1.547 0 .56-.138 1.075-.412 1.547-.274.472-.647.848-1.12 1.128-.471.28-.987.42-1.547.42z"
              />
            </svg>
          </div>
        )}
        <div className="text-input-input">
          <input
            ref={this.inputRef}
            type={inputType}
            data-name={dataname}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className={inputType === 'number' ? 'without-spinner' : ''}
            onKeyDown={onKeyDown}
          />
        </div>
        {isClearEnabled && (
          <div className={clearClass} onClick={onClear}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <g fill="none" fillRule="evenodd">
                <circle cx="8" cy="8" r="8" fill="var(--wash-me)" />
                <path
                  fill="var(--lynch)"
                  fillRule="nonzero"
                  d="M10.658 11.333L11.333 10.658 8.676 8 11.333 5.342 10.658 4.667 8 7.324 5.342 4.667 4.667 5.342 7.324 8 4.667 10.658 5.342 11.333 8 8.676z"
                />
              </g>
            </svg>
          </div>
        )}
        {postChildrenJsx}
      </div>
    );
  }
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  dataname: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  listitemsearchboxClass: PropTypes.string,
};

TextInput.defaultProps = {
  placeholder: '',
  listitemsearchboxClass: '',
};

export default TextInput;
