import React from "react";
import { IconPropsType } from "./types";

interface IFolderPlusIconProps extends IconPropsType {}

const FolderPlusIcon = ({
  size = 24,
  stroke = "var(--rhino)",
  strokeWidth = 1.25,
  fill = "transparent",
  ...props
}: IFolderPlusIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 13H15M12 10V16M3 17V7C3 5.89543 3.89543 5 5 5H11L13 7H19C20.1046 7 21 7.89543 21 9V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FolderPlusIcon;
