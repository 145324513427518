import mixpanel from "mixpanel-browser";
import { omit } from "../utils/index";
import { ObjectType } from "../@types/defined";
import { getIsCookiesEnabled } from "../services/cookies";
import { IS_REACT_NATIVE_ENABLED } from "../services/reactNative";
import {
  ILinkedSupplier,
  RoleType,
  getUserRoleEntityData,
} from "../utils/user";
import config from "../config";
import { store } from "../redux";

interface SetUserDataType {
  username: any;
  source: any;
  supplier_role: any;
  brand_role: any;
  first_name: any;
  last_name: any;
  full_name: any;
  user_id: any;
  is_admin: boolean;
  is_supplier: boolean;
  is_brand: boolean;
  brand_name: any;
  brand_id: any;
  linked_supplier: ILinkedSupplier;
}

type ComposeDataType = Pick<SetUserDataType, "username"> & {
  role: RoleType;
  entity_name: string | null;
  entity_id: number | null;
  auth_source: SetUserDataType["source"];
  designation: SetUserDataType["supplier_role"] | SetUserDataType["brand_role"];
};

type PeopleDataType = ComposeDataType &
  Pick<SetUserDataType, "first_name" | "last_name" | "full_name" | "user_id">;

function composeUserData(userData: SetUserDataType): ComposeDataType {
  const {
    username,
    is_admin,
    is_supplier,
    is_brand,
    brand_name,
    brand_id,
    linked_supplier,
    source,
    supplier_role,
    brand_role,
  } = userData;
  const { role, entityId, entityName } = getUserRoleEntityData(
    brand_name,
    brand_id,
    linked_supplier,
    is_admin,
    is_supplier,
    is_brand
  );
  return {
    username,
    role,
    entity_id: entityId,
    entity_name: entityName,
    auth_source: source,
    designation: supplier_role ?? brand_role,
  };
}

class Tracker {
  static get canTrack(): boolean {
    return getIsCookiesEnabled();
  }

  static get deviceViewport(): "mobile app" | "mobile" | "desktop" {
    if (IS_REACT_NATIVE_ENABLED) {
      return "mobile app";
    }
    // @ts-ignore
    const { is_mobile: isMobile } = store.getState().common;
    return isMobile ? "mobile" : "desktop";
  }

  static initTracking() {
    if (!Tracker.canTrack) {
      return;
    }
    if (config.MIXPANEL_TOKEN) {
      mixpanel.init(config.MIXPANEL_TOKEN);
    }
  }

  static trackAction(eventName: string, properties: ObjectType) {
    if (!Tracker.canTrack) {
      return;
    }
    let trackingData = omit(properties, ["token"]);
    trackingData = {
      ...trackingData,
      viewport: Tracker.deviceViewport,
    };

    if (config.IS_MIXPANEL_LOGGING_ENABLED) {
      console.log(eventName, trackingData);
    } else {
      mixpanel.track(eventName, trackingData);
    }
  }

  static registerSuperPropertiesOnce(properties: ComposeDataType) {
    if (!Tracker.canTrack) {
      return;
    }
    if (config.IS_MIXPANEL_LOGGING_ENABLED) {
      console.log("Mixpanel Super properties: ", properties);
    } else {
      mixpanel.register(properties);
    }
  }

  // clears super properties and generates a new random distinct_id for this instance. Useful for clearing data when a user logs out.
  static resetTracking() {
    if (!Tracker.canTrack) {
      return;
    }
    if (config.IS_MIXPANEL_LOGGING_ENABLED) {
      console.log("Reset tracking");
    } else {
      mixpanel.reset();
    }
  }

  static trackTimeEvent(eventName: string) {
    if (!Tracker.canTrack) {
      return;
    }
    if (config.IS_MIXPANEL_LOGGING_ENABLED) {
      console.log("Track time event: ", eventName);
    } else {
      mixpanel.time_event(eventName);
    }
  }

  static identify(username: string) {
    if (!Tracker.canTrack) {
      return;
    }
    if (config.IS_MIXPANEL_LOGGING_ENABLED) {
      console.log("Mixpanel Identify: ", username);
    } else {
      mixpanel.identify(username);
    }
  }

  static setClarityData(properties: PeopleDataType) {
    const keysToTrack: Array<keyof PeopleDataType> = [
      "username",
      "role",
      "entity_id",
      "entity_name",
    ];
    keysToTrack.forEach((keyName) => {
      // @ts-ignore
      window.clarity?.("set", keyName, String(properties[keyName] ?? ""));
    });
  }

  static setPeople(properties: PeopleDataType) {
    if (!Tracker.canTrack) {
      return;
    }
    const peopleProperties = Object.entries({
      ...properties,
      last_web_login: new Date(),
    }).reduce(
      (accumulator, current) => ({
        ...accumulator,
        [`$${current[0]}`]: current[1],
      }),
      {}
    );
    if (config.IS_MIXPANEL_LOGGING_ENABLED) {
      console.log("Mixpanel Set People: ", peopleProperties);
    } else {
      mixpanel.people.set(peopleProperties);
      Tracker.setClarityData(properties);
    }
  }
}

export function initMixpanel() {
  Tracker.initTracking();
}

export function trackAction(eventName = "", properties?: ObjectType) {
  Tracker.trackAction(eventName, properties || {});
}

export function registerSuperPropertiesOnce(userData: SetUserDataType) {
  const composedData = composeUserData(userData);
  Tracker.registerSuperPropertiesOnce(composedData);
}

export function resetMixpanel() {
  Tracker.resetTracking();
}

export function trackTimeEvent(eventName = "") {
  Tracker.trackTimeEvent(eventName);
}

export function identifyInTracker(username: string) {
  Tracker.identify(username);
}

// export function setPeopleInTracker(userData: SetUserDataType) {
//   Tracker.setPeople({
//     ...composeUserData(userData),
//     first_name: userData.first_name,
//     last_name: userData.last_name,
//     full_name: userData.full_name,
//     user_id: userData.user_id,
//   });
// }
