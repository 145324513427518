import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'washing_standard',
    label: 'Washing Standard',
    inputType: InputTypeEnum.COMBINED_RADIO_TEXT_FILE,
    textFieldSlug: 'washing_standard_remark',
    attachmentSlug: 'washing_standard_attachment',
  },
  {
    id: 'ironing_standard',
    label: 'Ironing Standard',
    inputType: InputTypeEnum.COMBINED_RADIO_TEXT_FILE,
    textFieldSlug: 'ironing_standard_remark',
    attachmentSlug: 'ironing_standard_attachment',
  },
  {
    id: 'packing_standard',
    label: 'Packing Standard',
    inputType: InputTypeEnum.COMBINED_RADIO_TEXT_FILE,
    textFieldSlug: 'packing_standard_remark',
    attachmentSlug: 'packing_standard_attachment',
  },
  {
    id: 'packing_assortment',
    label: 'Packing Assortment',
    inputType: InputTypeEnum.COMBINED_RADIO_TEXT_FILE,
    textFieldSlug: 'packing_assortment_remark',
    attachmentSlug: 'packing_assortment_attachment',
  },
  {
    id: 'hangtag_ticket_approval',
    label: 'Hangtag/ Price Ticket Approval',
    inputType: InputTypeEnum.COMBINED_RADIO_TEXT_FILE,
    textFieldSlug: 'hangtag_ticket_approval_remark',
    attachmentSlug: 'hangtag_ticket_approval_attachment',
  },
  {
    id: 'carton_marking',
    label: 'Carton Marking/ Label Position',
    inputType: InputTypeEnum.COMBINED_RADIO_TEXT_FILE,
    textFieldSlug: 'carton_marking_remark',
    attachmentSlug: 'carton_marking_attachment',
  },
];
