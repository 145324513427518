import { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { format, startOfMonth } from 'date-fns';
import { ObjectType } from '../../@types/defined';
import {
  DashboardSectionsSchema,
  DashboardSubSectionsSchema,
  IQualityDashboardState,
} from '../../@types/dashboard';
import { useTypedSelector } from '../../hooks';
import {
  setDashboardEntityFilters,
  setDashboardFilterDates,
  setDashboardSection,
  setDashboardSubSection,
} from '../../redux/actions/dashboard';
import { getQueryAndParamsFromQueryString } from '../../utils';

const useQueryAndStoreSync = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { section, subSection, dateFilters, entityFilters } = useTypedSelector(
    store => store.quality.dashboard
  );

  useEffect(() => {
    dispatch(
      push({
        search: new URLSearchParams({
          ...(section ? { section } : {}),
          ...(subSection ? { 'sub-section': subSection } : {}),
          ...(dateFilters.startDate
            ? { 'start-date': format(dateFilters.startDate, `yyyy-MM-d`) }
            : {}),
          ...(dateFilters.endDate
            ? { 'end-date': format(dateFilters.endDate, `yyyy-MM-d`) }
            : {}),
          ...(entityFilters.brand
            ? { brand: String(entityFilters.brand) }
            : {}),
          ...(entityFilters.inspector
            ? { inspector: String(entityFilters.inspector) }
            : {}),
          ...(entityFilters.supplier
            ? { supplier: String(entityFilters.supplier) }
            : {}),
        }).toString(),
      })
    );
  }, [section, subSection, dateFilters, entityFilters]);

  const { params } = getQueryAndParamsFromQueryString(location.search);

  function setInitialFilters(filterParams: ObjectType) {
    batch(() => {
      if (DashboardSectionsSchema.safeParse(filterParams.section).success) {
        dispatch(setDashboardSection(filterParams.section));
      }

      let initialSubSection = filterParams['sub-section'];
      if (!initialSubSection && filterParams.section === 'sample-inspection') {
        initialSubSection = 'all-samples';
      }

      if (DashboardSubSectionsSchema.safeParse(initialSubSection).success) {
        dispatch(setDashboardSubSection(initialSubSection));
      }

      const initialDateFilters: IQualityDashboardState['dateFilters'] = {
        text: 'Custom Date',
        startDate: null,
        endDate: null,
      };

      if (filterParams['start-date']) {
        initialDateFilters.startDate = new Date(filterParams['start-date']);
      }

      if (filterParams['end-date']) {
        initialDateFilters.endDate = new Date(filterParams['end-date']);
      }

      if (!initialDateFilters.startDate && !initialDateFilters.endDate) {
        initialDateFilters.startDate = startOfMonth(new Date());
        initialDateFilters.endDate = new Date();
        initialDateFilters.text = 'This month';
      }

      dispatch(setDashboardFilterDates(initialDateFilters));

      const initialEntityFilters: IQualityDashboardState['entityFilters'] = {
        brand: null,
        supplier: null,
        inspector: null,
      };

      Object.keys(initialEntityFilters).forEach(entity => {
        if (Number.isInteger(Number(filterParams[entity]))) {
          initialEntityFilters[
            entity as keyof IQualityDashboardState['entityFilters']
          ] = Number(filterParams[entity]);
        }
      });

      dispatch(setDashboardEntityFilters(initialEntityFilters));
    });
  }

  useEffect(() => {
    setInitialFilters(params);
  }, []);
};

export default useQueryAndStoreSync;
