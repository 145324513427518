import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";

export const Wrapper = styled.div`
  padding: 12px 0;

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InputGroup = styled.div`
  gap: 24px;

  ${device.mediumViewport} {
    width: 100%;
    flex-direction: column;
  }

  .forced-to-be-horizontal {
    display: flex;
    flex-direction: row-reverse;
    gap: 24px;
    align-items: flex-start;

    ${device.mediumViewport} {
      flex-direction: unset;
    }

    .files-list {
      width: fit-content;
    }

    & > div:last-child {
      display: flex;
      flex-direction: row-reverse;
      gap: 16px;
    }
  }
`;

export const TextInputWrapper = styled.div`
  width: 320px;

  ${device.mediumViewport} {
    width: 100%;
  }

  .TextInputWithLabel-wrapper input {
    margin-top: 0;
    height: 40px;
  }
`;
