import { IPPMListItem, IPreProductionMeetingForm } from './ppm';
import { IQualityDashboardState } from './dashboard';
import { ActionMetaOperationsEnum } from '../components/constants';
import { ObjectType } from './defined';

export interface IQualityAssuranceListItem {
  type: string;
  brand: {
    id: string;
    name: string;
    slug: string;
  };
  supplier: {
    id: string;
    name: string;
    slug: string;
  };
  scheduled_date: string;
  scheduled_time: string;
  location: string | null;
  assignee: string | number;
  process_step_ids: Array<string | number>;
  process_step: Array<string | number>;
  id: number;
  order_id: number;
}

export interface ITabularData {
  ids: Array<number>;
  data: Record<number, ObjectType>;
  count: number;
  totalPages: number;
}

interface IPaginationInfo {
  count: number;
  totalPages: number;
}

export interface IPaginationPayload extends IPaginationInfo {
  key: 'ppmListPaginationInfo' | 'qualityAssuranceListPaginationInfo';
}

export interface IQualityReducer {
  ppm_staging: IPreProductionMeetingForm;
  qualityAssuranceList: Array<IQualityAssuranceListItem>;
  ppmList: Array<IPPMListItem>;
  qaTeamList: Array<IQATeamListItem>;
  isLoading: boolean;
  ppmListPaginationInfo: IPaginationInfo;
  qualityAssuranceListPaginationInfo: IPaginationInfo;
  qualityReports: ITabularData;
  dashboard: IQualityDashboardState;
  tabularData: ITabularData;
}

export interface IQATeamListItem {
  id: string | number;
  first_name: string | null;
  last_name: string | null;
}

export interface IReschedulePPMItem {
  data: string;
}

export enum QualityActionEnum {
  ChangePPMStaging = 'quality/ChangePPMStaging',
  SetBulkPPMStaging = 'quality/SetBulkPPMStaging',
  FetchManageQAListRequest = 'quality/FetchManageQAListRequest',
  FetchManageQAListFailure = 'quality/FetchManageQAListFailure',
  FetchManageQAListSuccess = 'quality/FetchManageQAListSuccess',
  FetchManagePPMListRequest = 'quality/FetchManagePPMListRequest',
  FetchManagePPMListFailure = 'quality/FetchManagePPMListFailure',
  FetchManagePPMListSuccess = 'quality/FetchManagePPMListSuccess',
  FetchQAListRequest = 'quality/FetchQAListRequest',
  FetchQAListFailure = 'quality/FetchQAListFailure',
  FetchQAListSuccess = 'quality/FetchQAListSuccess',
  AssignQARequest = 'quality/AssignQARequest',
  AssignQAFailure = 'quality/AssignQAFailure',
  AssignQASuccess = 'quality/AssignQASuccess',
  ReschedulePPMRequest = 'quality/ReschedulePPMRequest',
  ReschedulePPMFailure = 'quality/ReschedulePPMFailure',
  ReschedulePPMSuccess = 'quality/ReschedulePPMSuccess',
  SetPaginationInfo = 'quality/SetPaginationInfo',
  SetQualityReports = 'quality/SetQualityReports',
  ResetQualityReports = 'quality/ResetQualityReports',
  SetQualityReportsIsLoading = 'quality/SetQualityReportsIsLoading',
  SetDashboardSection = 'quality/SetDashboardSection',
  SetDashboardSubSection = 'quality/SetDashboardSubSection',
  SetDashboardFilterDates = 'quality/SetDashboardFilterDates',
  SetDashboardEntityFilters = 'quality/SetDashboardEntityFilters',
  SetTabularData = 'quality/SetTabularData',
  SetLoadingState = 'quality/SetLoadingState',
  UpdateTabularDataById = 'quality/UpdateTabularDataById',
  SetSearch = 'quality/SetSearch',
  SetDashboardSortConfig = 'quality/SetDashboardSortConfig',
  SetDashboardColumnFilters = 'quality/SetDashboardColumnFilters',
}

export type OperationType = 'append' | 'replace';

export type QualityActionType =
  | {
      type: QualityActionEnum.ChangePPMStaging;
      payload: { key: string; value: string };
    }
  | {
      type: QualityActionEnum.SetBulkPPMStaging;
      payload: Partial<IPreProductionMeetingForm>;
    }
  | {
      type: QualityActionEnum.FetchManageQAListRequest;
    }
  | {
      type: QualityActionEnum.FetchManageQAListFailure;
    }
  | {
      type: QualityActionEnum.FetchManageQAListSuccess;
      payload: Array<IQualityAssuranceListItem>;
      meta: {
        operation: ActionMetaOperationsEnum;
      };
    }
  | {
      type: QualityActionEnum.FetchManagePPMListRequest;
    }
  | {
      type: QualityActionEnum.FetchManagePPMListFailure;
    }
  | {
      type: QualityActionEnum.FetchManagePPMListSuccess;
      payload: Array<IPPMListItem>;
      meta: {
        operation: ActionMetaOperationsEnum;
      };
    }
  | {
      type: QualityActionEnum.FetchQAListFailure;
    }
  | {
      type: QualityActionEnum.FetchQAListRequest;
    }
  | {
      type: QualityActionEnum.FetchQAListSuccess;
      payload: Array<IQATeamListItem>;
    }
  | {
      type: QualityActionEnum.AssignQARequest;
    }
  | {
      type: QualityActionEnum.AssignQAFailure;
    }
  | {
      type: QualityActionEnum.AssignQASuccess;
      payload: IQualityAssuranceListItem;
    }
  | {
      type: QualityActionEnum.SetPaginationInfo;
      payload: IPaginationPayload;
    }
  | {
      type: QualityActionEnum.SetQualityReports;
      payload: ITabularData;
      meta: {
        operation: ActionMetaOperationsEnum;
      };
    }
  | {
      type: QualityActionEnum.SetQualityReportsIsLoading;
      payload: boolean;
    }
  | {
      type: QualityActionEnum.ResetQualityReports;
    }
  | {
      type: QualityActionEnum.SetDashboardSection;
      payload: IQualityDashboardState['section'];
    }
  | {
      type: QualityActionEnum.SetDashboardSubSection;
      payload: IQualityDashboardState['subSection'];
    }
  | {
      type: QualityActionEnum.SetDashboardFilterDates;
      payload: IQualityDashboardState['dateFilters'];
    }
  | {
      type: QualityActionEnum.SetDashboardEntityFilters;
      payload: IQualityDashboardState['entityFilters'];
    }
  | {
      type: QualityActionEnum.SetTabularData;
      payload: ITabularData;
      meta: {
        operation: OperationType;
      };
    }
  | {
      type: QualityActionEnum.SetLoadingState;
      payload: boolean;
    }
  | {
      type: QualityActionEnum.UpdateTabularDataById;
      payload: {
        id: number | string;
        data: ObjectType;
      };
    }
  | {
      type: QualityActionEnum.SetSearch;
      payload: string;
    }
  | {
      type: QualityActionEnum.SetDashboardSortConfig;
      payload: IQualityDashboardState['sortConfig'];
    }
  | {
      type: QualityActionEnum.SetDashboardColumnFilters;
      payload: IQualityDashboardState['columnFilters'];
    };

export interface IAttendees {
  brand_pocs: Array<ObjectType>;
  supplier_pocs: Array<ObjectType>;
  user_pocs: Array<ObjectType>;
}
