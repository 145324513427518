import styled from 'styled-components';

interface INavigationItemProps {
  isActive: boolean;
}

export const Wrapper = styled.div`
  width: 220px;
  margin-top: 24px;
  padding-left: 12px;
`;

export const NavigationSection = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  color: var(--manatee);
  margin-bottom: 12px;
  padding: 0 10px 0 24px;
  text-transform: uppercase;
`;

export const NavigationItem = styled.div<INavigationItemProps>`
  cursor: pointer;
  font-size: 14px;
  font-weight: ${props => (props.isActive ? '600' : '400')};
  color: ${props => (props.isActive ? 'var(--all-ports)' : 'var(--rhino)')};
  background-color: ${props => (props.isActive ? 'var(--bubbles)' : 'inherit')};
  padding: 12px 10px 12px 24px;
  &:hover {
    background-color: var(--bubbles);
  }
  transition: all ease 300;
`;

export const SectionDivider = styled.div`
  height: 1px;
  margin: 32px 0;
  background-color: var(--athens-gray);
`;
