import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 0;
`;

export const InputGroupWrapper = styled.div`
  gap: 12px;
  width: auto;
  border: solid 1px var(--loblolly);
  border-radius: 4px;
`;

export const HistorySection = styled.span`
  padding: 4px 0;
  margin: 8px;
`;

export const HistoryLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: var(--manatee);
`;

export const HistoryStatusText = styled.p<{ isRed: boolean }>`
  padding: 0 4px;
  font-weight: 500;
  font-size: 14px;
  color: ${props => (props.isRed ? 'var( --red-dirt)' : 'var(--pacific-blue)')};
`;

export const HistoryCommentsText = styled.p`
  padding: 0 4px;
  font-weight: 500;
  font-size: 14px;
  color: var(--lynch);
`;

export const HistoryAttachment = styled.div<{ backgroundColor: string }>`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 4px;

  button {
    visibility: hidden;
  }

  &:hover {
    background: ${props => props.backgroundColor};
    button {
      visibility: unset;
    }
  }
`;

export const HistoryAttachmentFileName = styled.p`
  width: 150px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 14px;
  color: var(--rhino);
`;

export const HistoryActionButton = styled.button`
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background: none;
  outline: none;
  padding: 0;

  &:hover {
    background: rgba(44, 53, 86, 0.1);
  }
`;

export const TextInputWrapper = styled.div`
  width: 320px;

  .TextInputWithLabel-wrapper input {
    margin-top: 0;
    height: 40px;
  }
`;

export const Thumbnail = styled.div<{ isSmall?: boolean }>`
  height: ${({ isSmall }) => (isSmall ? '50px' : '300px')};
  width: auto;
  border-radius: 2px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 2px;
  }
`;
