import React, { useContext } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { PPMFormContext } from '../index';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { PPMFormSectionsEnum } from '../types';
import BorderedGreenTick from '../../../shared/iconography/BorderedGreenTick';
import * as Styles from './styles';

interface INavigationModalTriggerProps {
  isModalOpen: boolean;
  modalOpenActions: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  section: PPMFormSectionsEnum;
}

const NavigationModalTrigger = ({
  isModalOpen,
  section,
  modalOpenActions,
}: INavigationModalTriggerProps) => {
  const { checkSectionCompleteness } = useContext(PPMFormContext);

  const isSectionComplete = checkSectionCompleteness(section);

  return (
    <Styles.NavigationModalTrigger
      className="flex-start-center"
      onClick={() => {
        modalOpenActions.on();
      }}
      isHighlighted={isSectionComplete}
    >
      <Styles.CheckContainer className="center">
        <BorderedGreenTick isHighlighted={isSectionComplete} />
      </Styles.CheckContainer>
      {PPM_FORM_SECTION_HEADINGS[section]}
      <Styles.ChevronContainer>
        {isModalOpen ? (
          <FaChevronUp color="var(--lynch)" />
        ) : (
          <FaChevronDown color="var(--lynch)" />
        )}
      </Styles.ChevronContainer>
    </Styles.NavigationModalTrigger>
  );
};

export default NavigationModalTrigger;
