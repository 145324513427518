import React, { ChangeEvent, useEffect, useState } from 'react';
import { FiAlertCircle, FiCheck, FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { Toast } from '@fashinza/fashinza-design-system';
import { ReviewOperationsEnum } from '../../constants';
import { ResultActionEnum } from '../../ManagePPM/constants';
import {
  getFinalInspectionReportHtml,
  reviewFinalInspectionReport,
} from '../../../redux/actions/thenables';
import useBoolean from '../../../hooks/useBoolean';
import { ApiResponseEnum } from '../../../@types/api';
import ModalHoc from '../../../shared/ModalHoc';
import Buttons from './Buttons';
import * as Styles from '../styles';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type ReviewPPMParamsType = {
  finalInspectionId: string;
};

const ReviewFinalInspection = () => {
  const dispatch = useDispatch();

  const { finalInspectionId } = useParams<ReviewPPMParamsType>();
  const [isIframeLoading, iframeLoadingActions] = useBoolean(true);
  const [isConfirmModalVisible, confirmModalLoadingActions] = useBoolean(false);
  const [finalInspectionStatus, setFinalInspectionApprovalStatus] =
    useState<ReviewOperationsEnum>(ReviewOperationsEnum.REJECTED);

  const [reason, setReason] = useState<string>();
  const [content, setContent] = useState<string>();
  const [isApprovedStatus, setApprovedStatus] = useState<boolean | null>(null);
  const [remarksValue, setRemarksValue] = useState<string>('');

  async function fetchFinalInspectionReport() {
    if (!finalInspectionId) {
      return;
    }

    iframeLoadingActions.on();
    const response = await getFinalInspectionReportHtml(finalInspectionId);
    if (response.type === ApiResponseEnum.Success) {
      const { html, is_approved: isApproved, remarks } = response.data;
      setContent(html);
      setApprovedStatus(isApproved);
      setRemarksValue(remarks);
    } else {
      Toast.ERROR(response.error);
    }
    iframeLoadingActions.off();
  }

  useEffect(() => {
    fetchFinalInspectionReport();
  }, []);

  function onChangeReason(event: ChangeEvent<HTMLTextAreaElement>) {
    setReason(event.target.value);
  }

  async function reviewFinalInspection(action: ReviewOperationsEnum) {
    if (!finalInspectionId) {
      return;
    }

    const apiPayload = {
      review_remark: reason,
      review_result: action,
    };

    const response = await reviewFinalInspectionReport(
      finalInspectionId,
      apiPayload
    );

    if (response.type === ApiResponseEnum.Success) {
      Toast.SUCCESS(`Final Inspection Report ${action}`);
      fetchFinalInspectionReport();
    } else {
      Toast.ERROR(response.error);
    }
    confirmModalLoadingActions.off();
  }

  function onOpenModal(status: ReviewOperationsEnum) {
    setFinalInspectionApprovalStatus(status);
    confirmModalLoadingActions.on();
  }

  function onCloseModal() {
    confirmModalLoadingActions.off();
  }

  function getStatusText() {
    switch (isApprovedStatus) {
      case true:
        return ResultActionEnum.Approved;
      default:
        return ResultActionEnum.Rejected;
    }
  }

  if (!finalInspectionId) {
    dispatch(replace(`/quality?section=final-inspection`));
  }

  return (
    <Styles.Wrapper className="d-flex full-dimension">
      {isConfirmModalVisible && (
        <ModalHoc onClose={onCloseModal}>
          <Styles.ModalWrapper>
            <Styles.HeaderText>
              Make sure you are approving/rejecting correctly with confirm and
              back button
            </Styles.HeaderText>
            <Buttons
              submitText="Confirm"
              onCancel={onCloseModal}
              onSubmit={() => reviewFinalInspection(finalInspectionStatus)}
              isInvalidState={false}
            />
          </Styles.ModalWrapper>
        </ModalHoc>
      )}
      <Styles.Iframe
        title="ppm-report-preview"
        style={{
          visibility: isIframeLoading ? 'hidden' : 'visible',
        }}
        frameBorder="0"
        srcDoc={content}
      />
      <Styles.RightPanel className="v-d-flex">
        {isApprovedStatus === null && (
          <Styles.Warning className="d-flex">
            <Styles.WarningSection>
              <FiAlertCircle size={14} color="var(--rhino)" />
            </Styles.WarningSection>
            <Styles.WarningSection className="v-d-flex">
              <Styles.WarningHeading>
                This action is irreversible
              </Styles.WarningHeading>
              <Styles.WarningText>
                Make sure you are approving/rejecting correctly
              </Styles.WarningText>
            </Styles.WarningSection>
          </Styles.Warning>
        )}
        <Styles.TextArea
          placeholder="Please add remarks"
          value={isApprovedStatus === null ? reason : remarksValue}
          disabled={isApprovedStatus != null}
          onChange={onChangeReason}
          isApproved={Boolean(isApprovedStatus)}
        />
        {isApprovedStatus === null && (
          <>
            <Styles.AcceptButton
              className="center"
              onClick={() => onOpenModal(ReviewOperationsEnum.ACCEPTED)}
            >
              Approve <FiCheck size={20} color="var(--white)" />
            </Styles.AcceptButton>
            <Styles.RejectButton
              className="center"
              onClick={() => onOpenModal(ReviewOperationsEnum.REJECTED)}
            >
              Reject <FiX size={20} color="var(--red-dirt)" />
            </Styles.RejectButton>
          </>
        )}
        {isApprovedStatus !== null && (
          <div className="align-center">
            Report is &nbsp;
            <Styles.Status isApprovedStatus={isApprovedStatus}>
              {getStatusText()}
            </Styles.Status>
          </div>
        )}
      </Styles.RightPanel>
    </Styles.Wrapper>
  );
};

export default ReviewFinalInspection;
