import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
// import MobileHeader from 'components/common/Header/MobileHeader';
import { useHashIndex } from '../../helperHooks';
import Material from '../Material';
import Trim from '../Trim';
import ProductionWorksheet from '../ProductionWorksheet';
import SewingCheckPoints from '../SewingCheckPoints';
import PendingIssuesAndApprovals from '../PendingIssuesAndApprovals';
import Others from '../Others';
import ProductionPlanning from '../ProductionPlanning';
import PreProductionPreparation from '../PreProductionPreparation';
import CuttingCheckpoints from '../CuttingCheckpoints';
import FinishingPackingCheckpoints from '../FinishingPackingCheckpoints';
import ManageAttendees from '../ManageAttendees';
import SampleDetails from '../SampleDetails';
import SubmitForm from '../SubmitForm';
import { PPMFormSectionsEnum } from '../types';
import { PPMFormContext } from '../index';
import NavigationModalTrigger from './NavigationModalTrigger';
import NavigationModal from './NavigationModal';
import * as Styles from './styles';
import useBoolean from '../../../hooks/useBoolean';
import { Button, Toast } from '@fashinza/fashinza-design-system';

const PPMFormMobileView = () => {
  const { hash: initialHash } = useLocation();
  const { onNext, onBack, hashValue, setHashValue } = useHashIndex();

  const {
    updatePpmForm,
    sectionLoaders,
    inCompleteFields,
    attendeesContext: { getIsAttendeesSubmitButtonDisabled },
  } = useContext(PPMFormContext);

  const [isModalOpen, modalOpenActions] = useBoolean();

  const [section, setSection] = useState<PPMFormSectionsEnum>(
    PPMFormSectionsEnum.MATERIAL
  );

  function updateSection(newSection: PPMFormSectionsEnum) {
    if (section !== newSection) {
      setSection(newSection);
    }
  }

  useEffect(() => {
    if (initialHash.length === 0) return;
    setHashValue(initialHash.replace('#', '') as PPMFormSectionsEnum);
  }, []);

  function renderSection() {
    switch (hashValue) {
      case PPMFormSectionsEnum.MATERIAL: {
        updateSection(PPMFormSectionsEnum.MATERIAL);
        return <Material />;
      }

      case PPMFormSectionsEnum.TRIM: {
        updateSection(PPMFormSectionsEnum.TRIM);
        return <Trim />;
      }

      case PPMFormSectionsEnum.SAMPLE_DETAILS: {
        updateSection(PPMFormSectionsEnum.SAMPLE_DETAILS);
        return <SampleDetails />;
      }

      case PPMFormSectionsEnum.PRODUCTION_WORKSHEET: {
        updateSection(PPMFormSectionsEnum.PRODUCTION_WORKSHEET);
        return <ProductionWorksheet />;
      }

      case PPMFormSectionsEnum.PRODUCTION_PLANNING: {
        updateSection(PPMFormSectionsEnum.PRODUCTION_PLANNING);
        return <ProductionPlanning />;
      }

      case PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION: {
        updateSection(PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION);
        return <PreProductionPreparation />;
      }

      case PPMFormSectionsEnum.CUTTING_CHECKPOINTS: {
        updateSection(PPMFormSectionsEnum.CUTTING_CHECKPOINTS);
        return <CuttingCheckpoints />;
      }

      case PPMFormSectionsEnum.SEWING_CHECKPOINTS: {
        updateSection(PPMFormSectionsEnum.SEWING_CHECKPOINTS);
        return <SewingCheckPoints />;
      }

      case PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS: {
        updateSection(PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS);
        return <FinishingPackingCheckpoints />;
      }

      case PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS: {
        updateSection(PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS);
        return <PendingIssuesAndApprovals />;
      }

      case PPMFormSectionsEnum.OTHERS: {
        updateSection(PPMFormSectionsEnum.OTHERS);
        return <Others />;
      }

      case PPMFormSectionsEnum.MANAGE_ATTENDEES: {
        updateSection(PPMFormSectionsEnum.MANAGE_ATTENDEES);
        return <ManageAttendees />;
      }

      case PPMFormSectionsEnum.SUBMIT_FORM: {
        updateSection(PPMFormSectionsEnum.SUBMIT_FORM);
        return <SubmitForm setHashValue={setHashValue} />;
      }

      default: {
        updateSection(PPMFormSectionsEnum.MATERIAL);
        return <Material />;
      }
    }
  }

  function onSaveData() {
    if (section === PPMFormSectionsEnum.MANAGE_ATTENDEES) {
      const doNotProceed = getIsAttendeesSubmitButtonDisabled();

      if (doNotProceed) {
        Toast.WARN('Fill all the fields correctly');
        return;
      }
    }

    updatePpmForm(section, onNext);
  }

  const isLoading = sectionLoaders[section];

  return (
    <Styles.Wrapper>
      {/* TODO:use MobileHeader */}
      {/* <MobileHeader leftSideJSX="PPM" showIcons={false} hasSideNav /> */}
      <Styles.Content>
        <NavigationModalTrigger
          isModalOpen={isModalOpen}
          modalOpenActions={modalOpenActions}
          section={section}
        />
        {renderSection()}
      </Styles.Content>
      <Styles.Footer className="spread">
        <Styles.RoundButton className="center" onClick={onBack}>
          <FaChevronLeft size={24} color="var(--white)" />
        </Styles.RoundButton>
        <Styles.RoundButton className="center" onClick={onSaveData}>
          <FaChevronRight size={24} color="var(--white)" />
        </Styles.RoundButton>
        <Button
          isLoading={isLoading}
          onClick={onSaveData}
          isDisabled={
            section === PPMFormSectionsEnum.SUBMIT_FORM
              ? inCompleteFields.length > 0
              : false
          }
        >
          {section === PPMFormSectionsEnum.SUBMIT_FORM
            ? 'Submit PPM form'
            : 'Save and Continue'}
        </Button>
      </Styles.Footer>
      <NavigationModal
        isModalOpen={isModalOpen}
        modalOpenActions={modalOpenActions}
        section={section}
        setHashValue={setHashValue}
        setSection={setSection}
      />
    </Styles.Wrapper>
  );
};

export default PPMFormMobileView;
