import React from "react";
import FinalInspectionAnalytics from "./FinalInspectionAnalytics";
import SampleInspectionAnalytics from "./SampleInspectionAnalytics";
import { AnalyticsWrapper } from "../styles";
import { useTypedSelector } from "../../../hooks";

const Analytics = () => {
  const section = useTypedSelector((store) => store.quality.dashboard.section);

  switch (section) {
    case "final-inspection": {
      return (
        <AnalyticsWrapper>
          <FinalInspectionAnalytics />
        </AnalyticsWrapper>
      );
    }

    case "sample-inspection": {
      return (
        <AnalyticsWrapper>
          <SampleInspectionAnalytics />
        </AnalyticsWrapper>
      );
    }

    default: {
      return null;
    }
  }
};

export default Analytics;
