import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Avatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background: var(--butter-milk);
  font-weight: 600;
  font-size: 14px;
  color: var(--rhino);
  flex-shrink: 0;
`;

export const Body = styled.div`
  max-width: 156px;
`;

export const Name = styled.p`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  color: var(--rhino);
  max-width: 156px;
`;

export const BottomText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: var(--lynch);
`;
