/* eslint-disable default-param-last */
import { ICommonReducer } from '../../../@types/shared';

const INITIAL_STATE: ICommonReducer = {
  searchedEntity: {},
  users_list: [],
};

const commonReducer = (
  state = INITIAL_STATE,
  action: { type: string; data: any }
) => {
  switch (action.type) {
    case 'common/SET_SEARCHED_ENTITY_LIST': {
      return {
        ...state,
        searchedEntity: {
          ...state.searchedEntity,
          ...action.data,
        },
      };
    }

    default:
      return state;
  }
};

export default commonReducer;
