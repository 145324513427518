import React from 'react';
import PreferencesDropdown from './PreferencesDropdown';
import { AllFieldsType } from './types';
import useBoolean from '../../hooks/useBoolean';
import ICONOGRAPHY from '../iconography';
import ClickOutside from '../ClickOutside';
import * as Styles from './styles';

const { cog: CogIcon } = ICONOGRAPHY;

interface IColumnPreferencesProps {
  allFields: AllFieldsType;
  preferencesList: Array<string>;
  onApply(modifiedPreferencesList: Array<string>): void;
  wrapperProps?: Record<string, string>;
}

const ColumnPreferences = ({
  allFields,
  preferencesList,
  onApply,
  wrapperProps = {},
}: IColumnPreferencesProps) => {
  const [isDropdownOpen, dropdownOpenActions] = useBoolean();

  function handleClose() {
    dropdownOpenActions.off();
  }

  return (
    <Styles.PreferenceWrapper
      role="presentation"
      className="cursor-pointer center pos-r"
      isActive={isDropdownOpen}
      onClick={() => {
        console.log('OPENING PREFS');
        dropdownOpenActions.toggle();
      }}
      {...wrapperProps}
    >
      <CogIcon size={20} stroke="var(--rhino)" />
      {isDropdownOpen && (
        <ClickOutside
          onClose={() => {
            console.log('CLOSING PREFS');
            handleClose();
          }}
        >
          <PreferencesDropdown
            allFields={allFields}
            preferencesList={preferencesList}
            onApply={modifiedPreferencesList => {
              onApply(modifiedPreferencesList);
              handleClose();
            }}
          />
        </ClickOutside>
      )}
    </Styles.PreferenceWrapper>
  );
};

export default ColumnPreferences;
