import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@fashinza/fashinza-design-system';
import { useTypedSelector } from '../../../../hooks';
import { setDashboardEntityFilters } from '../../../../redux/actions/dashboard';
import { IQualityDashboardState } from '../../../../@types/dashboard';
import { IBooleanActions } from '../../../../hooks/useBoolean';
import DropdownWithSearch, {
  IOption,
} from '../../../../shared/DropdownWithSearch';
import * as Styles from './styles';
import { swallowEvent } from '../../../../utils';

const INITIAL_FILTER_STATE = {
  brand: null,
  supplier: null,
  inspector: null,
};

interface IEntityFilterProps {
  onClose: VoidFunction;
  entityFilterClickOutsideActions: IBooleanActions;
}

const EntityFilter = ({
  onClose,
  entityFilterClickOutsideActions,
}: IEntityFilterProps) => {
  const dispatch = useDispatch();

  const {
    is_brand: isBrand,
    is_supplier: isSupplier,
    is_admin: isAdmin,
  } = useTypedSelector(store => store.user);
  const section = useTypedSelector(store => store.quality.dashboard.section);

  const entityFilters = useTypedSelector(
    store => store.quality.dashboard.entityFilters
  );
  const users = useTypedSelector(store =>
    store.common.users_list.map(item => ({
      id: item.id,
      name: `${item.first_name} ${item.last_name}`,
    }))
  );
  const brands = useTypedSelector(store =>
    store.orders.brand_list.filter(item => item.name.length !== 0)
  );
  const suppliers = useTypedSelector(store =>
    store.orders.supplier_list.filter(item => item.name.length !== 0)
  );

  const [{ brand, inspector, supplier }, setFilters] =
    useState<IQualityDashboardState['entityFilters']>(INITIAL_FILTER_STATE);

  useEffect(() => {
    setFilters(entityFilters);
  }, []);

  function onChangeFilter(newSelection: IOption, key: string) {
    setFilters(prevState => ({
      ...prevState,
      [key]: newSelection,
    }));
  }

  function onClickReset() {
    setFilters(INITIAL_FILTER_STATE);
    dispatch(setDashboardEntityFilters(INITIAL_FILTER_STATE));
  }

  function onClickApply() {
    dispatch(setDashboardEntityFilters({ brand, inspector, supplier }));
    onClose();
  }

  const isPpm = section === 'pre-production-meeting';

  return (
    <Styles.Wrapper
      className="v-d-flex"
      onMouseEnter={() => {
        console.log('disabling out click');
        entityFilterClickOutsideActions.off();
      }}
      onMouseLeave={() => {
        console.log('enabling out click');
        entityFilterClickOutsideActions.on();
      }}
      onClick={swallowEvent}
    >
      <Styles.Content className="v-d-flex">
        {!isBrand && (
          <Styles.DropdownWrapper className="v-d-flex">
            <Styles.DropdownLabel>Brand</Styles.DropdownLabel>
            <DropdownWithSearch
              onSelectOption={newSelection =>
                onChangeFilter(newSelection, 'brand')
              }
              options={brands}
              // value={brand}
              // dataname="brand"
              placeholder="Brand"
              // datalabel="Brand"
              // showAllOption
            />
          </Styles.DropdownWrapper>
        )}
        {!isSupplier && (
          <Styles.DropdownWrapper className="v-d-flex">
            <Styles.DropdownLabel>Supplier</Styles.DropdownLabel>
            <DropdownWithSearch
              onSelectOption={newSelection =>
                onChangeFilter(newSelection, 'supplier')
              }
              options={suppliers}
              // value={supplier}
              // dataname="supplier"
              placeholder="Supplier"
              // datalabel="Supplier"
              // showAllOption
            />
          </Styles.DropdownWrapper>
        )}
        {isAdmin && !isPpm && (
          <Styles.DropdownWrapper className="v-d-flex">
            <Styles.DropdownLabel>Inspector</Styles.DropdownLabel>
            <DropdownWithSearch
              onSelectOption={newSelection =>
                onChangeFilter(newSelection, 'inspector')
              }
              options={users}
              // preSelected={{ id: inspector }}
              // dataname="inspector"
              placeholder="Inspector"
              // datalabel="Inspector"
              // showAllOption
            />
          </Styles.DropdownWrapper>
        )}
      </Styles.Content>
      <Styles.Footer className="d-flex">
        <Button onClick={onClickReset} sizeVariant="small" variant="tertiary">
          Reset Filters
        </Button>
        <Button onClick={onClickApply} sizeVariant="small">
          Apply
        </Button>
      </Styles.Footer>
    </Styles.Wrapper>
  );
};

export default EntityFilter;
