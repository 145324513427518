import React, { ChangeEvent, ReactNode } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";
import { Toast } from "@fashinza/fashinza-design-system";
import { useFileUploadContext } from "../helperHooks";
import { UploadStatusEnum } from "../../../enums";
import { IAWSAttachmentType } from "../../../@types/aws";
import { useTypedSelector } from "../../../hooks";
import { uploadFile } from "../../../utils/aws";
import * as Styles from "./styles";

interface IUploadButtonProps {
  labelText?: ReactNode;
  isError?: boolean;
  viewType?: "separated" | "single";
  isColor?: boolean;
  uploadButtonClass?: string;
}

const UploadButton = ({
  labelText,
  isError,
  viewType = "separated",
  isColor = false,
}: IUploadButtonProps) => {
  const credentials = useTypedSelector((state) => state.credentials.tna);
  const {
    fileInput,
    isUploading,
    uploadingActions,
    accept,
    sendFilesToCallback,
    showUploadIcon = true,
    maxFiles,
  } = useFileUploadContext();

  async function onUpload(event: ChangeEvent<HTMLInputElement>) {
    try {
      const { files } = event.target;
      if (files) {
        uploadingActions.on();
        const fileList = Array.from(files);

        const filesModified = [...fileList];
        const fileArray = filesModified.map((file) => ({
          name: file.name,
          link: "",
          type: "file-type",
          status: UploadStatusEnum.Loading,
          mime_type: file.type,
        }));

        const newFileArray = [...fileInput, ...fileArray];
        if (maxFiles !== undefined && newFileArray.length > maxFiles) {
          Toast.ERROR(`Maximum file upload limit is ${maxFiles}!`);
          uploadingActions.off();
          return;
        }
        sendFilesToCallback(newFileArray, "uploading");

        let filePromises: Array<Promise<IAWSAttachmentType>> = [];
        filesModified.forEach((file) => {
          const promise = uploadFile({ file, credentials });
          if (promise) {
            filePromises = [...filePromises, promise];
          }
        });
        const uploadedFiles = await Promise.allSettled(filePromises);
        uploadingActions.off();
        const dataModified = newFileArray.map((stateValue) => {
          const foundData = uploadedFiles.find(
            (uploadData) =>
              uploadData.status === "fulfilled" &&
              uploadData.value.name === stateValue.name
          );

          if (
            foundData?.status === "fulfilled" &&
            stateValue.status !== "SUCCESS"
          ) {
            return {
              name: foundData.value.name,
              link: foundData.value.url,
              type: "file-type",
              status: UploadStatusEnum.Success,
              mime_type: foundData.value.type,
            };
          }
          return stateValue;
        });

        sendFilesToCallback(dataModified, "success");
      }
    } catch (err) {
      console.log("[error][fileDocumentItem]: ", err);
      uploadingActions.off();
    }
  }

  return (
    <div className="align-center">
      <Styles.Label
        className="cursor-pointer center"
        isDisabled={isUploading}
        isError={isError}
        viewType={viewType}
        data-view-type="upload-button-label"
      >
        {isUploading ? (
          <Styles.Spinner
            size="24"
            fill={viewType === "single" ? "var(--lynch)" : "var(--white)"}
          />
        ) : (
          <>
            <input
              type="file"
              onChange={onUpload}
              disabled={isUploading}
              multiple
              accept={accept}
              style={{ display: "none" }}
            />
            {viewType === "single" ? (
              <>
                {showUploadIcon && (
                  <GrAttachment
                    size="14"
                    strokeWidth="4px"
                    fill="var(--manatee)"
                    data-view-type="attachment"
                  />
                )}
                {typeof labelText !== "undefined" && (
                  <Styles.Text isColor={isColor}>{labelText}</Styles.Text>
                )}
              </>
            ) : (
              <AiOutlinePlusCircle
                size="14"
                strokeWidth="4px"
                data-view-type="attachment"
              />
            )}
          </>
        )}
      </Styles.Label>
      {typeof labelText !== "undefined" && viewType === "separated" && (
        <Styles.Text isColor={isColor}>{labelText}</Styles.Text>
      )}
    </div>
  );
};

export default UploadButton;
