import { useEffect } from 'react';

import {
  QUALITY_DASHBOARD_BROADCAST_CHANNEL,
  QUALITY_DASHBOARD_BROADCAST_CHANNEL_MESSAGES,
} from './constants';
import { ObjectType } from '../../@types/defined';
import { useBroadcast } from '../QualityApprovalViewer/useBroadcast';

const useBroadcastChannelListener = () => {
  const qualityDashboardChannel = useBroadcast(
    QUALITY_DASHBOARD_BROADCAST_CHANNEL
  );

  useEffect(() => {
    if (qualityDashboardChannel) {
      qualityDashboardChannel.onmessage = (event: ObjectType) => {
        if (
          event.data === QUALITY_DASHBOARD_BROADCAST_CHANNEL_MESSAGES.REFRESH
        ) {
          window.location.reload();
        }
      };
    }
  }, [qualityDashboardChannel]);
};

export default useBroadcastChannelListener;
