import React from 'react';
import { IconPropsType } from './types';

interface ICrossBlackProps extends IconPropsType {}

const CrossBlack = ({
  fill = 'var(--rhino)',
  ...restProps
}: ICrossBlackProps) => (
  <svg width={16} height={16} fill="none" viewBox="0 0 20 20" {...restProps}>
    <path
      fill={fill}
      d="M13.986 15L15 13.986 11.014 10 15 6.014 13.986 5 10 8.986 6.014 5 5 6.014 8.986 10 5 13.986 6.014 15 10 11.014 13.986 15z"
    />
  </svg>
);

export default CrossBlack;
