import { z } from 'zod';

export const DashboardSectionsSchema = z.union([
  z.literal('final-inspection'),
  z.literal('fabric-inspection'),
  z.literal('sample-inspection'),
  z.literal('pre-production-meeting'),
  z.literal('manage-qa'),
]);

export type DashboardSectionsType = z.infer<typeof DashboardSectionsSchema>;

export const DashboardSubSectionsSchema = z
  .union([
    z.literal('all-samples'),
    z.literal('pp-sample'),
    z.literal('size-set'),
    z.literal('fit-sample'),
    z.literal('lab-fit'),
    z.literal('strike-off'),
    z.literal('top-sample'),
    z.literal('shipment-sample'),
    z.literal('photo-shoot-sample'),
  ])
  .nullable();

export type DashboardSubSectionsType = z.infer<
  typeof DashboardSubSectionsSchema
>;

export const DateFilterTextsSchema = z.union([
  z.literal('This week'),
  z.literal('Last 2 weeks'),
  z.literal('This month'),
  z.literal('Last 2 months'),
  z.literal('Last 3 months'),
  z.literal('Last 6 months'),
  z.literal('Custom Date'),
]);

export type DateFilterTextsType = z.infer<typeof DateFilterTextsSchema>;

export interface IQualityDashboardState {
  section: DashboardSectionsType | null;
  subSection: DashboardSubSectionsType;
  dateFilters: {
    text: DateFilterTextsType | null;
    startDate: Date | null;
    endDate: Date | null;
  };
  entityFilters: {
    brand: number | null;
    supplier: number | null;
    inspector: number | null;
  };
  columnFilters: Record<string, string>;
  sortConfig: Record<string, string>;
  searchText: string;
}
