import React from 'react';
import { swallowEvent } from '../../../utils';
import { useCustomPopper } from '../../../hooks/useCustomPopper';
import * as Styles from './styles';
import CrossBlack from '../../iconography/CrossBlack';

const POPPER_OPTIONS = Object.freeze({
  strategy: 'fixed',
  placement: 'auto',
});

interface IAbsoluteCrossProps {
  onDelete(): void;
}

const AbsoluteCross = ({ onDelete }: IAbsoluteCrossProps) => {
  const popperAttributes = useCustomPopper(POPPER_OPTIONS);

  return (
    <>
      <Styles.AbsoluteCrossReferenceWrapper {...popperAttributes.reference} />

      <Styles.AbsoluteCrossWrapper
        {...popperAttributes.fixed}
        className="v-center cursor-pointer"
        onClick={event => {
          onDelete();
          swallowEvent(event);
        }}
      >
        <CrossBlack height="12px" width="12px" stroke="var(--lynch)" />
      </Styles.AbsoluteCrossWrapper>
    </>
  );
};

export default AbsoluteCross;
