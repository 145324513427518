import React from "react";
import { IconPropsType } from "./types";

interface IFabricIconProps extends IconPropsType {}

const FabricIcon = ({
  size = 24,
  stroke = "var(--rhino)",
  strokeWidth = 1.25,
  ...props
}: IFabricIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.85714 20C8.33294 19.2875 9.51097 18.4984 10.5194 17.6711M21 8.96552C16.9619 10.59 15.1532 12.9798 12.9482 15.3463M7.71429 4C12.6966 6.36305 13.8282 8.10644 15.4286 10.2412M20.1429 15.0345C19.1219 14.2458 18.3171 13.5268 17.6529 12.8596M17.1429 4C15.7322 4.57348 14.5986 5.16341 13.6514 5.76698M3 15.0345C7.40628 12.6312 8.6528 10.2305 10.9225 7.96255M3.42857 9.51724C4.53072 9.82128 5.38703 10.2184 6.1011 10.6915M16.2857 20C11.6914 17.5097 10.0986 15.0194 8.50514 13.0245"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FabricIcon;
