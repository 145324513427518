import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'cutting_start_date',
    startDateSlug: 'cutting_start_date',
    endDateSlug: 'cutting_end_date',
    choiceSlug: 'cutting_not_available',
    label: 'Cutting',
    inputType: InputTypeEnum.DATE_INTERVAL,
  },
  {
    id: 'sewing_start_date',
    startDateSlug: 'sewing_start_date',
    endDateSlug: 'sewing_end_date',
    choiceSlug: 'sewing_not_available',
    label: 'Sewing',
    inputType: InputTypeEnum.DATE_INTERVAL,
  },
  {
    id: 'washing_start_date',
    startDateSlug: 'washing_start_date',
    endDateSlug: 'washing_end_date',
    choiceSlug: 'washing_not_available',
    label: 'Washing',
    inputType: InputTypeEnum.DATE_INTERVAL,
  },
  {
    id: 'finishing_start_date',
    startDateSlug: 'finishing_start_date',
    endDateSlug: 'finishing_end_date',
    choiceSlug: 'finishing_not_available',
    label: 'Finishing',
    inputType: InputTypeEnum.DATE_INTERVAL,
  },
  {
    id: 'packing_start_date',
    startDateSlug: 'packing_start_date',
    endDateSlug: 'packing_end_date',
    choiceSlug: 'packing_not_available',
    label: 'Packing',
    inputType: InputTypeEnum.DATE_INTERVAL,
  },
  {
    id: 'lines_allocated_count',
    label: 'Lines allocated (count)',
    inputType: InputTypeEnum.NUMERIC_TEXT,
  },
  {
    id: 'estimated_daily_sewing_output',
    label: 'Estimated Daily Sewing Output',
    inputType: InputTypeEnum.NUMERIC_TEXT,
  },
];
