import { BiLoaderAlt } from "react-icons/bi";
import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: var(--white);
`;

export const MainContent = styled.div`
  padding: 24px;
  /* height: 87.5vh; */
`;

export const RightSideContent = styled.div`
  padding: 24px 24px 24px 12px;
  max-height: 100vh;
  min-height: 100vh;
`;

export const Spinner = styled(BiLoaderAlt)`
  animation: rotate360 1s linear infinite;
`;
