import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: var(--wash-me);
`;

export const Content = styled.div`
  height: calc(90vh - 60px);
  overflow: scroll;
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--white);
  gap: 24px;
  padding: 16px 24px;
  border: 1px solid var(--athens-gray);
`;

export const RoundButton = styled.button`
  height: 48px;
  width: 48px;
  min-width: 48px;
  background: var(--pacific-blue);
  border: none;
  outline: none;
  border-radius: 100%;
  padding: 0;
`;

export const NavigationModalWrapper = styled.div`
  background: rgba(44, 53, 86, 0.6);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: 10;
`;

export const NavigationModal = styled(motion.div)`
  background: var(--white);
  opacity: 1;
  height: fit-content;
  width: 100vw;
  position: fixed;
  bottom: 0;
  border-radius: 12px 12px 0px 0px;
  padding: 24px 0 0 0;
`;

export const NavigationItem = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: var(--rhino);
  padding: 12px 24px 12px 16px;
  width: 100%;
  text-align: left;
  gap: 8px;

  &:focus {
    font-weight: 500;
  }
`;

export const CheckContainer = styled.div`
  width: 16px;
`;

export const NavigationFooter = styled.footer`
  border-top: 1px solid var(--athens-gray);
  padding: 12px 0;
`;

export const CloseModalButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  height: 26px;
  width: 26px;
  border-radius: 100%;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
`;

export const NavigationModalTrigger = styled.button<{ isHighlighted: boolean }>`
  font-weight: 500;
  font-size: 14px;
  border: 1px solid var(--athens-gray);
  position: sticky;
  top: 0;
  width: 100vw;
  color: ${(props) =>
    props.isHighlighted ? "var(--pacific-blue)" : "var(--lynch)"};
  padding: 12px 24px;
  background: var(--white);
  z-index: 2;
  gap: 24px;

  &:focus {
    font-weight: 500;
  }
`;

export const ChevronContainer = styled.span`
  flex-grow: 1;
  svg {
    float: right;
  }
`;
