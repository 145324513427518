import React, { Key, CSSProperties } from 'react';
import {
  AreaChart as ReChartsAreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

interface IProps<T extends Record<string, unknown>> {
  data: Array<T>;
  areaData: Array<{
    dataKey: keyof T & Key;
    stroke: CSSProperties['stroke'];
    strokeWidth?: CSSProperties['strokeWidth'];
    gradientColor?: CSSProperties['stopColor'];
    gradientOpacity?: CSSProperties['stopOpacity'];
  }>;
  xAxisDataKey: keyof T & Key;
  height?: number;
  width?: number;
  className?: string;
  showTick?: boolean;
}

const AreaChart = <T extends Record<string, unknown>>({
  data,
  xAxisDataKey,
  areaData,
  height,
  width,
  className,
  showTick = true,
}: IProps<T>) => (
  <ReChartsAreaChart
    className={className}
    width={width}
    height={height}
    data={data}
  >
    <defs>
      {areaData.map(eachData => (
        <linearGradient
          key={eachData.dataKey}
          id={`color${eachData.dataKey}`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop
            offset="5%"
            stopColor={eachData.gradientColor ?? eachData.stroke}
            stopOpacity={eachData.gradientOpacity ?? 0.25}
          />
          <stop
            offset="95%"
            stopColor={eachData.gradientColor ?? eachData.stroke}
            stopOpacity={0}
          />
        </linearGradient>
      ))}
    </defs>
    <CartesianGrid vertical={false} />
    <XAxis
      axisLine={false}
      tickLine={false}
      dataKey={xAxisDataKey}
      style={{
        fontSize: '12px',
        fontWeight: 400,
      }}
      tickMargin={8}
      scale="point"
      interval="preserveStartEnd"
      tick={showTick}
    />
    <Tooltip />
    {areaData.map(eachData => (
      <Area
        key={eachData.dataKey}
        type="monotone"
        dataKey={eachData.dataKey}
        stroke={eachData.stroke}
        fill={`url(#color${eachData.dataKey})`}
        strokeWidth={eachData.strokeWidth ?? 1}
      />
    ))}
  </ReChartsAreaChart>
);

export default AreaChart;
