import React, { useState } from 'react';
import { addDays, addMonths } from 'date-fns';
import { Button } from '@fashinza/fashinza-design-system';
import { DayPicker } from 'react-day-picker';
import { ObjectType } from '../../../../@types/defined';
import * as Styles from './styles';
import { swallowEvent } from '../../../../utils';

interface IDateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onClose: VoidFunction;
  onChangeDays: (startDate: Date | null, endDate: Date | null) => void;
}

const DateRangePicker = ({
  startDate,
  endDate,
  onClose,
  onChangeDays,
}: IDateRangePickerProps) => {
  const [range, setRange] = useState({
    startDate,
    endDate,
  });

  function getModifiers() {
    if (!range.startDate || !range.endDate) {
      return {
        modifiers: {},
        styles: {},
      };
    }

    const modifiers: ObjectType = {};
    const styles: ObjectType = {};

    for (
      let date = addDays(range.startDate, 1);
      date < range.endDate;
      date = addDays(date, 1)
    ) {
      const key = date.getTime();
      modifiers[key] = date;
      styles[key] = {
        color: 'var(--rhino)',
        backgroundColor: 'var(--bubbles)',
      };
    }

    return {
      modifiers,
      styles,
    };
  }

  return (
    <Styles.Wrapper className="v-d-flex" onClick={swallowEvent}>
      <Styles.Body className="d-flex">
        <DayPicker
          selected={
            // eslint-disable-next-line no-nested-ternary
            range.startDate && range.endDate
              ? [range.startDate, range.endDate]
              : range.startDate && range.endDate === null
              ? [range.startDate]
              : []
          }
          onDayClick={(day, modifiers, event) => {
            if (modifiers.disabled) {
              return;
            }
            setRange(prev => ({ ...prev, startDate: day }));
            event.stopPropagation();
          }}
          {...(range.endDate
            ? {
                disabledDays: [
                  {
                    after: range.endDate,
                  },
                ],
              }
            : {})}
          month={new Date()}
          modifiers={getModifiers().modifiers}
          modifiersStyles={getModifiers().styles}
        />

        <DayPicker
          selected={
            // eslint-disable-next-line no-nested-ternary
            range.startDate && range.endDate
              ? [range.startDate, range.endDate]
              : range.endDate && range.startDate === null
              ? [range.endDate]
              : []
          }
          onDayClick={(day, modifiers, event) => {
            if (modifiers.disabled) {
              return;
            }
            setRange(prev => ({ ...prev, endDate: day }));
            event.stopPropagation();
          }}
          {...(range.startDate
            ? {
                disabledDays: [
                  {
                    before: range.startDate,
                  },
                ],
              }
            : {})}
          month={addMonths(new Date(), 1)}
          modifiers={getModifiers().modifiers}
          modifiersStyles={getModifiers().styles}
        />
      </Styles.Body>
      <Styles.Footer className="end-align-center gap-24">
        <Button
          onClick={() => {
            setRange({ endDate: null, startDate: null });
          }}
          variant="tertiary"
          colorScheme="primary"
          sizeVariant="small"
        >
          Clear Selection
        </Button>
        <Button
          onClick={() => {
            onChangeDays(range.startDate, range.endDate);
            onClose();
          }}
          variant="fill"
          colorScheme="primary"
          sizeVariant="small"
        >
          Apply
        </Button>
      </Styles.Footer>
    </Styles.Wrapper>
  );
};

export default DateRangePicker;
