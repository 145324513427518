import React, { ReactNode, useEffect, useState } from 'react';
import * as Styles from './styles';
import ICONOGRAPHY from '../iconography';
import { detectKeyPress } from '../../utils';

const { close: CloseIcon } = ICONOGRAPHY;

const gCount = { x: 0 };

interface IModalWrapperHocProps {
  onClose?(): void;
  className?: string;
  children?: ReactNode | null;
  dataProps?: any;
  isTransparent?: boolean;
}

interface ICustomMouseEvent extends KeyboardEvent {
  isCloseHandled?: boolean;
}

const ModalHoc = ({
  onClose,
  children,
  className = '',
  dataProps = {},
  isTransparent = false,
}: IModalWrapperHocProps) => {
  const [count, setCount] = useState<number | undefined>();

  useEffect(() => {
    gCount.x += 1;
    setCount(gCount.x);
  }, []);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleKeyEvent = (e: ICustomMouseEvent) => {
    if (detectKeyPress(e).escape && !e.isCloseHandled) {
      if (count === gCount.x) {
        e.isCloseHandled = true;
        handleClose();
      }
    }
  };

  useEffect(() => {
    // this.state = {
    document.addEventListener('keydown', handleKeyEvent);
    return () => {
      document.removeEventListener('keydown', handleKeyEvent);
      gCount.x -= 1;
    };
  }, []);

  return (
    <Styles.Wrapper
      className={`modalWrapperHoc-wrapper center ${className}`}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ opacity: 0 }}
      {...dataProps}
    >
      <Styles.WrapperOverlay
        onClick={handleClose}
        className={`modalWrapperHoc-bg ${isTransparent ? 'transparent' : ''}`}
      />
      {children}
    </Styles.Wrapper>
  );
};

export default ModalHoc;

// -------------------ChildrenHoc------------------

interface IChildrenHocProps {
  children?: ReactNode;
  width?: number | string;
  height?: number | string;
}

export const ChildrenHoc = ({
  children,
  width = '60vw',
  height = 'auto',
}: IChildrenHocProps) => (
  <Styles.ChildrenHocWrapper
    className="v-d-flex"
    style={{ width, height }}
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 50 }}
  >
    {children}
  </Styles.ChildrenHocWrapper>
);
// -------------------ChildrenHoc End------------------

// -------------------Header------------------

interface IHeaderProps {
  label?: string | undefined;
  leftJsx?: ReactNode | null;
  moreJsx?: ReactNode | null;
  onClose?: () => void;
}

export const Header = ({
  leftJsx = null,
  label,
  onClose,
  moreJsx = null,
}: IHeaderProps) => {
  const header = leftJsx || label;
  return (
    <Styles.HeaderWrapper>
      <div className="spread pos-r">
        <Styles.HeaderLeft>{header}</Styles.HeaderLeft>
        <Styles.HeaderRight className="pos-a">
          <Styles.CloseButton
            className="center cursor-pointer"
            onClick={onClose}
          >
            <CloseIcon />
          </Styles.CloseButton>
        </Styles.HeaderRight>
      </div>
      {moreJsx}
    </Styles.HeaderWrapper>
  );
};
// -------------------Header End------------------

// -------------------Content------------------

interface IContentProps {
  children?: ReactNode;
}

export const Content = ({ children }: IContentProps) => (
  <Styles.ContentWrapper className="full-flex-grow">
    {children}
  </Styles.ContentWrapper>
);
// -------------------Content End------------------

// -------------------Footer------------------

interface IFooterProps {
  leftJsx?: ReactNode | null;
  rightJsx?: ReactNode | null;
  bottomJsx?: ReactNode | null;
}

export const Footer = ({
  leftJsx = <div />,
  rightJsx = <div />,
  bottomJsx = null,
}: IFooterProps) => (
  <Styles.FooterWrapper className="spread">
    {bottomJsx || (
      <>
        <Styles.FooterLeft>{leftJsx}</Styles.FooterLeft>
        <Styles.FooterRight>{rightJsx}</Styles.FooterRight>
      </>
    )}
  </Styles.FooterWrapper>
);
// -------------------Footer End------------------
