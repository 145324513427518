import React from 'react';
import { COLOR_MAPPING } from './constants';
import { SectionsType } from './types';
import * as Styles from './styles';
import ICONOGRAPHY from '../iconography';
import { isUndefined } from '../../utils/assertion';
import { CustomPopperReturnType } from '../../hooks/useCustomPopper';

interface IDropdownMenuProps {
  sections: SectionsType;
  onClick(): void;
  fixedAttributes: CustomPopperReturnType['fixed'];
}

const DropdownMenu = ({
  sections,
  onClick,
  fixedAttributes,
}: IDropdownMenuProps) => (
  <Styles.DropdownWrapper
    className="v-d-flex has-y-scroll"
    {...fixedAttributes}
  >
    {sections.map((eachSection, index) => (
      <Styles.EachSection
        key={eachSection.key ?? `${eachSection.label}` ?? index}
      >
        {eachSection.label && <Styles.Label>{eachSection.label}</Styles.Label>}
        {eachSection.options.map((eachOption, index) => {
          const IconNode = isUndefined(eachOption.icon)
            ? null
            : ICONOGRAPHY[eachOption.icon];
          return (
            <Styles.ListItem
              key={`${index}--${eachOption.text}`}
              className="align-center cursor-pointer"
              onClick={event => {
                event.stopPropagation();
                eachOption.onClick?.();
                onClick();
              }}
            >
              {IconNode && (
                <IconNode
                  size={18}
                  stroke={COLOR_MAPPING[eachOption.variant ?? 'default']}
                  {...(eachOption.iconProps ?? {})}
                />
              )}
              <Styles.ItemData variant={eachOption.variant ?? 'default'}>
                {eachOption.text}
              </Styles.ItemData>
            </Styles.ListItem>
          );
        })}
      </Styles.EachSection>
    ))}
  </Styles.DropdownWrapper>
);

export default DropdownMenu;
