import { startOfMonth, startOfWeek, subMonths, subWeeks } from "date-fns";
import {
  DashboardSubSectionsType,
  DateFilterTextsType,
} from "../../../@types/dashboard";

export const SAMPLE_INSPECTION_SUB_SECTIONS: Array<{
  id: DashboardSubSectionsType;
  name: string;
}> = [
  {
    id: "all-samples",
    name: "All",
  },
  {
    id: "pp-sample",
    name: "PP Sample",
  },
  {
    id: "size-set",
    name: "Size Set",
  },
  {
    id: "fit-sample",
    name: "Fit Sample",
  },
  {
    id: "lab-fit",
    name: "Lab Dip",
  },
  {
    id: "strike-off",
    name: "Strike Off",
  },
  {
    id: "top-sample",
    name: "Top Sample",
  },
  {
    id: "shipment-sample",
    name: "Shipment Sample",
  },
  {
    id: "photo-shoot-sample",
    name: "PhotoShoot Sample",
  },
];

export const DATE_FILTER_OPTIONS: Array<{
  dates: {
    startDate: Date;
    endDate: Date;
  };
  text: DateFilterTextsType;
}> = [
  {
    dates: {
      startDate: startOfWeek(new Date()),
      endDate: new Date(),
    },
    text: "This week",
  },
  {
    dates: {
      startDate: subWeeks(new Date(), 2),
      endDate: new Date(),
    },
    text: "Last 2 weeks",
  },
  {
    dates: {
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
    },
    text: "This month",
  },
  {
    dates: {
      startDate: subMonths(new Date(), 2),
      endDate: new Date(),
    },
    text: "Last 2 months",
  },
  {
    dates: {
      startDate: subMonths(new Date(), 3),
      endDate: new Date(),
    },
    text: "Last 3 months",
  },
  {
    dates: {
      startDate: subMonths(new Date(), 6),
      endDate: new Date(),
    },
    text: "Last 6 months",
  },
];
