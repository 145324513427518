import React from 'react';
import ReactToggle from 'react-toggle';
import * as Styles from './styles';

interface IToggleProps {
  isChecked: boolean;
  onChange: () => void;
  showIcons?: boolean;
  isDisabled?: boolean;
  onText?: string | null;
  offText?: string | null;
  className?: string;
  showLabel?: boolean;
}

const Toggle = ({
  isChecked,
  onChange,
  showIcons = false,
  isDisabled = false,
  onText = null,
  offText = null,
  className = '',
  showLabel = true,
}: IToggleProps) => (
  <Styles.ToggleWrapper className={`spread ${className}`}>
    {showLabel && <Styles.Label>{isChecked ? onText : offText}</Styles.Label>}
    <ReactToggle
      checked={isChecked}
      icons={showIcons}
      onChange={onChange}
      disabled={isDisabled}
    />
  </Styles.ToggleWrapper>
);

export default Toggle;
