import React from 'react';
import { useDispatch } from 'react-redux';
import { ObjectType } from '../../../@types/defined';
import {
  ACTION_STATUS_FILTER_OPTIONS,
  INSPECTION_RESULT_FILTER_OPTIONS,
  SECTION_WISE_FIELDS,
} from '../../constants';
import { useTypedSelector } from '../../../hooks';
import {
  setDashboardColumnFilters,
  setDashboardSortConfig,
} from '../../../redux/actions';
import HeaderContent from '../../../shared/TableLayout/HeaderContent';

interface IHeaderCellProps {
  columnIndex: number;
}

const HeaderCell = ({ columnIndex }: IHeaderCellProps) => {
  const dispatch = useDispatch();

  const listFields = useTypedSelector(store => store.preferences.qualityList);
  const section = useTypedSelector(store => store.quality.dashboard.section);
  const searchText = useTypedSelector(
    store => store.quality.dashboard.searchText
  );
  const sortConfig = useTypedSelector(
    store => store.quality.dashboard.sortConfig
  );

  if (!section) {
    return null;
  }

  const field = SECTION_WISE_FIELDS[section][listFields[columnIndex]];

  function handleSort(sortKeyName: string) {
    if (searchText.length > 0) {
      return;
    }

    const sortMethod = sortConfig[sortKeyName] === 'desc' ? 'asc' : 'desc';

    dispatch(setDashboardSortConfig(sortKeyName, sortMethod));
  }

  function onChangeFilter(data: ObjectType) {
    dispatch(setDashboardColumnFilters({ [data.name]: data.value }));
  }

  return (
    <HeaderContent
      fieldValue={field?.value}
      fieldLabel={field?.label}
      showFilter={field?.showFilter ?? false}
      isSortable={field?.isSortable ?? false}
      className="quality-table-header"
      handleSort={handleSort}
      onChangeFilter={onChangeFilter}
      filterOptions={{
        is_approved: ACTION_STATUS_FILTER_OPTIONS,
        inspection_result: INSPECTION_RESULT_FILTER_OPTIONS,
      }}
    />
  );
};

export default HeaderCell;
