export enum PreferencesActionEnum {
  SetRfqList = "preferences/SET_RFQ_LIST",
  SetQualityList = "preferences/SET_QUALITY_LIST",
  SetPoList = "preferences/SET_PO_LIST",
}

export const setQualityPreferenceList = (payload: Array<string>) => ({
  type: PreferencesActionEnum.SetQualityList,
  payload,
});
