import config from "../config";

export type LocalStorageKeyType =
  | "showCollection"
  | "lsKey"
  | "token"
  | "showCollectionModal"
  | "deviceUuid"
  | "requestNotificationLastShownAt"
  | "showToolTipForOrders"
  | "showFeedback"
  | "costingWalkThroughFrequency"
  | "programListWalkThroughFrequency";

export const LOCAL_STORAGE_KEYS: Record<LocalStorageKeyType, string> =
  Object.freeze({
    showCollection: "show_collection_tutorial",
    showFeedback: "show_feedback",
    lsKey: "previousCatalogModalShownTime",
    token: config.TOKEN ?? "",
    showCollectionModal: "show_collection_modal",
    deviceUuid: "device_uuid",
    requestNotificationLastShownAt: "notification_last_shown_at",
    showToolTipForOrders: "show_order_tutorial",
    costingWalkThroughFrequency: "costingWalkThroughFrequency",
    programListWalkThroughFrequency: "programListWalkThroughFrequency",
  });

export const KEYS_TO_NOT_CLEAR: ReadonlyArray<LocalStorageKeyType> = [
  "showCollection",
  "showCollectionModal",
  "deviceUuid",
  "showToolTipForOrders",
  "costingWalkThroughFrequency",
  "programListWalkThroughFrequency",
];
