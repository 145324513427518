import React, { useState } from 'react';
import ClickOutside from '../ClickOutside';
import Portal from '../Portal';
import { getCoords } from '../ToggleDropdownControl/toggleDropdownControl';
import CollaboratorColumn from './collaboratorColumn/CollaboratorColumn';
import TaggedCollaborators from '../TaggedCollaborators';

interface IManageCollaboratorsProps {
  is_edit_collaborators_allowed: boolean;
  collaborators: Array<any>;
  users_list: Array<any>;
  id: string;
  onClickUser: (
    data: { action: string; userid: string; id: string } | any
  ) => void;
  availwidth: number;
  ovalwidth: number;
  fontSizeInitials: number;
  brand_pocs?: Array<any>;
  supplier_pocs?: Array<any>;
}

const ManageCollaborators: React.FC<IManageCollaboratorsProps> = ({
  is_edit_collaborators_allowed,
  collaborators,
  users_list,
  id,
  onClickUser,
  availwidth,
  ovalwidth,
  fontSizeInitials,
  brand_pocs = [],
  supplier_pocs = [],
}) => {
  const [isCardOpen, setCardOpen] = useState<
    { top: number; left: number } | false
  >(false);

  const onOpenCollaboratorsCard = (
    _id: string,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    const coords = getCoords(e);
    setCardOpen(coords);
  };

  const onCloseCollaboratorsCard = () => {
    setCardOpen(false);
  };

  const onAddCollaborator = (e: React.MouseEvent<HTMLDivElement>) => {
    const type = e.currentTarget.getAttribute('data-action');
    const userid = e.currentTarget.getAttribute('data-name');

    onClickUser({ action: type!, userid: userid!, id });
  };

  // const isSupplierOs = useIsSupplierOsEnabled();

  const newCollaborators = collaborators;
  // if (isSupplierOs) {
  //   newCollaborators = newCollaborators.filter(
  //     x => !disabledCollaboratorIdInTraktez.includes(x.id)
  //   );
  // }

  return (
    <div className="ManageTnaCollaborators-wrapper">
      {isCardOpen && (
        <Portal>
          <ClickOutside onClose={onCloseCollaboratorsCard}>
            <div
              className="orderListItemDesktop-collaborators"
              style={{
                width: '300px',
                position: 'absolute',
                top: isCardOpen.top + 45,
                left: isCardOpen.left - 200,
                zIndex: 1,
              }}
            >
              <TaggedCollaborators
                onClose={onCloseCollaboratorsCard}
                users={users_list}
                tag_users={collaborators}
                onAdd={onAddCollaborator}
                brand_pocs={brand_pocs}
                supplier_pocs={supplier_pocs}
                suggested_users={[]}
                bigHeading={false}
              />
            </div>
          </ClickOutside>
        </Portal>
      )}
      <CollaboratorColumn
        data={newCollaborators}
        onOpenCollaboratorModal={onOpenCollaboratorsCard}
        disableAddAction={!is_edit_collaborators_allowed}
        availwidth={availwidth}
        ovalwidth={ovalwidth}
        fontSizeInitials={fontSizeInitials}
      />
    </div>
  );
};

export default ManageCollaborators;
