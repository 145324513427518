import React from "react";
import { IconPropsType } from "./types";

interface ISuppliersIconProps extends IconPropsType {}

const SuppliersIcon = ({
  stroke = "var(--rhino)",
  strokeWidth = 1.25,
  size = 24,
  ...props
}: ISuppliersIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 20.99V3H17.68V9.81621L14.84 11.2754V8.35702L9.16 11.2754V8.35702L3 11.2754V21H21V20.99ZM10 14.4936V17.492H7V14.4936H10ZM17 14.4936V17.492H14V14.4936H17Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinejoin="round"
    />
  </svg>
);

export default SuppliersIcon;
