import React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
// import Quality from './apps';
import { initMixpanel } from './analytics/MixpanelHelper';
import { store, history } from './redux';
import { QARoutesEnum } from './components/constants';
import PPMForm from './components/PPMForm';
import ReviewPPM from './components/Reviews/Ppm';
import ReviewFinalInspection from './components/Reviews/FinalInspection';
import Dashboard from './components/Dashboard';

initMixpanel();

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            path={`/${QARoutesEnum.PPM}/:ppmId/${QARoutesEnum.EDIT}`}
            component={PPMForm}
          />
          <Route
            path={`/${QARoutesEnum.PPM}/:ppmId/${QARoutesEnum.REVIEW}`}
            component={ReviewPPM}
          />
          <Route
            path={`/${QARoutesEnum.FINAL_INSPECTION}/:finalInspectionId/${QARoutesEnum.REVIEW}`}
            component={ReviewFinalInspection}
          />
          <Route path="/" component={Dashboard} />
          <Redirect to="/?section=sample-inspection" />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
