import React from "react";
import AbsoluteCross from "./AbsoluteCross";
import RightControls from "./RightControls";
import { useFileUploadContext } from "../helperHooks";
import { FileControlsType, IFileOutputProps } from "../types";
import * as Styles from "./styles";
import { getFileAttributes } from "../../../utils/files";

interface IDetermineFileTypeProps {
  className?: string;
  fileName: IFileOutputProps["name"];
  fileLink: IFileOutputProps["link"];
  onDelete?: (() => void) | null;
  showText?: boolean;
  controlsType?: FileControlsType;
  canDownloadOnWrapper?: boolean;
}

const DetermineFileType = ({
  className = "",
  fileName,
  fileLink,
  onDelete = null,
  showText = false,
  controlsType = "",
  canDownloadOnWrapper = false,
}: IDetermineFileTypeProps) => {
  const { backgroundColor, Icon } = getFileAttributes(fileName);
  const shouldShowRightSideControls = [
    "static",
    "hover",
    "static-with-background",
  ].includes(controlsType);
  const shouldShowBackgroundDirectly =
    controlsType === "static-with-background";
  const { isMobileView } = useFileUploadContext();
  return (
    <Styles.Wrapper
      className={`spread ${className}`}
      backgroundColor={backgroundColor}
      showBackgroundDirectly={shouldShowBackgroundDirectly}
      showRightSideControls={shouldShowRightSideControls}
      as={canDownloadOnWrapper ? "a" : "div"}
      {...(canDownloadOnWrapper && { href: fileLink, target: "_blank" })}
    >
      <Styles.LeftWrapper className="full-flex align-center no-scroll full-view-max-width">
        <Styles.IconWrapper className="pos-r" backgroundColor={backgroundColor}>
          <Icon />
          {onDelete !== null && controlsType === "absolute" && (
            <AbsoluteCross onDelete={onDelete} />
          )}
        </Styles.IconWrapper>
        {showText && (
          <Styles.FileName className="truncated-text">
            {fileName}
          </Styles.FileName>
        )}
      </Styles.LeftWrapper>

      {shouldShowRightSideControls && (
        <RightControls
          shouldShow={
            controlsType === "static" ||
            controlsType === "static-with-background" ||
            isMobileView
          }
          onDelete={onDelete}
          fileLink={fileLink}
        />
      )}
    </Styles.Wrapper>
  );
};

export default DetermineFileType;
