import styled from "styled-components";
import { device } from "../../../constants/mediaQueries";

export const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: var(--lynch);
  width: 20%;
  padding-right: 16px;
  line-height: 21px;

  ${device.mediumViewport} {
    width: 100%;
    font-size: 16px;
    padding-bottom: 16px;
  }
`;

export const DeleteButton = styled.button`
  cursor: pointer;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background: none;
  outline: none;
  padding: 0;

  &:hover {
    background: rgba(44, 53, 86, 0.1);
  }
`;
