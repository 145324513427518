import { useEffect, useRef } from 'react';

export function useBroadcast(channelName: string) {
  const channelRef = useRef<any>(null);

  useEffect(() => {
    try {
      channelRef.current = new BroadcastChannel(channelName);
    } catch (error) {
      channelRef.current = null;
    }

    return () => {
      if (channelRef) {
        channelRef?.current?.close();
      }
    };
  }, []);

  return channelRef ? channelRef.current : null;
}
