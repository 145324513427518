import React, { useContext, useEffect } from "react";
import { v1 as uuidv1 } from "uuid";
import { PPMFormContext } from "../index";
import Accordion from "../accessoryComponents/Accordion";
import CombinedDropdownFile from "../accessoryComponents/CombinedDropdownFile";
import CombinedRadioFile from "../accessoryComponents/CombinedRadioFile";
import PlainTextInput from "../accessoryComponents/PlainTextInput";
import {
  InputTypeEnum,
  IOtherTestReport,
  OtherTestReportsType,
  PPMFormSectionsEnum,
} from "../types";
import { FIELDS } from "./fields";
import { PPM_FORM_SECTION_HEADINGS } from "../constants";
import { AttachmentType } from "../../../@types/defined";

const PreProductionPreparation = () => {
  const {
    getFieldValueFromStore,
    onChangeRadio,
    onChangeText,
    onFileChange,
    onChange,
  } = useContext(PPMFormContext);

  const allOtherReports: OtherTestReportsType = getFieldValueFromStore(
    "other_preproduction_planning"
  );

  function onChangeOtherReportName(identifier: string, value: string) {
    const allReports = [...allOtherReports];

    const indexToUpdate = allReports.findIndex(
      (item: IOtherTestReport) => item.identifier === identifier
    );

    if (indexToUpdate === -1) {
      return;
    }

    allReports[indexToUpdate].name = value;

    onChange("other_preproduction_planning", [...allReports]);
  }

  function onChangeOtherReportAttachment(
    identifier: string,
    value: Array<AttachmentType>
  ) {
    const allReports = [...allOtherReports];

    const indexToUpdate = allReports.findIndex(
      (item: IOtherTestReport) => item.identifier === identifier
    );

    if (indexToUpdate === -1) {
      return;
    }

    allReports[indexToUpdate].attachments = value;

    onChange("other_preproduction_planning", [...allReports]);
  }

  function deleteOtherReport(identifier: string) {
    const allReports = [...allOtherReports];

    const indexToDelete = allReports.findIndex(
      (item: IOtherTestReport) => item.identifier === identifier
    );

    if (indexToDelete === -1) {
      return;
    }

    allReports.splice(indexToDelete, 1);

    onChange("other_preproduction_planning", [...allReports]);
  }

  function addOtherReport() {
    const newAttendee: IOtherTestReport = {
      identifier: uuidv1(),
      name: null,
      attachments: [],
    };

    onChange("other_preproduction_planning", [...allOtherReports, newAttendee]);
  }

  useEffect(() => {
    if (allOtherReports.length === 0) {
      addOtherReport();
    }
  }, []);

  return (
    <Accordion
      header={
        PPM_FORM_SECTION_HEADINGS[
          PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION
        ]
      }
      section={PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION}
    >
      {FIELDS.map((field) => {
        switch (field.inputType) {
          case InputTypeEnum.COMBINED_RADIO_FILE: {
            if (field.options && field.attachmentSlug) {
              return (
                <CombinedRadioFile
                  key={field.id}
                  id={field.id}
                  label={field.label}
                  options={field.options}
                  getFieldValueFromStore={getFieldValueFromStore}
                  onChangeRadio={onChangeRadio}
                  onFileChange={onFileChange}
                  attachmentSlug={field.attachmentSlug}
                  section={PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION}
                />
              );
            }
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION}
              />
            );
          }

          case InputTypeEnum.COMBINED_DROPDOWN_FILE: {
            if (field.options) {
              return (
                <CombinedDropdownFile
                  key={field.id}
                  id={field.id}
                  label={field.label}
                  options={field.options}
                  value={allOtherReports}
                  onChangeOtherReportName={onChangeOtherReportName}
                  addOtherReport={addOtherReport}
                  deleteOtherReport={deleteOtherReport}
                  onChangeOtherReportAttachment={onChangeOtherReportAttachment}
                  section={PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION}
                />
              );
            }

            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION}
              />
            );
          }

          default:
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION}
              />
            );
        }
      })}
    </Accordion>
  );
};

export default PreProductionPreparation;
