import React from "react";
import { IconPropsType } from "../types";

interface IPptFileIconProps extends IconPropsType {}

const PptFileIcon = (props: IPptFileIconProps) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={40} height={40} rx={4} fill="var(--fair-pink)" />
    <path
      d="M22.25 21.5L20.75 10.5H20.638C15.1836 10.5159 10.7659 14.9336 10.75 20.388V20.5L22.25 21.5Z"
      fill="#ED6C47"
    />
    <path
      d="M20.862 10.5H20.75V20.5L25.75 22.5L30.75 20.5V20.388C30.7341 14.9336 26.3164 10.5159 20.862 10.5Z"
      fill="#FF8F6B"
    />
    <path
      d="M30.75 20.5V20.61C30.7343 26.0656 26.3156 30.4843 20.86 30.5H20.64C15.1844 30.4843 10.7657 26.0656 10.75 20.61V20.5H30.75Z"
      fill="#D35230"
    />
    <path
      opacity={0.1}
      d="M21.25 15.415V26.085C21.2475 26.4558 21.0228 26.7888 20.68 26.93C20.5708 26.9762 20.4535 27 20.335 27H13.11C12.97 26.84 12.835 26.67 12.71 26.5C11.4362 24.8007 10.7484 22.7338 10.75 20.61V20.39C10.7471 18.4744 11.3069 16.6002 12.36 15C12.47 14.83 12.585 14.66 12.71 14.5H20.335C20.8388 14.5038 21.2462 14.9113 21.25 15.415Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M20.75 15.915V26.585C20.75 26.7035 20.7262 26.8208 20.68 26.93C20.5389 27.2728 20.2058 27.4975 19.835 27.5H13.565C13.4068 27.3394 13.255 27.1726 13.11 27C12.97 26.84 12.835 26.67 12.71 26.5C11.4362 24.8007 10.7484 22.7338 10.75 20.61V20.39C10.7471 18.4744 11.3069 16.6002 12.36 15H19.835C20.3388 15.0038 20.7462 15.4112 20.75 15.915Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M20.75 15.9151V25.585C20.7462 26.0888 20.3388 26.4962 19.835 26.5001H12.71C11.4362 24.8007 10.7484 22.7338 10.75 20.6101V20.3901C10.7471 18.4745 11.3069 16.6002 12.36 15.0001H19.835C20.3388 15.0039 20.7462 15.4113 20.75 15.9151Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M20.25 15.9151V25.585C20.2462 26.0888 19.8388 26.4962 19.335 26.5001H12.71C11.4362 24.8007 10.7484 22.7338 10.75 20.6101V20.3901C10.7471 18.4745 11.3069 16.6002 12.36 15.0001H19.335C19.8388 15.0039 20.2462 15.4113 20.25 15.9151Z"
      fill="black"
    />
    <path
      d="M10.1665 15H19.3335C19.8397 15 20.25 15.4103 20.25 15.9165V25.0835C20.25 25.5897 19.8396 26 19.3335 26H10.1665C9.66034 26 9.25 25.5897 9.25 25.0835V15.9165C9.25 15.4103 9.66034 15 10.1665 15Z"
      fill="url(#paint0_linear_235_6335)"
    />
    <path
      d="M14.8502 17.4445C15.4469 17.4042 16.0385 17.5776 16.5192 17.9335C16.717 18.1101 16.8722 18.3294 16.9728 18.5748C17.0735 18.8202 17.117 19.0853 17.1002 19.35C17.1067 19.7228 17.0074 20.0899 16.8137 20.4085C16.6176 20.7216 16.3351 20.9714 16.0002 21.1275C15.6174 21.3056 15.1988 21.3935 14.7767 21.3845H13.6172V23.5395H12.4297V17.4445H14.8502ZM13.6162 20.4535H14.6382C14.9622 20.4773 15.2837 20.3814 15.5417 20.184C15.6471 20.0829 15.7291 19.9599 15.7819 19.8236C15.8347 19.6874 15.857 19.5413 15.8472 19.3955C15.8472 18.7238 15.4569 18.388 14.6762 18.388H13.6162V20.4535H13.6162Z"
      fill="var(--white)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_235_6335"
        x1={11.1609}
        y1={14.2839}
        x2={18.3391}
        y2={26.7161}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CA4C28" />
        <stop offset={0.5} stopColor="#C5401E" />
        <stop offset={1} stopColor="#B62F14" />
      </linearGradient>
    </defs>
  </svg>
);

export default PptFileIcon;
