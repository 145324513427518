import { RefObject, useEffect, useState } from "react";

const useDimensions = (ref: RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    if (ref.current) {
      setDimensions({
        height: ref.current.offsetHeight,
        width: ref.current.offsetWidth,
      });
    }
  }, [ref]);

  return dimensions;
};

export default useDimensions;
