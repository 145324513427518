import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { IoWarningOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { Button } from '@fashinza/fashinza-design-system';
import { BiChevronDown, BiChevronRight, BiChevronUp } from 'react-icons/bi';
import { PPMFormContext } from '../../index';
import { PPMFormSectionsEnum } from '../../types';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import ICONOGRAPHY from '../../../../shared/iconography';
import * as Styles from './styles';

const { check: CheckIcon } = ICONOGRAPHY;

interface IAccordionProps {
  header: string;
  section: PPMFormSectionsEnum;
  isLast?: boolean;
  children: ReactNode;
  footer?: ReactNode;
  isSubmitDisabled?: boolean;
  disclaimer?: ReactNode;
}

const Accordion = ({
  header,
  children,
  footer,
  section,
  isLast = false,
  isSubmitDisabled = false,
  disclaimer = null,
}: IAccordionProps) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const ref = useRef<HTMLHeadingElement>(null);
  const {
    updatePpmForm,
    checkSectionCompleteness,
    sectionLoaders,
    sectionAccordions,
    openAccordion,
    toggleAccordion,
  } = useContext(PPMFormContext);
  const { hash } = useLocation();

  const isComplete = checkSectionCompleteness(section);

  let { isOpen } = sectionAccordions[section];

  const isLoading = sectionLoaders[section];

  const isSubmitForm = section === PPMFormSectionsEnum.SUBMIT_FORM;

  function scrollToView() {
    if (ref.current?.scrollIntoView) {
      ref.current.scrollIntoView({ behavior: 'auto' });
    }
  }

  useEffect(() => {
    if (hash.replace('#', '') === section) {
      openAccordion(section);
      scrollToView();
    }
  }, [hash, section]);

  function onToggle() {
    if (isSubmitForm) {
      return;
    }

    toggleAccordion(section);
    dispatch(replace({ hash: section }));
  }

  isOpen = isSubmitForm ? true : isOpen;

  return (
    <Styles.Wrapper className="d-flex">
      {!isMobile && (
        <Styles.Stepper className="pos-r v-align-center">
          <Styles.StepperCircle className="center" isComplete={isComplete}>
            {isComplete && (
              <CheckIcon stroke="var(--pacific-blue)" strokeWidth={1} />
            )}
          </Styles.StepperCircle>
          <Styles.StepperLine
            className="full-height"
            isLast={isLast}
            isComplete={isComplete}
          />
        </Styles.Stepper>
      )}
      <Styles.Content>
        <Styles.HeaderText
          ref={ref}
          className="align-center cursor-pointer"
          onClick={onToggle}
          initial={false}
          animate={isOpen ? 'increase' : 'decrease'}
          variants={{
            increase: {
              fontSize: '24px',
              color: isComplete ? 'var(--pacific-blue)' : 'var(--rhino)',
            },
            decrease: {
              fontSize: '16px',
              color: isComplete ? 'var(--pacific-blue)' : 'var(--rhino)',
            },
          }}
          transition={{ duration: 0.3 }}
        >
          {header}
          {!isSubmitForm && (
            <>
              {isOpen ? <BiChevronUp size={24} /> : <BiChevronDown size={16} />}
            </>
          )}
        </Styles.HeaderText>

        <AnimatePresence>
          {isOpen && (
            <Styles.Main
              initial={{
                opacity: 0,
                height: 0,
              }}
              animate={{
                opacity: 1,
                height: 'auto',
              }}
              exit={{
                opacity: 0,
                height: 0,
              }}
              transition={{ duration: 0.3 }}
            >
              {disclaimer && (
                <Styles.Disclaimer className="d-flex">
                  <IoWarningOutline stroke="var(--chilean-fire)" />
                  {disclaimer}
                </Styles.Disclaimer>
              )}
              <Styles.Body>{children}</Styles.Body>
              {!isMobile && (
                <Styles.Footer>
                  {footer ?? (
                    <Button
                      isLoading={isLoading}
                      isDisabled={isSubmitDisabled}
                      sizeVariant="large"
                      variant="outline"
                      onClick={() => updatePpmForm(section)}
                    >
                      Save & Proceed
                      <BiChevronRight size={10} />
                    </Button>
                  )}
                </Styles.Footer>
              )}
            </Styles.Main>
          )}
        </AnimatePresence>
      </Styles.Content>
    </Styles.Wrapper>
  );
};

export default Accordion;
