import React from 'react';
import { Button } from '@fashinza/fashinza-design-system';
import { emptyFunction } from '../../utils';
import ICONOGRAPHY from '../../shared/iconography';
import EmptyViewIcon from '../../shared/iconography/EmptyViewIcon';
import * as Styles from './styles';

const {
  filter: FilterIcon,
  calendar: CalenderIcon,
  'chevron-down': ChevronDown,
} = ICONOGRAPHY;

interface IEmptyViewProps {
  text: string;
}

const EmptyView = ({ text }: IEmptyViewProps) => (
  <Styles.Wrapper className="v-d-flex center gap-32">
    <EmptyViewIcon />
    <Styles.Text>{text}</Styles.Text>
    <Styles.Footer className="align-center gap-4">
      <p>Please change your filters from</p>
      <Button
        onClick={emptyFunction}
        variant="outline"
        colorScheme="neutral"
        sizeVariant="small"
        leftIcon={<CalenderIcon size={16} />}
        rightIcon={<ChevronDown size={16} />}
      >
        Custom
      </Button>
      <Button
        onClick={emptyFunction}
        variant="outline"
        colorScheme="neutral"
        sizeVariant="small"
      >
        <FilterIcon size={16} />
      </Button>
      <p>to view data</p>
    </Styles.Footer>
  </Styles.Wrapper>
);

export default EmptyView;
