import React, { useContext } from "react";
import { PPMFormContext } from "../index";
import Accordion from "../accessoryComponents/Accordion";
import PlainTextInput from "../accessoryComponents/PlainTextInput";
import HistoryAndRemarks from "../accessoryComponents/HistoryAndRemarks";
import {
  NON_BOOLEAN_AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  PPM_FORM_SECTION_HEADINGS,
} from "../constants";
import { PPMFormSectionsEnum } from "../types";
import MultipleChoiceInput from "../accessoryComponents/MultipleChoiceInput";
import { ObjectType } from "../../../@types/defined";

const SampleDetails = () => {
  const { getFieldValueFromStore, onChangeText, onChangeRadio } =
    useContext(PPMFormContext);

  const previousReports = getFieldValueFromStore("previous_qc_reports");

  return (
    <Accordion
      header={PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.SAMPLE_DETAILS]}
      section={PPMFormSectionsEnum.SAMPLE_DETAILS}
    >
      <>
        <MultipleChoiceInput
          field="approved_sample"
          label="Physical approved sample"
          options={NON_BOOLEAN_AVAILABILITY_MULTIPLE_CHOICE_OPTIONS}
          value={getFieldValueFromStore("approved_sample")}
          onChangeRadio={onChangeRadio}
          section={PPMFormSectionsEnum.SAMPLE_DETAILS}
        />

        {previousReports.map((report: ObjectType) => (
          <HistoryAndRemarks
            key={report.id}
            status={report.status ?? ""}
            fileUrl={report.qc_report_pdf ?? ""}
            label={report.process_step_name}
            skuData={report.sku_data}
          />
        ))}
        <PlainTextInput
          id="other_sample_history"
          label="Remarks"
          value={getFieldValueFromStore("other_sample_history")}
          onChangeText={onChangeText}
          section={PPMFormSectionsEnum.SAMPLE_DETAILS}
        />
      </>
    </Accordion>
  );
};

export default SampleDetails;
