import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'other_comments',
    label:
      'Additional Comments /Special Attention / Potential Quality Failures for Production Reference',
    inputType: InputTypeEnum.TEXT_AREA,
    placeholder: 'Additional comments',
  },
];
