import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--white);
  padding: 32px;
  border-left: 1px solid var(--athens-gray);
  border-right: 1px solid var(--athens-gray);
  border-bottom: 1px solid var(--athens-gray);
  border-radius: 0 0 8px 8px;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 24px;
  color: var(--manatee);
  margin-top: 40px;
`;

export const Footer = styled.div`
  pointer-events: none;

  & button:nth-child(3) {
    padding: 6px;
  }

  p {
    color: var(--manatee);
    font-weight: 400;
    font-size: 14px;

    &:nth-child(1) {
      padding-right: 10px;
    }

    &:nth-child(4) {
      padding-left: 10px;
    }
  }
`;
