import React from "react";
import Tooltip from "react-tooltip-lite";
import { CellData, CollectionName } from "../styles";
import * as Styles from "./styles";
import { pluralize } from "../../../../utils";
import {
  DEFAULT_RFQ_IMAGE,
  TT_WHITE_CLASS,
  defaultValue,
} from "../../../../constants";

interface IStyle {
  tna_id: number;
  name: string;
  image: string;
  tna_quantity: number;
  color: string;
}

interface IStylesPopupProps {
  styles: Array<IStyle> | null;
}

const StylesPopup = ({ styles }: IStylesPopupProps) => {
  if (!styles) {
    return <CellData>{defaultValue}</CellData>;
  }

  return (
    <Styles.Wrapper>
      <Styles.Text>
        {styles.length} {pluralize("style", styles.length)}
      </Styles.Text>
      <Tooltip
        content={
          <Styles.SKUWrapper>
            {styles.map((style) => (
              <Styles.SKU className="d-flex" key={style.name + style.tna_id}>
                <img src={style.image ?? DEFAULT_RFQ_IMAGE} alt={style.name} />
                <div className="v-d-flex">
                  {`${style.name ?? defaultValue}`}
                  <span>
                    TNA {style.tna_id}, {style.tna_quantity}pcs
                  </span>
                </div>
              </Styles.SKU>
            ))}
          </Styles.SKUWrapper>
        }
        mouseOutDelay={300}
        arrow={false}
        className={`${TT_WHITE_CLASS} tooltip-without-padding`}
      >
        <CollectionName style={{ fontSize: "12px" }}>View</CollectionName>
      </Tooltip>
    </Styles.Wrapper>
  );
};

export default StylesPopup;
