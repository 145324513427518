import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  max-height: 45vh;
`;
