import { Toast } from '@fashinza/fashinza-design-system';
import { ObjectType } from '../../../@types/defined';
import { dynamicSort } from '../../../utils';
import { ThunkActionType } from '../../types';
import { getBrandsListApi, getSuppliersListApi } from '../../../api/entities';

const getSearchStringFromObject = (searchObject: ObjectType) => {
  const query = Object.entries(searchObject).reduce(
    (accumulator, currentValue) => {
      let thisReturnValue = '';
      if (accumulator.length > 0) {
        thisReturnValue = '&';
      }
      const [key, value] = currentValue;
      return `${accumulator + thisReturnValue}${key}=${value}`;
    },
    ''
  );
  return `?${query}`;
};

export const setSuppliersList = (data: ObjectType) => ({
  type: 'orders/SET_SUPPLIER_LIST',
  data,
});

export const setBrandsList = (data: ObjectType) => ({
  type: 'orders/SET_BRANDS_LIST',
  data,
});

export const getSupplierList =
  (queryObject = {}): ThunkActionType =>
  (dispatch, getState) => {
    if (getState().orders.supplier_list.length > 0) return;
    const searchObject = {
      pagination: false,
      ...queryObject,
    };
    const searchString = getSearchStringFromObject(searchObject);
    getSuppliersListApi(searchString)
      .then(res => {
        const { status, data, msg } = res;
        if (!status) throw msg;
        const rawData = data.map((item: ObjectType) => ({
          name: item.name,
          id: item.id.toString(),
          is_blacklisted: item.is_blacklisted,
        }));
        const sorted = rawData.sort(dynamicSort('name'));
        dispatch(setSuppliersList(sorted));
      })
      .catch((error: Error) => {
        Toast.ERROR(error.toString());
      });
  };

export const getBrandList =
  (forceFetch = false): ThunkActionType =>
  (dispatch, getState) => {
    // if already exists than return
    if (!forceFetch && getState().orders.brand_list.length > 0) return;
    // callsomeapi
    const searchObject = {
      pagination: false,
    };
    const searchString = getSearchStringFromObject(searchObject);
    getBrandsListApi(searchString)
      .then(res => {
        const { status, data, msg } = res;
        if (!status) throw msg;

        const rawData = data.map((item: ObjectType) => ({
          ...item,
          id: item.id.toString(),
        }));

        const sorted = rawData.sort(dynamicSort('name'));
        dispatch(setBrandsList(sorted));
      })
      .catch((error: Error) => {
        Toast.ERROR(error.toString());
      });
  };
