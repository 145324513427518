import { COMMON_MULTIPLE_CHOICE_OPTIONS } from '../constants';
import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'sewing_threads',
    label: 'Sewing Threads',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'main_label',
    label: 'Main Label',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'size_care_label',
    label: 'Size/Care label',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'content_label',
    label: 'Content Label',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'coo_label',
    label: 'COO Label',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'button',
    label: 'Button',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'zipper',
    label: 'Zipper',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'snaps',
    label: 'Snaps',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'price_ticket',
    label: 'Price Ticket',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'hangtag',
    label: 'Hangtag',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'polybag',
    label: 'Polybag/Hanger',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'carton',
    label: 'Carton',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'elastic',
    label: 'Elastic/Drawstring',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'beading',
    label: 'Beading',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'other_trim_field',
    label: 'Remarks',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'trim_inhouse_date',
    label: 'Trims Inhouse Date',
    inputType: InputTypeEnum.DATE,
  },
  {
    id: 'trim_cards',
    label: 'Upload Trim Card',
    inputType: InputTypeEnum.PLAIN_FILE,
  },
];
