import React, { useEffect, useRef } from 'react';
import { Button } from '@fashinza/fashinza-design-system';
import { useCustomPopper } from '../../../hooks/useCustomPopper';
import RedDot from '../../../shared/RedDot';
import useBoolean from '../../../hooks/useBoolean';
import { emptyFunction } from '../../../utils';
import ICONOGRAPHY from '../../../shared/iconography';
import EntityFilter from './EntityFilter';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import * as Styles from './styles';

const { filter: FilterIcon } = ICONOGRAPHY;

interface IEntityFilterWrapperProps {
  isEntityFilterStateDirty: boolean;
}

const EntityFilterWrapper = ({
  isEntityFilterStateDirty,
}: IEntityFilterWrapperProps) => {
  const outsideClickRef = useRef(null);

  const [isEntityFilterOpen, entityFilterOpenActions] = useBoolean();
  const [isEntityFilterClickOutsideEnabled, entityFilterClickOutsideActions] =
    useBoolean(true);

  const popperAttributes = useCustomPopper({
    strategy: 'fixed',
    placement: 'bottom-end',
  });

  useEffect(() => {
    console.log({ isEntityFilterClickOutsideEnabled });
  }, [isEntityFilterClickOutsideEnabled]);

  useOnClickOutside(outsideClickRef, () => {
    if (isEntityFilterClickOutsideEnabled) {
      entityFilterOpenActions.off();
    }
  });

  return (
    <Styles.EntityFilterTriggerWrapper
      ref={outsideClickRef}
      onClick={() => {
        entityFilterOpenActions.toggle();
      }}
      className="pos-r"
    >
      {isEntityFilterStateDirty && <RedDot />}
      <Button
        {...popperAttributes.reference}
        onClick={emptyFunction}
        variant="outline"
        colorScheme="neutral"
      >
        <FilterIcon size={20} stroke="var(--rhino)" />
      </Button>
      {isEntityFilterOpen && (
        <div
          role="presentation"
          {...popperAttributes.fixed}
          style={{
            ...popperAttributes.fixed.style,
            zIndex: 4,
          }}
        >
          <EntityFilter
            onClose={entityFilterOpenActions.off}
            entityFilterClickOutsideActions={entityFilterClickOutsideActions}
          />
        </div>
      )}
    </Styles.EntityFilterTriggerWrapper>
  );
};

export default EntityFilterWrapper;
