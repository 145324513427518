import React, { useContext } from 'react';
import { PPMFormContext } from '../index';
import Accordion from '../accessoryComponents/Accordion';
import CombinedTextFile from '../accessoryComponents/CombinedTextFile';
import PlainTextInput from '../accessoryComponents/PlainTextInput';
import { InputTypeEnum, PPMFormSectionsEnum } from '../types';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { FIELDS } from './fields';

const CuttingCheckpoints = () => {
  const { getFieldValueFromStore, onChangeText, onFileChange } = useContext(
    PPMFormContext
  );

  return (
    <Accordion
      header={
        PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.CUTTING_CHECKPOINTS]
      }
      section={PPMFormSectionsEnum.CUTTING_CHECKPOINTS}
    >
      {FIELDS.map(field => {
        switch (field.inputType) {
          case InputTypeEnum.COMBINED_TEXT_FILE: {
            if (field.attachmentSlug) {
              return (
                <CombinedTextFile
                  key={field.id}
                  id={field.id}
                  label={field.label}
                  attachmentSlug={field.attachmentSlug}
                  getFieldValueFromStore={getFieldValueFromStore}
                  onFileChange={onFileChange}
                  onChangeText={onChangeText}
                  section={PPMFormSectionsEnum.CUTTING_CHECKPOINTS}
                />
              );
            }
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.CUTTING_CHECKPOINTS}
              />
            );
          }

          case InputTypeEnum.PLAIN_TEXT: {
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.CUTTING_CHECKPOINTS}
              />
            );
          }

          default:
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.CUTTING_CHECKPOINTS}
              />
            );
        }
      })}
    </Accordion>
  );
};

export default CuttingCheckpoints;
