import React from "react";
import { IconPropsType } from "./types";

interface ICloseIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  close: "M6 18L18 6M6 6L18 18",
  "close-circle":
    "M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z",
});

const CloseIcon = ({
  stroke = "var(--rhino)",
  size = 24,
  strokeWidth = 1.25,
  viewType = "close",
  ...props
}: ICloseIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseIcon;
