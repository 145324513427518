import React from "react";
import { IconPropsType } from "../types";

interface IImageFileIconProps extends IconPropsType {}

const ImageFileIcon = (props: IImageFileIconProps) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={40} height={40} rx={4} fill="var(--early-dawn)" />
    <path
      d="M25.8333 13H14.1667C13.2462 13 12.5 13.7462 12.5 14.6667V26.3333C12.5 27.2538 13.2462 28 14.1667 28H25.8333C26.7538 28 27.5 27.2538 27.5 26.3333V14.6667C27.5 13.7462 26.7538 13 25.8333 13Z"
      fill="var(--kournikova)"
      stroke="var(--pizazz)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.083 18.8332C17.7734 18.8332 18.333 18.2735 18.333 17.5832C18.333 16.8928 17.7734 16.3332 17.083 16.3332C16.3927 16.3332 15.833 16.8928 15.833 17.5832C15.833 18.2735 16.3927 18.8332 17.083 18.8332Z"
      stroke="var(--pizazz)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5003 22.9999L23.3337 18.8332L14.167 27.9999"
      stroke="var(--pizazz)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImageFileIcon;
