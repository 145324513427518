import { z } from 'zod';

const ReportDataItemSchema = z.object({
  id: z.number(),
  sku_name: z.string(),
});

export type ReportDataItemType = z.infer<typeof ReportDataItemSchema>;

export const ClubbedReportsDataSchema = z.object({
  review_reports: z.array(ReportDataItemSchema),
  failed_reports: z.array(ReportDataItemSchema),
  ongoing_reports: z.array(ReportDataItemSchema),
  rejected_reports: z.array(ReportDataItemSchema),
  report_id: z.number().nullable(),
  rejected: z.boolean(),
  resume: z.boolean(),
  create_new: z.boolean(),
  cancel: z.boolean(),
  in_review: z.boolean(),
  ongoing: z.boolean(),
  failed: z.boolean(),
});

export type ClubbedReportsDataType = z.infer<typeof ClubbedReportsDataSchema>;

export const FinalInspectionReportUrlSchema = z.object({
  url: z.string().url().nullable(),
});

export type FinalInspectionReportUrlType = z.infer<
  typeof FinalInspectionReportUrlSchema
>;

export const FinalInspectionAnalyticsSchema = z.object({
  critical_defects: z.number(),
  major_defects: z.number(),
  minor_defects: z.number(),
  total_sample_size: z.number(),
  total_styles: z.number(),
  total_scheduled_quantity: z.number(),
  average_styles: z.number(),
  top_five_defects: z.record(z.number()),
  observed_quality_level_percentage: z.number(),
  current_month_reject_and_short: z.number(),
  previous_month_reject_and_short: z.number(),
});

export type FinalInspectionAnalyticsType = z.infer<
  typeof FinalInspectionAnalyticsSchema
>;

export const SampleInspectionAnalyticsSchema = z.object({
  total_created: z.number(),
  total_approved: z.number(),
  current_month_total_created: z.number(),
  current_month_approval_percentage: z.number(),
  past_month_total: z.number(),
  previous_month_approval_percentage: z.number(),
  interval_based_comparison_data: z.array(
    z.object({
      interval: z.union([z.string(), z.number()]),
      approved: z.number(),
      created: z.number(),
    })
  ),
});

export type SampleInspectionAnalyticsType = z.infer<
  typeof SampleInspectionAnalyticsSchema
>;
