import React, { useContext } from 'react';
import Accordion from '../accessoryComponents/Accordion';
import DateSelector from '../accessoryComponents/DateSelector';
import MultipleChoiceInput from '../accessoryComponents/MultipleChoiceInput';
import PlainTextInput from '../accessoryComponents/PlainTextInput';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { PPMFormContext } from '../index';
import { InputTypeEnum, PPMFormSectionsEnum } from '../types';
import { FIELDS } from './fields';

const Material = () => {
  const {
    getFieldValueFromStore,
    onChangeRadio,
    onChangeText,
    onChangeDay,
  } = useContext(PPMFormContext);

  return (
    <Accordion
      header={PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.MATERIAL]}
      section={PPMFormSectionsEnum.MATERIAL}
    >
      {FIELDS.map(field => {
        switch (field.inputType) {
          case InputTypeEnum.MULTIPLE_RADIO: {
            if (field.options) {
              return (
                <MultipleChoiceInput
                  key={field.id}
                  field={field.id}
                  label={field.label}
                  options={field.options}
                  value={getFieldValueFromStore(field.id)}
                  onChangeRadio={onChangeRadio}
                  section={PPMFormSectionsEnum.MATERIAL}
                />
              );
            }
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.MATERIAL}
              />
            );
          }

          case InputTypeEnum.PLAIN_TEXT:
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.MATERIAL}
              />
            );

          case InputTypeEnum.DATE:
            return (
              <DateSelector
                key={field.id}
                field={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeDay={onChangeDay}
                section={PPMFormSectionsEnum.MATERIAL}
              />
            );

          default:
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.MATERIAL}
              />
            );
        }
      })}
    </Accordion>
  );
};

export default Material;
