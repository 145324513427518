import React from "react";
import { IconPropsType } from "./types";

interface IAttachmentIconProps extends IconPropsType {}

const AttachmentIcon = ({
  stroke = "var(--rhino)",
  strokeWidth = 1.25,
  size = 24,
  ...props
}: IAttachmentIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12.172 5-6.586 6.586a2 2 0 1 0 2.828 2.828l6.414-6.586a4 4 0 0 0-5.656-5.656L2.757 8.757a6 6 0 1 0 8.486 8.486L17.5 11"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AttachmentIcon;
