import React from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import * as Styles from "./styles";
import ICONOGRAPHY from "../iconography";

const { drag: DragIcon } = ICONOGRAPHY;

interface IDraggableEachOptionProps {
  label: string;
  isStatic: boolean;
  isChecked: boolean;
  onChange(): void;
  innerRef?: DraggableProvided["innerRef"];
  wrapperProps?: Record<string, unknown>;
}

const DraggableEachOption = ({
  label,
  isStatic,
  isChecked,
  onChange,
  innerRef,
  wrapperProps,
}: IDraggableEachOptionProps) => (
  <Styles.EachOption
    ref={innerRef}
    className="spread"
    {...(wrapperProps ?? {})}
  >
    <div className="align-center">
      <DragIcon size={18} stroke="var(--manatee)" />
      <Styles.OptionLabel isDisabled={isStatic}>{label}</Styles.OptionLabel>
    </div>
    {!isStatic && (
      <Styles.ToggleWrapper isChecked={isChecked} onChange={onChange} />
    )}
  </Styles.EachOption>
);

export default DraggableEachOption;
