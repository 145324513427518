import React from 'react';
import { IconPropsType } from './types';

interface IBorderedGreenTickProps extends IconPropsType {
  isHighlighted: boolean;
}

const BorderedGreenTick = (props: IBorderedGreenTickProps) => {
  const { isHighlighted } = props;
  const fill = isHighlighted ? 'var(--pacific-blue)' : 'var(--mischka)';

  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9375 8.16998C13.9375 11.4492 11.2792 14.1075 8 14.1075C4.72081 14.1075 2.0625 11.4492 2.0625 8.16998C2.0625 4.89079 4.72081 2.23248 8 2.23248C11.2792 2.23248 13.9375 4.89079 13.9375 8.16998ZM8 15.9825C12.3147 15.9825 15.8125 12.4847 15.8125 8.16998C15.8125 3.85526 12.3147 0.357483 8 0.357483C3.68528 0.357483 0.1875 3.85526 0.1875 8.16998C0.1875 12.4847 3.68528 15.9825 8 15.9825Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83709 6.08709L7.16667 8.75751L6.16291 7.75375C5.7968 7.38764 5.2032 7.38764 4.83709 7.75375C4.47097 8.11987 4.47097 8.71346 4.83709 9.07958L6.50375 10.7462C6.67957 10.9221 6.91803 11.0208 7.16667 11.0208C7.41531 11.0208 7.65376 10.9221 7.82958 10.7462L11.1629 7.41291C11.529 7.0468 11.529 6.4532 11.1629 6.08709C10.7968 5.72097 10.2032 5.72097 9.83709 6.08709Z"
        fill={fill}
      />
    </svg>
  );
};

export default BorderedGreenTick;
