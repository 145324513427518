import React from "react";
import { IconPropsType } from "./types";

interface IFilterAlternateIconProps extends IconPropsType {}

const FilterAlternateIcon = ({
  size = 24,
  stroke = "var(--rhino)",
  strokeWidth = 1.25,
  ...props
}: IFilterAlternateIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 6H21M6.25 12H17.75M9.5 18H14.5"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterAlternateIcon;
