import React, { ChangeEvent } from 'react';
// import { GrAttachment } from 'react-icons/gr';
import { BsTrashFill } from 'react-icons/bs';
import {
  OptionsType,
  OtherTestReportsType,
  PPMFormSectionsEnum,
} from '../../types';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import { useTypedSelector } from '../../../../hooks';
import { AttachmentType } from '../../../../@types/defined';
import SimpleDropdown from '../../../../shared/SimpleDropdown/simpleDropdown';
// import VerticalFileUpload from '../../../../components/VerticalFileUpload';
// import FileDocument from '../../../../components/fileDocument/fileDocument';
import * as Styles from './styles';
import * as CommonStyles from '../styles';

interface ICombinedDropdownFileProps {
  id: string;
  label: string;
  options: OptionsType;
  value: OtherTestReportsType; // *  component specifically made for this type
  onChangeOtherReportName: (identifier: string, value: string) => void;
  onChangeOtherReportAttachment: (
    identifier: string,
    value: Array<AttachmentType>
  ) => void;
  deleteOtherReport: (identifier: string) => void;
  addOtherReport: () => void;
  section: PPMFormSectionsEnum;
}

const CombinedDropdownFile = ({
  id,
  label,
  options,
  value,
  onChangeOtherReportName,
  addOtherReport,
  deleteOtherReport,
  onChangeOtherReportAttachment,
  section,
}: ICombinedDropdownFileProps) => {
  const credentials = useTypedSelector(store => store.credentials.tna);

  const allOtherReports = [...value];

  return (
    <Styles.Wrapper className="align-stretch">
      <Styles.PaddedLabel>
        {label} <ShowAsteriskIfMandatory field={id} section={section} />
      </Styles.PaddedLabel>
      <Styles.InputGroupContainer className="v-d-flex">
        {allOtherReports.map(item => (
          <Styles.InputGroup key={item.identifier} className="center">
            <Styles.InputGroupUpper>
              <SimpleDropdown
                labelPlaceholder="Type of Report"
                options={options}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  onChangeOtherReportName(item.identifier, event.target.value)
                }
                value={item.name}
                dataname={id}
                dropdownClass="dropdownClass"
                dropdownPlaceholderClass="dropdownPlaceholderClass"
                dropdownBoxClass="dropdownBoxClass"
                dropDownItemClass="dropDownItemClass"
                dropDownItemActiveClass="dropDownItemActiveClass"
              />
              {/* <VerticalFileUpload /> */}
              {/* TODO:use VFU */}
              {/* <FileDocument
                noLink
                btnText="Attach Files"
                dataname={label}
                placeholder={label}
                hasOneInput
                value={item.attachments}
                onChange={(
                  event: ChangeEvent<HTMLInputElement>,
                  data: Array<AttachmentType>
                ) => onChangeOtherReportAttachment(item.identifier, data)}
                readOnly={false}
                credentials={credentials}
                leftIcon={<GrAttachment />}
                allowMultiple={false}
              /> */}
            </Styles.InputGroupUpper>

            {value.length > 1 && (
              <CommonStyles.DeleteButton
                onClick={() => deleteOtherReport(item.identifier)}
              >
                <BsTrashFill size={12} color="var(--lynch)" />
              </CommonStyles.DeleteButton>
            )}
          </Styles.InputGroup>
        ))}

        <Styles.AddMoreButton onClick={addOtherReport}>
          Add more files
        </Styles.AddMoreButton>
      </Styles.InputGroupContainer>
    </Styles.Wrapper>
  );
};

export default CombinedDropdownFile;
