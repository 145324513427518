import React from 'react';
import RedAsterisk from '../iconography';
import './textInputWithLabel.scss';

export const REGEX_NUM = new RegExp('^\\d*$');

export const isStringNumber = (
  e,
  onChange,
  max = undefined,
  min = undefined
) => {
  const value = String(e.target.value);
  if (REGEX_NUM.test(value)) {
    if (max && Number(max) && Number(value) && Number(value) > max) return;
    if (min && Number(min) && Number(value) && Number(value) < min) return;
    if (onChange) return onChange(e);
    else return true;
  }
  return false;
};

export function isStringDecimal(value, max, min) {
  if (!value) {
    return true;
  }
  const reg = /^[0-9]*\.?[0-9]*$/;
  if (reg.test(value)) {
    if (max && Number(max) && Number(value) && Number(value) > max) {
      return false;
    }
    if (min && Number(min) && Number(value) && Number(value) < min) {
      return false;
    }
    return true;
  }
  return false;
}

const TextInputWithLabel = ({
  className = '',
  datalabel,
  placeholder,
  dataname,
  value,
  max = 0,
  min = 0,
  onChange,
  isDisabled = false,
  errorClass = '',
  isNumber = false,
  isDecimal = false,
  warning_text = null,
  onKeyUp = event => {},
  inputProps = {},
  shouldAutoFocus = true,
  focusColor = 'watermelon',
  asteriskCount = 0,
  type = 'text',
}) => (
  <div className={`TextInputWithLabel-wrapper ${className}`}>
    <label>
      {datalabel}
      <RedAsterisk count={asteriskCount} />
      <input
        type={type}
        placeholder={placeholder}
        data-name={dataname}
        value={value}
        onChange={
          isDecimal
            ? e => isStringDecimal(e, onChange, max, min)
            : isNumber
            ? e => isStringNumber(e, onChange, max, min)
            : onChange
        }
        disabled={isDisabled}
        className={[errorClass, focusColor].join(' ')}
        onKeyUp={onKeyUp}
        autoFocus={shouldAutoFocus}
        {...inputProps}
      />
    </label>
    {warning_text && <div className="warning-text">{warning_text}</div>}
  </div>
);

TextInputWithLabel.defaultProps = {
  value: '',
  inputProps: {},
};

export default TextInputWithLabel;
