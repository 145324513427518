import Cookies from 'js-cookie';
import config from '../config';
import { getIsCookiesEnabled } from './cookies';
import { removeItemFromStorage } from './localStorage';
import { store } from '../redux';

const authKey = config.TOKEN ?? '';

export function getDomainForCookiePreferences(): {
  domain?: string;
} {
  const cookieDomain = config.COOKIE_DOMAIN;

  if (!cookieDomain) {
    return {};
  }

  return {
    domain: cookieDomain,
  };
}

const domainObject = getDomainForCookiePreferences();

const cookiePreferences = {
  ...domainObject,
  expires: new Date('Fri, 31 Dec 9999 23:59:59 GMT'),
};

export enum AuthenticationActionEnum {
  SetIsLoading = 'auth/SET_IS_LOADING',
  SetLoginIdentifier = 'auth/SET_LOGIN_IDENTIFIER',
  SetShowOtpInput = 'auth/SET_SHOW_OTP_INPUT',
  SetIsCheckingAuth = 'auth/SET_IS_CHECKING_AUTH',
  SetUser = 'auth/SET_USER',
  ResetUser = 'auth/RESET_USER',
  SetToken = 'auth/SET_TOKEN',
  SetRedirectUrl = 'auth/SET_REDIRECT_URL',
  SetIsSupplierOsActive = 'auth/SET_IS_SUPPLIER_OS_ACTIVE',
}

export function setAuthToken(value: string) {
  store.dispatch({
    type: AuthenticationActionEnum.SetToken,
    token: value,
  });
  if (getIsCookiesEnabled()) {
    Cookies.set(authKey, value, cookiePreferences);
  }
}

export const getAuthToken = () => {
  const { token } = store.getState().user;
  if (token) {
    return token;
  }
  if (token !== null) {
    store.dispatch({
      type: AuthenticationActionEnum.SetToken,
      token: null,
    });
  }

  if (!getIsCookiesEnabled()) {
    return null;
  }

  let cookieValue: string | null = Cookies.get(authKey) ?? null;
  if (cookieValue) {
    setAuthToken(cookieValue);
    return cookieValue;
  }
  cookieValue = localStorage.getItem(authKey);
  if (cookieValue) {
    setAuthToken(cookieValue);
    removeItemFromStorage('token');
  }
  return cookieValue;
};
