import React, { useContext } from 'react';
import { PPMFormContext } from '../index';
import Accordion from '../accessoryComponents/Accordion';
import PlainTextInput from '../accessoryComponents/PlainTextInput';
import { PPMFormSectionsEnum } from '../types';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { FIELDS } from './fields';

const SewingCheckPoints = () => {
  const { getFieldValueFromStore, onChangeText } = useContext(PPMFormContext);

  return (
    <Accordion
      header={PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.SEWING_CHECKPOINTS]}
      section={PPMFormSectionsEnum.SEWING_CHECKPOINTS}
    >
      {FIELDS.map(field => (
        <PlainTextInput
          key={field.id}
          id={field.id}
          label={field.label}
          value={getFieldValueFromStore(field.id)}
          onChangeText={onChangeText}
          section={PPMFormSectionsEnum.SEWING_CHECKPOINTS}
        />
      ))}
    </Accordion>
  );
};

export default SewingCheckPoints;
