import React, { SyntheticEvent } from 'react';
import { OptionsType, PPMFormSectionsEnum } from '../../types';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import { IPreProductionMeetingForm } from '../../../../@types/ppm';
import { AttachmentType } from '../../../../@types/defined';
import VerticalFileUpload from '../../../../shared/VerticalFileUpload';
import * as CommonStyles from '../styles';
import * as Styles from './styles';
import RadioButton from '../../../../shared/RadioButton';

interface ICombinedRadioFileProps {
  id: keyof IPreProductionMeetingForm;
  attachmentSlug: keyof IPreProductionMeetingForm;
  label: string;
  options: OptionsType;
  onChangeRadio: (event: SyntheticEvent, booleanValue?: boolean) => void;
  onFileChange: (
    field: keyof IPreProductionMeetingForm,
    newFiles: Array<AttachmentType>,
    actionType: string
  ) => void;
  getFieldValueFromStore: (
    field: keyof IPreProductionMeetingForm
  ) => string | Array<AttachmentType> | null;
  section: PPMFormSectionsEnum;
}

const CombinedRadioFile = ({
  id,
  attachmentSlug,
  label,
  options,
  onChangeRadio,
  onFileChange,
  getFieldValueFromStore,
  section,
}: ICombinedRadioFileProps) => {
  const isCheckedIfBoolean = (booleanValue: boolean) =>
    getFieldValueFromStore(id) !== null
      ? Boolean(getFieldValueFromStore(id)) === booleanValue
      : false;

  return (
    <Styles.Wrapper className="align-center">
      <CommonStyles.Label>
        {label} <ShowAsteriskIfMandatory field={id} section={section} />
      </CommonStyles.Label>
      <Styles.InputGroup className="full-flex d-flex">
        {options.map(option => (
          <RadioButton
            key={option.id}
            dataname={id}
            datalabel={option.name}
            datavalue={option.id}
            onChange={(event: SyntheticEvent) => {
              onChangeRadio(event, option.booleanValue);
            }}
            isChecked={
              option.booleanValue !== undefined
                ? isCheckedIfBoolean(option.booleanValue)
                : getFieldValueFromStore(id) === option.id
            }
            item={undefined}
          />
        ))}

        {Boolean(getFieldValueFromStore(id)) && (
          <VerticalFileUpload
            className="forced-to-be-horizontal"
            labelText="Attach Document"
            maxListFilesToShow={5}
            // canUpload={Boolean(getFieldValueFromStore(id))}
            allFiles={
              (getFieldValueFromStore(
                attachmentSlug
              ) as Array<AttachmentType>) ?? []
            }
            onSetFile={(newFiles, actionType) =>
              onFileChange(attachmentSlug, newFiles, actionType)
            }
          />
        )}
      </Styles.InputGroup>
    </Styles.Wrapper>
  );
};

export default CombinedRadioFile;
