import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 320px;
  padding: 16px;
  background: var(--white);
  border: 1px solid var(--athens-gray);
  box-shadow: 0px 16px 32px rgba(44, 53, 86, 0.15);
  border-radius: 8px;
  gap: 12px;
  margin-top: 4px;
`;

export const Content = styled.div`
  gap: 8px;
`;

export const Footer = styled.div`
  gap: 12px;

  button {
    flex: 1;
  }
`;

export const DropdownLabel = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--rhino);
`;

export const DropdownWrapper = styled.div`
  gap: 4px;

  .dropdownWithSearch-wrapper {
    overflow: visible;
    border: 1px solid var(--athens-gray);
    border-radius: 4px;

    .dropdownWithSearch-value {
      height: 40px;
      border: none;
      padding: 8px 8px 8px 16px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      .value {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .dropdownBox-wrapper {
      width: 300px;

      .text-input-wrapper {
        min-height: 30px;
        input {
          border: none;
          &:hover,
          &::active {
            border: none;
          }
        }
      }
    }
    .dropdownBox-list {
      .item {
        padding: 10px 16px;
        font-size: 14px;
      }
    }
  }
`;
