import React, { ChangeEvent } from 'react';
import FilterOptionsDropdown from './FilterOptionsDropdown';
import { FilterOptionsType } from './types';
import ICONOGRAPHY from '../../iconography';
import * as Styles from './styles';
import { useCustomPopper } from '../../../hooks/useCustomPopper';
import useBoolean from '../../../hooks/useBoolean';
import { ObjectType } from '../../../@types/defined';
import ClickOutside from '../../ClickOutside';
import { SAME_WIDTH_MODIFIER } from '../../../constants/popper';

const SAME_WIDTH_POPPER_OPTIONS = Object.freeze({
  strategy: 'fixed',
  placement: 'bottom-start',
  modifiers: [SAME_WIDTH_MODIFIER],
});

const { 'double-arrow': SortIcon, 'filter-alternate': FiltersIcon } =
  ICONOGRAPHY;

interface IHeaderContentProps {
  fieldValue: string;
  fieldLabel: string;
  showFilter?: boolean;
  isSortable?: boolean;
  handleSort?: (fieldValue: string) => void;
  onChangeFilter?: (
    data: ObjectType,
    event?: ChangeEvent<HTMLInputElement>
  ) => void;
  filterOptions?: FilterOptionsType;
  canHover?: boolean;
  className?: string;
}

const HeaderContent = ({
  fieldValue,
  fieldLabel,
  showFilter,
  isSortable,
  handleSort,
  onChangeFilter,
  filterOptions,
  canHover = true,
  className = '',
}: IHeaderContentProps) => {
  const [areFilterOptionsShown, filterOptionsShownActions] = useBoolean();
  const popperAttributes = useCustomPopper(SAME_WIDTH_POPPER_OPTIONS);

  return (
    <Styles.Wrapper
      className={`spread full-dimension ${className}`}
      isActive={areFilterOptionsShown}
      canHover={canHover}
      {...popperAttributes.reference}
    >
      {fieldLabel}
      <div>
        {isSortable && (
          <Styles.IconWrapper
            className="cursor-pointer center"
            onClick={() => handleSort?.(fieldValue)}
          >
            <SortIcon size={16} />
          </Styles.IconWrapper>
        )}
        {showFilter && (
          <Styles.IconWrapper
            className="cursor-pointer center"
            onClick={filterOptionsShownActions.toggle}
            isActive={areFilterOptionsShown}
          >
            <FiltersIcon size={16} />
            {areFilterOptionsShown && (
              <ClickOutside onClose={filterOptionsShownActions.off}>
                <FilterOptionsDropdown
                  fieldValue={fieldValue}
                  fixedAttributes={popperAttributes.fixed}
                  onChangeFilter={onChangeFilter}
                  filterOptions={filterOptions}
                />
              </ClickOutside>
            )}
          </Styles.IconWrapper>
        )}
      </div>
    </Styles.Wrapper>
  );
};

export default HeaderContent;
