import React from 'react';

const Pdf2Icon = () => (
  <svg
    width="20"
    height="26"
    viewBox="0 0 20 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3749_35357)">
      <path
        d="M2.53954 9.22049e-05C1.13959 9.22049e-05 0 1.20592 0 2.68611V23.314C0 24.7942 1.13959 26 2.53954 26H17.4573C18.8572 26 19.9968 24.7942 19.9968 23.314V6.35213C19.9968 6.35213 20.0368 5.85545 19.8329 5.37214C19.6417 4.9191 19.3302 4.60377 19.3302 4.60377C19.3294 4.60266 19.3286 4.60155 19.3279 4.60044L15.6267 0.761864C15.6247 0.759674 15.6226 0.757506 15.6206 0.755362C15.6206 0.755362 15.3046 0.437206 14.8361 0.224668C14.2846 -0.0255844 13.7161 0.000833024 13.7161 0.000833024L13.7246 0L2.53954 9.22049e-05Z"
        fill="#FA5762"
      />
      <path
        d="M2.53907 0.882324H13.7241C13.7269 0.882355 13.7298 0.882355 13.7326 0.882324C13.7326 0.882324 14.1798 0.886907 14.507 1.03535C14.8219 1.17821 15.0451 1.39448 15.0466 1.39593C15.0466 1.39593 15.0482 1.39759 15.0482 1.39759L18.7401 5.22721C18.7401 5.22721 18.9625 5.47552 19.0703 5.73105C19.1573 5.9373 19.1627 6.31465 19.1627 6.31465C19.1623 6.32088 19.162 6.32712 19.1619 6.33336V23.3139C19.1619 24.3207 18.409 25.1176 17.4568 25.1176H2.53907C1.58688 25.1176 0.833984 24.3208 0.833984 23.3139V2.68606C0.833984 1.6792 1.58688 0.882356 2.53907 0.882356V0.882324Z"
        fill="#F5F5F5"
      />
      <path
        d="M4.99285 15.4801C4.41872 14.873 5.03989 14.0388 6.72565 13.153L7.78635 12.5957L8.19958 11.6397C8.42686 11.1139 8.76606 10.2559 8.95337 9.73315L9.29394 8.78264L9.05925 8.07921C8.77068 7.21428 8.66778 5.9145 8.85087 5.44713C9.09887 4.81406 9.9112 4.87901 10.2328 5.55763C10.484 6.08762 10.4583 7.04743 10.1606 8.25788L9.91647 9.25025L10.1315 9.63616C10.2497 9.84842 10.5951 10.3524 10.899 10.7561L11.4702 11.5074L12.181 11.4093C14.439 11.0978 15.2124 11.6273 15.2124 12.3865C15.2124 13.3447 13.4394 13.4237 11.9505 12.318C11.6155 12.0693 11.3854 11.8221 11.3854 11.8221C11.3854 11.8221 10.4527 12.0229 9.99349 12.1538C9.51946 12.2889 9.28297 12.3735 8.5886 12.6213C8.5886 12.6213 8.34497 12.9954 8.18622 13.2673C7.59558 14.2792 6.90594 15.1177 6.41344 15.4229C5.86201 15.7646 5.28392 15.7878 4.99285 15.4801ZM5.89386 15.1397C6.21659 14.9288 6.86979 14.112 7.32196 13.3538L7.50503 13.0468L6.67145 13.49C5.384 14.1744 4.79502 14.8195 5.10129 15.2097C5.27334 15.4289 5.47916 15.4107 5.89386 15.1397ZM14.2566 12.6577C14.5723 12.4239 14.5264 11.9528 14.1696 11.7629C13.8918 11.6151 13.668 11.5847 12.9465 11.596C12.5031 11.6279 11.7902 11.7224 11.6694 11.7511C11.6694 11.7511 12.061 12.0374 12.2349 12.1426C12.4664 12.2824 13.0289 12.5418 13.4396 12.6748C13.8447 12.8059 14.079 12.7921 14.2566 12.6577V12.6577ZM10.894 11.18C10.703 10.9677 10.3782 10.5245 10.1722 10.1951C9.90278 9.82157 9.76769 9.55801 9.76769 9.55801C9.76769 9.55801 9.57078 10.2277 9.40924 10.6307L8.90523 11.9479L8.75911 12.2467C8.75911 12.2467 9.53596 11.9773 9.93122 11.8682C10.3499 11.7526 11.1995 11.5759 11.1995 11.5759L10.894 11.18ZM9.81043 6.58511C9.85915 6.15275 9.87983 5.72102 9.74845 5.50331C9.38404 5.08208 8.9442 5.43335 9.0187 6.4348C9.04377 6.77169 9.12292 7.3476 9.22874 7.70256L9.42115 8.34794L9.55656 7.86186C9.63104 7.59452 9.74528 7.01998 9.81043 6.58511Z"
        fill="#FA5762"
      />
      <path
        d="M5.96973 18.2915H6.90184C7.19968 18.2915 7.44144 18.3216 7.62713 18.3819C7.81282 18.4402 7.96909 18.5714 8.09595 18.7755C8.2228 18.9777 8.28623 19.2217 8.28623 19.5074C8.28623 19.7699 8.23476 19.9954 8.1318 20.1839C8.02884 20.3725 7.89004 20.5086 7.71538 20.5922C7.54256 20.6758 7.27598 20.7176 6.91563 20.7176H6.59298V22.2718H5.96973V18.2915ZM6.59298 18.8018V20.1898H6.90184C7.17762 20.1898 7.3679 20.1353 7.4727 20.0265C7.57933 19.9176 7.63265 19.7407 7.63265 19.4958C7.63265 19.313 7.59772 19.1653 7.52785 19.0526C7.45799 18.9379 7.38077 18.8679 7.2962 18.8426C7.21347 18.8154 7.08202 18.8018 6.90184 18.8018L6.59298 18.8018Z"
        fill="#2C3556"
      />
      <path
        d="M8.76367 18.2915H9.6103C10.0203 18.2915 10.3475 18.3683 10.5921 18.5219C10.8384 18.6754 11.0241 18.9038 11.1491 19.2071C11.276 19.5104 11.3394 19.8467 11.3394 20.216C11.3394 20.6048 11.2824 20.9518 11.1684 21.257C11.0563 21.5603 10.8835 21.8052 10.65 21.9918C10.4183 22.1784 10.0874 22.2718 9.65718 22.2718H8.76367V18.2915ZM9.38692 18.8193V21.744H9.64615C10.0083 21.744 10.2712 21.6118 10.4349 21.3474C10.5985 21.0811 10.6803 20.7263 10.6803 20.2831C10.6803 19.3072 10.3356 18.8193 9.64615 18.8193H9.38692Z"
        fill="#2C3556"
      />
      <path
        d="M11.9375 18.2915H14.0279V18.8193H12.5608V20.009H13.7355V20.5368H12.5608V22.2718H11.9375V18.2915Z"
        fill="#2C3556"
      />
    </g>
    <defs>
      <clipPath id="clip0_3749_35357">
        <rect width="20" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Pdf2Icon;
