/* eslint-disable no-param-reassign */
import { Options } from '@popperjs/core';

/*
Placement is bottom.
If the popper has less space below, it truncates itself to available height only.
*/
export function getFixedHeightModifier(
  offsetY: number
): Options['modifiers'][0] {
  return {
    name: 'applyMaxSize',
    enabled: true,
    phase: 'beforeWrite',
    requiresIfExists: ['offset', 'preventOverflow', 'flip'],
    fn({ state }) {
      const maxPossibleHeight = state.elements.popper.scrollHeight;
      if (state.placement === 'bottom-end') {
        const popperOffset = state.modifiersData.popperOffsets?.y ?? 0;
        if (
          popperOffset > 0 &&
          popperOffset + maxPossibleHeight > window.innerHeight
        ) {
          state.elements.popper.style.maxHeight = `${
            window.innerHeight - popperOffset - offsetY
          }px`;
        } else {
          state.elements.popper.style.maxHeight = 'none';
        }
      } else {
        state.elements.popper.style.maxHeight = 'none';
      }
    },
  };
}
