import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";

export const Wrapper = styled.div`
  padding: 12px 0;

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextInputWrapper = styled.div`
  width: 320px;

  ${device.mediumViewport} {
    width: 100%;
  }

  .TextInputWithLabel-wrapper input {
    margin-top: 0;
    height: 40px;
  }
`;
