import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";

export const Wrapper = styled.div`
  padding: 12px 0;

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DateSelectorWrapper = styled.div`
  width: 320px;
  border: 1px solid var(--loblolly);
  border-radius: 4px;
  padding-right: 16px;

  ${device.mediumViewport} {
    width: 100%;
  }

  .DayPickerInput {
    flex-grow: 1;

    input {
      height: 40px;
    }
  }
`;
