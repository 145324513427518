import {
  OptionsType,
  PPMFormSectionsEnum,
  SectionAccordionType,
  SectionLoadersType,
} from "./types";
import { ObjectType } from "../../@types/defined";
import { IPreProductionMeetingForm } from "../../@types/ppm";

export const PPM_FORM_SECTION_HEADINGS: ObjectType = Object.freeze({
  [PPMFormSectionsEnum.MATERIAL]: "Material",
  [PPMFormSectionsEnum.TRIM]: "Trim",
  [PPMFormSectionsEnum.CUTTING_CHECKPOINTS]: "Cutting Checkpoints",
  [PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS]:
    "Finishing/Packing Checkpoints",
  [PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS]:
    "Pending Issues and Approvals",
  [PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION]:
    "Pre-Production Preparation",
  [PPMFormSectionsEnum.PRODUCTION_PLANNING]: "Production Planning",
  [PPMFormSectionsEnum.PRODUCTION_WORKSHEET]: "Production Worksheet",
  // [PPMFormSectionsEnum.PROJECTED_INSPECTION_DATES]:
  //   'Projected/Inspection Dates',
  [PPMFormSectionsEnum.SEWING_CHECKPOINTS]: "Sewing Checkpoints",
  [PPMFormSectionsEnum.MANAGE_ATTENDEES]: "Manage Attendees",
  [PPMFormSectionsEnum.OTHERS]: "Others",
  [PPMFormSectionsEnum.SAMPLE_DETAILS]: "Sample Details",
  [PPMFormSectionsEnum.SUBMIT_FORM]: "Submit Form",
});

type PPMFormSectionFieldsType = {
  [key in PPMFormSectionsEnum]: Array<Partial<keyof IPreProductionMeetingForm>>;
};

export const PPM_FORM_SECTION_FIELDS: PPMFormSectionFieldsType = Object.freeze({
  [PPMFormSectionsEnum.MATERIAL]: [
    "shell_fabric",
    "lining_fabric",
    "interlining_fabric",
    "trims_fabric",
    "screen_print",
    "embroidery",
    "other_material_field",
    "fabric_inhouse_date",
  ],
  [PPMFormSectionsEnum.TRIM]: [
    "sewing_threads",
    "main_label",
    "size_care_label",
    "content_label",
    "coo_label",
    "button",
    "zipper",
    "snaps",
    "price_ticket",
    "hangtag",
    "polybag",
    "carton",
    "elastic",
    "beading",
    "other_trim_field",
    "trim_inhouse_date",
    "trim_cards",
  ],
  [PPMFormSectionsEnum.CUTTING_CHECKPOINTS]: [
    "cutting_marker",
    "cutting_marker_document",
    "cutting_notches",
    "cutting_direction",
  ],
  [PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS]: [
    "washing_standard",
    "washing_standard_remark",
    "washing_standard_attachment",
    "ironing_standard",
    "ironing_standard_remark",
    "ironing_standard_attachment",
    "packing_standard",
    "packing_standard_remark",
    "packing_standard_attachment",
    "packing_assortment",
    "packing_assortment_remark",
    "packing_assortment_attachment",
    "hangtag_ticket_approval",
    "hangtag_ticket_approval_remark",
    "hangtag_ticket_approval_attachment",
    "carton_marking",
    "carton_marking_remark",
    "carton_marking_attachment",
  ],
  [PPMFormSectionsEnum.MANAGE_ATTENDEES]: ["attendees_list", "new_attendees"],
  [PPMFormSectionsEnum.OTHERS]: ["other_comments"],
  [PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS]: [
    "pending_issues_and_approvals",
  ],
  [PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION]: [
    "is_fabric_inspection_required",
    "fabric_inspection_document",
    "is_fabric_relaxation_required",
    "fabric_relaxation_document",
    "is_shadelot_identification_approval_required",
    "shadelot_identification_approval_document",
    "is_fabric_shrinkage_check_required",
    "fabric_shrinkage_check_document",
    "is_approved_techpack_required",
    "approved_techpack_document",
    "is_fpt_report_required",
    "fpt_report_document",
    "is_gpt_report_required",
    "gpt_report_document",
    "other_preproduction_planning",
    "is_trim_card_approval_required",
    "trim_card_approval_document",
  ],
  [PPMFormSectionsEnum.PRODUCTION_PLANNING]: [
    "cutting_start_date",
    "cutting_end_date",
    "cutting_not_available",
    "sewing_start_date",
    "sewing_end_date",
    "sewing_not_available",
    "washing_start_date",
    "washing_end_date",
    "washing_not_available",
    "finishing_start_date",
    "finishing_end_date",
    "finishing_not_available",
    "packing_start_date",
    "packing_end_date",
    "packing_not_available",
    "lines_allocated_count",
    "estimated_daily_sewing_output",
  ],
  [PPMFormSectionsEnum.PRODUCTION_WORKSHEET]: [
    "style_description_and_construction",
    "size_color_ratio",
    "order_quantity_and_color_size",
    "approved_graded_size_specifications",
    "label_position",
    "hangtag_position",
    "packing_instruction",
    "ucc_label",
  ],
  // [PPMFormSectionsEnum.PROJECTED_INSPECTION_DATES]: [
  //   'inline_inspection_date',
  //   'midline_inspection_date',
  //   'final_random_inspection_date',
  //   'other_projected_inspection_dates',
  // ],
  [PPMFormSectionsEnum.SEWING_CHECKPOINTS]: [
    "stitch_density",
    "needle_size",
    "machine_folders_attachments",
    "seam_allowance",
    "sewing_checkpoint_others",
  ],
  [PPMFormSectionsEnum.SUBMIT_FORM]: ["is_ppm_submitted"],
  [PPMFormSectionsEnum.SAMPLE_DETAILS]: [
    "other_sample_history",
    "approved_sample",
  ],
});

/*
 * Reference: https://docs.google.com/spreadsheets/d/1OmhmP5BNovEztOGX74xJmEizG38VEJCL4KAdhXXOGRc/edit#gid=0
 */
export const PPM_FORM_SECTION_MANDATORY_FIELDS: PPMFormSectionFieldsType =
  Object.freeze({
    [PPMFormSectionsEnum.MATERIAL]: [
      "shell_fabric",
      "lining_fabric",
      "interlining_fabric",
      "trims_fabric",
      "screen_print",
      "embroidery",
      "fabric_inhouse_date",
    ],
    [PPMFormSectionsEnum.TRIM]: [
      "sewing_threads",
      "main_label",
      "size_care_label",
      "content_label",
      "coo_label",
      "button",
      "zipper",
      "snaps",
      "price_ticket",
      "hangtag",
      "polybag",
      "carton",
      "elastic",
      "beading",
      "trim_inhouse_date",
    ],
    [PPMFormSectionsEnum.CUTTING_CHECKPOINTS]: [
      "cutting_marker",
      "cutting_marker_document",
      "cutting_notches",
      "cutting_direction",
    ],
    [PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS]: [
      "washing_standard",
      "washing_standard_remark",
      "washing_standard_attachment",
      "ironing_standard",
      "ironing_standard_remark",
      "ironing_standard_attachment",
      "packing_standard",
      "packing_standard_remark",
      "packing_standard_attachment",
      "packing_assortment",
      "packing_assortment_remark",
      "packing_assortment_attachment",
      "hangtag_ticket_approval",
      "hangtag_ticket_approval_remark",
      "hangtag_ticket_approval_attachment",
      "carton_marking",
      "carton_marking_remark",
      "carton_marking_attachment",
    ],
    [PPMFormSectionsEnum.MANAGE_ATTENDEES]: [],
    [PPMFormSectionsEnum.OTHERS]: [],
    [PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS]: [],
    [PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION]: [
      "is_fabric_inspection_required",
      "fabric_inspection_document",
      "is_fabric_relaxation_required",
      "fabric_relaxation_document",
      "is_shadelot_identification_approval_required",
      "shadelot_identification_approval_document",
      "is_fabric_shrinkage_check_required",
      "fabric_shrinkage_check_document",
      "is_approved_techpack_required",
      "approved_techpack_document",
      "is_fpt_report_required",
      "fpt_report_document",
      "is_gpt_report_required",
      "gpt_report_document",
      "is_trim_card_approval_required",
      "trim_card_approval_document",
    ],
    [PPMFormSectionsEnum.PRODUCTION_PLANNING]: [
      "cutting_start_date",
      "cutting_end_date",
      "cutting_not_available",
      "sewing_start_date",
      "sewing_end_date",
      "sewing_not_available",
      "washing_start_date",
      "washing_end_date",
      "washing_not_available",
      "finishing_start_date",
      "finishing_end_date",
      "finishing_not_available",
      "packing_start_date",
      "packing_end_date",
      "packing_not_available",
      "lines_allocated_count",
      "estimated_daily_sewing_output",
    ],
    [PPMFormSectionsEnum.PRODUCTION_WORKSHEET]: [
      "style_description_and_construction",
      "size_color_ratio",
      "order_quantity_and_color_size",
      "approved_graded_size_specifications",
      "label_position",
      "hangtag_position",
      "packing_instruction",
      "ucc_label",
    ],
    // [PPMFormSectionsEnum.PROJECTED_INSPECTION_DATES]: [
    //   'inline_inspection_date',
    //   'midline_inspection_date',
    //   'final_random_inspection_date',
    // ],
    [PPMFormSectionsEnum.SEWING_CHECKPOINTS]: [
      "stitch_density",
      "needle_size",
      "machine_folders_attachments",
      "seam_allowance",
    ],
    [PPMFormSectionsEnum.SUBMIT_FORM]: ["is_ppm_submitted"],
    [PPMFormSectionsEnum.SAMPLE_DETAILS]: [],
  });

export const INITIAL_PPM_STAGING: IPreProductionMeetingForm = {
  id: null,

  // * MATERIAL fields
  shell_fabric: null,
  lining_fabric: null,
  interlining_fabric: null,
  trims_fabric: null,
  screen_print: null,
  embroidery: null,
  other_material_field: null,
  fabric_inhouse_date: null,

  // * TRIM fields
  sewing_threads: null,
  main_label: null,
  size_care_label: null,
  content_label: null,
  coo_label: null,
  button: null,
  zipper: null,
  snaps: null,
  price_ticket: null,
  hangtag: null,
  polybag: null,
  carton: null,
  elastic: null,
  beading: null,
  other_trim_field: null,
  trim_inhouse_date: null,
  trim_cards: [],

  // * Production Worksheet fields
  style_description_and_construction: null,
  size_color_ratio: null,
  order_quantity_and_color_size: null,
  approved_graded_size_specifications: null,
  label_position: null,
  hangtag_position: null,
  packing_instruction: null,
  ucc_label: null,

  // * Production PLanning fields
  cutting_start_date: null,
  cutting_end_date: null,
  cutting_not_available: null,
  sewing_start_date: null,
  sewing_end_date: null,
  sewing_not_available: null,
  washing_start_date: null,
  washing_end_date: null,
  washing_not_available: null,
  finishing_start_date: null,
  finishing_end_date: null,
  finishing_not_available: null,
  packing_start_date: null,
  packing_end_date: null,
  packing_not_available: null,
  lines_allocated_count: null,
  estimated_daily_sewing_output: null,

  // * Pre Production Planning Fields
  is_fabric_inspection_required: null,
  fabric_inspection_document: null,
  is_fabric_relaxation_required: null,
  fabric_relaxation_document: null,
  is_shadelot_identification_approval_required: null,
  shadelot_identification_approval_document: null,
  is_fabric_shrinkage_check_required: null,
  fabric_shrinkage_check_document: null,
  is_approved_techpack_required: null,
  approved_techpack_document: null,
  is_fpt_report_required: null,
  fpt_report_document: null,
  is_gpt_report_required: null,
  gpt_report_document: null,
  other_preproduction_planning: [],
  is_trim_card_approval_required: null,
  trim_card_approval_document: null,

  // * Projected Inspection Dates Fields
  // inline_inspection_date: null,
  // midline_inspection_date: null,
  // final_random_inspection_date: null,
  // other_projected_inspection_dates: null,

  // *  Cutting Fields
  cutting_marker: null,
  cutting_marker_document: null,
  cutting_notches: null,
  cutting_direction: null,

  // * Sewing Checkpoint fields
  stitch_density: null,
  needle_size: null,
  machine_folders_attachments: null,
  seam_allowance: null,
  sewing_checkpoint_others: null,

  // * Finishing / Packing checkpoint fields
  washing_standard: null,
  washing_standard_remark: null,
  washing_standard_attachment: null,
  ironing_standard: null,
  ironing_standard_remark: null,
  ironing_standard_attachment: null,
  packing_standard: null,
  packing_standard_remark: null,
  packing_standard_attachment: null,
  packing_assortment: null,
  packing_assortment_remark: null,
  packing_assortment_attachment: null,
  hangtag_ticket_approval: null,
  hangtag_ticket_approval_remark: null,
  hangtag_ticket_approval_attachment: null,
  carton_marking: null,
  carton_marking_remark: null,
  carton_marking_attachment: null,

  // * Pending issues / approval fields
  pending_issues_and_approvals: null,

  // * Others fields
  other_comments: null,

  // * Manage Attendees fields
  attendees_list: null,
  new_attendees: [],

  // * Submit Form fields
  is_ppm_submitted: null,

  // *  sample detail fields
  previous_qc_reports: [],
  other_sample_history: null,
  approved_sample: null,

  // * review flow related
  reason: null,
  result: null,
};

export const NEGATIVE_CHOICES = ["not_required", "not_available"];

export const REQUIREMENT_MULTIPLE_CHOICE_OPTIONS: OptionsType = [
  { name: "Required", id: "required", booleanValue: true },
  { name: "Not Required", id: "not_required", booleanValue: false },
];

export const DONE_NOT_DONE_CHOICE_OPTIONS: OptionsType = [
  { name: "Done", id: "done", booleanValue: true },
  { name: "Not Done", id: "not_done", booleanValue: false },
];

export const AVAILABILITY_MULTIPLE_CHOICE_OPTIONS: OptionsType = [
  { name: "Available", id: "available", booleanValue: true },
  { name: "Not Available", id: "not_available", booleanValue: false },
];

export const NON_BOOLEAN_AVAILABILITY_MULTIPLE_CHOICE_OPTIONS: OptionsType = [
  { name: "Available", id: "available" },
  { name: "Not Available", id: "not_available" },
];

export const CONFIRMATION_MULTIPLE_CHOICE_OPTIONS: OptionsType = [
  { name: "Confirmed", id: "confirmed" },
  { name: "Not Confirmed", id: "not_confirmed" },
];

export const COMMON_MULTIPLE_CHOICE_OPTIONS: OptionsType = [
  { name: "Not Available", id: "not_available" },
  { name: "Not as Approved", id: "not_as_approved" },
  { name: "Check as approved", id: "check_as_approved" },
  { name: "Not Applicable", id: "not_applicable" },
];

export const PASS_FAIL_CHOICE_OPTIONS: OptionsType = [
  { name: "Pass", id: "pass" },
  { name: "Fail", id: "fail" },
  { name: "Awaited", id: "awaited" },
  { name: "Not Required", id: "not_required" },
];

export const APPAREL_TEST_OPTIONS = [
  { id: "color_shading", name: "Color shading" },
  { id: "rub_test", name: "Colorfastness check (Rub test)" },
  {
    id: "fabric_weight_test",
    name: "Fabric weight test (for knitted garments)",
  },
  { id: "waterproof_test", name: "Waterproof test" },
  {
    id: "adhesive_check",
    name: "Adhesive check (logos, printings, markings fastness)",
  },
  { id: "down_feather_leakage_test", name: "Down feather leakage testing" },
  { id: "zip_quality_test", name: "Fasteners fatigue and zip quality test" },
  { id: "ventilation_test", name: "Ventilation test" },
  {
    id: "metal_contamination_prevention",
    name: "Metal contamination prevention",
  },
  {
    id: "mold_contamination_prevention",
    name: "Mold contamination prevention",
  },
  { id: "burn_test", name: "Burn test (for 100% cotton garment)" },
  {
    id: "needle_damage_check",
    name: "Needle damage check (for knitted garment)",
  },
  { id: "seam_slippage_test", name: "Seam slippage test (for woven garments)" },
  { id: "eco_textile_testing", name: "Eco textile testing" },
  { id: "shrinkage_test", name: "Shrinkage test" },
];

export const NEW_ATTENDEES_ROLE_OPTIONS: OptionsType = [
  { name: "Quality Head", id: "quality_head" },
  { name: "Quality Manager", id: "quality_manager" },
  { name: "Quality Associate", id: "quality_associate" },
];

export const INITIAL_SECTION_LOADERS: SectionLoadersType = Object.freeze({
  [PPMFormSectionsEnum.MATERIAL]: false,
  [PPMFormSectionsEnum.TRIM]: false,
  [PPMFormSectionsEnum.CUTTING_CHECKPOINTS]: false,
  [PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS]: false,
  [PPMFormSectionsEnum.MANAGE_ATTENDEES]: false,
  [PPMFormSectionsEnum.OTHERS]: false,
  [PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS]: false,
  [PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION]: false,
  [PPMFormSectionsEnum.PRODUCTION_PLANNING]: false,
  [PPMFormSectionsEnum.PRODUCTION_WORKSHEET]: false,
  [PPMFormSectionsEnum.SAMPLE_DETAILS]: false,
  [PPMFormSectionsEnum.SEWING_CHECKPOINTS]: false,
  [PPMFormSectionsEnum.SUBMIT_FORM]: false,
  [PPMFormSectionsEnum.SAMPLE_DETAILS]: false,
  // [PPMFormSectionsEnum.PROJECTED_INSPECTION_DATES]: false,
});

// * PRODUCTION PLANNING FIELDS TO HANDLE EDGE CASES
export const PRODUCTION_PLANNING_DEPENDENT_FIELDS: ObjectType = Object.freeze({
  cutting_not_available: ["cutting_start_date", "cutting_end_date"],
  sewing_not_available: ["sewing_start_date", "sewing_end_date"],
  finishing_not_available: ["finishing_start_date", "finishing_end_date"],
  washing_not_available: ["washing_start_date", "washing_end_date"],
  packing_not_available: ["packing_start_date", "packing_end_date"],
});

export const PRODUCTION_PLANNING_INDEPENDENT_FIELDS: Array<
  Partial<keyof IPreProductionMeetingForm>
> = ["lines_allocated_count", "estimated_daily_sewing_output"];

export const INITIAL_SECTION_ACCORDION_STATES: SectionAccordionType =
  Object.freeze({
    [PPMFormSectionsEnum.MATERIAL]: {
      isOpen: false,
      next: PPMFormSectionsEnum.TRIM,
    },
    [PPMFormSectionsEnum.TRIM]: {
      isOpen: false,
      next: PPMFormSectionsEnum.SAMPLE_DETAILS,
    },
    [PPMFormSectionsEnum.SAMPLE_DETAILS]: {
      isOpen: false,
      next: PPMFormSectionsEnum.PRODUCTION_WORKSHEET,
    },
    [PPMFormSectionsEnum.PRODUCTION_WORKSHEET]: {
      isOpen: false,
      next: PPMFormSectionsEnum.PRODUCTION_PLANNING,
    },
    [PPMFormSectionsEnum.PRODUCTION_PLANNING]: {
      isOpen: false,
      next: PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION,
    },
    [PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION]: {
      isOpen: false,
      next: PPMFormSectionsEnum.CUTTING_CHECKPOINTS,
    },
    [PPMFormSectionsEnum.CUTTING_CHECKPOINTS]: {
      isOpen: false,
      next: PPMFormSectionsEnum.SEWING_CHECKPOINTS,
    },
    [PPMFormSectionsEnum.SEWING_CHECKPOINTS]: {
      isOpen: false,
      next: PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS,
    },
    [PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS]: {
      isOpen: false,
      next: PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS,
    },
    [PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS]: {
      isOpen: false,
      next: PPMFormSectionsEnum.OTHERS,
    },
    [PPMFormSectionsEnum.OTHERS]: {
      isOpen: false,
      next: PPMFormSectionsEnum.MANAGE_ATTENDEES,
    },
    [PPMFormSectionsEnum.MANAGE_ATTENDEES]: {
      isOpen: false,
      next: null,
    },
    [PPMFormSectionsEnum.SUBMIT_FORM]: {
      isOpen: true,
      next: null,
    },
  });
