import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@fashinza/fashinza-design-system';
import {
  setDashboardFilterDates,
  setDashboardSubSection,
} from '../../../redux/actions/dashboard';
import {
  DATE_FILTER_OPTIONS,
  SAMPLE_INSPECTION_SUB_SECTIONS,
} from './constants';
import DateRangePicker from './DateRangePicker';
import QualityTracker from '../../mixpanel';
import { QualityTrackerActionsEnum } from '../../../analytics/sources';
import { useTypedSelector } from '../../../hooks';
import { emptyFunction, removeFalsyKeys, swallowEvent } from '../../../utils';
import useBoolean from '../../../hooks/useBoolean';
import { useCustomPopper } from '../../../hooks/useCustomPopper';
import { IQualityDashboardState } from '../../../@types/dashboard';
import ICONOGRAPHY from '../../../shared/iconography';
import DropdownControls from '../../../shared/DropdownControls';
import EntityFilterWrapper from './EntityFilterWrapper';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import * as Styles from './styles';

const {
  calendar: CalenderIcon,
  'chevron-down': ChevronDown,
  check: CheckIcon,
  'chevron-right': ChevronRight,
} = ICONOGRAPHY;

const SubHeader = () => {
  const dispatch = useDispatch();
  const outsideCalenderClickRef = useRef(null);

  const popperAttributes = useCustomPopper({
    strategy: 'fixed',
    placement: 'bottom-end',
  });

  const [isCalendarOpen, calendarActions] = useBoolean();

  const section = useTypedSelector(store => store.quality.dashboard.section);
  const currentSubSection = useTypedSelector(
    store => store.quality.dashboard.subSection
  );
  const dateFiltersText = useTypedSelector(
    store => store.quality.dashboard.dateFilters.text
  );
  const { startDate, endDate } = useTypedSelector(
    store => store.quality.dashboard.dateFilters
  );
  const entityFilters = useTypedSelector(
    store => store.quality.dashboard.entityFilters
  );

  function changeSubSection(subSection: IQualityDashboardState['subSection']) {
    dispatch(setDashboardSubSection(subSection));
  }

  const isEntityFilterStateDirty =
    Object.keys(removeFalsyKeys(entityFilters)).length > 0;

  useOnClickOutside(outsideCalenderClickRef, () => {
    calendarActions.off();
  });

  if (section === 'manage-qa') {
    return null;
  }

  return (
    <Styles.Wrapper className="spread">
      <Styles.Left className="d-flex">
        {currentSubSection &&
          SAMPLE_INSPECTION_SUB_SECTIONS.map(item => (
            <Styles.Pill
              key={item.id}
              className="center cursor-pointer"
              isActive={item.id === currentSubSection}
              onClick={() => {
                changeSubSection(item.id);
                QualityTracker.track(
                  QualityTrackerActionsEnum.ChangeSubSection,
                  {
                    sub_section: item.id,
                  }
                );
              }}
            >
              {item.name}
            </Styles.Pill>
          ))}
      </Styles.Left>

      <Styles.Right className="flex-end">
        <DropdownControls
          trigger={
            <Button
              onClick={emptyFunction}
              variant="outline"
              colorScheme="neutral"
              sizeVariant="medium"
              leftIcon={<CalenderIcon size={20} stroke="var(--rhino)" />}
              rightIcon={<ChevronDown size={20} stroke="var(--rhino)" />}
            >
              {dateFiltersText}
            </Button>
          }
          sections={[
            {
              options: DATE_FILTER_OPTIONS.map(item => ({
                text: (
                  <Styles.DateFilterItem
                    className="spread"
                    key={item.text}
                    isSelected={item.text === dateFiltersText}
                  >
                    {item.text}
                    {item.text === dateFiltersText && (
                      <CheckIcon stroke="var(--all-ports)" />
                    )}
                  </Styles.DateFilterItem>
                ),
                onClick: () =>
                  dispatch(
                    setDashboardFilterDates({ ...item.dates, text: item.text })
                  ),
              })),
            },
            {
              options: [
                {
                  onClick: calendarActions.on,
                  text: (
                    <Styles.DateFilterItem
                      className="spread"
                      key="custom_date"
                      isSelected={dateFiltersText === 'Custom Date'}
                    >
                      Custom Date
                      <ChevronRight
                        stroke={
                          dateFiltersText === 'Custom Date'
                            ? 'var(--all-ports)'
                            : 'var(--rhino)'
                        }
                      />
                    </Styles.DateFilterItem>
                  ),
                },
              ],
            },
          ]}
        />

        {isCalendarOpen && (
          <div ref={outsideCalenderClickRef}>
            <div {...popperAttributes.fixed}>
              <DateRangePicker
                endDate={endDate}
                startDate={startDate}
                onClose={calendarActions.off}
                onChangeDays={(newStartDate, newEndDate) => {
                  dispatch(
                    setDashboardFilterDates({
                      startDate: newStartDate,
                      endDate: newEndDate,
                      text: 'Custom Date',
                    })
                  );
                }}
              />
            </div>
          </div>
        )}

        <EntityFilterWrapper
          isEntityFilterStateDirty={isEntityFilterStateDirty}
        />
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default SubHeader;
