import React from "react";
import { IconPropsType } from "./types";

interface IGiftIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  "slashed-no":
    "M12 8V21M12 8C12 8 12 6.50722 12 6C12 4.89543 12.8954 4 14 4C15.1046 4 16 4.89543 16 6C16 7.10457 15.1046 8 14 8C13.4027 8 12 8 12 8ZM12 8C12 8 12 6.06291 12 5.5C12 4.11929 10.8807 3 9.5 3C8.11929 3 7 4.11929 7 5.5C7 6.88071 8.11929 8 9.5 8C10.3178 8 12 8 12 8ZM5 12H19M5 12C3.89543 12 3 11.1046 3 10C3 8.89543 3.89543 8 5 8H19C20.1046 8 21 8.89543 21 10C21 11.1046 20.1046 12 19 12M5 12L5 19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V12",
  "slashed-yes":
    "M12 8C12 8 12 6.50722 12 6C12 4.89543 12.8954 4 14 4C15.1046 4 16 4.89543 16 6C16 7.10457 15.1046 8 14 8M12 8C12 8 13.4027 8 14 8M12 8C12 8 12 6.06291 12 5.5C12 4.11929 10.8807 3 9.5 3C8.11929 3 7 4.11929 7 5.5C7 6.88071 8.11929 8 9.5 8C10.3178 8 12 8 12 8ZM12 8V12M12 21V15.5M5 12C3.89543 12 3 11.1046 3 10C3 8.89543 3.89543 8 5 8M5 12L5 19C5 20.1046 5.89543 21 7 21H17M5 12H8.5M19 12C20.1046 12 21 11.1046 21 10C21 8.89543 20.1046 8 19 8H14M19 12V19M19 12H12M12 12L21 21M12 12L8 8L4 4",
});

const GiftIcon = ({
  stroke = "var(--rhino)",
  size = 24,
  strokeWidth = 1.25,
  viewType = "slashed-no",
  ...props
}: IGiftIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GiftIcon;
