import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";

export const Wrapper = styled.div`
  padding: 12px 0;

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const OptionsWrapper = styled.div`
  gap: 56px;
  width: 80%;

  ${device.mediumViewport} {
    width: 100%;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 12px;
  }

  .radiobutton-wrapper {
    font-weight: 400;
    font-size: 14px;
    color: var(--rhino);
    min-width: 110px;
  }

  .radiobutton-label {
    border: 1px solid var(--rhino);
  }
`;
