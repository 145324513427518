/* eslint-disable max-lines */
import { BaseSyntheticEvent } from 'react';
import { ObjectType } from '../@types/defined';

export function openInNewTab(url: string) {
  window.open(url, '_blank')?.focus();
}

export function openInSameTab(url: string) {
  window.open(url, '_self')?.focus();
}

export const sanitizeUrl = (url: string) =>
  url.startsWith('?') ? url.slice(1) : '';

export function getObjectKeys<T extends ObjectType>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}

export function isArray(array: any): array is Array<any> {
  return Array.isArray(array);
}

/**
 *
 * @returns whether the provided parameter is of type `object` but **not**
 *	`null`, an `array`, a `regexp`, nor a `date`.
 */
export function isObject(obj: unknown): boolean {
  // The method can't do a type cast since there are type (like strings) which
  // are subclasses of any put not positvely matched by the function. Hence type
  // narrowing results in wrong results.
  return (
    typeof obj === 'object' &&
    obj !== null &&
    !Array.isArray(obj) &&
    !(obj instanceof RegExp) &&
    !(obj instanceof Date)
  );
}

export function isEmptyArray(value: any): boolean {
  return isArray(value) && value.length === 0;
}

export function isEmptyObject(value: any): boolean {
  if (isObject(value)) {
    return Object.keys(value).length === 0;
  }
  return true;
}

// Empty assertions
export function isEmpty(value: any): boolean {
  if (isArray(value)) {
    return isEmptyArray(value);
  }
  if (isObject(value)) {
    return isEmptyObject(value);
  }
  return Boolean(value == null || value === '');
}

export function removeFalsyKeys(object: ObjectType) {
  let result: ObjectType = {};
  getObjectKeys(object).forEach(eachKey => {
    const value = object[eachKey];
    if (!isEmpty(value)) {
      result = {
        ...result,
        [eachKey]: value,
      };
    }
  });
  return result;
}

export const emptyFunction = () => {};

export function getInitialsNames(name: string) {
  const [firstName, lastName] = name.split(' ');
  const calculatedName =
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : firstName.charAt(0);
  // TODO: Find a better fix than F. @sourav_maity
  return calculatedName.length > 0 ? calculatedName : 'F';
}

export function pluralize(str: string, counter: number, suffix = 's'): string {
  return counter === 1 ? str : `${str}${suffix}`;
}

export function omit<T extends ObjectType, TKey extends keyof T>(
  object: T,
  keysToRemove: Array<TKey>
) {
  const result: ObjectType = {};
  const stringifiedKeys = keysToRemove.map(x => String(x));

  Object.keys(object).forEach(eachKey => {
    if (stringifiedKeys.includes(eachKey)) {
      return;
    }
    result[eachKey] = object[eachKey];
  });

  return result as Omit<T, TKey>;
}

export function roundWithPrecision(num: number, precision: number) {
  const multiplier = 10 ** precision;
  return Math.round(num * multiplier) / multiplier;
}

export function numberWithCommas(number: number) {
  // TODO: Find a better alternative than Intl.
  // https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group/51568859
  // return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  return Intl.NumberFormat().format(number);
}

export const titleCase = (string = '') => {
  const newString = string.toLowerCase().split(' ');
  if (newString.length) {
    return newString
      .map(function (word) {
        if (!word) return '';
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ');
  }
  return '';
};

export const removeUnderscoresAndCapitalize = (str: string | number) =>
  titleCase(String(str)).replaceAll('_', ' ');

export function reorderArray<T>(
  array: Array<T>,
  startIndex: number,
  endIndex: number
): Array<T> {
  const result = Array.from(array);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function swallowEvent(event: BaseSyntheticEvent) {
  event.preventDefault();
  event.stopPropagation();
}

export function createArrayOfSize(elementCount: number) {
  return Array.from(Array(elementCount).keys());
}
export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function formatNumberToLocale(numberToFormat: number, locale?: 'en-IN') {
  return new Intl.NumberFormat(locale).format(numberToFormat);
}

export const detectKeyPress = (e: any) => {
  const returnobj: ObjectType = {};
  if ('key' in e) {
    switch (e.key) {
      case 'Escape':
      case 'Esc': {
        returnobj['escape'] = true;
        break;
      }
      case 'ArrowLeft': {
        returnobj['leftarrow'] = true;
        break;
      }
      case 'ArrowUp': {
        returnobj['uparrow'] = true;
        break;
      }
      case 'ArrowRight': {
        returnobj['rightarrow'] = true;
        break;
      }
      case 'ArrowDown': {
        returnobj['downarrow'] = true;
        break;
      }
      case 'Enter': {
        returnobj['enter'] = true;
        break;
      }
      case 'Backspace': {
        returnobj['Backspace'] = true;
        break;
      }
      default:
        break;
    }
  } else {
    switch (e.keyCode) {
      case 27:
      case '27': {
        returnobj['escape'] = true;
        break;
      }
      case 37:
      case '37': {
        returnobj['leftarrow'] = true;
        break;
      }
      case 38:
      case '38': {
        returnobj['uparrow'] = true;
        break;
      }
      case 39:
      case '39': {
        returnobj['rightarrow'] = true;
        break;
      }
      case 40:
      case '40': {
        returnobj['downarrow'] = true;
        break;
      }
      case 13:
      case '13': {
        returnobj['enter'] = true;
        break;
      }
      default:
        break;
    }
  }
  return returnobj;
};

export function getQueryAndParamsFromQueryString(search: string) {
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return { params, query: urlSearchParams.toString() };
}

// time: '12:30:45' --> 12:30 PM, '00:20:12'--->12:20 AM
export function get12HourTimeString(time: string) {
  const [hours, minutes, seconds] = time.split(':');
  const beforeAfterNoon = Number(hours) > 11 ? 'PM' : 'AM';
  const newHours = Number(hours) > 12 ? Number(hours) - 12 : hours;
  let twelveHourTimeStr = '';
  if (hours === '00') {
    twelveHourTimeStr = `12:${minutes} ${beforeAfterNoon}`;
  } else {
    twelveHourTimeStr = `${newHours}:${minutes} ${beforeAfterNoon}`;
  }
  return twelveHourTimeStr;
}

// date: '2022-04-13' ---> '13/04/22'
export function getShortDate(date: string) {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year.substring(year.length - 2)}`;
}

export function convertArrayToObject<T extends ObjectType>(
  arrayElements: Array<T>,
  idElement: keyof T
): Record<string, T> {
  return arrayElements.reduce(
    (accumulator, eachElement) => ({
      ...accumulator,
      [eachElement[idElement]]: eachElement,
    }),
    {} as Record<string, T>
  );
}

export function get24HourTimeString(
  hours: number,
  minutes: number,
  amPm: string,
  seconds?: number
) {
  let newSeconds = '00';
  if (seconds) {
    newSeconds = String(seconds);
  }
  let timeString = '';
  let newHour = hours;
  if (['pm', 'P.M.', 'p.m.', 'PM'].includes(amPm)) {
    newHour = (12 + newHour) % 24;
    if (newHour === 0) {
      newHour = 12;
    }
  }
  if (['am', 'A.M.', 'a.m.', 'AM'].includes(amPm) && newHour === 12) {
    newHour = 0;
  }
  timeString = `${newHour === 0 ? '00' : newHour}:${
    minutes === 0 ? '00' : minutes
  }:${newSeconds}`;
  return timeString;
}

export function removeDuplicatesFromArray<T>(array: Array<T>) {
  return Array.from(new Set([...array]));
}

export const dynamicSort = (property: string) => {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a: ObjectType, b: ObjectType) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    }
    return a[property].localeCompare(b[property]);
  };
};
