import styled from "styled-components";
import { device } from "../../../constants/mediaQueries";

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  margin: 20px 0;
  font-weight: 500;
  font-size: 14px;
  color: var(--lynch);
  position: relative;

  ${device.mediumViewport} {
    margin: 6px 0;
    border-bottom: 1px solid var(--athens-gray);
    padding: 12px 0;
  }

  i {
    color: var(--mischka);
    margin-right: 8px;
    font-style: normal;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    color: var(--rhino);
    padding: 0 4px;

    ${device.mediumViewport} {
      font-size: 12px;
      padding: 4px 4px 4px 0;
    }
  }

  button {
    padding: 0 12px;
    font-size: 14px;
    color: var(--rhino);

    &:hover,
    &:focus {
      color: var(--rhino);
    }

    ${device.mediumViewport} {
      position: absolute;
      right: 5px;
    }
  }
`;

export const PlainText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: var(--lynch);
  padding-left: 8px;

  ${device.mediumViewport} {
    font-size: 20px;
  }
`;

export const ReadyStateWrapper = styled.div`
  ${device.mediumViewport} {
    height: 60vh;
    gap: 40px;
    text-align: center;
  }
`;
