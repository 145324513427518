import React, { PureComponent } from 'react';
import { swallowEvent } from '../../utils';
import Portal from '../Portal';
import ClickOutside from '../ClickOutside';
import './toggleDropdownControl.scss';
import ICONOGRAPHY from '../iconography';

const { ThreeDot } = ICONOGRAPHY;

export const getCoords = e => {
  const rect = e.currentTarget.getBoundingClientRect();
  return {
    left: rect.x,
    top: rect.y + window.scrollY,
    bottom: rect.bottom + window.scrollY,
  };
};

class ToggleDropdownControl extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      coords: {},
    };
  }

  hideDropdown = () => this.setState({ show: false, coords: {} });

  toggleDropdown = e => {
    if (!this.state.show) {
      const coords = getCoords(e);
      this.setState({ coords, show: !this.state.show });
      const { trackingFunction } = this.props;
      if (trackingFunction) {
        trackingFunction();
      }
    } else {
      this.hideDropdown();
    }
    swallowEvent(e);
  };

  render() {
    const { show, coords } = this.state;
    const {
      data,
      listitemClass,
      listitemcontainerClass,
      portalWrapperClass = '',
      onSelect = undefined, // genric cb if indivisual onClick not provided
      renderIcon = undefined,
      iconWrapperClass = '',
      listSectionHeaderClass = '',
    } = this.props;

    return (
      <ClickOutside onClose={this.hideDropdown}>
        <div
          className={[
            'toggleDropdownControl-wrapper',
            listitemcontainerClass,
          ].join(' ')}
        >
          <Portal>
            {show && (
              <div
                className={[
                  'toggleDropdownControl-portal',
                  portalWrapperClass,
                ].join(' ')}
                style={{
                  position: 'absolute',
                  right: window.innerWidth - coords.left,
                  top: coords.bottom,
                }}
              >
                {data.map((item, index) => {
                  if (item.hideOption) return null;
                  const {
                    icon = '',
                    id = '',
                    name,
                    dataProps = {},
                    onClick,
                  } = item;
                  if (item.isSectionHeader) {
                    return (
                      <>
                        {name && (
                          <div
                            className={[
                              'toggleDropdownControl-section-header',
                              listSectionHeaderClass,
                            ].join(' ')}
                          >
                            {name}
                          </div>
                        )}
                        {item.subMenu.map(subItem => {
                          const { onClick: subItemOnClick } = subItem;

                          return (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                            <div
                              key={subItem.name}
                              className={[
                                'toggleDropdownControl-list-item',
                                subItem.isDisabled ? 'disabled-item' : '',
                                listitemClass,
                              ].join(' ')}
                              onClick={e => {
                                if (subItemOnClick) subItemOnClick(e);
                                else if (onSelect) onSelect(e);
                                this.hideDropdown();
                                swallowEvent(e);
                              }}
                              data-id={subItem.id}
                              {...dataProps}
                            >
                              {subItem.icon && (
                                <div className={iconWrapperClass}>
                                  {subItem.icon}
                                </div>
                              )}
                              <div
                                className={
                                  subItem.isIrreversibleAction
                                    ? 'irreversible-action-text'
                                    : ''
                                }
                              >
                                {subItem.name}
                              </div>
                            </div>
                          );
                        })}
                        {index !== data.length - 1 && (
                          <div className="toggleDropdown-horizontal-divider" />
                        )}
                      </>
                    );
                  }
                  return (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div
                      key={item.name}
                      className={[
                        'toggleDropdownControl-list-item',
                        listitemClass,
                      ].join(' ')}
                      onClick={e => {
                        if (onClick) onClick(e);
                        else if (onSelect) onSelect(e);
                        this.hideDropdown();
                        swallowEvent(e);
                      }}
                      data-id={id}
                      {...dataProps}
                    >
                      {icon && <div className={iconWrapperClass}>{icon}</div>}
                      <div>{name}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </Portal>

          <div
            className="toggle-btn-container pos-r"
            onClick={this.toggleDropdown}
          >
            {renderIcon || <ThreeDot />}
          </div>
        </div>
      </ClickOutside>
    );
  }
}

export default ToggleDropdownControl;
