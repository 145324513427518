import styled, { css } from 'styled-components';
import { COLOR_MAPPING } from './constants';
import { TextVariantType } from './types';

export const ControlWrapper = styled.div<{
  isActive: boolean;
  haveDynamicWidth: boolean;
}>`
  height: 40px;
  width: ${props => (props.haveDynamicWidth ? 'auto' : '40px')};
  border-radius: 50%;

  ${props =>
    props.isActive &&
    !props.haveDynamicWidth &&
    css`
      background-color: var(--wash-me);
      box-shadow: 0 1px 2px rgba(12, 16, 33, 0.05), 0 0 0 4px var(--athens-gray);
    `}
`;

export const DropdownWrapper = styled.div`
  width: 300px;
  background-color: var(--white);
  border: 1px solid var(--athens-gray);
  border-radius: 8px;
  box-shadow: 0 32px 64px -12px rgba(12, 16, 33, 0.14);
`;

export const EachSection = styled.div`
  padding-top: 8px;
  border-bottom: 1px solid var(--athens-gray);
  &:last-of-type {
    border-bottom: none;
  }
`;

export const Label = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--lynch);
  padding: 0 16px;
  margin: 4px 0 8px;
`;

export const ListItem = styled.div`
  padding: 8px 16px;
  margin-bottom: 4px;

  &:hover {
    background-color: var(--wash-me);
  }

  svg {
    margin-right: 8px;
  }
`;

export const ItemData = styled.p<{ variant: TextVariantType }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => COLOR_MAPPING[props.variant]};
  width: 100%;
`;
