import React from "react";
import { IconPropsType } from "./types";

interface IQualityIconProps extends IconPropsType {}

const QualityIcon = ({
  stroke = "var(--rhino)",
  ...props
}: IQualityIconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.73364 15.0571C7.42056 14.9578 6.37787 13.97 6.27309 12.726C6.22818 12.193 6.00692 11.6869 5.64079 11.2799C4.7864 10.3301 4.7864 8.93309 5.64079 7.98328C6.00692 7.57626 6.22818 7.0702 6.27309 6.53713C6.37787 5.29316 7.42056 4.30535 8.73364 4.20608C9.29632 4.16354 9.8305 3.95392 10.2601 3.60707C11.2627 2.79764 12.7373 2.79764 13.7399 3.60707C14.1695 3.95392 14.7037 4.16354 15.2664 4.20608C16.5794 4.30535 17.6221 5.29316 17.7269 6.53713C17.7718 7.0702 17.9931 7.57626 18.3592 7.98328C19.2136 8.93309 19.2136 10.3301 18.3592 11.2799C17.9931 11.6869 17.7718 12.193 17.7269 12.726C17.6221 13.97 16.5794 14.9578 15.2664 15.0571M8.73364 15.0571C9.29632 15.0996 9.8305 15.3092 10.2601 15.6561C11.2627 16.4655 12.7373 16.4655 13.7399 15.6561C14.1695 15.3092 14.7037 15.0996 15.2664 15.0571M8.73364 15.0571V21L12 19.1053L15.2664 21V15.0571M9 9.63158L11 11.5263L15 7.73684"
      stroke={stroke}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default QualityIcon;
