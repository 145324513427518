import styled from "styled-components";
import { device } from "../../../constants/mediaQueries";

export const Section = styled.div`
  padding: 8px 0 16px 0;

  &:first-child {
    border-bottom: 1px solid var(--athens-gray);
  }

  .collaboratorColumn-wrapper .addmore {
    border: 1px solid var(--athens-gray);
  }

  ${device.mediumViewport} {
    flex-direction: column;

    .ManageTnaCollaborators-wrapper {
      padding-left: 12px;
    }
  }
`;

export const NewAttendeesLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: var(--manatee);
  padding: 8px 0;
  text-transform: uppercase;
`;

export const NewAttendeesItem = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 8px 0 8px 16px;

  ${device.mediumViewport} {
    margin-bottom: 12px;
    width: 100%;
  }

  .simpleDropdown-wrapper {
    height: 40px;
    flex-grow: unset;

    .label-name,
    .label-value {
      font-weight: 400;
      font-size: 14px;
      color: var(--rhino);
    }

    span {
      border: 1px solid var(--loblolly);
      border-radius: 4px;
      width: 200px;
      height: 40px;
    }

    div[role="menuitem"] {
      padding: 6px;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background-color: var(--fair-pink);
      }
    }
  }

  .TextInputWithLabel-wrapper {
    width: unset;

    input {
      margin-top: 0;
      height: 40px;
    }
  }
`;

export const NewAttendeesItemUpper = styled.div`
  display: flex;
  gap: 24px;

  ${device.mediumViewport} {
    border-bottom: 1px solid var(--athens-gray);
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-bottom: 12px;
  }

  .simpleDropdown-wrapper {
    height: 40px;
    flex-grow: unset;

    span {
      border: 1px solid var(--loblolly);
      border-radius: 4px;
      width: 200px;

      ${device.mediumViewport} {
        width: 100%;
      }
    }

    div[role="menuitem"] {
      padding: 6px;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background-color: var(--fair-pink);
      }
    }
  }

  .TextInputWithLabel-wrapper {
    width: unset;

    input {
      margin-top: 0;
      height: 40px;
    }
  }
`;

export const AddNewButton = styled.button`
  cursor: pointer;
  padding: 8px 16px;
  color: var(--rhino);
  font-weight: 600;
  font-size: 14px;
  gap: 12px;
  background: var(--wash-me);

  &:hover {
    text-decoration: underline;
  }
`;
