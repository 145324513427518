import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import DraggableEachOption from './DraggableEachOption';
import { AllFieldsType } from './types';
import * as Styles from './styles';

interface IDraggableOptionsProps {
  allFields: AllFieldsType;
  combinedSlugs: Array<string>;
  modifiedPreference: Set<string>;
  handleDragEnd(result: DropResult): void;
  handleToggleSlug(slug: string): void;
}

const DraggableOptions = ({
  allFields,
  combinedSlugs,
  modifiedPreference,
  handleDragEnd,
  handleToggleSlug,
}: IDraggableOptionsProps) => {
  const staticSlugs: IDraggableOptionsProps['combinedSlugs'] = [];
  const movableSlugs: IDraggableOptionsProps['combinedSlugs'] = [];
  for (const eachSlug of combinedSlugs) {
    const eachField = allFields[eachSlug];
    if (eachField.isStatic) {
      staticSlugs.push(eachSlug);
    } else {
      movableSlugs.push(eachSlug);
    }
  }

  return (
    <div className="full-flex has-y-scroll pos-r">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="list">
          {droppableProvided => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {staticSlugs.length > 0 && (
                <Styles.SectionArea>
                  <Styles.SectionHeader>fixed columns</Styles.SectionHeader>
                  {staticSlugs.map(eachSlug => (
                    <DraggableEachOption
                      key={eachSlug}
                      label={allFields[eachSlug].label}
                      isStatic
                      isChecked={modifiedPreference.has(eachSlug)}
                      onChange={() => handleToggleSlug(eachSlug)}
                    />
                  ))}
                </Styles.SectionArea>
              )}

              {movableSlugs.length > 0 && (
                <Styles.SectionArea>
                  <Styles.SectionHeader>
                    scrollable columns
                  </Styles.SectionHeader>
                  {movableSlugs.map((eachSlug, index) => (
                    <Draggable
                      key={eachSlug}
                      draggableId={eachSlug}
                      index={index + staticSlugs.length}
                    >
                      {draggabledProvided => (
                        <DraggableEachOption
                          innerRef={draggabledProvided.innerRef}
                          label={allFields[eachSlug].label}
                          isStatic={false}
                          isChecked={modifiedPreference.has(eachSlug)}
                          onChange={() => handleToggleSlug(eachSlug)}
                          wrapperProps={{
                            ...draggabledProvided.draggableProps,
                            ...draggabledProvided.dragHandleProps,
                          }}
                        />
                      )}
                    </Draggable>
                  ))}
                </Styles.SectionArea>
              )}

              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DraggableOptions;
