import React from "react";
import { FiDownload } from "react-icons/fi";
import ICONOGRAPHY from "../../iconography";
import * as Styles from "./styles";

const { trash: DeleteIcon } = ICONOGRAPHY;

interface IRightControlsProps {
  fileLink: string;
  shouldShow?: boolean;
  onDelete?: (() => void) | null;
}

const RightControls = ({
  fileLink,
  shouldShow = true,
  onDelete,
}: IRightControlsProps) => (
  <Styles.RightWrapper className="center" shouldShow={shouldShow}>
    <a
      href={fileLink}
      target="_blank"
      rel="noopener noreferrer"
      className="v-center"
    >
      <FiDownload size="14" stroke="var(--rhino)" />
    </a>
    {typeof onDelete !== "undefined" && onDelete !== null && (
      <button type="button" onClick={onDelete} className="v-center">
        <DeleteIcon size={16} stroke="var(--red-dirt)" strokeWidth={1.75} />
      </button>
    )}
  </Styles.RightWrapper>
);

export default RightControls;
