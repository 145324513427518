import React, { useContext } from 'react';
import { PPMFormContext } from '../index';
import Accordion from '../accessoryComponents/Accordion';
import DateIntervalSelector from '../accessoryComponents/DateIntervalSelector';
import PlainTextInput from '../accessoryComponents/PlainTextInput';
import { InputTypeEnum, PPMFormSectionsEnum } from '../types';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { FIELDS } from './fields';

const ProductionPlanning = () => {
  const {
    getFieldValueFromStore,
    onChangeText,
    onChangeDay,
    onChange,
  } = useContext(PPMFormContext);

  return (
    <Accordion
      header={
        PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.PRODUCTION_PLANNING]
      }
      section={PPMFormSectionsEnum.PRODUCTION_PLANNING}
    >
      {FIELDS.map(field => {
        switch (field.inputType) {
          case InputTypeEnum.NUMERIC_TEXT:
            return (
              <PlainTextInput
                isNumber
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRODUCTION_PLANNING}
              />
            );

          case InputTypeEnum.DATE_INTERVAL: {
            if (field.startDateSlug && field.endDateSlug && field.choiceSlug) {
              return (
                <DateIntervalSelector
                  key={field.id}
                  startDateSlug={field.startDateSlug}
                  endDateSlug={field.endDateSlug}
                  choiceSlug={field.choiceSlug}
                  label={field.label}
                  getFieldValueFromStore={getFieldValueFromStore}
                  onChangeDay={onChangeDay}
                  onChange={onChange}
                  section={PPMFormSectionsEnum.PRODUCTION_PLANNING}
                />
              );
            }
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRODUCTION_PLANNING}
              />
            );
          }

          default:
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRODUCTION_PLANNING}
              />
            );
        }
      })}
    </Accordion>
  );
};

export default ProductionPlanning;
