import React, { SyntheticEvent } from "react";
import "./styles.scss";

interface IRadioButtonProps {
  dataname: string;
  datavalue: string;
  datalabel: string;
  onChange?: (e: SyntheticEvent, item: any) => void;
  isChecked: boolean;
  item: any;
  name?: string;
  disabled?: boolean;
  onClick?: VoidFunction;
}

const RadioButton = ({
  dataname,
  datavalue,
  datalabel,
  onChange,
  isChecked,
  item,
  name = dataname,
  disabled = false,
  onClick = () => {},
}: IRadioButtonProps) => (
  <label className="radiobutton-wrapper align-center">
    <input
      disabled={disabled}
      type="radio"
      style={{ display: "none" }}
      name={name}
      data-name={dataname}
      onChange={(e) => onChange?.(e, item)}
      value={datavalue}
      checked={isChecked}
      onClick={onClick}
    />
    <span className="radiobutton-label" />
    {datalabel}
  </label>
);

export default RadioButton;
