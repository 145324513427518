import { useTypedSelector } from './index';

export function formatCountDisplay(count: number, baseCount = 9): string {
  return count > baseCount ? `${baseCount}+` : `${count}`;
}

export function useGetChatAndNotificationsCount() {
  const totalCount = useTypedSelector(
    store =>
      store.chat.unreadCount + (store.notifications.notificationsCount ?? 0)
  );
  const formattedCount = formatCountDisplay(totalCount, 99);

  return {
    totalCount,
    formattedCount,
  };
}
