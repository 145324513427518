// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { RouterState } from 'connected-react-router';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { IQualityReducer } from '../@types/base';
import { ICommonReducer, IOrderReducer } from '../@types/shared';

export interface IRootState {
  router: RouterState;
  preferences: any;
  user: any;
  quality: IQualityReducer;
  credentials: any;
  common: ICommonReducer;
  orders: IOrderReducer;
  chat: any;
  notifications: any;
  purchaseOrder: any;
}

export const useTypedSelector: TypedUseSelectorHook<IRootState> = useSelector;
