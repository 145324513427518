export const uploadFileToS3Bucket = ({
  awsCredentials,
  Bucket,
  Key,
  Body,
  ACL,
  ContentType,
  progressUpdateCallback,
}) => {
  AWS.config.update({
    accessKeyId: awsCredentials.AWS_ACCESS_KEY_ID,
    secretAccessKey: awsCredentials.AWS_SECRET_ACCESS_KEY,
    region: awsCredentials.AWS_REGION,
  });

  var objectParams = {
    Bucket, // name of the bucket
    Key, // basically filename
    Body, // compressed file
    ACL: ACL || 'public-read', // public access or not
    ContentType, // file type
  };
  var uploadObj = new AWS.S3({ apiVersion: '2006-03-01' });

  return new Promise(async (resolve, reject) => {
    try {
      await uploadObj
        .putObject(objectParams)
        .on('httpUploadProgress', progress => {
          progressUpdateCallback(
            Math.round((progress.loaded / progress.total) * 100)
          );
        })
        .promise();
      return resolve(`https://${Bucket}.s3.amazonaws.com/${Key}`);
    } catch (err) {
      return reject('');
    }
  });
};

export const uploadFile = ({
  file,
  credentials,
  progressUpdateCallback = () => {},
  customFileName = null,
}) => {
  if (
    !credentials.AWS_ACCESS_KEY_ID ||
    !credentials.AWS_REGION ||
    !credentials.AWS_SECRET_ACCESS_KEY ||
    !credentials.S3_BUCKET
  ) {
    return;
  }

  return new Promise(async (resolve, reject) => {
    const { name, type } = file;
    try {
      const hashedKey = getHashedFileName(name, getFileExt(name));
      // upload compressed image
      const imagelink = await uploadFileToS3Bucket({
        awsCredentials: credentials,
        Bucket: credentials.S3_BUCKET,
        Key: customFileName ?? `public/${hashedKey}`,
        Body: file,
        ContentType: type,
        progressUpdateCallback,
      });

      return resolve({ url: imagelink, name: name, type });
    } catch (err) {
      console.log('upload err: ', err);
      return reject({ err });
    }
  });
};
