import React from "react";
import Lottie from "react-lottie";
import { ObjectType } from "../../../../@types/defined";
import * as Styles from "./styles";

interface IOverlayLoaderProps {
  isLoading: boolean;
  animationData: ObjectType;
}

/**
 * @description Parent must have position: relative
 */
const OverlayLoader = ({ isLoading, animationData }: IOverlayLoaderProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Styles.Wrapper className="pos-a center">
      <Lottie
        isClickToPauseDisabled
        options={{
          loop: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={120}
        width={120}
      />
    </Styles.Wrapper>
  );
};

export default OverlayLoader;
