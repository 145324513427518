import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import config from '../config';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const configureStore = () => {
  const middleware = applyMiddleware(routerMiddleware(history), thunk);

  return createStore(
    rootReducer(history),
    config.IS_REDUX_ENABLED ? composeWithDevTools(middleware) : middleware
  );
};

export const store = configureStore();
