import styled, { css } from 'styled-components';
import { BiLoaderAlt } from 'react-icons/bi';
import { boxCss } from '../styles';

const loadingButtonCss = css`
  pointer-events: none;
  cursor: auto;
  background-color: var(--rhino);
`;

function getStylesAsPerViewType(viewType: 'separated' | 'single') {
  if (viewType === 'single') {
    return css`
      width: 100%;
      padding: 0 12px;
      background: var(--white);
      border: 1px solid var(--mischka);
      border-radius: 4px;

      [data-view-type='attachment'] path {
        stroke: var(--lynch);
        stroke-width: 2.6px;
      }
    `;
  }

  return css`
    background-color: var(--wash-me);
  `;
}

export const Label = styled.label<{
  isDisabled: boolean;
  isError?: boolean;
  viewType: 'separated' | 'single';
}>`
  ${boxCss};


  ${props => (props.isDisabled ? loadingButtonCss : '')}
  ${props =>
    props.isError &&
    css`
      border: 1px solid var(--red-dirt) !important;
      box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
    `}

  ${props => getStylesAsPerViewType(props.viewType)}
`;

export const Text = styled.p<{ isColor: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-left: 8px;
  ${props => (props.isColor ? '' : `color: var(--manatee);`)}
`;

export const Spinner = styled(BiLoaderAlt)`
  animation: rotate360 1s linear infinite;
`;
