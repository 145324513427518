import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 10px;
  min-width: 10px;
  border-radius: 100%;
  background-color: var(--watermelon);
`;

const RedDot = () => <Wrapper data-type="red-dot" />;

export default RedDot;
