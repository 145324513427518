import { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  SAMPLING_INSPECTION_FILTER_TO_SLUG,
  SAMPLING_INSPECTION_SLUGS,
} from "../../constants";
import { removeFalsyKeys } from "../../../../utils";
import { useTypedSelector } from "../../../../hooks";
import { getSampleInspectionAnalytics } from "../../../../redux/actions/thenables";
import useBoolean from "../../../../hooks/useBoolean";
import { ApiResponseEnum } from "../../../../@types/api";
import { SampleInspectionAnalyticsType } from "../../../../@types/finalInspection";

const INITIAL_STATE = {
  total_created: 0,
  total_approved: 0,
  current_month_total_created: 0,
  current_month_approval_percentage: 0,
  past_month_total: 0,
  previous_month_approval_percentage: 0,
  interval_based_comparison_data: [],
};

const useAnalyticsData = () => {
  const {
    section,
    subSection,
    dateFilters: { endDate, startDate },
    entityFilters: { brand, inspector, supplier },
  } = useTypedSelector((store) => store.quality.dashboard);

  const [state, setState] =
    useState<SampleInspectionAnalyticsType>(INITIAL_STATE);

  const [isLoading, loadingActions] = useBoolean();

  useEffect(() => {
    async function getData() {
      if (section !== "sample-inspection" || !subSection) {
        return;
      }

      if (["shipment-sample", "photo-shoot-sample"].includes(subSection)) {
        setState(INITIAL_STATE);
        return;
      }

      loadingActions.on();

      const sections =
        subSection === "all-samples"
          ? SAMPLING_INSPECTION_SLUGS.join(",")
          : SAMPLING_INSPECTION_FILTER_TO_SLUG[subSection];

      const query = new URLSearchParams(
        removeFalsyKeys({
          sections,
          end_date: endDate ? format(endDate, `yyyy-MM-d`) : null,
          start_date: startDate ? format(startDate, `yyyy-MM-d`) : null,
          brand,
          inspector,
          supplier,
        })
      ).toString();

      const response = await getSampleInspectionAnalytics(query);

      loadingActions.off();

      if (response.type === ApiResponseEnum.Failure) {
        return;
      }

      setState(response.data);
    }

    getData();
  }, [section, subSection, endDate, startDate, brand, inspector, supplier]);

  return { ...state, isLoading };
};

export default useAnalyticsData;
