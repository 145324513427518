/* eslint-disable max-lines */
import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Button, Toast } from '@fashinza/fashinza-design-system';
import { ObjectType } from '../../../@types/defined';
import UserCard from './UserCard';
import {
  APPROVAL_STEP_MAP,
  QARoutesEnum,
  ReviewOperationsEnum,
} from '../../constants';
import { getResultAction } from '../../helpers';
import { ResultActionEnum } from '../../ManagePPM/constants';
import QualityTracker from '../../mixpanel';
import ManageQa from './ManageQa';
import StylesPopup from './StylesPopup';
import * as Styles from './styles';
import { QualityTrackerActionsEnum } from '../../../analytics/sources';
import {
  capitalizeFirstLetter,
  formatNumberToLocale,
  openInNewTab,
  pluralize,
  removeUnderscoresAndCapitalize,
} from '../../../utils';
import { useTypedSelector } from '../../../hooks';
import { DEFAULT_RFQ_IMAGE, defaultValue } from '../../../constants';
import VerticalFileUpload from '../../../shared/VerticalFileUpload';
import ICONOGRAPHY from '../../../shared/iconography';
import TooltipWrapper from '../../../shared/TableLayout/EditableCell/TooltipWrapper';
import ProductImage from '../../../shared/ProductImage';

const { close: CloseIcon, check: CheckIcon } = ICONOGRAPHY;

const CheckOrCrossIcon = ({
  truthy,
  iconSize = 10,
}: {
  truthy: boolean;
  iconSize?: number;
}) => (
  <>
    {truthy ? (
      <Styles.SmallIconWrapper
        size={iconSize}
        className="center"
        background="var(--deep-sea)"
      >
        <CheckIcon size={iconSize} strokeWidth={2} stroke="var(--white)" />
      </Styles.SmallIconWrapper>
    ) : (
      <Styles.SmallIconWrapper
        size={iconSize}
        className="center"
        background="var(--red-dirt)"
      >
        <CloseIcon size={iconSize} strokeWidth={2} stroke="var(--white)" />
      </Styles.SmallIconWrapper>
    )}
  </>
);

interface ICellContentProps {
  data: ObjectType;
  identifier: string;
}

const CellContent = ({ data, identifier }: ICellContentProps) => {
  const section = useTypedSelector(store => store.quality.dashboard.section);
  const { is_supplier: isSupplier } = useTypedSelector(store => store.user);

  switch (identifier) {
    case 'serial_number': {
      return (
        <Styles.CellData className="v-d-flex full-height">
          <Styles.Text className="full-width">{data.id}</Styles.Text>
          <TooltipWrapper
            className=""
            valueToShow={
              <Link
                to={`/purchaseorder/${data?.purchase_order?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styles.OrderId className="cursor-pointer">
                  Order #{data?.purchase_order?.id}
                </Styles.OrderId>
              </Link>
            }
            textType="sub-text"
          />
        </Styles.CellData>
      );
    }

    case 'manage_qa': {
      return (
        <Styles.CellData className="full-height align-center">
          <ManageQa manageQaId={data.id} />
        </Styles.CellData>
      );
    }

    case 'purchase_order': {
      const value = data[identifier];

      return (
        <Styles.CellData className="d-flex full-height align-center">
          <ProductImage
            imageUrl={value?.image ?? DEFAULT_RFQ_IMAGE}
            width={32}
            height={48}
            borderRadius={0}
            customMargin="0 8px 0 0"
          />
          <div className="v-d-flex">
            <TooltipWrapper
              content={value?.collection_name ?? defaultValue}
              valueToShow={
                <Link
                  to={`/purchaseorder/${value?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Styles.CollectionName className="truncated">
                    {value?.collection_name ?? defaultValue}
                  </Styles.CollectionName>
                </Link>
              }
              textType="main-text"
            />

            <TooltipWrapper
              valueToShow={
                <>
                  {formatNumberToLocale(value?.num_products || 0)}
                  {pluralize(' style', value?.num_products || 0)}, &nbsp;
                  {formatNumberToLocale(value?.total_quantity || 0)}
                  {pluralize(' pc', value?.total_quantity || 0)}
                </>
              }
              textType="sub-text"
            />
          </div>
        </Styles.CellData>
      );
    }

    case 'supplier':
    case 'brand': {
      const value = data[identifier];

      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            content={value?.name || defaultValue}
            valueToShow={
              <Link
                to={`/${identifier}/${value?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value?.name || defaultValue}
              </Link>
            }
            textType="main-text"
          />
        </Styles.CellData>
      );
    }

    case 'slug': {
      const value = data[identifier]
        ? removeUnderscoresAndCapitalize(data[identifier]).replace(
            'submission',
            ''
          )
        : defaultValue;

      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper valueToShow={value} textType="main-text" />
        </Styles.CellData>
      );
    }

    case 'audited_on':
    case 'updated_on': {
      let displayValue = defaultValue;

      if (data.inspection_result && data[identifier]) {
        displayValue = format(new Date(data[identifier]), 'd MMM yy');
      }

      return (
        <Styles.CellData className="full-height align-center">
          {displayValue}
        </Styles.CellData>
      );
    }

    case 'inspection_result': {
      const isResultPresent = Boolean(data[identifier]);
      const value = data[identifier];

      return (
        <Styles.CellData className="full-height align-center gap-8">
          {isResultPresent ? (
            <>
              <CheckOrCrossIcon truthy={value === 'pass'} />
              {capitalizeFirstLetter(value)}
            </>
          ) : (
            defaultValue
          )}
        </Styles.CellData>
      );
    }

    case 'approval_remark': {
      const value = data[identifier] ?? defaultValue;
      const valueWithoutTags = value?.replace(/(<([^>]+)>)/gi, '');
      const valueToShow =
        // eslint-disable-next-line no-nested-ternary
        value === defaultValue
          ? value
          : valueWithoutTags?.length > 10
          ? `${valueWithoutTags}...`
          : valueWithoutTags;

      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            content={
              <Styles.Paragraph dangerouslySetInnerHTML={{ __html: value }} />
            }
            valueToShow={valueToShow}
            textType="main-text"
          />
        </Styles.CellData>
      );
    }

    case 'pdf':
    case 'report_pdf': {
      if (!data[identifier]) {
        return (
          <Styles.CellData className="full-height align-center">
            {defaultValue}
          </Styles.CellData>
        );
      }

      const report = {
        name: '',
        link: data[identifier],
        type: 'file',
      };

      return (
        <VerticalFileUpload
          allFiles={[report]}
          canUpload={false}
          canDelete={false}
          canDownloadOnList
        />
      );
    }

    case 'submitted_by':
    case 'reviewed_by':
    case 'creator': {
      const value = data[identifier] ?? defaultValue;

      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={value?.name || defaultValue}
            textType="main-text"
          />
        </Styles.CellData>
      );
    }

    case 'assignee_data': {
      const value = data[identifier] ?? defaultValue;

      return (
        <UserCard
          firstName={value?.first_name}
          lastName={value?.last_name}
          bottomText={format(new Date(data.updated_on), 'd MMM yy p')}
        />
      );
    }

    case 'styles': {
      return <StylesPopup styles={data[identifier]} />;
    }

    case 'ppm_styles': {
      const value = data.style_count ?? defaultValue;

      return (
        <Styles.CellData className="truncated-text full-height align-center">
          {value} Styles
        </Styles.CellData>
      );
    }

    case 'is_approved': {
      if (data.is_approved !== null) {
        const reviewText = data.is_approved
          ? ReviewOperationsEnum.ACCEPTED
          : ReviewOperationsEnum.REJECTED;

        return (
          <Styles.CellData className="full-height align-center">
            <Styles.ReviewPill
              action={reviewText}
              className="align-center gap-4"
            >
              <CheckOrCrossIcon truthy={data.is_approved} iconSize={6} />
              {capitalizeFirstLetter(reviewText)}
            </Styles.ReviewPill>
          </Styles.CellData>
        );
      }

      if (isSupplier) {
        return (
          <Styles.CellData className="full-height align-center">
            Review Pending
          </Styles.CellData>
        );
      }

      let route: string;

      switch (section) {
        case 'sample-inspection': {
          const approvalStep =
            APPROVAL_STEP_MAP[data.slug as keyof typeof APPROVAL_STEP_MAP];

          if (!approvalStep) {
            return (
              <Styles.CellData className="full-height align-center">
                {defaultValue}
              </Styles.CellData>
            );
          }
          route = `/quality-approval/?slug=${approvalStep}&report_id=${data.id}`;
          break;
        }

        case 'final-inspection': {
          route = `/quality/${QARoutesEnum.FINAL_INSPECTION}/${data.id}/${QARoutesEnum.REVIEW}`;
          break;
        }

        case 'fabric-inspection': {
          return (
            <Styles.CellData className="full-height align-center">
              {defaultValue}
            </Styles.CellData>
          );
          // * enable after reports are created from mobile app
          // route = `/quality-approval/?slug=fabric-inspection&report_id=${data.id}`;
          break;
        }

        default: {
          return (
            <Styles.CellData className="full-height align-center">
              {defaultValue}
            </Styles.CellData>
          );
        }
      }

      return (
        <Styles.CellData className="full-height align-center">
          <Button
            onClick={() => {
              openInNewTab(route);
              QualityTracker.track(
                QualityTrackerActionsEnum.ClickApproveReject,
                {
                  report_type:
                    section === 'sample-inspection'
                      ? data.slug
                      : 'final_inspection',
                }
              );
            }}
            colorScheme="neutral"
            variant="outline"
            sizeVariant="small"
          >
            Approve / Reject
          </Button>
        </Styles.CellData>
      );
    }

    case 'result': {
      const { status, result, id } = data;
      const value = getResultAction(status, result, id);

      const route =
        value.result === ResultActionEnum.ApproveReject
          ? `/quality/${QARoutesEnum.PPM}/${value.id}/${QARoutesEnum.REVIEW}`
          : `/quality/${QARoutesEnum.PPM}/${value.id}/${QARoutesEnum.EDIT}`;

      return (
        <Styles.CellData className="full-height align-center">
          {value.result === ResultActionEnum.ApproveReject ||
          value.result === ResultActionEnum.StartPPM ? (
            <Button
              onClick={() => {
                if (value.result === ResultActionEnum.ApproveReject) {
                  QualityTracker.track(
                    QualityTrackerActionsEnum.ClickReviewPpm,
                    {
                      section: 'ppm',
                    }
                  );
                } else {
                  if (!isSupplier) {
                    Toast.WARN('Only Supplier can proceed with PPM Step');
                    return;
                  }
                  QualityTracker.track(
                    QualityTrackerActionsEnum.ClickStartPpm,
                    {
                      section: 'ppm',
                    }
                  );
                }
                openInNewTab(route);
              }}
              colorScheme="neutral"
              variant="outline"
              sizeVariant="small"
            >
              {value.result}
            </Button>
          ) : (
            <Styles.ReviewPill
              action={
                value.result === ResultActionEnum.Approved
                  ? ReviewOperationsEnum.ACCEPTED
                  : ReviewOperationsEnum.REJECTED
              }
              className="align-center gap-4"
            >
              <CheckOrCrossIcon
                truthy={value.result === ResultActionEnum.Approved}
                iconSize={8}
              />
              {capitalizeFirstLetter(value.result)}
            </Styles.ReviewPill>
          )}
        </Styles.CellData>
      );
    }

    default: {
      if (data[identifier] && typeof data[identifier] === 'object') {
        throw new Error(`[Quality Dashboard]: Unknown column ${identifier}`);
      }

      return (
        <Styles.CellData className="full-height align-center">
          <TooltipWrapper
            valueToShow={data[identifier] ?? defaultValue}
            textType="main-text"
          />
        </Styles.CellData>
      );
    }
  }
};

export default CellContent;
