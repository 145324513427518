import React from 'react';
import { IconPropsType } from './types';

interface IEmptyViewIconProps extends IconPropsType {}

const EmptyViewIcon = (props: IEmptyViewIconProps) => (
  <svg
    width={248}
    height={210}
    viewBox="0 0 248 210"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_906_120476)">
      <path
        d="M9.10502 106.286C23.995 94.5665 47.415 104.526 63.945 95.2565C73.965 89.6365 79.135 78.1365 81.935 66.9865C84.735 55.8365 85.895 44.1265 90.925 33.7865C95.955 23.4565 106.345 14.5065 117.765 15.7665C126.305 16.7065 133.175 22.9365 140.945 26.5965C161.925 36.4865 187.545 27.0765 209.545 34.4065C225.725 39.8065 238.065 54.0965 243.415 70.2965C248.765 86.4965 247.705 104.316 242.925 120.696C234.115 150.886 212.495 177.116 184.535 191.526C154.375 207.076 117.875 208.606 85.565 198.246C66.975 192.286 49.715 182.536 34.565 170.266C22.425 160.426 6.29502 147.406 1.97502 131.676C-0.494981 122.697 1.48502 112.276 9.09502 106.286H9.10502Z"
        fill="#F7F8FA"
      />
      <path
        d="M28.345 87.4865C33.125 88.5065 38.335 87.3565 42.175 84.3365C47.295 80.3265 49.605 73.0665 47.735 66.8365C44.145 54.8265 26.505 50.9865 17.925 60.1165C9.32496 69.2665 17.175 85.1165 28.345 87.4865Z"
        fill="#F7F8FA"
      />
      <path
        d="M65.4051 78.1665C69.5051 78.8865 73.8751 76.2165 75.3051 72.3065C77.9651 65.0065 69.6051 55.8165 62.1151 59.5865C54.4151 63.4665 57.2951 76.7365 65.4051 78.1665Z"
        fill="#F7F8FA"
      />
      <path
        d="M231.995 17.0564C234.204 17.0564 235.995 15.2655 235.995 13.0564C235.995 10.8473 234.204 9.0564 231.995 9.0564C229.785 9.0564 227.995 10.8473 227.995 13.0564C227.995 15.2655 229.785 17.0564 231.995 17.0564Z"
        fill="#E8EAEE"
      />
      <path
        d="M235.805 36.2164L228.175 31.8164V40.6264L235.805 36.2164Z"
        stroke="#E8EAEE"
        strokeWidth={0.66}
        strokeMiterlimit={10}
      />
      <path
        d="M235.965 28.4363V20.4363H227.965V28.4363H235.965Z"
        fill="#E8EAEE"
      />
      <path
        d="M216.065 199.006C217.17 199.006 218.065 198.111 218.065 197.006C218.065 195.902 217.17 195.006 216.065 195.006C214.96 195.006 214.065 195.902 214.065 197.006C214.065 198.111 214.96 199.006 216.065 199.006Z"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M228.055 157.256V165.256"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M224.055 161.256H232.055"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9448 185.106C26.154 185.106 27.9448 183.316 27.9448 181.106C27.9448 178.897 26.154 177.106 23.9448 177.106C21.7357 177.106 19.9448 178.897 19.9448 181.106C19.9448 183.316 21.7357 185.106 23.9448 185.106Z"
        fill="#E8EAEE"
      />
      <path
        d="M20.1348 204.266L27.7648 199.856V208.666L20.1348 204.266Z"
        stroke="#E8EAEE"
        strokeWidth={0.66}
        strokeMiterlimit={10}
      />
      <path
        d="M27.9746 196.477V188.477H19.9746V196.477H27.9746Z"
        fill="#E8EAEE"
      />
      <path
        d="M40.0649 9.35645C42.2741 9.35645 44.0649 7.56558 44.0649 5.35645C44.0649 3.14731 42.2741 1.35645 40.0649 1.35645C37.8558 1.35645 36.0649 3.14731 36.0649 5.35645C36.0649 7.56558 37.8558 9.35645 40.0649 9.35645Z"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9546 23.0264C25.0592 23.0264 25.9546 22.1309 25.9546 21.0264C25.9546 19.9218 25.0592 19.0264 23.9546 19.0264C22.85 19.0264 21.9546 19.9218 21.9546 21.0264C21.9546 22.1309 22.85 23.0264 23.9546 23.0264Z"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.925 83.8665C79.415 92.3965 72.975 89.6165 60.085 98.5065C52.675 103.617 46.475 116.667 46.505 125.667C46.535 134.667 51.955 144.027 60.605 146.517C66.995 148.357 75.085 145.687 76.505 138.457C77.225 134.797 75.485 130.957 72.105 129.377C65.435 126.257 59.485 134.167 60.955 140.517C62.275 146.207 67.535 150.177 72.975 152.327C87.155 157.937 100.995 132.157 114.125 124.407C127.255 116.657 137.395 90.6965 148.275 80.0165C151.325 77.0165 154.445 74.0365 157.675 71.1865C175.255 55.6365 198.795 51.6865 217.215 66.2165C231.035 77.1165 237.165 111.487 211.475 123.367C203.305 127.147 193.725 122.357 191.195 114.737C190.235 111.857 190.525 108.327 192.395 105.947C194.265 103.557 198.595 102.307 201.575 102.857C204.985 103.487 207.545 106.607 208.415 109.957C209.285 113.317 208.975 116.937 207.955 120.257C204.775 130.627 199.425 136.587 193.125 142.887"
        stroke="#FFA100"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M62.585 63.7764L68.515 69.1464"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.5253 66.9264L70.1753 59.8564"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.5051 71.3665L59.4351 71.7265"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.0447 164.697L72.3247 72.4965C72.1747 71.5265 72.8247 70.6165 73.7947 70.4465L121.745 62.2265C122.295 62.1265 122.865 62.2765 123.315 62.6165L146.755 80.6865C147.165 80.9965 147.435 81.4565 147.515 81.9565L159.045 153.147C159.225 154.277 158.435 155.337 157.295 155.467L89.2647 166.377C88.1947 166.507 87.2147 165.757 87.0447 164.697Z"
        fill="white"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M122.525 64.3364L125.595 85.5864L145.825 82.0464L122.525 64.3364Z"
        fill="#E8EAEE"
      />
      <path
        d="M108.275 107.046C109.391 107.046 110.295 106.142 110.295 105.026C110.295 103.911 109.391 103.006 108.275 103.006C107.159 103.006 106.255 103.911 106.255 105.026C106.255 106.142 107.159 107.046 108.275 107.046Z"
        fill="#929BAA"
      />
      <path
        d="M120.195 104.896C121.31 104.896 122.215 103.992 122.215 102.876C122.215 101.761 121.31 100.856 120.195 100.856C119.079 100.856 118.175 101.761 118.175 102.876C118.175 103.992 119.079 104.896 120.195 104.896Z"
        fill="#929BAA"
      />
      <path
        d="M111.405 111.036C111.405 111.036 114.715 107.336 119.355 109.606"
        stroke="#929BAA"
        strokeWidth={0.83}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M91.8647 135.137L147.465 126.227"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M92.8447 141.187L148.435 132.277"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M93.8149 147.246L149.405 138.336"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M94.7847 153.296L150.385 144.386"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M90.7777 87.6259L83.9956 88.7827L90.1228 124.704L96.9049 123.547L90.7777 87.6259Z"
        fill="#E8EAEE"
      />
      <path
        d="M100.294 73.2445L93.5117 74.4014L101.753 122.714L108.535 121.557L100.294 73.2445Z"
        fill="#E8EAEE"
      />
      <path
        d="M112.971 77.4806L106.188 78.6375L113.37 120.739L120.152 119.583L112.971 77.4806Z"
        fill="#E8EAEE"
      />
      <path
        d="M126.235 85.1486L119.453 86.3054L124.988 118.757L131.771 117.6L126.235 85.1486Z"
        fill="#E8EAEE"
      />
      <path
        d="M103.345 151.877L107.915 58.6266C107.965 57.6466 108.785 56.8866 109.765 56.9266L158.385 58.7466C158.945 58.7666 159.475 59.0266 159.845 59.4466L179.075 81.9466C179.405 82.3366 179.575 82.8366 179.555 83.3466L176.195 155.387C176.145 156.527 175.145 157.397 174.005 157.297L105.185 153.977C104.105 153.877 103.305 152.947 103.355 151.877H103.345Z"
        fill="white"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M158.705 60.9565L157.335 82.3865L177.865 83.0865L158.705 60.9565Z"
        fill="#E8EAEE"
      />
      <path
        d="M136.395 99.8667C137.51 99.8667 138.415 98.9623 138.415 97.8467C138.415 96.731 137.51 95.8267 136.395 95.8267C135.279 95.8267 134.375 96.731 134.375 97.8467C134.375 98.9623 135.279 99.8667 136.395 99.8667Z"
        fill="#929BAA"
      />
      <path
        d="M148.504 100.217C149.62 100.217 150.524 99.3124 150.524 98.1968C150.524 97.0811 149.62 96.1768 148.504 96.1768C147.389 96.1768 146.484 97.0811 146.484 98.1968C146.484 99.3124 147.389 100.217 148.504 100.217Z"
        fill="#929BAA"
      />
      <path
        d="M138.225 104.377C138.225 104.377 142.225 101.437 146.305 104.607"
        stroke="#929BAA"
        strokeWidth={0.83}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M135.615 124.977L170.385 126.657"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M135.325 131.097L170.095 132.777"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M135.025 137.227L169.795 138.907"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M134.725 143.347L169.505 145.027"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M176.895 125.847L153.735 166.627C153.435 167.147 153.815 167.807 154.415 167.807H203.255C203.875 167.807 204.255 167.127 203.925 166.597L178.245 125.817C177.925 125.317 177.185 125.327 176.895 125.847Z"
        fill="white"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M175.755 159.877C175.755 157.997 177.045 156.687 178.885 156.687C180.725 156.687 181.985 157.997 182.005 159.877C182.005 161.697 180.775 163.037 178.885 163.037C176.995 163.037 175.755 161.687 175.755 159.877ZM176.865 154.537L176.135 139.527C176.125 139.287 176.315 139.087 176.555 139.087H181.205C181.445 139.087 181.645 139.287 181.625 139.527L180.895 154.537C180.885 154.767 180.695 154.937 180.475 154.937H177.285C177.055 154.937 176.875 154.757 176.865 154.537Z"
        fill="#FFA100"
      />
      <path
        d="M121.485 140.347C117.565 140.347 114.375 137.157 114.375 133.227C114.375 129.997 116.565 127.287 119.525 126.427L119.015 124.157C115.025 125.247 112.075 128.887 112.075 133.227C112.075 138.437 116.285 142.647 121.485 142.647C126.685 142.647 130.905 138.437 130.905 133.227L128.565 133.467C128.435 137.287 125.335 140.347 121.485 140.347Z"
        fill="#E8EAEE"
      />
      <path
        d="M121.485 123.817C120.625 123.817 119.805 123.937 119.015 124.157L119.525 126.427C120.155 126.247 120.795 126.117 121.485 126.117C125.415 126.117 128.595 129.307 128.595 133.227C128.595 133.307 128.575 133.387 128.575 133.467L130.915 133.227C130.915 128.027 126.705 123.817 121.495 123.817H121.485Z"
        fill="#929BAA"
      />
    </g>
    <defs>
      <clipPath id="clip0_906_120476">
        <rect
          width={246.03}
          height={208.38}
          fill="white"
          transform="translate(0.984863 0.856445)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EmptyViewIcon;
