import React from "react";
import { IconPropsType } from "./types";

interface IProductionPlannerIconProps extends IconPropsType {}

const ProductionPlannerIcon = ({
  stroke = "var(--rhino)",
  size = 24,
  strokeWidth = 1.25,
  ...props
}: IProductionPlannerIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 7C4 7 3 7 3 6V4C3 4 3 3 4 3H16C16 3 17 3 17 4V6C17 7 16 7 16 7H4Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14C8 14 7 14 7 13V11C7 11 7 10 8 10H20C20 10 21 10 21 11V13C21 14 20 14 20 14H8Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 21C4 21 3 21 3 20V18C3 18 3 17 4 17H16C16 17 17 17 17 18V20C17 21 16 21 16 21H4Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProductionPlannerIcon;
