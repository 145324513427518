import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid var(--athens-gray);
  border-radius: 12px;
  background: var(--white);
  padding: 24px;
  gap: 4px;
  grid-row: 1 / 6;
  grid-column: 6 / 11;
`;

export const PieChartWrapper = styled.div`
  height: 100%;
`;

export const Heading = styled.h5`
  font-weight: 500;
  font-size: 16px;
`;

export const Left = styled.div`
  width: 40%;
  gap: 4px;
  padding-bottom: 12px;
`;

export const Right = styled.div`
  flex: 1;

  button {
    width: fit-content;
  }
`;

export const MetricLabel = styled.div`
  height: fit-content;
  padding: 0 24px;
  gap: 6px;

  &:nth-child(2) {
    position: relative;

    &::before,
    &::after {
      display: inline-block;
      background: var(--athens-gray);
      width: 2px;
      height: 32px;
      content: '';
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      position: absolute;
      left: 0;
    }

    &::after {
      position: absolute;
      right: 0;
    }
  }
`;

export const MetricLabelText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: var(--fiord);
`;

export const MetricLabelNumber = styled.h5<{ color: string }>`
  height: fit-content;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: ${props => props.color};
`;

export const TopDefectsTable = styled.table`
  width: 300px;
  border-spacing: 4px 12px;
  border: 1px solid var(--athens-gray);
  box-shadow: 0px 32px 64px -12px rgba(12, 16, 33, 0.14);
  border-radius: 8px;
  padding: 12px 20px;

  th {
    font-weight: 600;
    font-size: 14px;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
`;

export const DefectTd = styled.td`
  font-weight: 400;
  font-size: 12px;
  color: var(--rhino);
  max-width: 240px;
`;

export const CountTd = styled.td`
  font-weight: 600;
  font-size: 12px;
  color: var(--milano-red);
  text-align: right;
  width: 60px;
`;
