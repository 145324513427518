import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--brilliance);
  height: 100vh;
  overflow: auto;
`;

export const Top = styled.div`
  position: sticky;
  top: 0;
  z-index: 4;
  background: var(--brilliance);
`;

export const AnalyticsWrapper = styled.div`
  padding: 12px 24px 12px 24px;
`;

export const Content = styled.div`
  padding: 0 24px 24px 24px;
`;

export const ColumnPreferencesWrapper = styled.div`
  padding: 8px 0;
`;

export const Scrollable = styled.div`
  overflow: visible;
  height: 100%;
`;

export const StickyInScrollable = styled.div`
  padding: 0 24px;
  overflow: hidden;
`;

export const BottomFixed = styled.div`
  position: fixed;
  bottom: 0;
  background: var(--brilliance);
  padding: 12px 24px;
  width: calc(100% - 70px);
  border-top: 1px solid var(--athens-gray);
`;
