import React, { useContext } from 'react';
import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Button } from '@fashinza/fashinza-design-system';
import { BsChevronRight } from 'react-icons/bs';
import { PPMFormContext } from '../index';
import Accordion from '../accessoryComponents/Accordion';
import { PPMFormSectionsEnum } from '../types';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { useIsMobile } from '../../../hooks/useIsMobile';
import CatWithPaper from '../../../shared/iconography/CatWithPaper';
import * as Styles from './styles';

interface ISubmitFormProps {
  setHashValue?: (hashValue: PPMFormSectionsEnum) => void;
}

const SubmitForm = ({ setHashValue }: ISubmitFormProps) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const { updatePpmForm, inCompleteFields } = useContext(PPMFormContext);

  return (
    <Accordion
      header={PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.SUBMIT_FORM]}
      section={PPMFormSectionsEnum.SUBMIT_FORM}
      isLast
      footer={
        isMobile ? null : (
          <Button
            isDisabled={inCompleteFields.length > 0}
            sizeVariant="large"
            variant="outline"
            onClick={() => updatePpmForm(PPMFormSectionsEnum.SUBMIT_FORM)}
          >
            Submit PPM Report
          </Button>
        )
      }
    >
      {inCompleteFields.length > 0 ? (
        <Styles.List>
          {inCompleteFields.map(field => (
            <Styles.ListItem
              key={field.slug}
              className={isMobile ? 'v-center-flex-start' : ''}
            >
              {!isMobile && <i>●</i>}
              Few details are not filled in
              <span className={isMobile ? 'spread full-width' : ''}>
                {field.displayText}
              </span>
              <Button
                variant="ghost"
                sizeVariant="large"
                onClick={() => {
                  dispatch(replace({ hash: field.slug }));
                  if (setHashValue) {
                    setHashValue(field.slug as PPMFormSectionsEnum);
                  }
                }}
              >
                {!isMobile && 'Fill Details'}
                <BsChevronRight size={12} color="var(--lynch)" />
              </Button>
            </Styles.ListItem>
          ))}
        </Styles.List>
      ) : (
        <Styles.ReadyStateWrapper
          className={isMobile ? 'v-center' : 'flex-start-center'}
        >
          <CatWithPaper />
          <Styles.PlainText>
            PPM report is ready to be submitted
          </Styles.PlainText>
        </Styles.ReadyStateWrapper>
      )}
    </Accordion>
  );
};

export default SubmitForm;
