import React, { ReactNode } from "react";
import DetermineFileType from "../DetermineFileType";
import FilesModal from "../FilesModal";
import { useFileUploadContext } from "../helperHooks";
import { pluralize } from "../../../utils";
import useBoolean from "../../../hooks/useBoolean";
import { UploadStatusEnum } from "../../../enums";
import * as Styles from "./styles";

interface IFilesListProps {
  headerName?: ReactNode;
  showTextOnList?: boolean;
  maxListFilesToShow?: number;
}

const FilesList = ({
  headerName,
  showTextOnList = false,
  maxListFilesToShow = 1,
}: IFilesListProps) => {
  const {
    canDelete,
    canDownloadOnList,
    fileInput,
    onDelete,
    canUpload,
    listControlsType,
    detailsControlsType,
    isMobileView,
  } = useFileUploadContext();
  const filesToDisplay = fileInput.filter(
    (eachFile) => eachFile.status === UploadStatusEnum.Success
  );
  const [shouldShowAllFiles, allFilesActions] = useBoolean();

  const minLoop = Math.min(filesToDisplay.length, maxListFilesToShow);
  const slicedArray = filesToDisplay.slice(0, minLoop);
  const remainingCount = filesToDisplay.length - slicedArray.length;

  function handleShowAllFiles() {
    allFilesActions.on();
  }

  function handleModalClose() {
    allFilesActions.off();
  }

  return (
    <>
      {slicedArray.map((eachFile, index) => (
        <DetermineFileType
          // eslint-disable-next-line react/no-array-index-key
          key={`${eachFile.link}-${index}`}
          fileName={eachFile.name}
          fileLink={eachFile.link}
          onDelete={canDelete ? () => onDelete(eachFile.link) : null}
          controlsType={listControlsType || "absolute"}
          showText={showTextOnList}
          className="files-list"
          canDownloadOnWrapper={canDownloadOnList}
        />
      ))}
      {remainingCount > 0 && (
        <>
          <Styles.RemainingCountWrapper>
            <Styles.RemainingCount
              className="v-center"
              onClick={handleShowAllFiles}
            >
              +{remainingCount}
            </Styles.RemainingCount>
            {showTextOnList && (
              <Styles.CountText>
                {remainingCount} more {pluralize("file", remainingCount)}{" "}
                <Styles.ViewAll onClick={handleShowAllFiles}>
                  View
                </Styles.ViewAll>
              </Styles.CountText>
            )}
          </Styles.RemainingCountWrapper>
          {shouldShowAllFiles && (
            <FilesModal
              key={filesToDisplay.length}
              filesToDisplay={filesToDisplay}
              onClose={handleModalClose}
              onDelete={
                canDelete ? (linkToDelete) => onDelete(linkToDelete) : null
              }
              headerName={headerName}
              canUpload={canUpload}
              controlsType={detailsControlsType}
              isMobileView={isMobileView}
            />
          )}
        </>
      )}
    </>
  );
};

export default FilesList;
