import { createContext, Context, InputHTMLAttributes, useContext } from 'react';
import { FileControlsType, IFileOutputProps } from './types';

interface IFileUploadContext {
  canUpload: boolean;
  canDelete: boolean;
  canDownloadOnList: boolean;
  fileInput: Array<IFileOutputProps>;
  accept: InputHTMLAttributes<HTMLInputElement>['accept'];
  sendFilesToCallback(
    fileArray: Array<IFileOutputProps>,
    actionType: 'uploading' | 'success'
  ): void;
  isUploading: boolean;
  uploadingActions: {
    on(): void;
    off(): void;
    toggle(): void;
  };
  onDelete(linkToDelete: string): void;
  listControlsType?: FileControlsType;
  detailsControlsType?: FileControlsType;
  showUploadIcon?: boolean;
  maxFiles?: number;
  isMobileView?: boolean;
}

export const FileUploadContext: Context<IFileUploadContext> = createContext(
  {} as IFileUploadContext
);

export function useFileUploadContext() {
  const contextData = useContext(FileUploadContext);
  return contextData;
}
