import React from "react";
import { IconPropsType } from "./types";

interface IEnquiryIconProps extends IconPropsType {}

const EnquiryIcon = ({
  stroke = "var(--all-ports)",
  ...props
}: IEnquiryIconProps) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 6C15.8284 6 16.5 6.67157 16.5 7.5V12C16.5 12.8284 15.8284 13.5 15 13.5H13.5V16.5L10.5 13.5H9.5625H9C8.625 13.5 8.25 13.125 8.25 13.125M5.25 10.5H3.75C2.92157 10.5 2.25 9.82843 2.25 9V4.5C2.25 3.67157 2.92157 3 3.75 3H11.25C12.0784 3 12.75 3.67157 12.75 4.5V9C12.75 9.82843 12.0784 10.5 11.25 10.5H8.25L5.68934 13.0607L5.25 13.5V10.5Z"
      stroke={stroke}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EnquiryIcon;
