/* eslint-disable default-param-last */
import {
  ActionMetaOperationsEnum,
  INITIAL_TABULAR_DATA_STATE,
} from '../../../components/constants';
import { INITIAL_PPM_STAGING } from '../../../components/PPMForm/constants';
import { ObjectType } from '../../../@types/defined';
import {
  IQualityAssuranceListItem,
  IQualityReducer,
  QualityActionEnum,
  QualityActionType,
} from '../../../@types/base';
import { IPPMListItem } from '../../../@types/ppm';

const INITIAL_STATE: IQualityReducer = {
  ppm_staging: { ...INITIAL_PPM_STAGING },
  qualityAssuranceList: [],
  ppmList: [],
  qaTeamList: [],
  isLoading: false,
  ppmListPaginationInfo: {
    count: 0,
    totalPages: -1,
  },
  qualityAssuranceListPaginationInfo: {
    count: 0,
    totalPages: -1,
  },
  qualityReports: {
    ...INITIAL_TABULAR_DATA_STATE,
  },
  dashboard: {
    section: null,
    subSection: null,
    dateFilters: {
      text: null,
      startDate: null,
      endDate: null,
    },
    entityFilters: {
      brand: null,
      supplier: null,
      inspector: null,
    },
    searchText: '',
    columnFilters: {},
    sortConfig: {},
  },
  tabularData: INITIAL_TABULAR_DATA_STATE,
};

function qualityReducer(
  state = INITIAL_STATE,
  action: QualityActionType
): IQualityReducer {
  switch (action.type) {
    case QualityActionEnum.ChangePPMStaging: {
      const { key, value } = action.payload;
      return {
        ...state,
        ppm_staging: {
          ...state.ppm_staging,
          [key]: value,
        },
      };
    }

    case QualityActionEnum.SetBulkPPMStaging: {
      return {
        ...state,
        ppm_staging: {
          ...state.ppm_staging,
          ...action.payload,
        },
      };
    }

    case QualityActionEnum.FetchManageQAListFailure: {
      return { ...state, isLoading: false };
    }

    case QualityActionEnum.FetchManageQAListRequest: {
      return { ...state, isLoading: true };
    }

    case QualityActionEnum.FetchManageQAListSuccess: {
      const { operation } = action.meta;

      let newList: Array<IQualityAssuranceListItem> = [];

      if (operation === ActionMetaOperationsEnum.REPLACE) {
        newList = [...action.payload];
      } else if (operation === ActionMetaOperationsEnum.APPEND) {
        newList = [...state.qualityAssuranceList, ...action.payload];
      }

      return {
        ...state,
        qualityAssuranceList: [...newList],
        isLoading: false,
      };
    }

    case QualityActionEnum.FetchManagePPMListFailure: {
      return { ...state, isLoading: false };
    }

    case QualityActionEnum.FetchManagePPMListRequest: {
      return { ...state, isLoading: true };
    }

    case QualityActionEnum.FetchManagePPMListSuccess: {
      const { operation } = action.meta;

      let newList: Array<IPPMListItem> = [];

      if (operation === ActionMetaOperationsEnum.REPLACE) {
        newList = [...action.payload];
      } else if (operation === ActionMetaOperationsEnum.APPEND) {
        newList = [...state.ppmList, ...action.payload];
      }

      return {
        ...state,
        ppmList: [...newList],
        isLoading: false,
      };
    }

    case QualityActionEnum.FetchQAListFailure: {
      return { ...state, isLoading: false };
    }

    case QualityActionEnum.FetchQAListRequest: {
      return { ...state, isLoading: true };
    }

    case QualityActionEnum.FetchQAListSuccess: {
      return { ...state, qaTeamList: action.payload, isLoading: false };
    }

    case QualityActionEnum.AssignQARequest: {
      return { ...state, isLoading: true };
    }

    case QualityActionEnum.AssignQAFailure: {
      return { ...state, isLoading: false };
    }

    case QualityActionEnum.AssignQASuccess: {
      const newState = { ...state };
      const newQualityAssuranceList = newState.qualityAssuranceList.map(
        item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }
      );
      return {
        ...state,
        isLoading: false,
        qualityAssuranceList: newQualityAssuranceList,
      };
    }

    case QualityActionEnum.SetDashboardSection: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          section: action.payload,
        },
      };
    }

    case QualityActionEnum.SetDashboardSubSection: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          subSection: action.payload,
        },
      };
    }

    case QualityActionEnum.SetDashboardFilterDates: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          dateFilters: action.payload,
        },
      };
    }

    case QualityActionEnum.SetDashboardEntityFilters: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          entityFilters: action.payload,
        },
      };
    }

    case QualityActionEnum.SetTabularData: {
      const {
        payload,
        meta: { operation },
      } = action;

      let newIds: Array<number> = [];
      let newData: Record<number, ObjectType> = {};

      if (operation === 'replace') {
        newIds = payload.ids;
        newData = payload.data;
      } else if (operation === 'append') {
        newIds = [...state.tabularData.ids, ...payload.ids];
        newData = { ...state.tabularData.data, ...payload.data };
      }

      return {
        ...state,
        tabularData: {
          ids: newIds,
          data: newData,
          count: payload.count,
          totalPages: payload.totalPages,
        },
      };
    }

    case QualityActionEnum.SetLoadingState: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case QualityActionEnum.UpdateTabularDataById: {
      return {
        ...state,
        isLoading: false,
        tabularData: {
          ...state.tabularData,
          data: {
            ...state.tabularData.data,
            [action.payload.id]: action.payload.data,
          },
        },
      };
    }

    case QualityActionEnum.SetSearch: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          searchText: action.payload,
        },
      };
    }

    case QualityActionEnum.SetDashboardSortConfig: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          sortConfig: action.payload,
        },
      };
    }

    case QualityActionEnum.SetDashboardColumnFilters: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          columnFilters: action.payload,
        },
      };
    }

    default:
      return state;
  }
}

export default qualityReducer;
