import React from "react";
import { IconPropsType } from "../types";

interface IOtherFileIconProps extends IconPropsType {}

const OtherFileIcon = (props: IOtherFileIconProps) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={40} height={40} rx={4} fill="var(--titan-white)" />
    <path
      d="M20.833 12.1667H14.9997C14.5576 12.1667 14.1337 12.3423 13.8212 12.6548C13.5086 12.9674 13.333 13.3913 13.333 13.8334V27.1667C13.333 27.6087 13.5086 28.0326 13.8212 28.3452C14.1337 28.6578 14.5576 28.8334 14.9997 28.8334H24.9997C25.4417 28.8334 25.8656 28.6578 26.1782 28.3452C26.4907 28.0326 26.6663 27.6087 26.6663 27.1667V18L20.833 12.1667Z"
      fill="var(--moon-raker)"
    />
    <path
      d="M20.833 12.1667H14.9997C14.5576 12.1667 14.1337 12.3423 13.8212 12.6548C13.5086 12.9674 13.333 13.3913 13.333 13.8334V27.1667C13.333 27.6087 13.5086 28.0326 13.8212 28.3452C14.1337 28.6578 14.5576 28.8334 14.9997 28.8334H24.9997C25.4417 28.8334 25.8656 28.6578 26.1782 28.3452C26.4907 28.0326 26.6663 27.6087 26.6663 27.1667V18L20.833 12.1667Z"
      stroke="var(--fuschia-blue)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.833 12.1667V18H26.6663"
      stroke="var(--fuschia-blue)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default OtherFileIcon;
