import React, {
  useState,
  ReactNode,
  InputHTMLAttributes,
  useEffect,
} from "react";
import FilesList from "./FilesList";
import UploadButton from "./UploadButton";
import { FileUploadContext } from "./helperHooks";
import { FileControlsType, IFileOutputProps } from "./types";
import * as Styles from "./styles";
import { AttachmentType } from "../../@types/defined";
import { useTypedSelector } from "../../hooks";
import useBoolean from "../../hooks/useBoolean";
import { UploadStatusEnum } from "../../enums";

export interface IVerticalFileUploadProps {
  allFiles?: Array<AttachmentType>;
  onSetFile?: (
    uploadedData: Array<AttachmentType>,
    actionType: "uploading" | "success" | "delete"
  ) => void;
  accept?: InputHTMLAttributes<HTMLInputElement>["accept"];
  canUpload?: boolean;
  canDelete?: boolean;
  canDownloadOnList?: boolean;
  headerName?: ReactNode;
  showTextOnList?: boolean;
  listControlsType?: FileControlsType;
  detailsControlsType?: FileControlsType;
  maxListFilesToShow?: number;
  direction?: "column" | "row";
  itemCount?: number;
  labelText?: ReactNode;
  isError?: boolean;
  className?: string;
  ordering?: Array<"list" | "upload">;
  viewType?: "separated" | "single";
  showUploadIcon?: boolean;
  maxFiles?: number;
  isColor?: boolean;
  isMobileView?: boolean;
}

const VerticalFileUpload = ({
  allFiles = [],
  onSetFile,
  accept,
  canUpload = true,
  canDelete = true,
  canDownloadOnList = false,
  headerName,
  showTextOnList = false,
  listControlsType,
  detailsControlsType,
  maxListFilesToShow = 1,
  direction = "column",
  itemCount = 1,
  labelText,
  isError,
  className = "",
  isColor = false,
  ordering = ["list", "upload"],
  viewType = "separated",
  showUploadIcon = true,
  maxFiles,
  isMobileView = false,
}: IVerticalFileUploadProps) => {
  const userId = useTypedSelector((state) => state.user.user_id);
  const [fileInput, setFileInput] = useState<Array<IFileOutputProps>>([]);
  const [isUploading, uploadingActions] = useBoolean();

  useEffect(() => {
    const newFiles = allFiles.map((eachFile) => ({
      ...eachFile,
      status: UploadStatusEnum.Success,
    }));
    setFileInput(newFiles);
  }, [allFiles.length]);

  useEffect(() => {
    if (allFiles.length > 0 && fileInput.length === 0) {
      const calculatedFiles = allFiles.map((eachFile) => ({
        ...eachFile,
        status: UploadStatusEnum.Success,
      }));
      setFileInput(calculatedFiles);
    }
  }, [allFiles.length]);

  function sendFilesToCallback(
    fileArray: Array<IFileOutputProps>,
    actionType: "uploading" | "success"
  ) {
    setFileInput(fileArray);
    if (typeof onSetFile !== "undefined") {
      const callbackFiles: Array<AttachmentType> = fileArray.map(
        (eachFile) => ({
          name: eachFile.name,
          link: eachFile.link,
          type: eachFile.type,
          user_id: userId,
          mime_type: eachFile.mime_type,
        })
      );
      onSetFile(callbackFiles, actionType);
    }
  }

  function onDelete(linkToDelete: string) {
    const newFileInput = fileInput.filter((item) => item.link !== linkToDelete);
    setFileInput(newFileInput);
    if (typeof onSetFile !== "undefined") {
      const filteredValues = allFiles.filter(
        (item) => item.link !== linkToDelete
      );
      onSetFile(filteredValues, "delete");
    }
  }

  const isUploadPossible =
    canUpload &&
    (typeof maxFiles === "undefined" || maxFiles > allFiles.length);

  return (
    <FileUploadContext.Provider
      value={{
        canUpload: isUploadPossible,
        canDelete,
        canDownloadOnList,
        fileInput,
        accept,
        sendFilesToCallback,
        isUploading,
        uploadingActions,
        onDelete,
        listControlsType,
        detailsControlsType,
        showUploadIcon,
        maxFiles,
        isMobileView,
      }}
    >
      <Styles.Wrapper
        className={className}
        direction={direction}
        itemCount={itemCount}
      >
        {ordering.map((eachOrder) => {
          if (eachOrder === "list") {
            return (
              <FilesList
                key={eachOrder}
                headerName={headerName}
                showTextOnList={showTextOnList}
                maxListFilesToShow={maxListFilesToShow}
              />
            );
          }

          if (eachOrder === "upload" && isUploadPossible) {
            return (
              <UploadButton
                isColor={isColor}
                key={eachOrder}
                labelText={labelText}
                isError={isError}
                viewType={viewType}
              />
            );
          }
          return null;
        })}
      </Styles.Wrapper>
    </FileUploadContext.Provider>
  );
};

export default VerticalFileUpload;
