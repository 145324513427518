import React, { ChangeEvent } from 'react';
import { PPMFormSectionsEnum } from '../../types';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import { IPreProductionMeetingForm } from '../../../../@types/ppm';
import { AttachmentType } from '../../../../@types/defined';
import VerticalFileUpload from '../../../../shared/VerticalFileUpload';
import * as CommonStyles from '../styles';
import * as Styles from './styles';
import TextInputWithLabel from '../../../../shared/TextInputWithLabel/textInputWithLabel';

interface ICombinedTextFileProps {
  id: keyof IPreProductionMeetingForm;
  attachmentSlug: keyof IPreProductionMeetingForm;
  onChangeText: (event: ChangeEvent<HTMLInputElement>) => void;
  onFileChange: (
    field: keyof IPreProductionMeetingForm,
    newFiles: Array<AttachmentType>,
    actionType: string
  ) => void;
  label: string;
  getFieldValueFromStore: (
    field: keyof IPreProductionMeetingForm
  ) => string | Array<AttachmentType> | null;
  section: PPMFormSectionsEnum;
}

const CombinedTextFile = ({
  id,
  label,
  attachmentSlug,
  getFieldValueFromStore,
  onChangeText,
  onFileChange,
  section,
}: ICombinedTextFileProps) => (
  <Styles.Wrapper className="align-center">
    <CommonStyles.Label>
      {label} <ShowAsteriskIfMandatory field={id} section={section} />
    </CommonStyles.Label>
    <Styles.InputGroup className="full-flex-grow d-flex">
      <Styles.TextInputWrapper>
        <TextInputWithLabel
          datalabel=""
          dataname={id}
          placeholder={label}
          onChange={onChangeText}
          value={getFieldValueFromStore(id)}
          shouldAutoFocus={false}
        />
      </Styles.TextInputWrapper>
      <VerticalFileUpload
        labelText="Attach Document"
        maxListFilesToShow={2}
        className="forced-to-be-horizontal"
        allFiles={
          (getFieldValueFromStore(attachmentSlug) as Array<AttachmentType>) ??
          []
        }
        onSetFile={(newFiles, actionType) =>
          onFileChange(attachmentSlug, newFiles, actionType)
        }
      />
    </Styles.InputGroup>
  </Styles.Wrapper>
);

export default CombinedTextFile;
