import { Context, createContext, useContext } from 'react';
import { IToggleDropdownItem } from '../types';

interface IManageQAContext {
  getToggleDropdownOptions(ids: string | number): Array<IToggleDropdownItem>;
  selectedRowId: number | string;
  toggleAssignQAModal(): void;
}

export const ManageQAContext: Context<IManageQAContext> = createContext(
  {} as IManageQAContext
);

export function useManageQAContext() {
  const contextData = useContext(ManageQAContext);
  return contextData;
}
