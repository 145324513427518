// import { createPortal } from 'react-dom';
// import { ReactNode, useEffect, useRef } from 'react';

// const portalRoot = document.getElementById('portal');

// interface IPortalProps {
//   children: ReactNode;
// }

// const Portal = ({ children }: IPortalProps) => {
//   const portalRef = useRef<HTMLDivElement | null>(null);

//   useEffect(() => {
//     // 2: Append the element to the DOM when it mounts
//     portalRef.current = document.createElement('div');
//     portalRoot?.appendChild(portalRef.current);

//     return () => {
//       // 3: Remove the element when it unmounts
//       portalRoot?.removeChild(portalRef.current!);
//     };
//   }, []);

//   // 4: Render the element's children in a Portal
//   return portalRef.current ? createPortal(children, portalRef.current) : null;
// };

// export default Portal;

import ReactDOM from 'react-dom';
import React from 'react';

const portalRoot = document.getElementById('portal');

class Portal extends React.Component {
  constructor() {
    super();
    // 1: Create a new div that wraps the component
    this.el = document.createElement('div');
  }

  // 2: Append the element to the DOM when it mounts
  componentDidMount() {
    portalRoot.appendChild(this.el);
  }

  // 3: Remove the element when it unmounts
  componentWillUnmount() {
    portalRoot.removeChild(this.el);
  }

  render() {
    // 4: Render the element's children in a Portal
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}

export default Portal;
