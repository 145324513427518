import React from 'react';
import { IconPropsType } from './types';

interface ICatWithPaperProps extends IconPropsType {}

const CatWithPaper = ({ ...props }: ICatWithPaperProps) => (
  <svg
    width={100}
    height={120}
    viewBox="0 0 100 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M83.346 33.292c2.612-5.781 3.871-9.476 5.028-15.35a.929.929 0 0 0-1.027-1.1c-3.285.411-6.231.814-9.274 2.562-5.518-4.803-12.037-7.073-19.291-8.006-.55-1.954-1.446-5.378-2.168-8.064a1.863 1.863 0 0 0-2.733-1.128 679.79 679.79 0 0 1-4.322 2.486C24.6 18.782 2.986 47.076.596 75.805c-1.11 13.335 2.26 29.081 14.944 36.172 11.486 6.42 25.173 8.377 38.168 7.379 4.806-.368 9.855-.44 14.457-2.037 4.271-1.482 8.653-6.041 5.552-10.77l9.63-73.257Z"
      fill="var(--white)"
    />
    <path
      d="M50.276 23.9c.786 2.084 4.045.283 2.946-1.397-.897-1.371-3.592-.31-2.947 1.398Zm19.417 8.948c1.43 1.707 3.913-1.067 2.321-2.292-1.298-.998-3.493.893-2.321 2.292Zm-7.131-.35-2.846 1.219c-.422-.997-.845-1.993-1.269-2.99 1.36.59 2.755 1.183 4.115 1.771Z"
      fill="#5D7183"
    />
    <path
      d="m59.202 31.485.726 1.71 1.63-.698-1.201-.516-1.155-.496Zm.516 2.629a.397.397 0 0 1-.366-.242l-1.269-2.99a.397.397 0 0 1 .524-.519c.682.295 1.373.591 2.063.888l2.052.883a.397.397 0 0 1-.002.73l-2.845 1.218a.396.396 0 0 1-.157.032Zm-4.966-23.592c-.2 0-.372-.15-.395-.353l-.465-4.139c-1.6.89-3.149 1.896-4.609 2.998a.397.397 0 0 1-.478-.635 40.662 40.662 0 0 1 5.227-3.346.399.399 0 0 1 .583.306l.532 4.727a.398.398 0 0 1-.395.442Zm28.955 14.501a.397.397 0 0 1-.38-.516c.195-.617.369-1.3.537-1.96.117-.457.236-.923.363-1.376-.32.067-.64.146-.97.228-.535.132-1.088.27-1.666.355a.397.397 0 1 1-.117-.786c.541-.08 1.052-.207 1.592-.34.535-.134 1.09-.27 1.668-.357a.397.397 0 0 1 .437.513c-.194.616-.368 1.3-.537 1.96a37.16 37.16 0 0 1-.548 2.001.398.398 0 0 1-.38.278Zm-48.745-1.494a.397.397 0 0 1-.266-.103A130.406 130.406 0 0 1 19.629 7.3a.398.398 0 0 1 .625-.492 129.645 129.645 0 0 0 14.974 16.03.397.397 0 0 1-.266.692Zm-6.65 3.318a.39.39 0 0 1-.174-.04l-16.773-8.194a.397.397 0 1 1 .348-.714l16.774 8.194a.397.397 0 0 1-.175.754Zm12.254-4.655a.397.397 0 0 1-.346-.202L28.147.593a.398.398 0 0 1 .692-.391l12.073 21.397a.398.398 0 0 1-.346.593Z"
      fill="#5D7183"
    />
    <path
      d="M19.488 109.386c-.245-20.874-.523-55.668.245-76.621 21.119.693 42.495-.165 63.614.527 1.002 25.609.952 51.22.3 76.839l-64.159-.745Z"
      fill="var(--wash-me)"
    />
    <path
      d="M55.467 47.215c-.77 0-1.594-.11-2.322-.527-.422-.243-.767-.559-1.1-.864-.41-.377-.797-.732-1.235-.84-.729-.18-1.55.322-2.343.809l-.186.114c-1.5.912-2.835 1.025-3.76.318-.33-.252-.55-.572-.745-.855-.094-.135-.182-.263-.274-.373-.564-.673-1.856-1.148-3.357-.6-1.28.466-2.375 1.5-3.34 2.414a.636.636 0 0 1-.874-.924c1.056-.998 2.251-2.128 3.778-2.685 1.456-.53 3.569-.451 4.768.978.13.156.24.315.346.47.155.223.3.436.47.565.671.513 1.85-.106 2.327-.395l.182-.111c.92-.564 2.067-1.266 3.312-.96.754.187 1.305.692 1.79 1.138.295.27.574.526.873.698.771.442 1.776.39 2.912.284l10.237-.953a.635.635 0 1 1 .118 1.266l-10.237.953c-.402.038-.86.08-1.34.08Z"
      fill="#96E296"
    />
    <path
      d="M33.134 60.26a.397.397 0 0 1-.002-.794l35.364-.128h.001a.397.397 0 0 1 .002.794l-35.364.128h-.001Zm-4.172 9.611a.398.398 0 0 1-.002-.794l22.102-.11h.002a.398.398 0 0 1 .002.796l-22.102.108h-.002Zm9.189 21.12a.397.397 0 0 1-.002-.794l22.102-.109h.003a.397.397 0 0 1 .001.795l-22.102.108h-.002Zm35.015-11.179h-.003c-14.734-.116-29.688-.16-44.448-.13h-.001a.398.398 0 0 1-.001-.794c14.763-.03 29.72.013 44.455.13a.398.398 0 0 1-.002.794Zm10.668-25.369a.398.398 0 0 1-.397-.39l-.067-3.725c-.098-5.535-.2-11.254-.404-16.65-10.436-.333-21.103-.292-31.424-.252-2.308.009-4.695.018-7.042.023h-.001a.397.397 0 0 1-.001-.795c2.347-.005 4.733-.014 7.041-.023 10.45-.04 21.255-.082 31.822.265a.397.397 0 0 1 .384.381c.215 5.513.32 11.371.42 17.037.021 1.255.043 2.498.066 3.724.004.22-.17.4-.39.404l-.007.001Zm-64.422 48.394a.398.398 0 0 1-.398-.393c-.324-31.596-.215-55.044.322-69.695a.397.397 0 0 1 .41-.382c2.898.095 5.888.165 9.142.213a.397.397 0 0 1-.006.794h-.006c-3.11-.046-5.981-.111-8.76-.2-.525 14.663-.628 37.962-.307 69.262a.397.397 0 0 1-.393.401h-.004Zm64.233 7.691h-.005l-7.324-.085a.397.397 0 0 1 .01-.795l6.932.081c.349-13.838.5-26.62.458-39.068 0-.219.176-.398.396-.399h.001c.219 0 .397.178.397.397.042 12.572-.11 25.487-.468 39.482a.398.398 0 0 1-.397.387Z"
      fill="#5D7183"
    />
    <path
      d="M15.15 111.754c.128.076.258.15.39.223 11.485 6.42 25.172 8.377 38.167 7.379 4.806-.368 9.855-.44 14.456-2.037 4.272-1.482 8.654-6.041 5.553-10.77-1.508-2.299-4.28-3.508-7.013-3.815-7.327-.825-15.016 2.751-22.463 3.281-8.64.616-17.387-.204-25.749-2.473l-3.342 8.212Z"
      fill="var(--white)"
    />
    <path
      d="M47.331 120c-4.58.001-9.048-.388-13.343-1.161-6.837-1.231-13.109-3.423-18.641-6.515a26.276 26.276 0 0 1-7.962-6.872c-2.016-2.595-3.652-5.653-4.864-9.088C-.177 88.716-.23 80.944.201 75.772c.584-7.03 2.316-14.235 5.147-21.412 2.704-6.857 6.36-13.573 10.868-19.962a104.59 104.59 0 0 1 15.15-17.132c5.672-5.148 11.73-9.496 18.003-12.923.153-.083.422-.245.762-.45a61.608 61.608 0 0 1 3.535-2.014 2.349 2.349 0 0 1 2.04-.066c.657.288 1.14.852 1.327 1.545.232.864.48 1.8.73 2.734.477 1.798.97 3.653 1.33 4.946 3.79.506 7.106 1.338 10.133 2.543 3.336 1.328 6.248 3.076 8.895 5.34 2.861-1.583 5.563-2.02 9.035-2.456.47-.06.93.1 1.263.436.332.334.487.795.425 1.261-.704 5.244-2.36 9.19-4.454 13.803a.398.398 0 0 1-.724-.328c2.066-4.553 3.7-8.444 4.39-13.58a.708.708 0 0 0-.201-.597.714.714 0 0 0-.6-.207c-3.48.438-6.16.872-8.984 2.495a.397.397 0 0 1-.459-.044c-2.634-2.294-5.539-4.055-8.88-5.385-3.033-1.207-6.37-2.034-10.201-2.526a.397.397 0 0 1-.332-.286c-.367-1.304-.894-3.29-1.405-5.21-.248-.935-.497-1.87-.728-2.733a1.556 1.556 0 0 0-.88-1.024 1.56 1.56 0 0 0-1.354.044 61.67 61.67 0 0 0-3.49 1.989c-.363.218-.624.376-.792.468-12.43 6.789-24.11 17.378-32.884 29.815-4.471 6.337-8.098 12.997-10.779 19.795C3.285 61.757 1.571 68.885.992 75.838.568 80.933.62 88.585 3.271 96.1c2.496 7.077 6.69 12.302 12.464 15.53 5.455 3.05 11.643 5.212 18.394 6.427 6.21 1.118 12.788 1.422 19.55.903.807-.062 1.637-.116 2.439-.17 4-.263 8.138-.535 11.916-1.846 2.365-.821 5.152-2.824 6.046-5.448.546-1.602.312-3.194-.695-4.729-1.296-1.977-3.748-3.304-6.725-3.638-4.26-.48-8.758.578-13.107 1.602-3.05.719-6.205 1.461-9.284 1.68a78.9 78.9 0 0 1-5.639.202c-6.868 0-13.653-.9-20.242-2.688a.397.397 0 1 1 .209-.767c8.299 2.253 16.917 3.081 25.616 2.461 3.015-.215 6.138-.95 9.158-1.662 4.415-1.039 8.98-2.113 13.378-1.618 3.213.361 5.874 1.816 7.3 3.992 1.133 1.726 1.403 3.601.783 5.422-.861 2.53-3.427 4.862-6.538 5.942-3.88 1.346-8.07 1.622-12.124 1.889-.8.052-1.627.107-2.431.168a83.46 83.46 0 0 1-6.407.248Z"
      fill="#5D7183"
    />
    <path
      d="M41.53 34.322c-1.483 1.654-2.745 2.76-5.41 2.762-3.061.001-4.996-1.951-6.065-4.653-1.272-3.215 3.785-5.366 6.442-5.423 2.103-.046 4.4.79 5.886 2.308 1.503 1.534.43 3.575-.853 5.006Z"
      fill="var(--white)"
    />
    <path
      d="M41.529 34.322Zm-4.864-6.918-.16.001c-1.667.036-4.707 1.032-5.84 2.74-.453.68-.534 1.4-.241 2.14.794 2.008 2.427 4.402 5.693 4.402h.002c2.559-.001 3.734-1.09 5.114-2.63 2.178-2.428 1.477-3.839.865-4.462-1.331-1.36-3.4-2.191-5.433-2.191Zm-.548 10.077c-2.939 0-5.163-1.695-6.432-4.904-.388-.981-.279-1.974.317-2.872.74-1.113 2.035-1.841 2.991-2.256 1.16-.502 2.467-.816 3.495-.839 2.294-.048 4.663.881 6.178 2.428 1.372 1.401 1.065 3.424-.841 5.55-1.47 1.638-2.824 2.892-5.705 2.893h-.003Z"
      fill="#5D7183"
    />
    <path
      d="M80.757 67.244c-1.622-1.517-2.702-2.801-2.648-5.466.062-3.061 2.055-4.955 4.778-5.967 3.24-1.205 5.286 3.896 5.288 6.554.002 2.102-.881 4.382-2.43 5.836-1.565 1.47-3.583.356-4.988-.957Z"
      fill="var(--white)"
    />
    <path
      d="M81.029 66.953c2.383 2.229 3.807 1.557 4.444.959 1.423-1.338 2.306-3.463 2.305-5.547-.001-1.668-.934-4.727-2.618-5.896-.67-.467-1.388-.563-2.135-.285-2.025.752-4.452 2.335-4.52 5.602-.05 2.558 1.014 3.756 2.524 5.167Zm3.018 2.343c-1.088 0-2.311-.594-3.56-1.762-1.61-1.503-2.834-2.883-2.775-5.764.06-2.94 1.802-5.129 5.037-6.331.99-.368 1.98-.238 2.865.377 1.098.762 1.799 2.072 2.193 3.037.478 1.17.764 2.483.765 3.512.003 2.296-.977 4.643-2.555 6.126-.573.538-1.241.805-1.97.805Zm15.267-27.623a.376.376 0 0 1-.084-.01l-13.905-2.995a.396.396 0 1 1 .168-.776l13.904 2.995a.397.397 0 0 1-.084.786Zm-2.007 7.124a.396.396 0 0 1-.185-.045l-11.92-6.281a.398.398 0 0 1 .371-.703l11.92 6.28a.397.397 0 0 1-.186.749Z"
      fill="#5D7183"
    />
  </svg>
);

export default CatWithPaper;
