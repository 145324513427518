import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";

export const Wrapper = styled.div`
  padding: 12px 0;
  height: 64px;

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
    height: unset;
  }
`;

export const InputGroup = styled.div`
  gap: 24px;

  ${device.mediumViewport} {
    width: 100%;
    flex-direction: column;
  }

  .radiobutton-wrapper {
    font-weight: 400;
    font-size: 14px;
    color: var(--rhino);
  }

  .radiobutton-label {
    border: 1px solid var(--rhino);
  }

  .forced-to-be-horizontal {
    height: 40px;
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    align-items: flex-start;

    ${device.mediumViewport} {
      flex-direction: unset;
    }

    .files-list {
      width: fit-content;
    }

    & > div:last-child {
      display: flex;
      flex-direction: row-reverse;
      gap: 16px;
    }
  }
`;
