import React, { useEffect, useRef } from 'react';
import { batch, useDispatch } from 'react-redux';
import Table from './Table';
import Header from './Header';
import SubHeader from './SubHeader';
import Analytics from './Analytics';
import SearchBar from './SearchBar';
import { SECTION_WISE_FIELDS } from '../constants';
import useQueryAndStoreSync from './useQueryAndStoreSync';
import useBroadcastChannelListener from './useBroadcastChannelListener';
import { useTypedSelector } from '../../hooks';
import { fetchTableData } from '../../redux/actions/thunks';
import useDimensions from '../../hooks/useDimensions';
import ColumnPreferences from '../../shared/ColumnPreferences';
import { setQualityPreferenceList } from '../../redux/actions/preferences';
import BaseHelmet from '../../shared/Helmet/BaseHelmet';
import { getBrandList, getSupplierList } from '../../redux/actions/entities';
import * as Styles from './styles';

const Dashboard = () => {
  const dispatch = useDispatch();

  const topStickyContentRef = useRef<HTMLDivElement>(null);
  const tablePreferencesRef = useRef<HTMLDivElement>(null);

  const { height: topStickyContentHeight } = useDimensions(topStickyContentRef);
  const { height: tablePreferencesHeight } = useDimensions(tablePreferencesRef);

  const { is_brand: isBrand, is_supplier: isSupplier } = useTypedSelector(
    store => store.user
  );
  const listFields = useTypedSelector(store => store.preferences.qualityList);
  const {
    section,
    subSection,
    dateFilters: { endDate, startDate },
    entityFilters: { brand, inspector, supplier },
    columnFilters,
    sortConfig,
  } = useTypedSelector(store => store.quality.dashboard);

  useQueryAndStoreSync();

  useEffect(() => {
    batch(() => {
      dispatch(getBrandList());
      dispatch(getSupplierList());
    });
  }, []);

  useEffect(() => {
    if (section) {
      let preferences: Array<string> = Object.keys(
        SECTION_WISE_FIELDS[section]
      );

      if (isBrand) {
        preferences = preferences.filter(column => column !== 'brand');
      }

      if (isSupplier) {
        preferences = preferences.filter(column => column !== 'supplier');
      }

      dispatch(setQualityPreferenceList(preferences));
    }
  }, [section, isBrand, isSupplier]);

  useEffect(() => {
    dispatch(fetchTableData('replace'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    section,
    subSection,
    endDate,
    startDate,
    brand,
    inspector,
    supplier,
    columnFilters,
    sortConfig,
  ]);

  useBroadcastChannelListener();

  return (
    <>
      <BaseHelmet
        customization={{
          title: 'Quality Analytics',
        }}
      />

      <Styles.Wrapper>
        <Styles.Top ref={topStickyContentRef}>
          <Header title="Quality Analytics" />
          <SubHeader />
        </Styles.Top>
        <Analytics />
        <Styles.StickyInScrollable
          className="v-d-flex"
          style={{ height: `calc(100vh - ${topStickyContentHeight}px)` }}
        >
          <Styles.ColumnPreferencesWrapper
            className="flex-end"
            ref={tablePreferencesRef}
          >
            <SearchBar />
            <ColumnPreferences
              allFields={section ? SECTION_WISE_FIELDS[section] : {}}
              onApply={newFields =>
                dispatch(setQualityPreferenceList(newFields))
              }
              preferencesList={listFields}
            />
          </Styles.ColumnPreferencesWrapper>
          <Table
            height={`calc(100vh - ${
              topStickyContentHeight + tablePreferencesHeight
            }px)`}
          />
        </Styles.StickyInScrollable>
      </Styles.Wrapper>
    </>
  );
};

export default Dashboard;
