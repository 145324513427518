import { Button } from '@fashinza/fashinza-design-system';
import React from 'react';
import styled from 'styled-components';
// import StyledButton, {
//   StyledButtonVariant,
// } from 'components/common/StyledButton';

const Wrapper = styled.div`
  > * {
    margin-right: 16px;
    &:last-of-type {
      margin-right: 0;
    }
  }

  button {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 8px 24px;
  }
`;

interface IButtonsProps {
  isInvalidState: boolean;
  onSubmit(): void;
  onCancel(): void;
  submitText?: string;
}

const Buttons = ({
  isInvalidState,
  onSubmit,
  onCancel,
  submitText,
}: IButtonsProps) => (
  <Wrapper className="end-align-center">
    <Button variant="tertiary" onClick={onCancel}>
      Cancel
    </Button>
    <Button isDisabled={isInvalidState} onClick={onSubmit}>
      {`${submitText ?? 'Submit'}`}
    </Button>
  </Wrapper>
);

export default Buttons;
