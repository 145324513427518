import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  isDisabled: boolean;
  hasError: boolean;
}>`
  height: 40px;
  padding: 8px 16px;
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};

  &[data-selected='true'],
  &:focus {
    border-color: var(--all-ports);
    box-shadow: 0 0 0 3px rgba(3, 130, 152, 0.2);
    outline: none;
  }

  ${props =>
    props.hasError &&
    css`
      border-color: var(--red-dirt);
    `}
`;

export const Placeholder = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--manatee);
`;

export const Value = styled(Placeholder)`
  color: var(--rhino);
`;

export const DropdownWrapper = styled.div`
  max-height: 300px;
  margin-top: 4px;
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
`;

export const InputWrapper = styled.div`
  padding: 16px 16px 8px;

  .text-input-wrapper {
    height: 40px;
    border: 1px solid var(--athens-gray);
  }
`;

export const EachOption = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--rhino);
  padding: 8px 16px;

  &:hover {
    background-color: var(--wash-me);
  }
`;

export const AddNewOptionDropdown = styled.div`
  padding: 16px;
`;

export const SearchedText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--lynch);
`;

export const AddNewOptionText = styled.span`
  color: var(--all-ports);
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`;
