import React from 'react';
// import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { FaRegCalendar } from 'react-icons/fa';
import { PPMFormSectionsEnum } from '../../types';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import * as CommonStyles from '../styles';
import * as Styles from './styles';

interface IDateSelectorProps {
  field: string;
  label: string;
  value?: string | null;
  placeHolder?: string;
  onChangeDay: (field: string, date: Date | null) => void;
  section: PPMFormSectionsEnum;
}

const DateSelector = ({
  field,
  label,
  placeHolder = 'Select Date',
  onChangeDay,
  value,
  section,
}: IDateSelectorProps) => (
  <Styles.Wrapper className="align-center">
    <CommonStyles.Label>
      {label} <ShowAsteriskIfMandatory field={field} section={section} />
    </CommonStyles.Label>
    <Styles.DateSelectorWrapper className="align-center">
      <DayPicker
        onDayClick={(date: Date) => onChangeDay(field, date)}
        // placeholder={placeHolder}
        // selected={value ? format(new Date(value), 'd MMM YYY') : undefined}
        selected={value ? new Date(value) : undefined}
      />
      <FaRegCalendar color="var(--lynch)" size={14} />
    </Styles.DateSelectorWrapper>
  </Styles.Wrapper>
);

export default DateSelector;
