import React from "react";
import * as Styles from "./styles";
import { getInitialsNames } from "../../../../utils";

interface IUserCardProps {
  name?: string;
  firstName?: string;
  lastName?: string;
  bottomText?: string;
}

const UserCard = ({
  name,
  firstName,
  lastName,
  bottomText,
}: IUserCardProps) => {
  const displayName = name ?? `${firstName} ${lastName}`;

  return (
    <Styles.Wrapper className="d-flex gap-12">
      <Styles.Avatar className="center">
        {getInitialsNames(displayName)}
      </Styles.Avatar>
      <Styles.Body>
        <Styles.Name className="truncated-text">{displayName}</Styles.Name>
        {bottomText && <Styles.BottomText>{bottomText}</Styles.BottomText>}
      </Styles.Body>
    </Styles.Wrapper>
  );
};

export default UserCard;
