import { AttachmentType } from "../../@types/defined";
import { IPreProductionMeetingForm } from "../../@types/ppm";

export enum InputTypeEnum {
  MULTIPLE_RADIO,
  PLAIN_TEXT,
  NUMERIC_TEXT,
  DATE,
  TEXT_AREA,
  DATE_INTERVAL,
  COMBINED_DROPDOWN_FILE,
  COMBINED_RADIO_TEXT_FILE,
  COMBINED_TEXT_FILE,
  COMBINED_RADIO_FILE,
  HISTORY_AND_REMARKS,
  PLAIN_FILE,
}

export interface IOption {
  id: string;
  name: string;
  booleanValue?: boolean;
}

export type OptionsType = Array<IOption>;

export interface IField {
  id: keyof IPreProductionMeetingForm;
  label: string;
  inputType: InputTypeEnum;
  options?: OptionsType;
  placeholder?: string;
  textFieldSlug?: keyof IPreProductionMeetingForm;
  attachmentSlug?: keyof IPreProductionMeetingForm;
  startDateSlug?: keyof IPreProductionMeetingForm;
  endDateSlug?: keyof IPreProductionMeetingForm;
  choiceSlug?: keyof IPreProductionMeetingForm;
}

export type FieldsType = Array<IField>;

export enum PPMFormSectionsEnum {
  MATERIAL = "material",
  TRIM = "trim",
  SAMPLE_DETAILS = "sample-detail",
  PRODUCTION_WORKSHEET = "production-worksheet",
  PRODUCTION_PLANNING = "production-planning",
  PRE_PRODUCTION_PREPARATION = "pre-production-preparation",
  // PROJECTED_INSPECTION_DATES = 'projected-inspection-date',
  CUTTING_CHECKPOINTS = "cutting-checkpoint",
  SEWING_CHECKPOINTS = "sewing-checkpoint",
  FINISHING_PACKING_CHECKPOINTS = "finishing-packing-checkpoint",
  PENDING_ISSUES_AND_APPROVALS = "pending-issue-approval",
  OTHERS = "other",
  MANAGE_ATTENDEES = "manage-attendee",
  SUBMIT_FORM = "submit-form",
}

export interface INewAttendee {
  identifier: string;
  name: string | null;
  email: string | null;
  mobile: string | null;
  role: string | null;
}

export type NewAttendeesType = Array<INewAttendee>;

export interface IOtherTestReport {
  identifier: string;
  name: string | null;
  attachments: Array<AttachmentType>;
}

export type OtherTestReportsType = Array<IOtherTestReport>;

export type SectionLoadersType = {
  [key in PPMFormSectionsEnum]: boolean;
};

export type SectionAccordionType = {
  [key in PPMFormSectionsEnum]: {
    isOpen: boolean;
    next: PPMFormSectionsEnum | null;
  };
};
