import React from "react";
import { IconPropsType } from "../types";

interface IExcelFileIconProps extends IconPropsType {}

const ExcelFileIcon = (props: IExcelFileIconProps) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={40} height={40} rx={4} fill="var(--scandal)" />
    <path
      d="M22.75 19.9999L14.25 18.4999V29.5834C14.25 30.0896 14.6603 30.4999 15.1665 30.4999H29.8335C30.3397 30.4999 30.75 30.0896 30.75 29.5834V25.4999L22.75 19.9999Z"
      fill="#185C37"
    />
    <path
      d="M22.75 10.5001H15.1665C14.6603 10.5001 14.25 10.9104 14.25 11.4166V15.5001L22.75 20.5L27.25 22L30.75 20.5V15.5001L22.75 10.5001Z"
      fill="#21A366"
    />
    <path d="M14.25 15.5001H22.75V20.5001H14.25V15.5001Z" fill="#107C41" />
    <path
      opacity={0.1}
      d="M20.3335 14.5003H14.25V27.0003H20.3335C20.839 26.9986 21.2483 26.5893 21.25 26.0838V15.4168C21.2483 14.9113 20.839 14.5019 20.3335 14.5003Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M19.8335 15.0002H14.25V27.5002H19.8335C20.339 27.4985 20.7483 27.0892 20.75 26.5837V15.9167C20.7483 15.4112 20.339 15.0018 19.8335 15.0002Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M19.8335 15.0002H14.25V26.5002H19.8335C20.339 26.4985 20.7483 26.0892 20.75 25.5837V15.9167C20.7483 15.4112 20.339 15.0018 19.8335 15.0002Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M19.3335 15.0002H14.25V26.5002H19.3335C19.839 26.4985 20.2483 26.0892 20.25 25.5837V15.9167C20.2483 15.4112 19.839 15.0018 19.3335 15.0002Z"
      fill="black"
    />
    <path
      d="M10.1665 15.0002H19.3335C19.8396 15.0002 20.25 15.4105 20.25 15.9167V25.0837C20.25 25.5898 19.8396 26.0002 19.3335 26.0002H10.1665C9.66033 26.0002 9.25 25.5898 9.25 25.0837V15.9167C9.25 15.4105 9.66033 15.0002 10.1665 15.0002Z"
      fill="url(#paint0_linear_235_6354)"
    />
    <path
      d="M12.0889 23.4788L14.0169 20.4913L12.2504 17.5203H13.6714L14.6354 19.4203C14.7244 19.6008 14.7854 19.7348 14.8184 19.8233H14.8309C14.8932 19.6815 14.9599 19.5415 15.0309 19.4038L16.0614 17.5213H17.3659L15.5544 20.4748L17.4119 23.4788H16.0239L14.9104 21.3933C14.8579 21.3046 14.8134 21.2114 14.7774 21.1148H14.7609C14.7283 21.2094 14.685 21.3 14.6319 21.3848L13.4854 23.4788H12.0889Z"
      fill="var(--white)"
    />
    <path
      d="M29.8335 10.5001H22.75V15.5001H30.75V11.4166C30.75 10.9104 30.3397 10.5001 29.8335 10.5001Z"
      fill="#33C481"
    />
    <path d="M22.75 20.5001H30.75V25.5001H22.75V20.5001Z" fill="#107C41" />
    <defs>
      <linearGradient
        id="paint0_linear_235_6354"
        x1={11.1609}
        y1={14.2841}
        x2={18.3391}
        y2={26.7163}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18884F" />
        <stop offset={0.5} stopColor="#117E43" />
        <stop offset={1} stopColor="#0B6631" />
      </linearGradient>
    </defs>
  </svg>
);

export default ExcelFileIcon;
