import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid var(--athens-gray);
  border-radius: 12px;
  background: var(--white);
  padding: 24px;
  gap: 8px;
`;

export const TopText = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: var(--ebony);
`;

export const TopSubText = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: var(--lynch);
`;

export const Fact = styled.h5`
  font-weight: 600;
  font-size: 36px;
  color: var(--ebony);
`;

export const SideFact = styled.p`
  color: var(--ebony);
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 0;
`;

export const BottomFactContainer = styled.div`
  gap: 8px;
  margin-top: 8px;
`;

export const BottomFact = styled.p<{ isGreen: boolean }>`
  font-weight: 500;
  font-size: 14px;
  color: ${({ isGreen: isHigh }) =>
    isHigh ? 'var(--deep-sea)' : 'var(--red-dirt)'};
`;

export const BottomFactDescription = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: var(--lynch);
`;
