import React from "react";
import { PPM_FORM_SECTION_MANDATORY_FIELDS } from "../../constants";
import { PPMFormSectionsEnum } from "../../types";
import { IPreProductionMeetingForm } from "../../../../@types/ppm";

interface IShowAsteriskIfMandatoryProps {
  field: string;
  section: PPMFormSectionsEnum;
}

const ShowAsteriskIfMandatory = ({
  field,
  section,
}: IShowAsteriskIfMandatoryProps) => {
  const isMandatory = PPM_FORM_SECTION_MANDATORY_FIELDS[section].includes(
    field as Partial<keyof IPreProductionMeetingForm>
  );

  if (isMandatory) {
    return <sup>*</sup>;
  }

  return null;
};
export default ShowAsteriskIfMandatory;
