import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid var(--athens-gray);
  margin-top: 4px;
  box-shadow: 0px 16px 32px rgba(44, 53, 86, 0.15);
  border-radius: 8px;
  background: var(--white);
  border: 2px solid blue;

  .DayPicker-wrapper,
  .rdp {
    background-color: var(--white);
    padding: 0;
    z-index: 1;
  }

  .DayPicker-Month,
  .rdp-month {
    border-spacing: 4px;
    border-collapse: separate;
  }

  .rdp-head_cell {
    color: var(--loblolly);
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    width: 40px;
  }

  .rdp-cell > button {
    padding: unset;
  }

  .DayPicker-Day,
  .rdp-day {
    width: 40px;
    height: 40px;
    color: var(--rhino);
    border-radius: 2px;
    &:hover {
      background-color: var(--bubbles);
    }
  }

  .DayPicker-Day--disabled {
    color: var(--manatee);
    &:hover {
      background-color: inherit;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--outside
    ) {
    background-color: var(--all-ports);
    color: var(--white);
    font-weight: 600;
  }
`;

export const Body = styled.div`
  padding: 24px 0 12px 0;
  border: 2px dashed red;
`;

export const Footer = styled.div`
  padding: 12px 24px 24px 24px;
  background: var(--white);
`;
