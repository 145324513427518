import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IoClose } from 'react-icons/io5';
import Table from '../../Table';
import { getFields } from './fields';
import { useTypedSelector } from '../../../hooks';
import { fetchQaList } from '../../../redux/actions/thunks';
import ModalHoc from '../../../shared/ModalHoc';
import * as Styles from './styles';

interface IAssignQA {
  onClose(): void;
}

const AssignQA = ({ onClose }: IAssignQA) => {
  const dispatch = useDispatch();
  const column = useMemo(() => getFields(), []);
  const qaList = useTypedSelector(store => store.quality.qaTeamList);

  const data = useMemo(
    () =>
      qaList.map((item, idx) => ({
        ...item,
        serial_number: idx + 1,
        name: `${item.first_name} ${item.last_name}`,
      })),
    [qaList]
  );

  useEffect(() => {
    dispatch(fetchQaList());
  }, []);

  return (
    <ModalHoc onClose={onClose}>
      <Styles.Wrapper className="v-space-between">
        <Styles.Header className="spread">
          <span>Available QAs for this region</span>
          <Styles.IconWrapper onClick={onClose}>
            <IoClose />
          </Styles.IconWrapper>
        </Styles.Header>
        <Styles.Body>
          <Table columns={column} data={data} isLoading={false} />
        </Styles.Body>
      </Styles.Wrapper>
    </ModalHoc>
  );
};

export default AssignQA;
