import React from "react";
import { IconPropsType } from "./types";

interface IRocketIconProps extends IconPropsType {}

const RocketIcon = ({
  stroke = "var(--rhino)",
  size = 24,
  strokeWidth = 1.25,
  ...props
}: IRocketIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.1929 13.7633C17.2869 12.929 17.9326 12.2 17.9326 12.2C17.9326 12.2 21.847 8.4306 20.8319 3.21142C17.3496 2.31849 13.0226 4.32616 10.3938 7.87195M16.1929 13.7633L16.7728 18.434L14.0182 20.8986L11.1187 16.0849M16.1929 13.7633C14.9699 14.696 13.1866 15.7603 11.1187 16.0849M11.1187 16.0849C10.1562 16.2359 9.13198 16.2267 8.07394 15.9694C7.96707 14.8668 8.02418 13.7986 8.2169 12.7799M8.2169 12.7799L3 10.0253L5.60959 7.12581L10.3938 7.87195M8.2169 12.7799C8.56201 10.9558 9.34196 9.29076 10.3938 7.87195"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </svg>
);

export default RocketIcon;
