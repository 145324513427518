import React, { useState } from 'react';
// import ViewImage from '../viewImage/viewImage';
import * as Styles from './styles';
import { DEFAULT_RFQ_IMAGE } from '../../constants';
// import Portal from '../Portal';

interface IProductImageProps {
  imageUrl: string | null;
  size?: number;
  customMargin?: string;
  className?: string;
  disablePreviewImage?: boolean;
  height?: number;
  width?: number;
  borderRadius?: number;
}

const ProductImage = ({
  imageUrl,
  customMargin,
  height,
  width,
  size = 50,
  borderRadius = 4,
  className = 'product-image',
  disablePreviewImage = false,
}: IProductImageProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* TODO:use ViewImage  */}
      {/* {!disablePreviewImage && isOpen && imageUrl && (
        <Portal>
          <Styles.ImageViewerWrapper>
            <ViewImage image={imageUrl} onClose={() => setIsOpen(false)} />
          </Styles.ImageViewerWrapper>
        </Portal>
      )} */}
      <Styles.Wrapper
        size={size}
        height={height}
        width={width}
        borderRadius={borderRadius}
        customMargin={customMargin}
        className={className}
        onClick={() => setIsOpen(true)}
      >
        <Styles.Image
          borderRadius={borderRadius}
          src={imageUrl || DEFAULT_RFQ_IMAGE}
          alt="product"
          className="full-dimension"
        />
      </Styles.Wrapper>
    </>
  );
};

export default ProductImage;
