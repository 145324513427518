import { STANDARD_PAGE_SIZE } from './constants';
import { ResultActionEnum, StatusEnum } from './ManagePPM/constants';

export function getResultAction(
  status: string,
  result: string | null,
  id: number | null | string
) {
  if (status === StatusEnum.InProgress) {
    return { result: ResultActionEnum.Empty, id };
  }
  if (status === StatusEnum.NotYetStarted) {
    return { result: ResultActionEnum.StartPPM, id };
  }
  if (status === StatusEnum.Completed) {
    if (result === null) return { result: ResultActionEnum.ApproveReject, id };
    if (result === 'accepted') return { result: ResultActionEnum.Approved, id };
    if (result === 'rejected') return { result: ResultActionEnum.Rejected, id };
  }
  return { result: ResultActionEnum.Empty, id };
}

export function getToggleDropdownOptions(
  id: number | string,
  handleChangeQAClick: (id: number | string) => void
) {
  return [
    {
      name: 'Change QA',
      onClick: () => {
        handleChangeQAClick(id);
      },
    },
  ];
}

export function getNextPageCount(currentCount: number, totalPages: number) {
  // if not fetched yet, fetch first page
  if (totalPages === -1) return 1;

  const PAGE_SIZE = STANDARD_PAGE_SIZE;
  const newPage = Math.ceil(Number(currentCount) / PAGE_SIZE) + 1;
  return newPage <= totalPages ? newPage : -1;
}

export function getSectionFromPathname(pathname: string): string {
  return pathname.split('quality/')[1];
}
