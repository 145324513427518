import React, { ChangeEvent } from "react";
import { createPortal } from "react-dom";
import { FilterOptionsType } from "./types";
import * as Styles from "./styles";
import { ObjectType } from "../../../@types/defined";

function getRootNode() {
  return document.getElementById("root");
}

interface IFilterOptionsDropdownProps {
  fieldValue: string;
  fixedAttributes: ObjectType;
  onChangeFilter?: (
    data: ObjectType,
    event?: ChangeEvent<HTMLInputElement>
  ) => void;
  filterOptions?: FilterOptionsType;
}

const FilterOptionsDropdown = ({
  fieldValue,
  fixedAttributes,
  onChangeFilter,
  filterOptions = {},
}: IFilterOptionsDropdownProps) => {
  const rootNode = getRootNode();

  if (rootNode === null) {
    return null;
  }

  return createPortal(
    <Styles.AllOptions className="has-y-scroll" {...fixedAttributes}>
      {(filterOptions[fieldValue] ?? []).map((eachOption) => (
        <Styles.EachOption
          key={eachOption.id}
          role="presentation"
          className="cursor-pointer"
          onClick={() =>
            onChangeFilter?.({
              name: eachOption.dataProps["data-name"],
              value: eachOption.id,
            })
          }
        >
          {eachOption.name}
        </Styles.EachOption>
      ))}
    </Styles.AllOptions>,
    rootNode
  );
};

export default FilterOptionsDropdown;
