import styled, { css } from "styled-components";
import { device } from "../../../../constants/mediaQueries";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  ${device.mediumViewport} {
    margin-top: 12px;
  }
  * {
    user-select: none;
  }
`;

export const Stepper = styled.div`
  width: 5%;
  padding-top: 16px;
`;

export const StepperCircle = styled.div<{ isComplete: boolean }>`
  height: 24px;
  width: 24px;
  border-radius: 100%;
  background: var(--white);
  border: 1px solid
    ${(props) =>
      props.isComplete ? "var(--pacific-blue)" : "var(--athens-gray)"};
  transition: border linear 0.5s;
`;

export const StepperLine = styled.div<{ isLast: boolean; isComplete: boolean }>`
  position: absolute;
  top: 40px;
  border-left: 1px solid
    ${(props) =>
      props.isComplete ? "var(--pacific-blue)" : "var(--athens-gray)"};
  ${(props) =>
    props.isLast &&
    css`
      border-left: none;
    `}
  transition: border-left linear 0.5s;
`;

export const Content = styled.div`
  width: 95%;

  ${device.mediumViewport} {
    width: 100%;
  }
`;

export const HeaderText = styled(motion.h3)`
  font-weight: 500;
  gap: 8px;
  padding: 16px;
  font-family: "Lora";

  ${device.mediumViewport} {
    font-size: 14px;
    font-weight: 500;
    color: var(--lynch);
    border-bottom: none;
    padding: 16px 16px 0 16px;
  }
`;

export const Body = styled.div`
  padding: 16px;
`;

export const Main = styled(motion.div)`
  border: 1px solid var(--athens-gray);
  border-radius: 2px;
  margin-bottom: 24px;
  background: var(--wash-me);
`;

export const Footer = styled.footer`
  border-top: 1px solid var(--athens-gray);
  padding: 16px;

  button {
    background: var(--wash-me);
    &:hover {
      background: var(--wash-me);
    }
  }
`;

export const Disclaimer = styled.p`
  color: var(--chilean-fire);
  background: var(--early-dawn);
  font-weight: 600;
  font-size: 14px;
  gap: 12px;
  padding: 12px;
  border-radius: 2px;
`;
