import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid var(--athens-gray);
  border-radius: 12px;
  padding: 24px;
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  background: var(--white);
`;

export const Heading = styled.h5`
  font-weight: 500;
  font-size: 16px;
`;

export const Left = styled.div`
  width: 60%;
  gap: 24px;
`;

export const AreaChartWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Right = styled.div`
  flex: 1;

  button {
    width: fit-content;
  }
`;

export const MetricLabel = styled.div`
  height: fit-content;
  padding: 0 24px;
  gap: 6px;

  &:nth-child(1) {
    position: relative;

    &::after {
      display: inline-block;
      background: var(--athens-gray);
      width: 2px;
      height: 100%;
      content: '';
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      position: absolute;
      right: 0;
    }
  }
`;

export const MetricLabelText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: var(--fiord);
`;

export const MetricLabelNumber = styled.h5<{ color: string }>`
  height: fit-content;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: ${props => props.color};
`;
