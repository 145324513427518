import React, {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import DropdownMenu from './DropdownMenu';
import { SectionsType } from './types';
import ICONOGRAPHY from '../iconography';
import useBoolean from '../../hooks/useBoolean';
import { useCustomPopper } from '../../hooks/useCustomPopper';
import { getFixedHeightModifier } from '../../utils/popper';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import * as Styles from './styles';

const { dots: DotsIcon } = ICONOGRAPHY;

const FIXED_HEIGHT = getFixedHeightModifier(20);

interface IDropdownControlsProps {
  sections: SectionsType;
  onAction?: (mode: 'open' | 'close') => void;
  trigger?: ReactNode;
  shouldCenter?: boolean;
  fixedStyles?: CSSProperties;
}

const DropdownControls = ({
  sections,
  onAction,
  trigger,
  shouldCenter = true,
  fixedStyles = {},
}: IDropdownControlsProps) => {
  const outsideClickRef = useRef(null);

  const [isDropdownOpen, dropdownOpenActions] = useBoolean();
  const popperAttributes = useCustomPopper({
    strategy: 'fixed',
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altBoundary: false,
          boundary: document.body,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'flip',
        options: {
          altBoundary: false,
        },
      },
      FIXED_HEIGHT,
    ],
  });

  function handleClose() {
    dropdownOpenActions.off();
  }

  function handleClick() {
    if (isDropdownOpen) {
      onAction?.('close');
    } else {
      onAction?.('open');
    }
    dropdownOpenActions.toggle();
  }

  useOnClickOutside(outsideClickRef, () => {
    handleClose();
  });

  return (
    <Styles.ControlWrapper
      data-viewtype="walkthrough-element"
      role="presentation"
      className={`cursor-pointer pos-r ${shouldCenter ? 'center' : ''}`}
      isActive={isDropdownOpen}
      onClick={event => {
        // swallowEvent(event);
        handleClick();
      }}
      haveDynamicWidth={Boolean(trigger)}
      {...popperAttributes.reference}
    >
      {trigger ?? <DotsIcon size={20} />}
      {isDropdownOpen && (
        <div ref={outsideClickRef}>
          <DropdownMenu
            sections={sections}
            onClick={handleClose}
            fixedAttributes={{
              ...popperAttributes.fixed,
              style: {
                ...popperAttributes.fixed.style,
                ...fixedStyles,
              },
            }}
          />
        </div>
      )}
    </Styles.ControlWrapper>
  );
};

export default DropdownControls;
