import { useEffect, useRef, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { AllFieldsType } from "./types";
import { reorderArray } from "../../utils";

export function useSortedSlugsHandler(
  allFields: AllFieldsType,
  preferencesList: Array<string>
) {
  const [modifiedPreference, setModifiedPreference] = useState(preferencesList);

  function calculateNewSlugs(newPreference: Array<string>) {
    const newFields: Set<string> = new Set(newPreference);
    for (const key in allFields) {
      if (Object.prototype.hasOwnProperty.call(allFields, key)) {
        newFields.add(key);
      }
    }
    return Array.from(newFields);
  }

  const combinedSlugsRef = useRef(calculateNewSlugs(preferencesList));

  useEffect(() => {
    setModifiedPreference(preferencesList);
    combinedSlugsRef.current = calculateNewSlugs(preferencesList);
  }, [preferencesList]);

  function handleDragEnd(result: DropResult) {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const newSlugsList = reorderArray(
      combinedSlugsRef.current,
      result.source.index,
      result.destination?.index
    );

    combinedSlugsRef.current = calculateNewSlugs(newSlugsList);
    setModifiedPreference((oldPreferences) => {
      const oldSet = new Set(oldPreferences);
      let newPreferences: Array<string> = [];
      for (const slug of combinedSlugsRef.current) {
        if (oldSet.has(slug)) {
          newPreferences = [...newPreferences, slug];
        }
      }
      return newPreferences;
    });
  }

  function handleToggleSlug(toggledSlug: string) {
    setModifiedPreference((oldPreferences) => {
      const oldSet = new Set(oldPreferences);
      if (oldSet.has(toggledSlug)) {
        return oldPreferences.filter((eachSlug) => eachSlug !== toggledSlug);
      }

      oldSet.add(toggledSlug);

      let newPreferences: Array<string> = [];
      for (const slug of combinedSlugsRef.current) {
        if (oldSet.has(slug)) {
          newPreferences = [...newPreferences, slug];
        }
      }
      return newPreferences;
    });
  }

  function handleReset() {
    const originalKeys = Object.keys(allFields);
    setModifiedPreference(originalKeys);
    combinedSlugsRef.current = calculateNewSlugs(originalKeys);
  }

  return {
    combinedSlugs: combinedSlugsRef.current,
    modifiedPreference,
    handleDragEnd,
    handleToggleSlug,
    handleReset,
  };
}
