export enum ResultActionEnum {
  StartPPM = 'Start PPM',
  ApproveReject = 'Approve/Reject',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Empty = '',
}

export enum StatusEnum {
  NotYetStarted = 'not_yet_started',
  InProgress = 'in_progress',
  Completed = 'completed',
}

export const STATUS_MAPPING = Object.freeze({
  not_yet_started: 'Not yet started',
  in_progress: 'In Progress',
  completed: 'Completed',
});
