import { trackAction } from '../analytics/MixpanelHelper';
import {
  BaseTypes,
  MixpanelSources,
  QualityTrackerActionsEnum,
} from '../analytics/sources';

interface ITrackingInfo {
  source?: MixpanelSources;
  section?: string;
  to_section?: string;
  time_spent?: number;
  report_type?: string;
  sub_section?: string | null;
}

class QualityTracker {
  constructor() {
    throw new Error('QualityTracker is a static class');
  }

  static track(
    action: QualityTrackerActionsEnum,
    trackingInfo: ITrackingInfo = {}
  ) {
    trackAction(BaseTypes.Quality, {
      action,
      ...trackingInfo,
    });
  }
}

export default QualityTracker;
