import React from 'react';
import { PPMFormSectionsEnum } from '../../types';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import * as CommonStyles from '../styles';
import * as Styles from './styles';
import { IPreProductionMeetingForm } from '../../../../@types/ppm';
import { AttachmentType } from '../../../../@types/defined';
import VerticalFileUpload from '../../../../shared/VerticalFileUpload';

interface IPlainFileProps {
  id: keyof IPreProductionMeetingForm;
  onFileChange: (
    field: keyof IPreProductionMeetingForm,
    newFiles: Array<AttachmentType>,
    actionType: string
  ) => void;
  label: string;
  getFieldValueFromStore: (
    field: keyof IPreProductionMeetingForm
  ) => string | Array<AttachmentType> | null;
  section: PPMFormSectionsEnum;
}

const PlainFile = ({
  id,
  label,
  getFieldValueFromStore,
  onFileChange,
  section,
}: IPlainFileProps) => (
  <Styles.Wrapper className="align-center">
    <CommonStyles.Label>
      {label} <ShowAsteriskIfMandatory field={id} section={section} />
    </CommonStyles.Label>
    <Styles.InputGroup className="full-flex-grow d-flex">
      <VerticalFileUpload
        labelText={label}
        maxListFilesToShow={5}
        className="forced-to-be-horizontal"
        allFiles={(getFieldValueFromStore(id) as Array<AttachmentType>) ?? []}
        onSetFile={(newFiles, actionType) =>
          onFileChange(id, newFiles, actionType)
        }
      />
    </Styles.InputGroup>
  </Styles.Wrapper>
);

export default PlainFile;
