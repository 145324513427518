export const SAMPLING_INSPECTION_SLUGS = [
  'pp_sample_submission',
  'fit_sample_submission',
  'lab_dip_submission',
  'size_set_submission',
  'top_submission',
  'strike_off_submission',
];

export const SAMPLING_INSPECTION_FILTER_TO_SLUG = {
  'pp-sample': 'pp_sample_submission',
  'size-set': 'size_set_submission',
  'fit-sample': 'fit_sample_submission',
  'lab-fit': 'lab_dip_submission',
  'strike-off': 'strike_off_submission',
  'top-sample': 'top_submission',
  'shipment-sample': 'shipment_sample',
  'photo-shoot-sample': 'photo_shoot_sample',
};

export const QUALITY_REPORT_FILTER_SLUG_MAP = {
  'fabric-inspection': 'fabric_inspection',
  'final-inspection': 'final_inspection',
};

export const QUALITY_DASHBOARD_BROADCAST_CHANNEL =
  'QUALITY_DASHBOARD_BROADCAST_CHANNEL';

export const QUALITY_DASHBOARD_BROADCAST_CHANNEL_MESSAGES = {
  REFRESH: 'REFRESH',
};
