import React from 'react';
// import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { FaRegCalendar } from 'react-icons/fa';
import { PPMFormSectionsEnum } from '../../types';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import { IPreProductionMeetingForm } from '../../../../@types/ppm';
import RadioButton from '../../../../shared/RadioButton';
import * as CommonStyles from '../styles';
import * as Styles from './styles';

interface IDateIntervalSelectorProps {
  label: string;
  startDateSlug: keyof IPreProductionMeetingForm;
  endDateSlug: keyof IPreProductionMeetingForm;
  choiceSlug: keyof IPreProductionMeetingForm;
  getFieldValueFromStore: (
    field: keyof IPreProductionMeetingForm
  ) => string | null | Date;
  onChangeDay: (field: string, date: Date | null) => void;
  onChange: (key: string, value: string | boolean) => void;
  section: PPMFormSectionsEnum;
}

const DateIntervalSelector = ({
  label,
  startDateSlug,
  endDateSlug,
  choiceSlug,
  getFieldValueFromStore,
  onChangeDay,
  onChange,
  section,
}: IDateIntervalSelectorProps) => (
  <Styles.Wrapper className="align-center">
    <CommonStyles.Label>
      {label}
      <ShowAsteriskIfMandatory field={startDateSlug} section={section} />
    </CommonStyles.Label>
    <Styles.InputGroup className="full-flex-grow d-flex">
      <Styles.DateSelectorWrapper
        bgColor={
          getFieldValueFromStore(choiceSlug) ? 'var(--wash-me)' : 'var(--white)'
        }
        className="align-center"
      >
        {getFieldValueFromStore(choiceSlug) ? null : (
          <DayPicker
            onDayClick={date => onChangeDay(startDateSlug, date)}
            // inputProps={{
            //   disabled: Boolean(getFieldValueFromStore(choiceSlug)),
            // }}
            // placeholder="Start Date"
            // dayPickerProps={{
            //   disabledDays: {
            //     after: new Date(String(getFieldValueFromStore(endDateSlug))),
            //   },
            // }}
            disabled={{
              after: new Date(String(getFieldValueFromStore(endDateSlug))),
            }}
            selected={
              getFieldValueFromStore(startDateSlug)
                ? new Date(String(getFieldValueFromStore(startDateSlug)))
                : undefined
            }
            // value={
            //   getFieldValueFromStore(startDateSlug)
            //     ? format(
            //         new Date(String(getFieldValueFromStore(startDateSlug))),
            //         `dd MMM YYY`
            //       )
            //     : ''
            // }
          />
        )}

        <FaRegCalendar color="var(--lynch)" size={14} />
      </Styles.DateSelectorWrapper>
      <Styles.DateSelectorWrapper
        bgColor={
          getFieldValueFromStore(choiceSlug) ? 'var(--wash-me)' : 'var(--white)'
        }
        className="align-center on-the-left"
      >
        {getFieldValueFromStore(choiceSlug) ? null : (
          <DayPicker
            onDayClick={date => onChangeDay(endDateSlug, date)}
            // inputProps={{ disabled: Boolean(getFieldValueFromStore(choiceSlug)) }}
            // placeholder="End Date"
            disabled={{
              before: new Date(String(getFieldValueFromStore(startDateSlug))),
            }}
            selected={
              getFieldValueFromStore(endDateSlug)
                ? new Date(String(getFieldValueFromStore(endDateSlug)))
                : undefined
            }
            // value={
            //   getFieldValueFromStore(endDateSlug)
            //     ? format(
            //         new Date(String(getFieldValueFromStore(endDateSlug))),
            //         `dd MMM YYY`
            //       )
            //     : ''
            // }
          />
        )}

        <FaRegCalendar color="var(--lynch)" size={14} />
      </Styles.DateSelectorWrapper>
      <RadioButton
        datalabel="N/A"
        datavalue={label} // useless
        dataname={choiceSlug}
        onChange={() => {}}
        onClick={() => {
          const value = getFieldValueFromStore(choiceSlug);
          if (value) {
            onChange(choiceSlug, false);
          } else {
            onChangeDay(startDateSlug, null);
            onChangeDay(endDateSlug, null);
            onChange(choiceSlug, true);
          }
        }}
        isChecked={Boolean(getFieldValueFromStore(choiceSlug))}
        item={undefined}
      />
    </Styles.InputGroup>
  </Styles.Wrapper>
);

export default DateIntervalSelector;
