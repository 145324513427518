import React from "react";
import animationData from "../../../../lotties/finalInspection.json";
import StatisticCard from "../StatisticCard";
import OverlayLoader from "../OverlayLoader";
import DefectsStatistics from "./DefectsStatistics";
import useAnalyticsData from "./useAnalyticsData";
import * as Styles from "./styles";
import { numberWithCommas, roundWithPrecision } from "../../../../utils";

const FinalInspectionAnalytics = () => {
  const {
    critical_defects: criticalDefects,
    major_defects: majorDefects,
    minor_defects: minorDefects,
    total_sample_size: totalSampleSize,
    total_styles: totalStyles,
    total_scheduled_quantity: totalScheduledQuantity,
    average_styles: averageStyles,
    top_five_defects: topFiveDefects,
    observed_quality_level_percentage: observedQualityLevelPercentage,
    current_month_reject_and_short: currentMonthRejectAndShort,
    previous_month_reject_and_short: previousMonthRejectAndShort,
    isLoading,
  } = useAnalyticsData();

  return (
    <Styles.Wrapper className="pos-r">
      <OverlayLoader isLoading={isLoading} animationData={animationData} />
      <StatisticCard
        topText="Styles Inspected"
        fact={totalStyles}
        centerText={`${numberWithCommas(totalScheduledQuantity)} pcs`}
        bottomFact={averageStyles}
        bottomFactDescription="Avg. styles"
        gridRow="1/4"
        gridColumn="1/4"
        infoHeading="Styles inspected"
        infoDescription="Total styles inspected in the selected date"
      />
      <StatisticCard
        topText="Bulk Quantity Inspected"
        fact={totalSampleSize}
        gridRow="4/6"
        gridColumn="1/4"
        infoHeading="Bulk Quantity Inspected"
        infoDescription="Quantity inspected at the time of final inspection"
      />
      <StatisticCard
        topText="OQL"
        fact={observedQualityLevelPercentage}
        factSuffix="%"
        gridRow="1/3"
        gridColumn="4/6"
        infoHeading="OQL (Observed Quality Level)"
        infoDescription="Total major defect found per sample size (%)"
      />
      <StatisticCard
        topText="Reject & Short"
        topSubText="(form GRN)"
        fact={roundWithPrecision(currentMonthRejectAndShort, 2)}
        factSuffix="%"
        bottomFact={roundWithPrecision(previousMonthRejectAndShort, 2)}
        bottomFactDescription="Avg"
        gridRow="3/6"
        gridColumn="4/6"
        infoHeading="Reject & Short (from GRN)"
        infoDescription="(Reject + Short Quantity) / Total invoice"
        revertComparisonSpecificity
      />
      <DefectsStatistics
        critical={criticalDefects}
        major={majorDefects}
        minor={minorDefects}
        topFive={topFiveDefects}
      />
    </Styles.Wrapper>
  );
};

export default FinalInspectionAnalytics;
