import React, { useState } from 'react';
import { ManageQAContext } from '../../ManageQA/helperHooks';
import AssignQA from '../../ManageQA/AssignQAModal';
import QualityTracker from '../../mixpanel';
import { QARoutesEnum } from '../../constants';
import { QualityTrackerActionsEnum } from '../../../analytics/sources';
import useBoolean from '../../../hooks/useBoolean';
import ToggleDropdownControl from '../../../shared/ToggleDropdownControl/toggleDropdownControl';

interface IManageQaProps {
  manageQaId: number | string;
}

const ManageQa = ({ manageQaId }: IManageQaProps) => {
  const [isModalOpen, modalOpenActions] = useBoolean();
  const [selectedRowId, setSelectedRowId] = useState<string | number>('');

  function handleChangeQaClick(id: number | string) {
    modalOpenActions.toggle();
    setSelectedRowId(id);

    QualityTracker.track(QualityTrackerActionsEnum.ClickChangeQa, {
      section: QARoutesEnum.QUALITY_ASSURANCE,
    });
  }

  function getToggleDropdownOptions(id: number | string) {
    return [
      {
        name: 'Change QA',
        onClick: () => {
          handleChangeQaClick(id);
        },
      },
    ];
  }

  return (
    <ManageQAContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getToggleDropdownOptions,
        selectedRowId,
        toggleAssignQAModal: modalOpenActions.toggle,
      }}
    >
      {isModalOpen && <AssignQA onClose={modalOpenActions.off} />}
      <ToggleDropdownControl
        data={[
          {
            name: 'Change QA',
            onClick: () => {
              handleChangeQaClick(manageQaId);
            },
          },
        ]}
      />
    </ManageQAContext.Provider>
  );
};

export default ManageQa;
