import PdfFileIcon from '../shared/iconography/FileType/PdfFileIcon';
import ExcelFileIcon from '../shared/iconography/FileType/ExcelFileIcon';
import DocFileIcon from '../shared/iconography/FileType/DocFileIcon';
import PptFileIcon from '../shared/iconography/FileType/PptFileIcon';
import ImageFileIcon from '../shared/iconography/FileType/ImageFileIcon';
import VideoFileIcon from '../shared/iconography/FileType/VideoFileIcon';
import OtherFileIcon from '../shared/iconography/FileType/OtherFileIcon';
import { AttachmentType } from '../@types/defined';
import { FILE_SIZE_NAMES } from '../constants';

export const isImgFile = (ext = '') =>
  ['jpg', 'jpeg', 'png', 'svg', 'gif'].indexOf(ext.toLowerCase()) > -1;

export const isDocFile = (ext = '') =>
  ['doc', 'docx', 'docm', 'dot', 'dotx', 'odt', 'rtf'].indexOf(
    ext.toLowerCase()
  ) > -1;

export const isPptFile = (ext = '') =>
  ['ppt', 'pptx'].indexOf(ext.toLowerCase()) > -1;

export const isExcelFile = (ext = '') =>
  [
    'xls',
    'xlsx',
    'xlsm',
    'xlsb',
    'xltx',
    'xltm',
    'xlt',
    'xml',
    'xlam',
    'xla',
    'xlw',
    'xlr',
  ].indexOf(ext.toLowerCase()) > -1;

export const isPdfFile = (ext = '') => ['pdf'].indexOf(ext.toLowerCase()) > -1;

export const isVideoFile = (ext = '') =>
  ['avi', 'flv', 'webm', 'mpg', 'ogg', 'mp4', 'mov'].indexOf(
    ext.toLowerCase()
  ) > -1;

export function getFileAttributes(fileName: AttachmentType['name']) {
  const DEFAULT_FILE_ATTRIBUTES = {
    backgroundColor: 'var(--titan-white)',
    Icon: OtherFileIcon,
  };

  const re = /(?:\.([^.]+))?$/;
  const matcher = re.exec(fileName);
  if (matcher === null) {
    return DEFAULT_FILE_ATTRIBUTES;
  }

  const extension = matcher[1];

  if (isPdfFile(extension)) {
    return {
      backgroundColor: 'var(--bubbles)',
      Icon: PdfFileIcon,
    };
  }

  if (isExcelFile(extension)) {
    return {
      backgroundColor: 'var(--scandal)',
      Icon: ExcelFileIcon,
    };
  }

  if (isDocFile(extension)) {
    return {
      backgroundColor: 'var(--pattens-blue)',
      Icon: DocFileIcon,
    };
  }

  if (isPptFile(extension)) {
    return {
      backgroundColor: 'var(--fair-pink)',
      Icon: PptFileIcon,
    };
  }

  if (isImgFile(extension)) {
    return {
      backgroundColor: 'var(--early-dawn)',
      Icon: ImageFileIcon,
    };
  }

  if (isVideoFile(extension)) {
    return {
      backgroundColor: 'var(--early-dawn)',
      Icon: VideoFileIcon,
    };
  }

  return DEFAULT_FILE_ATTRIBUTES;
}

export function getEquivalentBytes(
  desiredSymbol: (typeof FILE_SIZE_NAMES)[number]
) {
  const index = FILE_SIZE_NAMES.indexOf(desiredSymbol);
  return index === 0 ? 1 : 1000 ** index;
}
