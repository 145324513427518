import { combineReducers } from 'redux';
import type { History } from 'history';
import { connectRouter } from 'connected-react-router';
import qualityReducer from './reducers/quality';
import ordersReducer from './reducers/entities';
import { IRootState } from '../hooks';
import commonReducer from './reducers/common';
import userReducer from './reducers/userReducer';
import credentialsReducer from './reducers/credentialsReducer';
import chatReducer from './reducers/chatReducer';
import notificationsReducer from './reducers/notificationsReducer';
import purchaseOrderReducer from './reducers/purchaseOrderReducer';
import preferencesReducer from './reducers/preferences';

const rootReducer = (history: History) =>
  combineReducers<IRootState>({
    router: connectRouter(history),
    quality: qualityReducer,
    orders: ordersReducer,
    common: commonReducer,
    user: userReducer,
    credentials: credentialsReducer,
    preferences: preferencesReducer,
    chat: chatReducer,
    notifications: notificationsReducer,
    purchaseOrder: purchaseOrderReducer,
  });

export default rootReducer;
