import React, { useRef } from 'react';
import { differenceInMonths, eachMonthOfInterval, format } from 'date-fns';
import { Dot, Label } from '../../../../../shared/Charting/PieChart/styles';
import { useTypedSelector } from '../../../../../hooks';
import { SampleInspectionAnalyticsType } from '../../../../../@types/finalInspection';
import useDimensions from '../../../../../hooks/useDimensions';
import AreaChart from '../../../../../shared/Charting/AreaChart';
import * as Styles from './styles';

interface IComparisonStatisticsProps {
  created: number;
  approved: number;
  data: SampleInspectionAnalyticsType['interval_based_comparison_data'];
}

const ComparisonStatistics = ({
  data,
  approved,
  created,
}: IComparisonStatisticsProps) => {
  const areaChartWrapperRef = useRef<HTMLDivElement>(null);
  const { startDate, endDate } = useTypedSelector(
    store => store.quality.dashboard.dateFilters
  );

  const { height, width } = useDimensions(areaChartWrapperRef);

  let shouldShowTick = false;

  let chartData = data;

  if (startDate && endDate) {
    shouldShowTick = differenceInMonths(endDate, startDate) >= 6;

    if (shouldShowTick) {
      const months = eachMonthOfInterval({
        start: startDate,
        end: endDate,
      }).map(date => format(date, 'MMM'));

      if (months.length === 7) {
        months.shift();
      }

      chartData = data.map((item, index) => ({
        interval: months[index],
        approved: item.approved,
        created: item.created,
      }));
    }
  }

  return (
    <Styles.Wrapper className="d-flex">
      <Styles.Left className="v-space-between">
        <Styles.Heading>Created vs Approved</Styles.Heading>
        <Styles.AreaChartWrapper
          ref={areaChartWrapperRef}
          className="v-center-flex-end"
        >
          <AreaChart
            showTick={shouldShowTick}
            data={chartData.map(item => ({
              name: item.interval,
              created: item.created,
              approved: item.approved,
            }))}
            height={height}
            width={width}
            xAxisDataKey="name"
            areaData={[
              {
                dataKey: 'approved',
                stroke: 'var(--seance)',
                strokeWidth: 2,
                gradientOpacity: 0.25,
              },
              {
                dataKey: 'created',
                stroke: 'var(--cerise)',
                strokeWidth: 2,
                gradientOpacity: 0.25,
              },
            ]}
          />
        </Styles.AreaChartWrapper>
      </Styles.Left>
      <Styles.Right className="v-align-flex-end">
        <div className="center">
          <>
            <Dot fill="var(--seance)" />
            <Label>Created</Label>
          </>
          <>
            <Dot fill="var(--cerise)" />
            <Label>Approved</Label>
          </>
        </div>
        <div className="center full-flex full-width">
          <Styles.MetricLabel className="v-d-flex">
            <Styles.MetricLabelText>Created</Styles.MetricLabelText>
            <Styles.MetricLabelNumber color="var(--seance)">
              {created}
            </Styles.MetricLabelNumber>
          </Styles.MetricLabel>
          <Styles.MetricLabel className="v-d-flex">
            <Styles.MetricLabelText>Approved</Styles.MetricLabelText>
            <Styles.MetricLabelNumber color="var(--cerise)">
              {approved}
            </Styles.MetricLabelNumber>
          </Styles.MetricLabel>
        </div>
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default ComparisonStatistics;
