import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useGetBaseHelmetData } from './helperHooks';
import { ICustomHelmetData } from './types';
import config from '../../config';
import { isUndefined } from '../../utils/assertion';
import { removeLastSlash } from '../../utils/strings';

interface IBaseHelmetProps {
  customization?: ICustomHelmetData;
}

const BaseHelmet = ({ customization }: IBaseHelmetProps) => {
  const { pathname } = useLocation();
  const currentUrl = `${config.PUBLIC_URL}${removeLastSlash(pathname)}`;
  const { formattedTitle, ogTitle, description, ogDescription } =
    useGetBaseHelmetData(customization);

  return (
    <>
      <Helmet>
        <meta property="og:url" content={currentUrl} />
        <title>{formattedTitle}</title>
        <meta property="og:title" content={ogTitle} />
      </Helmet>
      {!isUndefined(description) && (
        <Helmet>
          <meta name="description" content={description} />
          <meta
            property="og:description"
            content={ogDescription ?? description}
          />
        </Helmet>
      )}
    </>
  );
};

export default BaseHelmet;
