import React from "react";
import { IconPropsType } from "./types";

interface IChartLineIconProps extends IconPropsType {}

const ChartLineIcon = ({
  fill = "var(--rhino)",
  ...props
}: IChartLineIconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 4V3.375H3.375V4H4ZM20 4H20.625V3.375H20V4ZM6.55806 11.5581C6.31398 11.8021 6.31398 12.1979 6.55806 12.4419C6.80214 12.686 7.19786 12.686 7.44194 12.4419L6.55806 11.5581ZM10 9L10.4419 8.55806C10.1979 8.31398 9.80214 8.31398 9.55806 8.55806L10 9ZM13 12L12.5581 12.4419C12.8021 12.686 13.1979 12.686 13.4419 12.4419L13 12ZM17.4419 8.44194C17.686 8.19786 17.686 7.80214 17.4419 7.55806C17.1979 7.31398 16.8021 7.31398 16.5581 7.55806L17.4419 8.44194ZM7.55806 20.5581C7.31398 20.8021 7.31398 21.1979 7.55806 21.4419C7.80214 21.686 8.19786 21.686 8.44194 21.4419L7.55806 20.5581ZM12 17L12.4419 16.5581C12.1979 16.314 11.8021 16.314 11.5581 16.5581L12 17ZM15.5581 21.4419C15.8021 21.686 16.1979 21.686 16.4419 21.4419C16.686 21.1979 16.686 20.8021 16.4419 20.5581L15.5581 21.4419ZM3 3.375C2.65482 3.375 2.375 3.65482 2.375 4C2.375 4.34518 2.65482 4.625 3 4.625V3.375ZM21 4.625C21.3452 4.625 21.625 4.34518 21.625 4C21.625 3.65482 21.3452 3.375 21 3.375V4.625ZM4 4.625H20V3.375H4V4.625ZM19.375 4V16H20.625V4H19.375ZM19 16.375H5V17.625H19V16.375ZM4.625 16V4H3.375V16H4.625ZM5 16.375C4.79289 16.375 4.625 16.2071 4.625 16H3.375C3.375 16.8975 4.10254 17.625 5 17.625V16.375ZM19.375 16C19.375 16.2071 19.2071 16.375 19 16.375V17.625C19.8975 17.625 20.625 16.8975 20.625 16H19.375ZM7.44194 12.4419L10.4419 9.44194L9.55806 8.55806L6.55806 11.5581L7.44194 12.4419ZM9.55806 9.44194L12.5581 12.4419L13.4419 11.5581L10.4419 8.55806L9.55806 9.44194ZM13.4419 12.4419L17.4419 8.44194L16.5581 7.55806L12.5581 11.5581L13.4419 12.4419ZM8.44194 21.4419L12.4419 17.4419L11.5581 16.5581L7.55806 20.5581L8.44194 21.4419ZM11.5581 17.4419L15.5581 21.4419L16.4419 20.5581L12.4419 16.5581L11.5581 17.4419ZM3 4.625H21V3.375H3V4.625Z"
      fill={fill}
    />
  </svg>
);

export default ChartLineIcon;
