import { z } from 'zod';
import { Toast } from '@fashinza/fashinza-design-system';
import config from '../config';
import { openInNewTab } from '../utils';
import {
  ApiResponseEnum,
  APIResponseType,
  ApiResponseHandlerType,
  RawApiResponseHandlerType,
} from '../@types/api';

async function apiResponseHandler<T>(
  apiPromise: Promise<APIResponseType<T>>,
  schema?: z.ZodType<T>
): Promise<ApiResponseHandlerType<T>> {
  try {
    const response = await apiPromise;
    if (response.status) {
      return {
        type: ApiResponseEnum.Success,
        data:
          schema && config.IS_DEV_MODE
            ? schema.parse(response.data)
            : response.data,
        msg: response.msg,
      };
    }
    return {
      type: ApiResponseEnum.Failure,
      error: response.msg,
      source: 'response',
    };
  } catch (error) {
    if (config.IS_DEV_MODE) {
      console.log(error);
    }

    return {
      type: ApiResponseEnum.Failure,
      // @ts-ignore
      error: error.toString(),
      source: 'network',
    };
  }
}

export async function rawApiResponseHandler<
  TSuccessData,
  TSuccessMessage,
  TErrorData,
  TErrorMessage
>(
  apiPromise: Promise<
    Response & {
      json(): Promise<
        | {
            status: true;
            data: TSuccessData;
            msg: TSuccessMessage;
          }
        | {
            status: false;
            data: TErrorData;
            msg: TErrorMessage;
          }
      >;
    }
  >,
  schema?: z.ZodType<TSuccessData>
): Promise<
  RawApiResponseHandlerType<
    TSuccessData,
    TSuccessMessage,
    TErrorData,
    TErrorMessage
  >
> {
  try {
    const response = await apiPromise;
    const json = await response.json();
    if (json.status) {
      return {
        type: ApiResponseEnum.Success,
        data:
          schema && config.IS_DEV_MODE ? schema.parse(json.data) : json.data,
        msg: json.msg,
        response,
      };
    }

    return {
      type: ApiResponseEnum.Failure,
      source: 'response',
      data: json.data,
      msg: json.msg,
      response,
    };
  } catch (error) {
    if (config.IS_DEV_MODE) {
      console.trace(error);
    }

    return {
      type: ApiResponseEnum.NetworkFailure,
      error: error instanceof Error ? error.toString() : 'Something went wrong',
      source: 'network',
    };
  }
}

export async function handleFileDownload(
  promise: Promise<APIResponseType<{ url: string }>>,
  errorMessage?: string
) {
  const response = await apiResponseHandler(promise);
  if (response.type === ApiResponseEnum.Success) {
    openInNewTab(response.data.url);
  } else if (errorMessage) {
    Toast.ERROR(errorMessage);
  } else {
    Toast.ERROR(response.error);
  }
}

export default apiResponseHandler;
