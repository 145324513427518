import styled from 'styled-components';
import { boxCss, textCss } from '../styles';

export const RemainingCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RemainingCount = styled.button`
  ${boxCss};
  cursor: pointer;
  background-color: var(--rhino);
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  padding: 0;
`;

export const CountText = styled.p`
  ${textCss};
`;

export const ViewAll = styled.span`
  cursor: pointer;
  text-decoration: underline;
  margin-left: 4px;
  font-weight: 600;
  color: var(--lynch);
`;
