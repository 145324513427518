import styled from 'styled-components';

export const Wrapper = styled.div`
  gap: 24px;
`;

export const Iframe = styled.iframe`
  width: 80%;
  height: 85vh;
  border: 1px solid var(--athens-gray);
  border-radius: 2px;
  background: var(--white);
`;

export const RightPanel = styled.div`
  width: 20%;
  height: 85vh;
  padding: 16px;
  gap: 12px;
  border: 1px solid var(--athens-gray);
  border-radius: 2px;
  background: var(--white);
`;

export const Status = styled.div<{ isApprovedStatus: boolean }>`
  font-weight: 700;
  color: ${props =>
    props.isApprovedStatus ? 'var(--all-ports)' : 'var(--red-dirt)'};
`;

export const ModalWrapper = styled.div`
  width: 560px;
  padding: 32px;
  background: var(--white);
  z-index: 100;
`;

export const HeaderText = styled.div`
  font-size: 14px;
  margin: 0 0 12px 0;
`;

export const Warning = styled.div`
  background: var(--kournikova);
  border-radius: 2px;
  padding: 4px;
`;

export const WarningSection = styled.div`
  padding: 4px;
`;

export const WarningHeading = styled.div`
  padding: 0 4px;
  font-weight: 600;
  font-size: 12px;
`;

export const WarningText = styled.div`
  padding: 4px;
  font-weight: 400;
  font-size: 10px;
`;

export const TextArea = styled.textarea<{ isApproved?: boolean }>`
  border: 1px solid black;
  height: 100px;
  min-height: 100px;
  max-height: 200px;
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  padding: 8px;
  background-color: ${props =>
    !props.isApproved ? 'var(--wash-me)' : 'var(--white)'};
  resize: none;
  &:focus {
    border: 1px solid var(--athens-gray);
  }
`;

export const AcceptButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  padding: 14px 0;
  font-weight: 600;
  font-size: 16px;
  background: var(--pacific-blue);
  color: var(--white);
  gap: 8px;
`;

export const RejectButton = styled.button`
  cursor: pointer;
  border-radius: 4px;
  padding: 14px 0;
  font-weight: 600;
  font-size: 16px;
  border: 1px solid var(--red-dirt);
  color: var(--red-dirt);
  gap: 8px;
`;
