import React from "react";
import { IconPropsType } from "./types";

interface ICheckCircleFilledIconProps extends IconPropsType {}

const CheckCircleFilledIcon = ({
  size = 20,
  fill = "var(--deep-sea)",
  ...props
}: ICheckCircleFilledIconProps) => (
  <svg
    height={size}
    width={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16ZM12.5815 8.83145C12.7646 8.64839 12.7646 8.35159 12.5815 8.16854C12.3984 7.98548 12.1016 7.98548 11.9186 8.16854L9.25004 10.8371L8.08149 9.66854C7.89844 9.48548 7.60164 9.48548 7.41858 9.66854C7.23552 9.85159 7.23552 10.1484 7.41858 10.3314L8.91858 11.8314C9.10164 12.0145 9.39844 12.0145 9.58149 11.8314L12.5815 8.83145Z"
      fill={fill}
    />
  </svg>
);

export default CheckCircleFilledIcon;
