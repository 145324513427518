import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";
import { Label } from "../styles";

export const Wrapper = styled.div`
  padding: 12px 0;

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InputGroupContainer = styled.div`
  gap: 16px;

  ${device.mediumViewport} {
    width: 100%;
  }
`;

export const InputGroup = styled.div`
  gap: 24px;

  ${device.mediumViewport} {
    justify-content: space-between;
    border-bottom: 1px solid var(--athens-gray);
    padding-bottom: 16px;
  }
`;

export const AddMoreButton = styled.button`
  width: 200px;
  font-weight: 600;
  font-size: 14px;
  color: var(--rhino);
  cursor: pointer;
  width: fit-content;
  padding: 8px;
  background: var(--wash-me);

  &:hover {
    text-decoration: underline;
  }
`;

export const PaddedLabel = styled(Label)`
  padding-top: 8px;
`;

export const InputGroupUpper = styled.div`
  display: flex;
  gap: 24px;

  ${device.mediumViewport} {
    flex-direction: column;
    flex-grow: 1;
    gap: 12px;
  }

  .fileDocument-wrapper {
    width: 200px;

    ${device.mediumViewport} {
      width: 100%;
    }

    .fileDocumentItem-wrapper {
      height: 40px;

      .image-wrapper {
        background-color: unset;

        svg path {
          fill: var(--rhino);
        }
      }
    }

    .fileDocument-body-add {
      margin-top: 0;

      .add-documents .add-file-2 {
        height: 40px;
        border: 1px solid var(--loblolly);
        border-radius: 4px;
        justify-content: flex-start;
        padding: 12px;

        .text {
          color: var(--rhino);
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }

  .simpleDropdown-wrapper {
    width: 200px;
    min-width: 200px;
    height: 40px;
    flex-grow: unset;

    ${device.mediumViewport} {
      width: 100%;
    }

    .label-name,
    .label-value {
      font-weight: 400;
      font-size: 14px;
      color: var(--rhino);
    }

    .label-value {
      max-width: 70vw;
      text-overflow: ellipsis;
    }

    span {
      border: 1px solid var(--loblolly);
      border-radius: 4px;
      height: 40px;
    }

    div[role="menuitem"] {
      padding: 6px;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background-color: var(--fair-pink);
      }
    }
  }
`;
