import {
  AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  CONFIRMATION_MULTIPLE_CHOICE_OPTIONS,
} from '../constants';
import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'style_description_and_construction',
    label: 'Style Description & Construction',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'size_color_ratio',
    label: 'Size/Color Ratio',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'order_quantity_and_color_size',
    label: 'Order Quantity by Color/Size',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'approved_graded_size_specifications',
    label: 'Approved Graded Size Specifications',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'label_position',
    label: 'Label Position',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: CONFIRMATION_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'hangtag_position',
    label: 'Hangtag/ Price Ticket Position',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: CONFIRMATION_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'packing_instruction',
    label: 'Packing Instructions',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'ucc_label',
    label: 'Carton Mark/UCC Label',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  },
];
