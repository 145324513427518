import React, { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { replace } from 'connected-react-router';
import QualityTracker from '../mixpanel';
import { getSectionFromPathname } from '../helpers';
import { QualityTrackerActionsEnum } from '../../analytics/sources';
import * as Styles from './styles';

interface ISubNavigationItem {
  name: string;
  hash?: string;
  path?: string;
}

export interface INavigationItem {
  name: string;
  items: Array<ISubNavigationItem>;
}

interface IVerticalNavigationPanelProps {
  navigationItems: Array<INavigationItem>;
  changeRoutesOnTabChange?: boolean;
}

const VerticalNavigationPanel = ({
  navigationItems,
  changeRoutesOnTabChange = false,
}: IVerticalNavigationPanelProps) => {
  const dispatch = useDispatch();

  const { hash: currentHash, pathname } = useLocation();
  const lastIndex = navigationItems.length - 1;
  const firstNavItem = navigationItems[0].items[0];

  function changeHash(hash = '') {
    if (hash !== currentHash) {
      dispatch(replace({ hash }));
    }
  }

  function changePath(path = '') {
    if (path.length !== 0) {
      dispatch(replace(path));

      QualityTracker.track(QualityTrackerActionsEnum.ClickSideNav, {
        to_section: path,
      });
    }
  }

  function getIsTabActive(item: ISubNavigationItem): boolean {
    if (changeRoutesOnTabChange && item.path) {
      const section = getSectionFromPathname(pathname);
      return section === item.path;
    }

    return currentHash === item.hash;
  }

  useEffect(() => {
    if (currentHash.length === 0 && firstNavItem.hash) {
      dispatch(replace({ hash: firstNavItem.hash }));
    }
  }, [navigationItems]);

  return (
    <Styles.Wrapper className="full-height">
      {navigationItems.map((navItem, index) => (
        <Fragment key={navItem.name}>
          <Styles.NavigationSection>{navItem.name}</Styles.NavigationSection>
          {navItem.items.map(listItem => (
            <Styles.NavigationItem
              key={listItem.path}
              isActive={getIsTabActive(listItem)}
              onClick={() =>
                changeRoutesOnTabChange
                  ? changePath(listItem.path)
                  : changeHash(listItem.hash)
              }
            >
              {listItem.name}
            </Styles.NavigationItem>
          ))}
          {lastIndex !== index && (
            <Styles.SectionDivider className="full-width" />
          )}
        </Fragment>
      ))}
    </Styles.Wrapper>
  );
};

export default VerticalNavigationPanel;
