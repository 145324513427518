import React, { Fragment, useState } from 'react';
import { PieChart as PieGraph, Pie, Cell } from 'recharts';
import TooltipDisplay from './TooltipDisplay';
import { IConfig, IPieChartData, ITooltipPosition } from './types';
import * as Styles from './styles';

const CONFIG: IConfig = Object.freeze({
  chartHeight: 200,
  chartWidth: 400,
  chartInnerRadius: 40,
  chartOuterRadius: 100,
});

interface IPieChartProps {
  data: Array<IPieChartData>;
  shouldShowLabels?: boolean;
  config?: Partial<typeof CONFIG>;
  classNames?: Partial<{
    labelWrapper: string;
    chartWrapper: string;
    wholeWrapper: string;
  }>;
}

const PieChart = ({
  data,
  shouldShowLabels = true,
  config = {},
  classNames = {},
}: IPieChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeTooltipPosition, setActiveTooltipPosition] =
    useState<ITooltipPosition | null>(null);

  const updatedConfig = {
    ...CONFIG,
    ...config,
  };
  const updatedClassNames = {
    labelWrapper: 'center',
    chartWrapper: 'center',
    ...classNames,
  };

  return (
    <div className="pos-r">
      {shouldShowLabels && (
        <div className={updatedClassNames.labelWrapper}>
          {data.map(eachEntry => (
            <Fragment key={eachEntry.name}>
              <Styles.Dot fill={eachEntry.color} />
              <Styles.Label>{eachEntry.name}</Styles.Label>
            </Fragment>
          ))}
        </div>
      )}
      <div className={updatedClassNames.chartWrapper}>
        <PieGraph
          height={updatedConfig.chartHeight}
          width={updatedConfig.chartWidth}
        >
          <Pie
            activeIndex={activeIndex ?? -1}
            data={data.map(eachData => ({
              ...eachData,
              config: updatedConfig,
            }))}
            cx="50%"
            cy="50%"
            innerRadius={updatedConfig.chartInnerRadius}
            outerRadius={updatedConfig.chartOuterRadius}
            dataKey="value"
            minAngle={360 / 100}
          >
            {data.map((eachEntry, index) => (
              <Cell
                key={eachEntry.name}
                fill={eachEntry.color}
                onMouseMove={event => {
                  setActiveIndex(index);
                  setActiveTooltipPosition({
                    x: event.clientX,
                    y: event.clientY,
                  });
                }}
                onMouseLeave={() => {
                  setActiveIndex(null);
                  setActiveTooltipPosition(null);
                }}
              />
            ))}
          </Pie>
        </PieGraph>
      </div>
      {activeIndex !== null && activeTooltipPosition !== null && (
        <TooltipDisplay
          payload={data[activeIndex]}
          position={activeTooltipPosition}
        />
      )}
    </div>
  );
};

export default PieChart;
