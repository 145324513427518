import React, { Component } from 'react';
import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton';
import ICONOGRAPHY from '../iconography';
import './simpleDropdown.scss';
import { isEmptyObject } from '../../utils';

export const createElementWithEvent = ({
  value,
  dataname,
  onChange,
  event,
  dataProps,
}) => {
  const elem = document.createElement('input');
  if (!isEmptyObject(dataProps)) {
    for (const item in dataProps) {
      if (dataProps.hasOwnProperty(item)) {
        elem.setAttribute(item, dataProps[item]);
      }
    }
  }
  elem.setAttribute('data-name', dataname);
  elem.setAttribute('value', value);
  elem.addEventListener(event, onChange);
  elem.dispatchEvent(new Event(event));
  return elem;
};

const { 'chevron-down': ChevronIcon } = ICONOGRAPHY;

const ToggleIcon = ({ open }) => (
  <div className="label-downarrow">
    <ChevronIcon
      className={open ? 'inactive' : 'active'}
      size="18"
      stroke="var(--lynch)"
    />
  </div>
);

class SimpleDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onChange = (val, e) => {
    const { dataProps = {} } = this.props;
    const elem = createElementWithEvent({
      value: val,
      dataname: this.props.dataname,
      event: 'change',
      onChange: this.props.onChange,
      dataProps,
    });
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const {
      onChange,
      options,
      value,
      dataname,
      labelPlaceholder,
      deselectOptionShow,
      deselectItemName,
      isError,
      dataProps,
      disable = false,
    } = this.props;
    const {
      dropdownClass,
      dropdownPlaceholderClass,
      dropdownBoxClass,
      dropDownItemClass,
      dropDownItemActiveClass,
      errorClass,
      openClass,
      permanentLabeltext = '',
      className = '',
      displayNameField = 'name',
      displayOptionField = 'name',
    } = this.props;

    const { isOpen } = this.state;
    let item_label = value || '';

    if (options.length) {
      const items = options.filter(item => String(item.id) === String(value));
      if (items.length === 1) {
        item_label = items[0][displayNameField] || item_label;
      }
    }

    return (
      <Wrapper
        onSelection={this.onChange}
        className={`simpleDropdown-wrapper ${className}`}
        onMenuToggle={({ isOpen }) => {
          this.setState({ isOpen });
        }}
      >
        <Button
          className={[
            dropdownClass,
            isError ? errorClass : '',
            isOpen ? (openClass ? openClass : '') : '',
          ].join(' ')}
          {...dataProps}
          disabled={disable}
        >
          {!item_label && (
            <div className={['label-name', dropdownPlaceholderClass].join(' ')}>
              {labelPlaceholder}
            </div>
          )}
          {permanentLabeltext.length > 0 && (
            <div className="permanent-label-text">{permanentLabeltext}</div>
          )}
          {item_label && <div className="label-value">{item_label}</div>}
          <ToggleIcon open={isOpen} />
        </Button>

        <Menu
          className={['simpleDropdown-dropdownbox', dropdownBoxClass].join(' ')}
        >
          {deselectOptionShow && (
            <MenuItem
              className={[dropDownItemClass, !value ? 'activeClass' : ''].join(
                ' '
              )}
              data-name={dataname}
              data-label=""
              data-value=""
              value=""
            >
              {deselectItemName}
            </MenuItem>
          )}
          {options.map((item, i) => {
            return (
              <MenuItem
                key={'_' + item.id}
                className={[
                  dropDownItemClass,
                  String(value) === String(item.id)
                    ? dropDownItemActiveClass
                    : '',
                ].join(' ')}
                data-name={dataname}
                data-label={item[displayOptionField]}
                data-value={item.id}
                value={item.id}
              >
                {item[displayOptionField]}
              </MenuItem>
            );
          })}
        </Menu>
      </Wrapper>
    );
  }
}

export default SimpleDropdown;
