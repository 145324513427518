import React from "react";
import styled, { css } from "styled-components";
import Tooltip from "react-tooltip-lite";
import ICONOGRAPHY from "../iconography";

const { "information-circle": InfoIcon } = ICONOGRAPHY;

interface IStyledTooltipProps {
  maxWidth?: number;
}

export const StyledTooltip = styled(Tooltip)<IStyledTooltipProps>`
  margin-left: 8px;

  .react-tooltip-lite {
    ${(props) =>
      props.maxWidth &&
      css`
        max-width: ${props.maxWidth}px !important;
      `}
    color: var(--white);
    background-color: var(--ebony);
    border-color: var(--ebony);
    padding: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    white-space: pre-wrap;
  }

  .react-tooltip-lite-arrow {
    border-color: var(--ebony);
  }
`;

const Heading = styled.p`
  font-weight: 600;
`;

export const SubHeading = styled.p`
  margin-top: 4px;
`;

export interface IInfoTooltipProps {
  maxWidth?: number;
  heading: string;
  subHeading?: string;
}

const InfoTooltip = ({ maxWidth, heading, subHeading }: IInfoTooltipProps) => (
  <StyledTooltip
    maxWidth={maxWidth}
    content={
      <>
        <Heading>{heading}</Heading>
        {subHeading && <SubHeading>{subHeading}</SubHeading>}
      </>
    }
    arrowSize={8}
  >
    <div className="center cursor-pointer">
      <InfoIcon size={16} stroke="var(--rhino)" />
    </div>
  </StyledTooltip>
);

export default InfoTooltip;
