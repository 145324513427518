import {
  ClubbedReportsDataSchema,
  ClubbedReportsDataType,
  FinalInspectionAnalyticsSchema,
  FinalInspectionAnalyticsType,
  FinalInspectionReportUrlSchema,
  FinalInspectionReportUrlType,
  SampleInspectionAnalyticsSchema,
  SampleInspectionAnalyticsType,
} from "../../@types/finalInspection";
import { IAttendees } from "../../@types/base";
import { ObjectType } from "../../@types/defined";
import {
  getAttendeesApi,
  getFinalInspectionAnalyticsApi,
  getFinalInspectionReportHtmlApi,
  getFinalInspectionReportUrlApi,
  getPpmApi,
  getRelatedTnaDataForReportCreationApi,
  getSampleInspectionAnalyticsApi,
  reviewFinalInspectionReportApi,
  updatePpmApi,
  updatePpmResultApi,
} from "../../api";
import {
  IPreProductionMeetingForm,
  IUpdatePPMFormApiResponse,
} from "../../@types/ppm";
import apiResponseHandler from "../../services/apiResponseHandler";

export async function reviewFinalInspectionReport(
  processStepId: string,
  data: ObjectType
) {
  const promise = reviewFinalInspectionReportApi(processStepId, data);
  return apiResponseHandler(promise);
}

export async function getFinalInspectionReportHtml(processStepId: string) {
  const promise = getFinalInspectionReportHtmlApi(processStepId);
  return apiResponseHandler<{
    html: string;
    is_approved: boolean | null;
    remarks: string;
  }>(promise);
}

export async function updatePpmResult(
  ppmId: string,
  data: Partial<IPreProductionMeetingForm>
) {
  const promise = updatePpmResultApi(ppmId, data);
  return apiResponseHandler<{ pdf: string }>(promise);
}

export async function getPpmFormById(ppmId: string) {
  const promise = getPpmApi(ppmId);
  return apiResponseHandler<IPreProductionMeetingForm>(promise);
}

export async function getPpmAttendees(ppmId: string) {
  const promise = getAttendeesApi(ppmId);
  return apiResponseHandler<IAttendees>(promise);
}

export async function updatePpm(
  ppmId: string,
  data: Partial<IPreProductionMeetingForm>
) {
  const promise = updatePpmApi(ppmId, data);
  return apiResponseHandler<IUpdatePPMFormApiResponse>(promise);
}

export function getRelatedTnaDataForReportCreation(
  orderIds: Array<number | string>
) {
  const orderIdsAsApiParams = orderIds.join(",");
  const promise = getRelatedTnaDataForReportCreationApi(orderIdsAsApiParams);
  return apiResponseHandler<ClubbedReportsDataType>(
    promise,
    ClubbedReportsDataSchema
  );
}

export function getFinalInspectionReportUrl(reportId: number, slug: string) {
  const promise = getFinalInspectionReportUrlApi(reportId, slug);
  return apiResponseHandler<FinalInspectionReportUrlType>(
    promise,
    FinalInspectionReportUrlSchema
  );
}

export function getFinalInspectionAnalytics(query: string) {
  const promise = getFinalInspectionAnalyticsApi(query);
  return apiResponseHandler<FinalInspectionAnalyticsType>(
    promise,
    FinalInspectionAnalyticsSchema
  );
}

export function getSampleInspectionAnalytics(query: string) {
  const promise = getSampleInspectionAnalyticsApi(query);
  return apiResponseHandler<SampleInspectionAnalyticsType>(
    promise,
    SampleInspectionAnalyticsSchema
  );
}
