import React, { useRef, useEffect, ReactNode } from 'react';
import { detectKeyPress } from '../../utils';

interface ClickOutsideProps {
  children: ReactNode;
  onClose?: (e: MouseEvent | KeyboardEvent) => void;
  disableClickHandler?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}

const ClickOutside = ({
  children,
  onClose,
  style = {},
  className = '',
  onClick = () => null,
  disableClickHandler = false,
}: ClickOutsideProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickEvent = (e: MouseEvent) => {
    if (!containerRef.current?.contains(e.target as Node)) {
      if (onClose) onClose(e);
    }
  };

  const handleKeyEvent = (e: KeyboardEvent) => {
    if (detectKeyPress(e).escape) {
      if (onClose) onClose(e);
    }
  };

  useEffect(() => {
    if (!disableClickHandler) {
      document.addEventListener('click', handleClickEvent);
    }
    document.addEventListener('keydown', handleKeyEvent, true);

    return () => {
      if (!disableClickHandler) {
        document.removeEventListener('click', handleClickEvent);
      }
      document.removeEventListener('keydown', handleKeyEvent, true);
    };
  }, [disableClickHandler]);

  return (
    <div
      role="presentation"
      className={`click-outside-div ${className}`}
      onClick={onClick}
      style={style}
      ref={containerRef}
    >
      {children}
    </div>
  );
};

export default ClickOutside;

// import React, { PureComponent } from 'react';
// import { detectKeyPress } from '../../utils';

// class ClickOutside extends PureComponent {
//   constructor(props) {
//     super(props);
//   }

//   componentDidMount() {
//     const { disableClickHandler } = this.props;
//     if (!disableClickHandler)
//       document.addEventListener('click', this.handleClickEvent);

//     document.addEventListener('keydown', this.handleKeyEvent, true);
//   }

//   componentWillUnmount() {
//     const { disableClickHandler } = this.props;
//     if (!disableClickHandler)
//       document.removeEventListener('click', this.handleClickEvent);
//     document.removeEventListener('keydown', this.handleKeyEvent, true);
//   }

//   handleClickEvent = e => {
//     if (!this.containerRef.contains(e.target)) {
//       const { onClose } = this.props;

//       if (onClose) {
//         onClose(e);
//       }
//     }
//   };

//   handleKeyEvent = e => {
//     if (detectKeyPress(e).escape) {
//       const { onClose } = this.props;

//       if (onClose) {
//         onClose(e);
//       }
//     }
//   };

//   render() {
//     const {
//       children,
//       style = {},
//       onClick = () => null,
//       className = '',
//     } = this.props;
//     return (
//       <div
//         role="presentation"
//         className={`click-outside-div ${className}`}
//         onClick={onClick}
//         style={style}
//         ref={el => (this.containerRef = el)}
//       >
//         {children}
//       </div>
//     );
//   }
// }

// export default ClickOutside;
