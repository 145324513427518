import React from "react";
import { useDispatch } from "react-redux";
import { QUALITY_TABS } from "../../constants";
import QualityTracker from "../../mixpanel";
import { QualityTrackerActionsEnum } from "../../../analytics/sources";
import { useTypedSelector } from "../../../hooks";
import {
  setDashboardSection,
  setDashboardSubSection,
} from "../../../redux/actions/dashboard";
import {
  DashboardSectionsSchema,
  DashboardSectionsType,
  DashboardSubSectionsType,
} from "../../../@types/dashboard";
import * as Styles from "./styles";

interface IHeaderProps {
  title: string;
}

const Header = ({ title }: IHeaderProps) => {
  const dispatch = useDispatch();

  const isAdmin = useTypedSelector((store) => store.user.is_admin);
  const section = useTypedSelector((store) => store.quality.dashboard.section);

  function onClickTab(path: string) {
    if (DashboardSectionsSchema.safeParse(path).success) {
      dispatch(setDashboardSection(path as DashboardSectionsType));
      let newSubSection: DashboardSubSectionsType = null;
      if (path === "sample-inspection") {
        newSubSection = "all-samples";
      }
      dispatch(setDashboardSubSection(newSubSection));
    }
  }

  let qualityTabs = QUALITY_TABS;

  if (!isAdmin) {
    qualityTabs = qualityTabs.filter((tab) => tab.path !== "manage-qa");
  }

  return (
    <Styles.Wrapper className="v-d-flex">
      <Styles.H1 className="align-center">{title}</Styles.H1>
      <Styles.Nav>
        <Styles.Ul className="d-flex">
          {qualityTabs.map((tab) => (
            <Styles.Tab
              key={tab.path}
              className="cursor-pointer"
              isActive={tab.path === section}
              onClick={() => {
                onClickTab(tab.path);
                QualityTracker.track(QualityTrackerActionsEnum.ChangeSection, {
                  section: tab.path,
                });
              }}
            >
              {tab.title}
            </Styles.Tab>
          ))}
        </Styles.Ul>
      </Styles.Nav>
    </Styles.Wrapper>
  );
};

export default Header;
