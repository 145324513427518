/* eslint-disable default-param-last */
import { IOrderReducer } from '../../../@types/shared';

const INITIAL_STATE: IOrderReducer = {
  supplier_list: [],
  brand_list: [],
};

const ordersReducer = (
  state = INITIAL_STATE,
  action: { type: string; data: any }
) => {
  switch (action.type) {
    case 'orders/SET_SUPPLIER_LIST': {
      const newState = { ...state };

      // adding to supplier list
      const newList = [...action.data];
      for (let i = 0; i < newList.length; i += 1) {
        const supplier = { ...newList[i] };
        newList[i] = supplier;
      }
      newState.supplier_list = newList;

      // adding to new_order_input_layout
      // const new_order_input_layout = [...state.order_input_layout];
      // let i = 0;
      // for (i = 0; i < new_order_input_layout.length; i++) {
      //   if (new_order_input_layout[i].dataName === 'supplier') break;
      // }
      // const newobj = { ...new_order_input_layout[i] };
      // newobj.options = [...action.data];
      // new_order_input_layout[i] = newobj;
      // newstate.order_input_layout = new_order_input_layout;

      return newState;
    }

    case 'orders/SET_BRANDS_LIST': {
      const newState = { ...state };
      // adding to brand_list
      const newList = [...action.data];
      for (let i = 0; i < newList.length; i += 1) {
        const brand = { ...newList[i] };
        newList[i] = brand;
      }
      newState.brand_list = newList;

      // adding to new_order_input_layout
      // const new_order_input_layout = [...state.order_input_layout];
      // let i = 0;
      // for (i = 0; i < new_order_input_layout.length; i++) {
      //   if (new_order_input_layout[i].dataName === 'brand') break;
      // }
      // const newobj = { ...new_order_input_layout[i] };
      // newobj.options = [...action.data];
      // new_order_input_layout[i] = newobj;
      // newState.order_input_layout = new_order_input_layout;

      return newState;
    }

    default:
      return state;
  }
};

export default ordersReducer;
