/* eslint-disable max-lines */
import { DashboardSectionsType } from "../@types/dashboard";
import { ObjectType } from "../@types/defined";
import { PPMFormSectionsEnum } from "./PPMForm/types";
import { INavigationItem } from "./VerticalNavigationPanel";

export enum QARoutesEnum {
  PPM = "ppm",
  QUALITY_ASSURANCE = "quality-assurance",
  EDIT = "edit",
  REVIEW = "review",
  REPORTS = "reports",
  FINAL_INSPECTION = "final-inspection",
  FABRIC_INSPECTION = "fabric-inspection",
  FIT_SAMPLE_SUBMISSION = "fit-sample-submission",
  LAB_DIP_SUBMISSION = "lab-dip-submission",
  PP_SAMPLE_SUBMISSION = "pp-sample-submission",
  SIZE_SET_SUBMISSION = "size-set-submission",
  STRIKE_OFF_SUBMISSION = "strike-off-submission",
  TOP_SUBMISSION = "top-submission",
  APPROVAL = "approval",
}

export const QUALITY_REPORT_SLUGS = Object.freeze({
  FINAL_INSPECTION: "final_inspection",
  FABRIC_INSPECTION: "fabric_inspection",
  FIT_SAMPLE_SUBMISSION: "fit_sample_submission",
  LAB_DIP_SUBMISSION: "lab_dip_submission",
  PP_SAMPLE_SUBMISSION: "pp_sample_submission",
  SIZE_SET_SUBMISSION: "size_set_submission",
  STRIKE_OFF_SUBMISSION: "strike_off_submission",
  TOP_SUBMISSION: "top_submission",
});

export const QUALITY_REPORT_APPROVAL_SLUGS = Object.freeze({
  FIT_SAMPLE_APPROVAL: "fit_sample_approval",
  LAB_DIP_APPROVAL: "lab_dip_approval",
  PP_SAMPLE_APPROVAL: "pp_sample_approval",
  SIZE_SET_APPROVAL: "size_set_approval",
  STRIKE_OFF_APPROVAL: "strike_off_approval",
  TOP_APPROVAL: "top_approval",
});

export const QUALITY_REPORT_LIST = [
  {
    slug: QUALITY_REPORT_SLUGS.PP_SAMPLE_SUBMISSION,
    path: QARoutesEnum.PP_SAMPLE_SUBMISSION,
    heading: "PP Sample",
  },
  {
    slug: QUALITY_REPORT_SLUGS.FABRIC_INSPECTION,
    path: QARoutesEnum.FABRIC_INSPECTION,
    heading: "Fabric Inspection",
  },
  {
    slug: QUALITY_REPORT_SLUGS.FINAL_INSPECTION,
    path: QARoutesEnum.FINAL_INSPECTION,
    heading: "Final Inspection",
  },
  { slug: "", heading: "PPM", path: QARoutesEnum.PPM },
  {
    slug: QUALITY_REPORT_SLUGS.FIT_SAMPLE_SUBMISSION,
    path: QARoutesEnum.FIT_SAMPLE_SUBMISSION,
    heading: "Fit Sample",
  },
  {
    slug: QUALITY_REPORT_SLUGS.SIZE_SET_SUBMISSION,
    path: QARoutesEnum.SIZE_SET_SUBMISSION,
    heading: "Size Set",
  },
  {
    slug: QUALITY_REPORT_SLUGS.LAB_DIP_SUBMISSION,
    path: QARoutesEnum.LAB_DIP_SUBMISSION,
    heading: "Lab Dip",
  },

  {
    slug: QUALITY_REPORT_SLUGS.STRIKE_OFF_SUBMISSION,
    path: QARoutesEnum.STRIKE_OFF_SUBMISSION,
    heading: "Strike Off",
  },
  {
    slug: QUALITY_REPORT_SLUGS.TOP_SUBMISSION,
    path: QARoutesEnum.TOP_SUBMISSION,
    heading: "Top",
  },
];

export const MANAGE_QA_PPM_NAVIGATION_ITEMS: Array<INavigationItem> = [
  {
    name: "Qc tools",
    items: [{ name: "Manage QAs", path: QARoutesEnum.QUALITY_ASSURANCE }],
  },
  {
    name: "Report Type",
    items: [
      ...QUALITY_REPORT_LIST.map((item) => ({
        name: item.heading,
        path: item.path,
      })),
    ],
  },
];

export const PPM_FORM_NAVIGATION_ITEMS: Array<INavigationItem> = [
  {
    name: "",
    items: [
      { name: "Material", hash: `#${PPMFormSectionsEnum.MATERIAL}` },
      { name: "Trim", hash: `#${PPMFormSectionsEnum.TRIM}` },
      {
        name: "Sample Details",
        hash: `#${PPMFormSectionsEnum.SAMPLE_DETAILS}`,
      },
      {
        name: "Production Worksheet",
        hash: `#${PPMFormSectionsEnum.PRODUCTION_WORKSHEET}`,
      },
      {
        name: "Production Planning",
        hash: `#${PPMFormSectionsEnum.PRODUCTION_PLANNING}`,
      },
      {
        name: "Pre-Production Preparation",
        hash: `#${PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION}`,
      },

      {
        name: "Cutting Checkpoints",
        hash: `#${PPMFormSectionsEnum.CUTTING_CHECKPOINTS}`,
      },
      {
        name: "Sewing Checkpoints",
        hash: `#${PPMFormSectionsEnum.SEWING_CHECKPOINTS}`,
      },
      {
        name: "Finishing/Packing Checkpoints",
        hash: `#${PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS}`,
      },
      {
        name: "Pending Issues/Approvals",
        hash: `#${PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS}`,
      },
      { name: "Others", hash: `#${PPMFormSectionsEnum.OTHERS}` },
      {
        name: "Manage Attendees",
        hash: `#${PPMFormSectionsEnum.MANAGE_ATTENDEES}`,
      },
      { name: "Submit Form", hash: `#${PPMFormSectionsEnum.SUBMIT_FORM}` },
    ],
  },
];

export const STANDARD_PAGE_SIZE = 10;

export enum ActionMetaOperationsEnum {
  APPEND = "append",
  REPLACE = "replace",
}

export const INITIAL_TABULAR_DATA_STATE = Object.freeze({
  ids: [],
  data: {},
  count: 0,
  totalPages: -1,
  currentPage: -1,
});

export const HEADINGS: Record<string, string> = Object.freeze({
  PPM: "PPM",
  QUALITY_CONTROL: "Quality Control",
  FINAL_INSPECTION: "Final Inspection",
});

export enum ReviewOperationsEnum {
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export const QUALITY_TABS: Array<{
  path: DashboardSectionsType;
  title: string;
}> = [
  {
    path: "sample-inspection",
    title: "Sample Inspections",
  },
  {
    path: "final-inspection",
    title: "Final Inspection",
  },
  {
    path: "fabric-inspection",
    title: "Fabric Inspection",
  },
  {
    path: "pre-production-meeting",
    title: "PPM",
  },
  {
    path: "manage-qa",
    title: "Manage QA",
  },
];

interface IColumnStyle {
  minWidth: string;
  maxWidth: string;
  flexGrow: number;
  flexShrink: number;
  width: number;
  marginRight: number;
  paddingLeft: number;
  paddingRight: number;
}

const DEFAULT_COLUMN_STYLE: IColumnStyle = Object.freeze({
  minWidth: "50px",
  maxWidth: "200px",
  flexGrow: 1,
  flexShrink: 1,
  width: 100,
  marginRight: 1,
  paddingLeft: 12,
  paddingRight: 12,
});

interface IBaseFieldData {
  label: string;
  value: string;
  isSortable?: boolean;
  showFilter?: boolean;
  width: number;
  isStatic?: boolean;
  style?: Record<string, string>;
  isCellFullWidth?: boolean;
  prefix?: string;
  suffix?: string;
}

export const SAMPLING_FIELDS: Record<
  IBaseFieldData["value"],
  IBaseFieldData & IColumnStyle
> = {
  serial_number: {
    label: "Report Id",
    value: "serial_number",
    ...DEFAULT_COLUMN_STYLE,
    width: 130,
    isStatic: true,
  },
  purchase_order: {
    label: "Order",
    value: "purchase_order",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 200,
    isStatic: true,
  },
  style_ids: {
    label: "Styles",
    value: "styles",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 175,
    isStatic: true,
  },
  slug: {
    label: "Sample Type",
    value: "slug",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 175,
  },
  supplier: {
    label: "Supplier",
    value: "supplier",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
    isSortable: true,
  },
  brand: {
    label: "Brand",
    value: "brand",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
    isSortable: true,
  },
  report_pdf: {
    label: "Report",
    value: "report_pdf",
    ...DEFAULT_COLUMN_STYLE,
    width: 100,
  },
  creator: {
    label: "Created By",
    value: "creator",
    ...DEFAULT_COLUMN_STYLE,
    width: 220,
  },
  audited_on: {
    label: "Inspection Date",
    value: "audited_on",
    ...DEFAULT_COLUMN_STYLE,
    width: 175,
  },
  inspection_result: {
    label: "Result",
    value: "inspection_result",
    ...DEFAULT_COLUMN_STYLE,
    width: 120,
    showFilter: true,
  },
  is_approved: {
    label: "Action & Status",
    value: "is_approved",
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
    showFilter: true,
  },
  approval_remark: {
    label: "Approval/Rejection remark",
    value: "approval_remark",
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
  reviewed_by: {
    label: "Approve/Rejected by",
    value: "reviewed_by",
    ...DEFAULT_COLUMN_STYLE,
    width: 220,
  },
};

export const FINAL_INSPECTION_FIELDS: Record<
  IBaseFieldData["value"],
  IBaseFieldData & IColumnStyle
> = {
  serial_number: {
    label: "Report Id",
    value: "serial_number",
    ...DEFAULT_COLUMN_STYLE,
    width: 130,
    isStatic: true,
  },
  purchase_order: {
    label: "Order",
    value: "purchase_order",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 200,
    isStatic: true,
  },
  style_ids: {
    label: "Styles",
    value: "styles",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 175,
    isStatic: true,
  },
  supplier: {
    label: "Supplier",
    value: "supplier",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
    isSortable: true,
  },
  brand: {
    label: "Brand",
    value: "brand",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
    isSortable: true,
  },
  report_pdf: {
    label: "Report",
    value: "report_pdf",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 100,
  },
  creator: {
    label: "Created By",
    value: "creator",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 220,
  },
  inspection_result: {
    label: "Result",
    value: "inspection_result",
    ...DEFAULT_COLUMN_STYLE,
    width: 120,
    showFilter: true,
  },
  is_approved: {
    label: "Action & Status",
    value: "is_approved",
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
    showFilter: true,
  },
  approval_remark: {
    label: "Approval/Rejection remark",
    value: "approval_remark",
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
  reviewed_by: {
    label: "Approve/Rejected by",
    value: "reviewed_by",
    ...DEFAULT_COLUMN_STYLE,
    width: 220,
  },
};

export const FABRIC_INSPECTION_FIELDS: Record<
  IBaseFieldData["value"],
  IBaseFieldData & IColumnStyle
> = {
  serial_number: {
    label: "Report Id",
    value: "serial_number",
    ...DEFAULT_COLUMN_STYLE,
    width: 130,
    isStatic: true,
  },
  purchase_order: {
    label: "Order",
    value: "purchase_order",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 200,
    isStatic: true,
  },
  style_ids: {
    label: "Styles",
    value: "styles",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 175,
    isStatic: true,
  },
  supplier: {
    label: "Supplier",
    value: "supplier",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
    isSortable: true,
  },
  brand: {
    label: "Brand",
    value: "brand",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
    isSortable: true,
  },
  report_pdf: {
    label: "Report",
    value: "report_pdf",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 200,
  },
  creator: {
    label: "Created By",
    value: "creator",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 220,
  },
  inspection_result: {
    label: "Result",
    value: "inspection_result",
    ...DEFAULT_COLUMN_STYLE,
    width: 120,
    showFilter: true,
  },
  is_approved: {
    label: "Result",
    value: "is_approved",
    ...DEFAULT_COLUMN_STYLE,
    width: 200,
    showFilter: true,
  },
  approval_remark: {
    label: "Approval/Rejection remark",
    value: "approval_remark",
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
  reviewed_by: {
    label: "Approve/Rejected by",
    value: "reviewed_by",
    ...DEFAULT_COLUMN_STYLE,
    width: 220,
  },
};

export const PRE_PRODUCTION_MEETING_FIELDS: Record<
  IBaseFieldData["value"],
  IBaseFieldData & IColumnStyle
> = {
  serial_number: {
    label: "Report Id",
    value: "serial_number",
    ...DEFAULT_COLUMN_STYLE,
    width: 130,
    isStatic: true,
  },
  purchase_order: {
    label: "Order",
    value: "purchase_order",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 200,
    isStatic: true,
  },
  ppm_styles: {
    label: "Styles",
    value: "ppm_styles",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 175,
  },
  supplier: {
    label: "Supplier",
    value: "supplier",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
  },
  brand: {
    label: "Brand",
    value: "brand",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
    isStatic: true,
  },
  pdf: {
    label: "Report",
    value: "pdf",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 200,
  },
  submitted_by: {
    label: "Created By",
    value: "submitted_by",
    ...DEFAULT_COLUMN_STYLE,
    isCellFullWidth: true,
    width: 220,
  },
  audited_on: {
    label: "Inspection Date",
    value: "audited_on",
    ...DEFAULT_COLUMN_STYLE,
    width: 175,
  },
  result: {
    label: "Result",
    value: "result",
    ...DEFAULT_COLUMN_STYLE,
    width: 200,
  },
  approval_remark: {
    label: "Approval/Rejection remark",
    value: "approval_remark",
    ...DEFAULT_COLUMN_STYLE,
    width: 250,
  },
  reviewed_by: {
    label: "Approve/Rejected by",
    value: "reviewed_by",
    ...DEFAULT_COLUMN_STYLE,
    width: 220,
  },
};

export const MANAGE_QA_FIELDS: Record<
  IBaseFieldData["value"],
  IBaseFieldData & IColumnStyle
> = {
  serial_number: {
    label: "Report Id",
    value: "serial_number",
    ...DEFAULT_COLUMN_STYLE,
    width: 130,
    isStatic: true,
  },
  type: {
    label: "Type",
    value: "type",
    ...DEFAULT_COLUMN_STYLE,
    width: 100,
    isStatic: true,
  },
  order_id: {
    label: "Order Id",
    value: "order_id",
    ...DEFAULT_COLUMN_STYLE,
    width: 200,
    isStatic: true,
  },
  scheduled_date: {
    label: "Date",
    value: "scheduled_date",
    ...DEFAULT_COLUMN_STYLE,
    width: 120,
  },
  scheduled_time: {
    label: "Time",
    value: "scheduled_time",
    ...DEFAULT_COLUMN_STYLE,
    width: 120,
  },
  brand: {
    label: "Brand",
    value: "brand",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
  },
  supplier: {
    label: "Supplier",
    value: "supplier",
    ...DEFAULT_COLUMN_STYLE,
    width: 150,
  },
  assignee_data: {
    label: "Assigned Qa",
    value: "assignee_data",
    ...DEFAULT_COLUMN_STYLE,
    width: 240,
  },
  manage_qa: {
    label: "",
    value: "manage_qa",
    ...DEFAULT_COLUMN_STYLE,
    width: 120,
  },
};

export const SECTION_WISE_FIELDS: Record<DashboardSectionsType, ObjectType> = {
  "sample-inspection": SAMPLING_FIELDS,
  "final-inspection": FINAL_INSPECTION_FIELDS,
  "fabric-inspection": FABRIC_INSPECTION_FIELDS,
  "pre-production-meeting": PRE_PRODUCTION_MEETING_FIELDS,
  "manage-qa": MANAGE_QA_FIELDS,
};

export function getMultiGridSettings(section: DashboardSectionsType) {
  return Object.freeze({
    fixedColumnCount: Object.values(SECTION_WISE_FIELDS[section]).filter(
      (eachField) => eachField.isStatic
    ).length,
  });
}

export const APPROVAL_STEP_MAP = {
  pp_sample_submission: "pp_sample_approval",
  fit_sample_submission: "fit_sample_approval",
  lab_dip_submission: "lab_dip_approval",
  top_submission: "top_approval",
  size_set_submission: "size_set_approval",
  strike_off_submission: "strike_off_approval",
};

export const ACTION_STATUS_FILTER_OPTIONS = [
  {
    id: "",
    name: "All",
    dataProps: { "data-name": "is_approved" },
  },
  {
    id: "pending",
    name: "Review Pending",
    dataProps: { "data-name": "is_approved" },
  },
  {
    id: "accepted",
    name: "Report Accepted",
    dataProps: { "data-name": "is_approved" },
  },
  {
    id: "rejected",
    name: "Report Rejected",
    dataProps: { "data-name": "is_approved" },
  },
];

export const INSPECTION_RESULT_FILTER_OPTIONS = [
  {
    id: "",
    name: "All",
    dataProps: { "data-name": "inspection_result" },
  },
  {
    id: "pending",
    name: "Pending",
    dataProps: { "data-name": "inspection_result" },
  },
  {
    id: "pass",
    name: "Passed",
    dataProps: { "data-name": "inspection_result" },
  },
  {
    id: "fail",
    name: "Failed",
    dataProps: { "data-name": "inspection_result" },
  },
];
