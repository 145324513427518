export const DEVICE_ID = '3';

export const defaultValue = 'N/A';

export const TT_WHITE_CLASS = 'tooltip-white tooltip-wrapper';

export const DEFAULT_RFQ_IMAGE =
  'https://fashinza-media.s3.ap-south-1.amazonaws.com/default-img-rfq.svg';

export const FILE_SIZE_NAMES = ['bytes', 'kbs', 'mbs', 'gbs'] as const;

export const EMAIL_REGEX_IMPROVED =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
