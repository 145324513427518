const DEFAULT_UUID_LENGTH = 24;
const UUID_POSSIBLE_CHARACTERS =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export const ALPHABETS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export function generateUuid(
  length = DEFAULT_UUID_LENGTH,
  possibleCharacters = UUID_POSSIBLE_CHARACTERS
): string {
  return Array(length)
    .fill(1)
    .map(
      () =>
        possibleCharacters[
          Math.floor(Math.random() * possibleCharacters.length)
        ]
    )
    .join("");
}
