import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";

export const Wrapper = styled.div`
  padding: 12px 0;

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DateSelectorWrapper = styled.div<{ bgColor: string }>`
  width: 200px;
  border: 1px solid var(--loblolly);
  border-radius: 4px;
  padding-right: 16px;
  background: ${(props) => props.bgColor};
  position: relative;

  ${device.mediumViewport} {
    width: unset;
  }

  .DayPickerInput {
    flex-grow: 1;

    input {
      height: 40px;
    }
  }

  &.on-the-left .DayPickerInput-Overlay {
    left: unset;
    right: -25px;
  }
`;

export const InputGroup = styled.div`
  gap: 24px;

  ${device.mediumViewport} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 4px;
  }

  .radiobutton-wrapper {
    font-weight: 400;
    font-size: 14px;
    color: var(--rhino);

    ${device.mediumViewport} {
      padding-left: 12px;
    }
  }

  .radiobutton-label {
    border: 1px solid var(--rhino);
  }
`;
