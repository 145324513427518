import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Context, createContext, useContext, useEffect, useState } from 'react';
import { PPMFormSectionsEnum } from './PPMForm/types';

interface IPpmContext {
  onChangeNavigationTab: (hash: string) => void;
}

export const PpmContext: Context<IPpmContext> = createContext(
  {} as IPpmContext
);

export function usePpmContext() {
  const contextData = useContext(PpmContext);
  return contextData;
}

const HASH_LIST: Array<PPMFormSectionsEnum> = [
  PPMFormSectionsEnum.MATERIAL,
  PPMFormSectionsEnum.TRIM,
  PPMFormSectionsEnum.SAMPLE_DETAILS,
  PPMFormSectionsEnum.PRODUCTION_WORKSHEET,
  PPMFormSectionsEnum.PRODUCTION_PLANNING,
  PPMFormSectionsEnum.PRE_PRODUCTION_PREPARATION,
  // PPMFormSectionsEnum.PROJECTED_INSPECTION_DATES,
  PPMFormSectionsEnum.CUTTING_CHECKPOINTS,
  PPMFormSectionsEnum.SEWING_CHECKPOINTS,
  PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS,
  PPMFormSectionsEnum.PENDING_ISSUES_AND_APPROVALS,
  PPMFormSectionsEnum.OTHERS,
  PPMFormSectionsEnum.MANAGE_ATTENDEES,
  PPMFormSectionsEnum.SUBMIT_FORM,
];

interface IHashIndex {
  onNext: () => void;
  onBack: () => void;
  hashValue: PPMFormSectionsEnum;
  setHashValue: (hashValue: PPMFormSectionsEnum) => void;
}

export function useHashIndex(): IHashIndex {
  const dispatch = useDispatch();

  const [hashValue, setHashValue] = useState<PPMFormSectionsEnum>(HASH_LIST[0]);

  function onNext() {
    const newHashIndex = HASH_LIST.findIndex(element => element === hashValue);

    if (newHashIndex === -1 || newHashIndex === HASH_LIST.length - 1) {
      return;
    }
    setHashValue(HASH_LIST[newHashIndex + 1]);
  }

  function onBack() {
    const newHashIndex = HASH_LIST.findIndex(element => element === hashValue);

    if (newHashIndex === -1 || newHashIndex === 0) {
      return;
    }

    setHashValue(HASH_LIST[newHashIndex - 1]);
  }

  useEffect(() => {
    dispatch(replace({ hash: hashValue }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashValue]);

  return {
    onNext,
    onBack,
    hashValue,
    setHashValue,
  };
}
