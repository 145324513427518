export enum BaseTypes {
  Dashboard = 'Dashboard',
  BrandRfq = 'Brand Rfq',
  BrandOrder = 'Brand Order',
  BrandSamples = 'Brand Samples',
  NewRfq = 'New RFQ',
  Techpack = 'Techpack',
  Requirement = 'Requirement',
  PurchaseOrder = 'Purchase Order',
  Catalog = 'Catalog',
  Cart = 'Cart',
  Logout = 'logout',
  PublicCatalog = 'Public Catalog',
  ProductDetails = 'Product Details',
  Profile = 'Profile',
  Performance = 'Performance',
  SharedCatalog = 'Shared Catalog',
  Payment = 'Payment',
  QuickEnquiry = 'Quick Enquiry',
  SupplierOnboarding = 'Supplier Onboarding',
  Costing = 'Costing',
  Fintech = 'Fintech',
  CapacityPlanner = 'capacity_planner',
  BillOfMaterials = 'bill_of_materials',
  Logistics = 'Logistics',
  Tna = 'Tna',
  FreshDeskTab = 'Fresh Desk Tab',
  CatalogViewAll = 'catalog_view_all',
  NotificationListShown = 'notification_list_shown',
  Login = 'login',
  LinkedIn = 'linkedIn_signIn',
  Google = 'google_signIn',
  Quality = 'quality',
  SidebarProcessControl = 'sidebar_process_control',
  NotificationClicked = 'notification_clicked',
  NotificationPopup = 'notification_popup',
  OpenCosting = 'open_costing',
}

export enum SheetBaseTypes {
  BulkUploadRfq = 'Bulk Upload Rfq',
  BulkUploadPayment = 'Bulk Upload Payment',
  BrandReceiptUpload = 'Brand Receipt Upload',
}

export enum MixpanelSources {
  Direct = 'direct',
  BrandOverview = 'brand overview',
  BrandOverviewTab = 'brand overview tab',
  Navbar = 'navbar',
  Dashboard = 'dashboard',
  NewRfq = 'new rfq',
  Requirement = 'requirement',
  RequirementEdit = 'requirement edit',
  PurchaseOrderList = 'purchase order list',
  PurchaseOrderDetails = 'purchase order details',
  Catalog = 'catalog',
  Performance = 'performance',
  Notification = 'notification',
  TodaysTask = 'todays task',
  NewInCatalog = 'new in catalog',
  M2OFlow = 'm2o flow',
  Breadcrumbs = 'breadcrumbs',
  Cart = 'cart',
  ActionsNeeded = 'actions needed',
  ExploreMoreInCatalogs = 'Catalog explore more',
  QappTab = 'qapp tab',
  QuickEnquiry = 'quick enquiry',
  NewQuery = 'new query',
  MegaMenu = 'mega menu',
  Header = 'header',
  DashboardCount = 'dashboard_count',
  ExploreNowCollection = 'explore now collection',
  DashboardBanner = 'dashboard_banner',
  ChatList = 'chat_list',
  PaymentList = 'payment_list',
  ProgramList = 'program_list',
}

export enum RepeatOrderSources {
  Button = 'button',
  Link = 'link',
}

export enum TechpackSources {
  Create = 'create',
  PublicCreate = 'public create',
  Edit = 'edit',
  RfqCreate = 'rfq create',
}

export enum RequirementTypesEnum {
  Details = 'details',
  Edit = 'edit',
  List = 'list',
}

export enum PurchaseOrderTypes {
  Details = 'details',
  List = 'list',
}

export enum ProfileTypesEnum {
  Details = 'details',
  Edit = 'edit',
}

export enum CatalogTypesEnum {
  // pages
  ListPage = 'list',
  DetailsPage = 'details',
  Catalog = 'catalog',
  AllCollections = 'all_collection',
  ListSearch = 'product list search',
  TrendingReport = 'trending report',
  SharedCatalog = 'shared catalog',
  // sources will have `catalog` prepended
  List = 'catalog list',
  Details = 'catalog details',
  ListBreadcrumbs = 'catalog list breadcrumbs',
  DetailsBreadcrumbs = 'catalog details breadcrumbs',
  ListItem = 'catalog list item',
  VariantChange = 'catalog details variant change',
  Collections = 'catalog collections',
  CollectionDetails = 'catalog collection details',
  CollectionBreadcrumbs = 'catalog collection breadcrumbs',
}

export enum CartTypesEnum {
  // pages
  ListPage = 'list',
  // sources  will have `cart` prepended
  Edit = 'cart item edit',
}

export enum PaymentTypesEnum {
  // pages
  ListPage = 'list',
  DetailsPage = 'details',
}

export enum PerformancePageType {
  Route = 'route',
  Dashboard = 'dashboard',
  SupplierDetails = 'supplier details',
}

export enum SupplierOnboardingTypes {
  EditDetails = 'edit details',
  CompleteDetails = 'complete details',
}

export enum CostingSourceTypes {
  DetailsPage = 'details',
  ListPage = 'list',
}

export enum CostingActionsTypes {
  Save = 'save',
  CreateOrder = 'create order',
  OpenCost = 'open cost',
  SaveQuotedPrice = 'save quoted price',
  SaveRemarks = 'save remarks',
  UploadFile = 'upload file',
}

export enum CatalogCollectionActions {
  ImageClick = 'image click',
}

export enum CapacityPlannerActionsEnum {
  MonthChange = 'month change',
  ClickTnaStep = 'click tna step',
  ClickStyleDetail = 'click style detail',
  ClickOrderDetail = 'click order detail',
  ClickOrderChart = 'click order chart',
  ClickMilestone = 'click milestone',
  LeavePage = 'leave page',
  Shown = 'shown',
  SupplierSelection = 'supplier selection',
}

export enum CapacityPlannerTimeEventsEnum {
  PageViewed = 'capacity_planner_page_viewed',
  PageExit = 'capacity_planner_page_exit',
}

export enum QualityTrackerActionsEnum {
  ClickSideNav = 'click side nav',
  ClickBrand = 'click brand',
  ClickSupplier = 'click supplier',
  ClickReport = 'click report',
  ClickPurchaseOrder = 'click purchase order',
  ClickStartPpm = 'click start ppm',
  ClickReviewPpm = 'click review ppm',
  ReschedulePpm = 'reschedule ppm',
  ClickChangeQa = 'click change qa',
  ClickAssignQa = 'click assign qa',
  PageExit = 'page exit',
  PageViewed = 'page viewed',
  ClickApproveReject = 'click approve-reject',
  ChangeSection = 'change section',
  ChangeSubSection = 'change sub section',
  DashboardShown = 'dashboard shown',
}

export enum PurchaseOrderTrackerActionEnum {
  Shown = 'shown',
  ChangeHeaderTab = 'change header tab',
  ViewDetail = 'view detail',
  OpenTnaDropdown = 'open tna dropdown - list page',
  ChangeFollowUpDate = 'change follow up date',
  ClickAddTnaStep = 'click add tna step',
  ClickRequestSamplePickup = 'click request sample pickup',
  OpenTnaPage = 'open tna page',
  EnableTnaDateEditing = 'enable tna date editing',
  ToggleEditingMode = 'toggle editing mode',
  ChangeProcessStepDate = 'change process step date',
  DeletePackingList = 'delete packing list',
  OpenPackingList = 'open packing list',
  OpenControlDropdown = 'open control dropdown',
  ClickCreatePackingList = 'click create packing list',
  ClickExportData = 'click export data',
}

export enum ProcessDetailsChatTrackerActionEnum {
  SendChat = 'send_chat',
}

export enum SupplierDashboardTrackerActionEnum {
  ClickUploadDesign = 'click upload design',
  ViewRfqDetails = 'view rfq details',
  ViewPurchaseOrderDetails = 'view purchase order details',
  ClickCompleteProfile = 'click complete profile',
  ClickTodaysTaskTag = 'click todays task tag',
  ClickTodaysTask = 'click todays task',
}

export enum RequestForQuoteTrackerActionEnum {
  Shown = 'shown',
  DownloadPdf = 'download pdf',
  ViewDetail = 'view detail',
  EditDetail = 'edit detail',
  OpenControlDropdown = 'open control dropdown',
  ChangeFollowUpDate = 'change follow up date',
  ChangeClosureDate = 'change closure date',
  ChangeHeaderTab = 'change header tab',
  ClickCreateDigitalPattern = 'click create digital pattern',
  ClickBookedFabric = 'click booked fabric',
  ClickFabricDemand = 'click fabric demand',
  // TODO: not in use; ask what to do
  ClickFabricLibrary = 'click fabric library',
  ClickViewLeads = 'click view leads',
  ClickAddRfq = 'click add rfq',
  ClickExportData = 'click export data',
}

export enum SidebarProcessControlTrackerSourceEnum {
  CapacityPlanner = 'capacity planner',
  PurchaseOrderDetail = 'purchase order detail',
  PurchaseOrderList = 'purchase order list',
  BrandOrderDetail = 'brand order detail',
  TnaList = 'tna list',
}

export enum SidebarProcessControlTrackerActionEnum {
  Shown = 'shown',
  CreateReport = 'create report',
  GenerateReport = 'generate report',
  ChangeStatusOfProcessStep = 'change status of process step',
  ClickFillPpm = 'click fill ppm',
  ClickPpmDetail = 'click ppm detail',
}

export enum LogisticsTrackerTrackerActionEnum {
  Shown = 'shown',
  ChangeTabs = 'change tabs',
  ViewDetails = 'view details',
}

export enum ChatEventEnum {
  Open = 'open_chat',
  Error = 'chat_error',
  Send = 'send_chat',
  AddAttachment = 'add_attachment',
  DeleteAttachment = 'delete_attachment',
  OpenThread = 'open_thread',
}

export enum ChatTypeEnum {
  StreamChat = 'get_stream_chat',
  TnaChat = 'tna_chat',
}

export enum BillOfMaterialsTrackerActionEnum {
  ClickCreateEditBomOrderListPage = 'click create/edit bom order list',
  ClickCreateEditBomOrderDetailPage = 'click create/edit bom order detail',
}

export enum BillOfMaterialsSupplierViewBasedTrackerActionEnum {
  Shown = 'shown',
  MaterialView = 'material view',
  StyleView = 'style view',
  SupplierView = 'supplier view',
  ClickUploadDocuments = 'click upload documents',
  ClickUploadNewDocument = 'click upload new document',
  ClickViewDocuments = 'click overall view documents',
  ClickViewEachPurchaseOrderDocuments = 'click view each purchase order documents',
  ClickDownloadPo = 'click download po',
  ClickRaisePo = 'click raise po',
  ClickUploadExcel = 'click upload excel',
  ClickDownloadExcelTemplate = 'click download excel template',
  ClickExportExcel = 'click export excel',
}

export enum BillofMaterialsAddRawMaterialBasedTrackerActionEnum {
  ClickAddNewMaterialLeftPane = 'click add raw material in left pane',
  ClickAddNewMaterialBesideExcel = 'click add new material beside excel',
  ClickAddRawMaterialInHeader = 'click add raw material in header',
  SearchRawMaterialsInPopup = 'search raw materials in popup',
  SelectAllStylesInAddRawMaterial = 'select all styles in add raw material',
}

export enum HelpTypesEnum {
  HelpCategoryPage = 'help category page',
}

export enum OpenCostingActionsEnum {
  Shown = 'shown',
  ClickCollectionName = 'click collection name',
  ClickSubmitQuote = 'click submit quote',
  ClickDownloadDefaultTemplate = 'click download default template',
  ClickCreateTemplate = 'click create template',
  ClickUploadExcel = 'click upload excel',
  ClickDeleteButton = 'click delete button',
  ClickAddButton = 'click add button',
  AddCostItemFromDefaultList = 'add cost item from default list',
  AddNewCostItem = 'add new cost item',
  ClickSaveAndDownload = 'click save and download',
  Reorder = 'reorder',
  UploadExcel = 'upload excel',
  UploadExcelSuccess = 'upload excel success',
  UploadExcelFailure = 'upload excel failure',
  ReUpload = 're upload',
  ClickCostComparison = 'click cost comparison',
  ClickPricePerUnit = 'click price per unit',
  ClickConsumption = 'click consumption',
  ClickTotal = 'click total',
  ClickExpand = 'click expand',
  ClickEditTemplate = 'click edit template',
}

export enum BillingAndDeliveryEnum {
  BomTerms = 'bom terms list',
  BomInvoices = 'bom invoices list',
  BomPos = 'bom pos list',
  BrandPos = 'brand pos list',
  SupplierInvoices = 'supplier invoices list',
}
