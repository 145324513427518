import React from "react";
import { useDispatch } from "react-redux";
import { Column } from "react-table";
import Tooltip from "react-tooltip-lite";
import { useManageQAContext } from "../helperHooks";
import { AssignQaActionEnum } from "./constants";
import QualityTracker from "../../mixpanel";
import { QARoutesEnum } from "../../constants";
import * as Styles from "./styles";
import { QualityTrackerActionsEnum } from "../../../analytics/sources";
import { TT_WHITE_CLASS, defaultValue } from "../../../constants";
import { assignQa } from "../../../redux/actions/thunks";

export function getFields(): Array<Column> {
  return [
    {
      Header: <Styles.CellHeader>S. No.</Styles.CellHeader>,
      accessor: "serial_number",
      width: 100,
      Cell: ({ value }) => (
        <Styles.CellBody className="align-center">{value}</Styles.CellBody>
      ),
    },
    {
      Header: <Styles.CellHeader>Qa name</Styles.CellHeader>,
      accessor: "name",
      Cell: ({ value }) => (
        <Styles.CellBody className="align-center">
          <Tooltip
            content={value}
            direction="bottom-left"
            arrowSize={8}
            className={TT_WHITE_CLASS}
          >
            <div className="truncated-text">{value || defaultValue}</div>
          </Tooltip>
        </Styles.CellBody>
      ),
    },
    // ***DO NOT REMOVE THIS***
    // {
    //   Header: <Styles.CellHeader> </Styles.CellHeader>,
    //   accessor: 'is_conflicting',
    //   width: 180,
    //   Cell: ({ value }) => (
    //     <Styles.CellBody className="align-center">{value}</Styles.CellBody>
    //   ),
    // },
    // {
    //   Header: <Styles.CellHeader>location</Styles.CellHeader>,
    //   accessor: 'location',
    //   width: 120,
    //   Cell: ({ value }) => (
    //     <Styles.CellBody className="align-center">{value}</Styles.CellBody>
    //   ),
    // },
    {
      Header: <Styles.CellHeader>Action</Styles.CellHeader>,
      accessor: "id",
      Cell: ({ value }) => {
        const dispatch = useDispatch();
        const { selectedRowId, toggleAssignQAModal } = useManageQAContext();

        function onAssignQa() {
          dispatch(assignQa(selectedRowId, value, toggleAssignQAModal));
          QualityTracker.track(QualityTrackerActionsEnum.ClickAssignQa, {
            section: QARoutesEnum.QUALITY_ASSURANCE,
          });
        }

        return (
          <Styles.CellBody>
            <Styles.Action onClick={onAssignQa}>
              {AssignQaActionEnum.Assign}
            </Styles.Action>
          </Styles.CellBody>
        );
      },
    },
  ];
}
