import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 60vh;
  overflow-y: auto;
  padding: 12px 0 16px;
  background: var(--white);
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  border-radius: 4px;
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: var(--rhino);
`;

export const InputWrapper = styled.div`
  margin-bottom: 16px;
  padding: 0 16px;
  input {
    height: 32px;
    width: 100%;
    border: 1px solid var(--mischka);
    border-radius: 4px;
    &::placeholder {
      color: var(--manatee);
    }
  }
`;

export const Body = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
`;

export const UserBlockWrapper = styled.div`
  .taggedCollaborators-user-block-item {
    margin-bottom: 12px;

    .taggedCollaborators-user-block-item-head {
      font-family: 'Montserrat';
      font-style: normal;
      font-size: 10px;
      font-weight: 600;
      color: var(--lynch);
      margin-bottom: 12px;
    }

    .taggedCollaborators-user-block-item-content {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      .user-block-item-selected {
        position: absolute;
        top: 0;
        left: calc(80% - 10px);
      }
      .user-block-item {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        margin-right: 8px;
        margin-bottom: 8px;
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        background: var(--wax-flower);
        color: var(--rhino);
        &.selected {
          background-color: yellow;
        }
      }
    }
  }

  .taggedCollaborators-user-block-item .user-block-empty,
  .taggedCollaborators-empty-block {
    width: 100%;
    border-radius: 4px;
    background-color: #f6f6f7;
    color: var(--lynch);
    padding: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 10px;
    font-weight: 300;
  }
`;
