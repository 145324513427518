import React from "react";
import { IconPropsType } from "../types";

interface IDocFileIconProps extends IconPropsType {}

const DocFileIcon = (props: IDocFileIconProps) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={40} height={40} rx={4} fill="var(--pattens-blue)" />
    <path
      d="M29.8335 10.5H15.1665C14.6603 10.5 14.25 10.9103 14.25 11.4165V15.5L22.75 18L30.75 15.5V11.4165C30.75 10.9103 30.3397 10.5 29.8335 10.5Z"
      fill="#41A5EE"
    />
    <path d="M30.75 15.5H14.25V20.5L22.75 22L30.75 20.5V15.5Z" fill="#2B7CD3" />
    <path
      d="M14.25 20.5V25.5L22.25 26.5L30.75 25.5V20.5H14.25Z"
      fill="#185ABD"
    />
    <path
      d="M15.1665 30.5H29.8335C30.3397 30.5 30.75 30.0897 30.75 29.5835V25.5H14.25V29.5835C14.25 30.0897 14.6603 30.5 15.1665 30.5Z"
      fill="#103F91"
    />
    <path
      opacity={0.1}
      d="M20.3335 14.5H14.25V27H20.3335C20.839 26.9984 21.2484 26.589 21.25 26.0835V15.4165C21.2484 14.911 20.839 14.5016 20.3335 14.5Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M19.8335 15H14.25V27.5H19.8335C20.339 27.4984 20.7484 27.089 20.75 26.5835V15.9165C20.7484 15.411 20.339 15.0016 19.8335 15Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M19.8335 15H14.25V26.5H19.8335C20.339 26.4984 20.7484 26.089 20.75 25.5835V15.9165C20.7484 15.411 20.339 15.0016 19.8335 15Z"
      fill="black"
    />
    <path
      opacity={0.2}
      d="M19.3335 15H14.25V26.5H19.3335C19.839 26.4984 20.2484 26.089 20.25 25.5835V15.9165C20.2484 15.411 19.839 15.0016 19.3335 15Z"
      fill="black"
    />
    <path
      d="M10.1665 15H19.3335C19.8397 15 20.25 15.4103 20.25 15.9165V25.0835C20.25 25.5897 19.8397 26 19.3335 26H10.1665C9.66033 26 9.25 25.5897 9.25 25.0835V15.9165C9.25 15.4103 9.66033 15 10.1665 15Z"
      fill="url(#paint0_linear_235_6411)"
    />
    <path
      d="M13.0111 22.029C13.0291 22.1705 13.0411 22.2935 13.0466 22.399H13.0676C13.0756 22.299 13.0923 22.1785 13.1176 22.0375C13.143 21.8965 13.1658 21.7774 13.1861 21.68L14.1501 17.521H15.3966L16.3966 21.6175C16.4547 21.8723 16.4963 22.1305 16.5211 22.3905H16.5377C16.5563 22.1386 16.591 21.8881 16.6417 21.6405L17.4391 17.519H18.5737L17.1726 23.479H15.8471L14.8971 19.532C14.8697 19.4185 14.8382 19.27 14.8037 19.0875C14.7691 18.905 14.7477 18.7715 14.7391 18.6875H14.7226C14.7117 18.7845 14.6901 18.9285 14.6581 19.1195C14.6261 19.3105 14.6005 19.4519 14.5811 19.5435L13.6881 23.478H12.3401L10.9316 17.521H12.0866L12.9551 21.6885C12.9746 21.774 12.9931 21.888 13.0111 22.029Z"
      fill="var(--white)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_235_6411"
        x1={11.1609}
        y1={14.2839}
        x2={18.3391}
        y2={26.7161}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2368C4" />
        <stop offset={0.5} stopColor="#1A5DBE" />
        <stop offset={1} stopColor="#1146AC" />
      </linearGradient>
    </defs>
  </svg>
);

export default DocFileIcon;
