import { ObjectType } from '../@types/defined';

export const getNameInitials = (name = '') => {
  name = name ? name.trim() : '';
  const parts = name.split(' ');
  let initials = '';

  if (name.length === 0) {
    initials = 'AU';
  } else {
    parts.length > 1
      ? (initials =
          parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase())
      : (initials = parts[0][0].toUpperCase());
  }
  return initials;
};

export const removeLastSlash = (url: string) =>
  url.endsWith('/') ? url.slice(0, url.length - 1) : url;

export const buildUserName = (user: ObjectType, default_name = '') => {
  const { first_name, last_name, email } = user;
  let name = default_name || email;
  if (first_name || last_name) {
    const newname = `${first_name || ''} ${last_name || ''}`.trim();
    if (newname) name = newname;
  }
  return name || '';
};
