import React, { SyntheticEvent } from 'react';
import { OptionsType, PPMFormSectionsEnum } from '../../types';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import * as CommonStyles from '../styles';
import * as Styles from './styles';
import RadioButton from '../../../../shared/RadioButton';

interface IMultipleChoiceInputProps {
  field: string;
  label: string;
  options: OptionsType;
  value?: string | null;
  onChangeRadio?: (event: SyntheticEvent) => void;
  section: PPMFormSectionsEnum;
}

const MultipleChoiceInput = ({
  field,
  label,
  options,
  value,
  onChangeRadio,
  section,
}: IMultipleChoiceInputProps) => (
  <Styles.Wrapper className="align-center">
    <CommonStyles.Label>
      {label} <ShowAsteriskIfMandatory field={field} section={section} />
    </CommonStyles.Label>
    <Styles.OptionsWrapper className="flex-start-center d-flex">
      {options.map(option => (
        <RadioButton
          key={option.id}
          dataname={field}
          datalabel={option.name}
          datavalue={option.id}
          onChange={onChangeRadio}
          isChecked={value === option.id}
          item={undefined}
        />
      ))}
    </Styles.OptionsWrapper>
  </Styles.Wrapper>
);

export default MultipleChoiceInput;
