import React, { ChangeEvent } from 'react';
import { PPMFormSectionsEnum } from '../../types';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import TextInputWithLabel from '../../../../shared/TextInputWithLabel/textInputWithLabel';
import * as CommonStyles from '../styles';
import * as Styles from './styles';

interface IPlainTextInputProps {
  id: string;
  label: string;
  value?: string | null;
  onChangeText?: (event: ChangeEvent<HTMLInputElement>) => void;
  isNumber?: boolean;
  section: PPMFormSectionsEnum;
}

const PlainTextInput = ({
  label,
  id,
  value,
  onChangeText,
  isNumber = false,
  section,
}: IPlainTextInputProps) => (
  <Styles.Wrapper className="align-center">
    <CommonStyles.Label>
      {label} <ShowAsteriskIfMandatory field={id} section={section} />
    </CommonStyles.Label>
    <Styles.TextInputWrapper>
      <TextInputWithLabel
        isNumber={isNumber}
        datalabel=""
        placeholder={label}
        onChange={onChangeText}
        dataname={id}
        value={value}
        shouldAutoFocus={false}
      />
    </Styles.TextInputWrapper>
  </Styles.Wrapper>
);

export default PlainTextInput;
