import { QualityActionEnum } from "../../@types/base";
import { IQualityDashboardState } from "../../@types/dashboard";

export const setDashboardSection = (
  payload: IQualityDashboardState["section"]
) => ({
  type: QualityActionEnum.SetDashboardSection,
  payload,
});

export const setDashboardSubSection = (
  payload: IQualityDashboardState["subSection"]
) => ({
  type: QualityActionEnum.SetDashboardSubSection,
  payload,
});

export const setDashboardFilterDates = (
  payload: IQualityDashboardState["dateFilters"]
) => ({
  type: QualityActionEnum.SetDashboardFilterDates,
  payload,
});

export const setDashboardEntityFilters = (
  payload: IQualityDashboardState["entityFilters"]
) => ({
  type: QualityActionEnum.SetDashboardEntityFilters,
  payload,
});
