import { ICustomHelmetData } from './types';
import { isUndefined } from '../../utils/assertion';
import { useGetChatAndNotificationsCount } from '../../hooks/useGetChatAndNotificationsCount';
import useIsSupplierOsEnabled from '../../hooks/useIsSupplierOsEnabled';

const FASHINZA_OG_CONTENT = Object.freeze({
  title:
    'Fashinza - Manufacturing Platform for Apparel Industry | Fast & Sustainable',
  description:
    'New Generation Supply Chain And Product Development Platform For Fashion Industry. No matter where you are! We provide one stop solution from design to delivery.',
});

const TRAKTEZ_OG_CONTENT = Object.freeze({
  title: 'TrakTez',
  description: 'Simplifying workflow for manufacturers',
});

export function useGetBaseHelmetData(customization?: ICustomHelmetData) {
  const isSupplierOs = useIsSupplierOsEnabled();
  const { totalCount, formattedCount } = useGetChatAndNotificationsCount();

  let data: ICustomHelmetData = FASHINZA_OG_CONTENT;
  if (isUndefined(customization)) {
    data = isSupplierOs ? TRAKTEZ_OG_CONTENT : FASHINZA_OG_CONTENT;
  } else {
    data = customization;
  }

  return {
    formattedTitle: `${totalCount > 0 ? `(${formattedCount}) ` : ''}${
      data.title
    }`,
    ogTitle: data.ogTitle ?? data.title,
    description: data.description,
    ogDescription: data.ogDescription ?? data.description,
  };
}
