import React, { ChangeEvent } from 'react';
import * as CommonStyles from '../styles';
import * as Styles from './styles';

interface ITextAreaInputProps {
  field: string;
  label: string;
  value: string | null;
  placeholder?: string;
  onChangeText: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextAreaInput = ({
  field,
  label,
  placeholder,
  value,
  onChangeText,
}: ITextAreaInputProps) => (
  <Styles.Wrapper className="align-center">
    <CommonStyles.Label>{label}</CommonStyles.Label>
    <Styles.TextAreaWrapper>
      <Styles.TextArea
        value={value ?? ''}
        data-name={field}
        onChange={onChangeText}
        placeholder={placeholder ?? label}
      />
    </Styles.TextAreaWrapper>
  </Styles.Wrapper>
);

export default TextAreaInput;
