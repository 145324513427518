import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 80%;
  max-height: 95vh;
  background-color: var(--white);
  border-radius: 4px;
`;

export const Header = styled.div`
  position: relative;
  padding: 1rem;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--rhino);
  border-bottom: 1px solid var(--mischka);
`;

export const CrossWrapper = styled.button`
  cursor: pointer;
  position: absolute;
  right: 1rem;
  padding: 0;
`;

export const Body = styled.div`
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 1rem;
`;

export const EachFileColumn = styled.div<{
  isMobileView: boolean;
}>`
  flex: 1;
  min-width: ${props => (props.isMobileView ? '37%' : '30%')};
  max-width: 30%;
  margin: 0 1rem 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid var(--mischka);

  button {
    padding: 0.5rem 1rem;
    font-size: 12px;
    line-height: 15px;
  }
`;
