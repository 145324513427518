import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'cutting_marker',
    attachmentSlug: 'cutting_marker_document',
    label: 'Cutting Marker',
    inputType: InputTypeEnum.COMBINED_TEXT_FILE,
  },
  {
    id: 'cutting_notches',
    label: 'Cutting Notches',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'cutting_direction',
    label: 'Cutting Direction',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
];
