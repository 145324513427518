import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { Button } from '@fashinza/fashinza-design-system';
import {
  emptyFunction,
  numberWithCommas,
  removeUnderscoresAndCapitalize,
} from '../../../../../utils';
import { FinalInspectionAnalyticsType } from '../../../../../@types/finalInspection';
import { TT_WHITE_CLASS } from '../../../../../constants';
import { IPieChartData } from '../../../../../shared/Charting/PieChart/types';
import PieChart from '../../../../../shared/Charting/PieChart';
import * as Styles from './styles';
import InfoTooltip from '../../../../../shared/InfoTooltip';

interface IDefectsStatisticsProps {
  critical: number;
  major: number;
  minor: number;
  topFive: FinalInspectionAnalyticsType['top_five_defects'];
}

const DefectsStatistics = ({
  critical,
  major,
  minor,
  topFive,
}: IDefectsStatisticsProps) => {
  const data: Array<IPieChartData> = [
    {
      color: 'var(--red-dirt)',
      name: 'Critical',
      subTexts: [`${critical} Defects`],
      value: critical,
    },
    {
      color: 'var(--clementine)',
      name: 'Major',
      subTexts: [`${major} Defects`],
      value: major,
    },
    {
      color: 'var(--web-orange)',
      name: 'Minor',
      subTexts: [`${minor} Defects`],
      value: minor,
    },
  ];

  return (
    <Styles.Wrapper className="d-flex">
      <Styles.Left className="v-space-between">
        <Styles.Heading className="align-center">
          Defects
          <InfoTooltip
            maxWidth={320}
            heading="What are these Defects?"
            subHeading="Breakdown of defects found during final inspection"
          />
        </Styles.Heading>
        <Styles.PieChartWrapper>
          <PieChart
            data={data}
            classNames={{
              labelWrapper: 'flex-start-center',
              chartWrapper: 'center',
              wholeWrapper: 'v-space-between full-height',
            }}
          />
        </Styles.PieChartWrapper>
      </Styles.Left>

      <Styles.Right className="v-align-flex-end">
        <Tooltip
          content={
            <Styles.TopDefectsTable>
              <thead>
                <th>Defect</th>
                <th>Count</th>
              </thead>
              <tbody>
                {Object.keys(topFive).map(key => (
                  <tr key={key} title={removeUnderscoresAndCapitalize(key)}>
                    <Styles.DefectTd className="truncated-text">
                      {removeUnderscoresAndCapitalize(key)}
                    </Styles.DefectTd>
                    <Styles.CountTd>{topFive[key]}</Styles.CountTd>
                  </tr>
                ))}
              </tbody>
            </Styles.TopDefectsTable>
          }
          direction="left"
          arrow={false}
          className={`${TT_WHITE_CLASS}  tooltip-without-padding`}
          mouseOutDelay={300}
        >
          <Button
            onClick={emptyFunction}
            colorScheme="neutral"
            variant="outline"
          >
            Top {Object.keys(topFive).length} defects
          </Button>
        </Tooltip>
        <div className="center full-flex full-width">
          {data.map(metric => (
            <Styles.MetricLabel className="v-d-flex" key={metric.name}>
              <Styles.MetricLabelText>{metric.name}</Styles.MetricLabelText>
              <Styles.MetricLabelNumber color={metric.color}>
                {numberWithCommas(metric.value)}
              </Styles.MetricLabelNumber>
            </Styles.MetricLabel>
          ))}
        </div>
      </Styles.Right>
    </Styles.Wrapper>
  );
};

export default DefectsStatistics;
