import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Toast } from '@fashinza/fashinza-design-system';
import { removeFalsyKeys } from '../../../../utils';
import { useTypedSelector } from '../../../../hooks';
import { FinalInspectionAnalyticsType } from '../../../../@types/finalInspection';
import { getFinalInspectionAnalytics } from '../../../../redux/actions/thenables';
import useBoolean from '../../../../hooks/useBoolean';
import { ApiResponseEnum } from '../../../../@types/api';

const useAnalyticsData = () => {
  const {
    dateFilters: { endDate, startDate },
    entityFilters: { brand, inspector, supplier },
  } = useTypedSelector(store => store.quality.dashboard);

  const [state, setState] = useState<FinalInspectionAnalyticsType>({
    critical_defects: 0,
    major_defects: 0,
    minor_defects: 0,
    total_sample_size: 0,
    total_styles: 0,
    total_scheduled_quantity: 0,
    average_styles: 0,
    top_five_defects: {} as FinalInspectionAnalyticsType['top_five_defects'],
    observed_quality_level_percentage: 0,
    current_month_reject_and_short: 0,
    previous_month_reject_and_short: 0,
  });

  const [isLoading, loadingActions] = useBoolean();

  useEffect(() => {
    async function getData() {
      loadingActions.on();

      const query = new URLSearchParams(
        removeFalsyKeys({
          end_date: endDate ? format(endDate, `yyyy-MM-d`) : null,
          start_date: startDate ? format(startDate, `yyyy-MM-d`) : null,
          brand,
          inspector,
          supplier,
        })
      ).toString();

      const response = await getFinalInspectionAnalytics(query);

      loadingActions.off();

      if (response.type === ApiResponseEnum.Failure) {
        Toast.ERROR(response.error);
        return;
      }

      setState(response.data);
    }

    getData();
  }, [endDate, startDate, brand, inspector, supplier]);

  return { ...state, isLoading };
};

export default useAnalyticsData;
