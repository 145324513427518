import React from 'react';
import { Button } from '@fashinza/fashinza-design-system';
import DraggableOptions from './DraggableOptions';
import { useSortedSlugsHandler } from './helperHooks';
import { AllFieldsType } from './types';
import { swallowEvent } from '../../utils';
import * as Styles from './styles';

interface IPreferencesDropdownProps {
  allFields: AllFieldsType;
  preferencesList: Array<string>;
  onApply(modifiedPreferencesList: Array<string>): void;
}

const PreferencesDropdown = ({
  allFields,
  preferencesList,
  onApply,
}: IPreferencesDropdownProps) => {
  const {
    combinedSlugs,
    modifiedPreference,
    handleDragEnd,
    handleToggleSlug,
    handleReset,
  } = useSortedSlugsHandler(allFields, preferencesList);

  function handleApply() {
    onApply(modifiedPreference);
  }

  return (
    <Styles.DropdownWrapper
      className="v-d-flex no-y-scroll"
      onClick={swallowEvent}
    >
      <div className="v-d-flex full-flex no-y-scroll">
        <Styles.Label>Column sequence and visibility</Styles.Label>
        <DraggableOptions
          allFields={allFields}
          combinedSlugs={combinedSlugs}
          modifiedPreference={new Set(modifiedPreference)}
          handleDragEnd={handleDragEnd}
          handleToggleSlug={handleToggleSlug}
        />
      </div>
      <Styles.ButtonsWrapper className="spread">
        <Button variant="ghost" onClick={handleReset}>
          Reset to Default
        </Button>
        <Button
          onClick={() => {
            handleApply();
          }}
        >
          Apply
        </Button>
      </Styles.ButtonsWrapper>
    </Styles.DropdownWrapper>
  );
};

export default PreferencesDropdown;
