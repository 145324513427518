import { AiFillThunderbolt } from 'react-icons/ai';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { FaSquareFull } from 'react-icons/fa';
import ArrowOutcomingIcon from './ArrowOutcomingIcon';
import ArrowIncomingOutgoingIcon from './ArrowIncomingAndOutgoingIcon';
import ArrowIncomingIcon from './ArrowIncomingIcon';
import ArchiveIcon from './ArchiveIcon';
import AttachmentIcon from './AttachmentIcon';
import ArrowIcon from './ArrowIcon';
import ChevronRightIcon from './ChevronRightIcon';
import ChevronDownIcon from './ChevronDownIcon';
import CheckIcon from './CheckIcon';
import CloseIcon from './CloseIcon';
import CogIcon from './CogIcon';
import RfqsIcon from './RfqsIcon';
import TrashIcon from './TrashIcon';
import ClockIcon from './ClockIcon';
import DoubleArrowIcon from './DoubleArrowIcon';
import DownloadIcon from './DownloadIcon';
import DownloadInvalidIcon from './DownloadInvalidIcon';
import CalendarIcon from './CalendarIcon';
import TshirtIcon from './TshirtIcon';
import EyeIcon from './EyeIcon';
import EnquiryIcon from './EnquiryIcon';
import GiftIcon from './GiftIcon';
import EnquiryInfoIcon from './EnquiryInfoIcon';
import LockIcon from './LockIcon';
import ProductionTrackingIcon from './ProductionTrackingIcon';
import DashboardIcon from './DashboardIcon';
import TechpackIcon from './TechpackIcon';
import CreditCardIcon from './CreditCardIcon';
import DevicesIcon from './DevicesIcon';
import QualityIcon from './QualityIcon';
import QualityAndTestingIcon from './QualityAndTestingIcon';
import TruckIcon from './TruckIcon';
import ChartBarIcon from './ChartBarIcon';
import ChartLineIcon from './ChartLineIcon';
import ChatIcon from './ChatIcon';
import UserIcon from './UserIcon';
import BrandsIcon from './BrandsIcon';
import BuildingIcon from './BuildingIcon';
import SuppliersIcon from './SuppliersIcon';
import FilesAddIcon from './FilesAddIcon';
import FabricIcon from './FabricIcon';
import FilesIcon from './FilesIcon';
import LogoIcon from './LogoIcon';
import SupportIcon from './SupportIcon';
import DotsIcon from './DotsIcon';
import BellIcon from './BellIcon';
import UploadIcon from './UploadIcon';
import MenuIcon from './MenuIcon';
import BookmarkIcon from './BookmarkIcon';
import ExclamationTriangleIcon from './ExclaimationTriangleIcon';
import ExclamationCircleIcon from './ExclamationCircleIcon';
import ExternalLinkIcon from './ExternalLinkIcon';
import PdfIcon from './PdfIcon';
import PencilIcon from './PencilIcon';
import PoInvoiceReceiptIcon from './PoInvoiceReceiptIcon';
import ProductionPlannerIcon from './ProductionPlannerIcon';
import BanIcon from './BanIcon';
import ColorSwatchIcon from './ColorSwatchIcon';
import EditIcon from './EditIcon';
import CopyIcon from './CopyIcon';
import FolderMinusIcon from './FolderMinusIcon';
import LogoutIcon from './LogoutIcon';
import FolderPlusIcon from './FolderPlusIcon';
import ShoppingCartIcon from './ShoppingCartIcon';
import SubtractIcon from './SubtractIcon';
import DollarIcon from './DollarIcon';
import ReplyOrUndoIcon from './ReplyOrUndoIcon';
import RefreshIcon from './RefreshIcon';
import FilterIcon from './FilterIcon';
import FilterAlternateIcon from './FilterAlternateIcon';
import SingleProductViewIcon from './ViewList2';
import SearchIcon from './SearchIcon';
import ShareIcon from './ShareIcon';
import TraktezIcon from './TraktezIcon';
import LightBulbIcon from './LightBulbIcon';
import OrdersIcon from './OrdersIcon';
import ProductionTrackingAppliedIcon from './ProductionTrackingAppliedIcon';
import NoActionsNeededIcon from './NoActionsNeededIcon';
import HalfFilledCircleIcon from './HalfFilledCircleIcon';
import ShieldExclamation from './ShieldExclamation';
import StarIcon from './StarIcon';
import LocationIcon from './LocationIcon';
import VideoIcon from './VideoIcon';
import PlayVideoIcon from './PlayVideoIcon';
import ClipBoard from './Clipboard';
import InformationCircleIcon from './InformationCircleIcon';
import TrakTezTextLogo from './TrakTezTextLogo';
import FashinzaTextLogo from './FashinzaTextLogo';
import HelpIcon from './HelpIcon';
import RocketIcon from './RocketIcon';
import ScissorsIcon from './ScissorsIcon';
import AddIcon from './AddIcon';
import DragIcon from './DragIcon';
import BriefcaseIcon from './BriefcaseIcon';
import Pdf2Icon from './Pdf2Icon';
import ShipmentIcon from './ShipmentIcon';
import FlagIcon from './FlagIcon';
import NoInventoryIcon from './NoInventoryIcon';
import ArrowTrendingUp from './ArrowTrendingUp';
import CheckCircleFilledIcon from './CheckCircleFilledIcon';
import ApproveTickWithWavyCircleIcon from './ApproveTickWithWavyCircle';
import CheckUnderCircleIcon from './CheckUnderCircle';
import CrossWithCircleIcon from './CrossWithCircle';
import SettingsIcon from './SettingsIcon';
import NoInternetIcon from './NoInternetIcon';
import { IconographyNameType, IconPropsType } from './types';
import CrossBlack from './CrossBlack';
import { isTraktezEnvironment } from '../../utils/environments';

const ICONOGRAPHY: Record<
  IconographyNameType,
  (props: IconPropsType) => JSX.Element
> = Object.freeze({
  add: AddIcon,
  'approve-with-tick': ApproveTickWithWavyCircleIcon,
  archive: ArchiveIcon,
  'arrow-incoming-outgoing': ArrowIncomingOutgoingIcon,
  'arrow-incoming': ArrowIncomingIcon,
  'arrow-outcoming': ArrowOutcomingIcon,
  arrow: ArrowIcon,
  attachments: AttachmentIcon,
  ban: BanIcon,
  bell: BellIcon,
  bookmarks: BookmarkIcon,
  brands: BrandsIcon,
  briefcase: BriefcaseIcon,
  building: BuildingIcon,
  calendar: CalendarIcon,
  'chart-bar': ChartBarIcon,
  'chart-line': ChartLineIcon,
  chat: ChatIcon,
  check: CheckIcon,
  'check-circle-filled': CheckCircleFilledIcon,
  'check-inside-circle': CheckUnderCircleIcon,
  'chevron-down': ChevronDownIcon,
  'chevron-right': ChevronRightIcon,
  clipboard: ClipBoard,
  clock: ClockIcon,
  close: CloseIcon,
  cog: CogIcon,
  'color-swatch': ColorSwatchIcon,
  copy: CopyIcon,
  'credit-card': CreditCardIcon,
  'cross-with-circle': CrossWithCircleIcon,
  devices: DevicesIcon,
  dollar: DollarIcon,
  dots: DotsIcon,
  'double-arrow': DoubleArrowIcon,
  download: DownloadIcon,
  'download-invalid': DownloadInvalidIcon,
  drag: DragIcon,
  edit: EditIcon,
  enquiry: EnquiryIcon,
  'exclaimation-triangle': ExclamationTriangleIcon,
  'exclamation-circle': ExclamationCircleIcon,
  'external-link': ExternalLinkIcon,
  eye: EyeIcon,
  shipment: ShipmentIcon,
  fabric: FabricIcon,
  files: FilesIcon,
  'files-add': FilesAddIcon,
  filter: FilterIcon,
  'filter-alternate': FilterAlternateIcon,
  flag: FlagIcon,
  'folder-plus': FolderPlusIcon,
  'folder-remove': FolderMinusIcon,
  gift: GiftIcon,
  halfFilledCircle: HalfFilledCircleIcon,
  info: EnquiryInfoIcon,
  'production-tracking': ProductionTrackingIcon,
  'production-tracking-applied': ProductionTrackingAppliedIcon,
  'no-actions-needed': NoActionsNeededIcon,
  'help-icon': HelpIcon,
  'info-icon': HiOutlineInformationCircle,
  'light-bulb': LightBulbIcon,
  location: LocationIcon,
  lock: LockIcon,
  logo: isTraktezEnvironment ? TraktezIcon : LogoIcon,
  logout: LogoutIcon,
  menu: MenuIcon,
  orders: OrdersIcon,
  'play-video': PlayVideoIcon,
  pdf: PdfIcon,
  pdfFile: Pdf2Icon,
  pencil: PencilIcon,
  'po-invoice-receipt': PoInvoiceReceiptIcon,
  'production-planner': ProductionPlannerIcon,
  quality: QualityIcon,
  'quality-and-testing': QualityAndTestingIcon,
  'reply-or-undo': ReplyOrUndoIcon,
  refresh: RefreshIcon,
  'rfqs-icon': RfqsIcon,
  rocket: RocketIcon,
  scissors: ScissorsIcon,
  search: SearchIcon,
  share: ShareIcon,
  'shield-exclamation': ShieldExclamation,
  'shopping-cart': ShoppingCartIcon,
  square: FaSquareFull,
  star: StarIcon,
  subtract: SubtractIcon,
  suppliers: SuppliersIcon,
  support: SupportIcon,
  techpack: TechpackIcon,
  thunderbolt: AiFillThunderbolt,
  trash: TrashIcon,
  truck: TruckIcon,
  tshirt: TshirtIcon,
  upload: UploadIcon,
  user: UserIcon,
  video: VideoIcon,
  view: DashboardIcon,
  'view-list-2': SingleProductViewIcon,
  'information-circle': InformationCircleIcon,
  'mobile-header': isTraktezEnvironment ? TrakTezTextLogo : FashinzaTextLogo,
  'no-inventory': NoInventoryIcon,
  arrowUp: BsArrowUp,
  arrowDown: BsArrowDown,
  settings: SettingsIcon,
  ArrowTrendingUp,
  noInternet: NoInternetIcon,
});

export default ICONOGRAPHY;
