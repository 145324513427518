import {
  IQualityAssuranceListItem,
  QualityActionEnum,
  IQATeamListItem,
  IReschedulePPMItem,
  IPaginationPayload,
  ITabularData,
  //   ITabularData,
} from '../../@types/base';
import { IPPMListItem, IPreProductionMeetingForm } from '../../@types/ppm';
import { AttachmentType, ObjectType } from '../../@types/defined';
import {
  NewAttendeesType,
  OtherTestReportsType,
} from '../../components/PPMForm/types';

export const onChangePpmStaging = (payload: {
  key: string;
  value:
    | string
    | boolean
    | Array<AttachmentType>
    | null
    | NewAttendeesType
    | OtherTestReportsType;
}) => ({
  type: QualityActionEnum.ChangePPMStaging,
  payload,
});

export const setBulkPpmStaging = (
  payload: Partial<IPreProductionMeetingForm>
) => ({
  type: QualityActionEnum.SetBulkPPMStaging,
  payload,
});

export const fetchManageQaListRequest = () => ({
  type: QualityActionEnum.FetchManageQAListRequest,
});

export const fetchManageQaListFailure = () => ({
  type: QualityActionEnum.FetchManageQAListFailure,
});

export const fetchManageQaListSuccess = (
  data: Array<IQualityAssuranceListItem>
) => ({
  type: QualityActionEnum.FetchManageQAListSuccess,
  payload: data,
});

export const fetchManagePpmListRequest = () => ({
  type: QualityActionEnum.FetchManagePPMListRequest,
});

export const fetchManagePpmListSuccess = (data: Array<IPPMListItem>) => ({
  type: QualityActionEnum.FetchManagePPMListSuccess,
  payload: data,
});

export const fetchQaListRequest = () => ({
  type: QualityActionEnum.FetchQAListRequest,
});

export const fetchQaListFailure = () => ({
  type: QualityActionEnum.FetchQAListFailure,
});

export const fetchQaListSuccess = (data: Array<IQATeamListItem>) => ({
  type: QualityActionEnum.FetchQAListSuccess,
  payload: data,
});

export const assignQaRequest = () => ({
  type: QualityActionEnum.AssignQARequest,
});

export const assignQaFailure = () => ({
  type: QualityActionEnum.AssignQAFailure,
});

export const assignQaSuccess = (data: IQualityAssuranceListItem) => ({
  type: QualityActionEnum.AssignQASuccess,
  payload: data,
});

export const reschedulePpmRequest = () => ({
  type: QualityActionEnum.ReschedulePPMRequest,
});

export const reschedulePpmFailure = () => ({
  type: QualityActionEnum.ReschedulePPMFailure,
});

export const reschedulePpmSuccess = (data: IReschedulePPMItem) => ({
  type: QualityActionEnum.ReschedulePPMSuccess,
  payload: data,
});

export const setPaginationInfo = ({
  key,
  count,
  totalPages,
}: IPaginationPayload) => ({
  type: QualityActionEnum.SetPaginationInfo,
  payload: {
    key,
    count,
    totalPages,
  },
});

export const setQualityReports = (payload: ITabularData) => ({
  type: QualityActionEnum.SetQualityReports,
  payload,
});

export const resetQualityReports = () => ({
  type: QualityActionEnum.ResetQualityReports,
});

export const setQualityReportsIsLoading = (payload: boolean) => ({
  type: QualityActionEnum.SetQualityReportsIsLoading,
  payload,
});

export const setTabularData = (payload: ITabularData) => ({
  type: QualityActionEnum.SetTabularData,
  payload,
});

export const setLoadingState = (payload: boolean) => ({
  type: QualityActionEnum.SetLoadingState,
  payload,
});

export const updateTabularDataById = (payload: {
  id: number | string;
  data: ObjectType;
}) => ({
  type: QualityActionEnum.UpdateTabularDataById,
  payload,
});

export const setSearchText = (payload: string) => ({
  type: QualityActionEnum.SetSearch,
  payload,
});

export const setDashboardSortConfig = (
  sortKeyName: string,
  sortMethod: string
) => ({
  type: QualityActionEnum.SetDashboardSortConfig,
  payload: {
    [sortKeyName]: sortMethod,
  },
});

export const setDashboardColumnFilters = (payload: ObjectType) => ({
  type: QualityActionEnum.SetDashboardColumnFilters,
  payload,
});
