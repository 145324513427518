import React, { MouseEvent, useContext } from 'react';
import { AnimatePresence } from 'framer-motion';
import { replace } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Toast } from '@fashinza/fashinza-design-system';
import { FiX } from 'react-icons/fi';
import { PPMFormContext } from '../index';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { PPMFormSectionsEnum } from '../types';
import BorderedGreenTick from '../../../shared/iconography/BorderedGreenTick';
import * as Styles from './styles';

interface INavigationModalProps {
  isModalOpen: boolean;
  modalOpenActions: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  setSection: (value: PPMFormSectionsEnum) => void;
  setHashValue: (value: PPMFormSectionsEnum) => void;
  section: PPMFormSectionsEnum;
}

const NavigationModal = ({
  isModalOpen,
  modalOpenActions,
  setSection,
  setHashValue,
  section,
}: INavigationModalProps) => {
  const dispatch = useDispatch();

  const { checkSectionCompleteness, updatePpmForm, inCompleteFields } =
    useContext(PPMFormContext);

  function closeNavigationModal(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    modalOpenActions.off();
  }

  function navigateToSection(
    event: MouseEvent<HTMLButtonElement>,
    key: PPMFormSectionsEnum
  ) {
    if (section !== key) {
      dispatch(replace({ hash: key }));
      setHashValue(key as PPMFormSectionsEnum);
      setSection(key as PPMFormSectionsEnum);
      modalOpenActions.off();
    }
    event.stopPropagation();
  }

  function onSubmitPPMReport() {
    if (inCompleteFields.length > 0) {
      Toast.WARN('Please fill all the required fields');
      return;
    }
    updatePpmForm(PPMFormSectionsEnum.SUBMIT_FORM);
  }

  return (
    <AnimatePresence>
      {isModalOpen && (
        <Styles.NavigationModalWrapper
          onClick={() => {
            modalOpenActions.off();
          }}
        >
          <Styles.NavigationModal
            initial={{ y: '100%', opacity: 0 }}
            animate={{ y: '0%', opacity: 1 }}
            exit={{ y: '100%', opacity: 0, transition: { duration: 0.1 } }}
          >
            <Styles.CloseModalButton
              className="center"
              onClick={closeNavigationModal}
            >
              <FiX size={20} color="var(--rhino)" />
            </Styles.CloseModalButton>
            {Object.keys(PPM_FORM_SECTION_HEADINGS).map(key => {
              const isSectionComplete = checkSectionCompleteness(
                key as PPMFormSectionsEnum
              );

              return (
                <Styles.NavigationItem
                  className="flex-start-center"
                  key={key}
                  onClick={(event: MouseEvent<HTMLButtonElement>) =>
                    navigateToSection(event, key as PPMFormSectionsEnum)
                  }
                >
                  <Styles.CheckContainer className="center">
                    <BorderedGreenTick isHighlighted={isSectionComplete} />
                  </Styles.CheckContainer>
                  {PPM_FORM_SECTION_HEADINGS[key]}
                </Styles.NavigationItem>
              );
            })}
            <Styles.NavigationFooter>
              <Styles.NavigationItem onClick={onSubmitPPMReport}>
                Submit PPM Report
              </Styles.NavigationItem>
            </Styles.NavigationFooter>
          </Styles.NavigationModal>
        </Styles.NavigationModalWrapper>
      )}
    </AnimatePresence>
  );
};

export default NavigationModal;
