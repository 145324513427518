import React from "react";
import { IconPropsType } from "./types";

interface IPdfIconProps extends IconPropsType {}

const PdfIcon = ({
  stroke = "var(--rhino)",
  size = 24,
  strokeWidth = 1.25,
  fill = "var(--rhino)",
  ...props
}: IPdfIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.608 17.484a1.29 1.29 0 0 0-.442-.242 1.702 1.702 0 0 0-.5-.084H6.532v3.934h.642v-1.416h.476c.208 0 .4-.034.566-.092s.316-.15.434-.258c.116-.108.216-.25.284-.408.066-.166.1-.342.1-.542a1.125 1.125 0 0 0-.426-.892zm-.242 1.3a.626.626 0 0 1-.158.25.837.837 0 0 1-.208.134.731.731 0 0 1-.234.042h-.592v-1.558h.484c.166 0 .3.026.4.074.1.05.176.116.234.192a.56.56 0 0 1 .108.242c.016.084.026.158.026.216-.002.158-.018.29-.06.408zm4.118-1.05c-.166-.176-.376-.316-.626-.416s-.542-.158-.876-.158H9.798v3.932h1.492c.05 0 .126-.008.226-.016s.216-.042.342-.084c.126-.042.25-.108.392-.2s.25-.208.358-.366.2-.35.266-.576.1-.508.1-.834c0-.234-.042-.466-.126-.692a1.589 1.589 0 0 0-.364-.59zm-.468 2.474c-.192.276-.5.416-.942.416h-.634V17.64h.376c.308 0 .558.042.75.116s.342.184.458.316.192.276.226.434c.042.158.058.326.058.484-.008.536-.1.936-.292 1.218zm1.884.884h.658v-1.776H16.2v-.432h-1.642v-1.242h1.808v-.484H13.9Zm1.108-12.126c-.358 0-.8.05-1.308.142-.716-.758-1.458-1.866-1.992-2.95.526-2.2.258-2.508.142-2.658-.126-.158-.3-.408-.492-.408-.084 0-.308.034-.4.066-.226.074-.35.25-.442.476-.276.642.1 1.75.492 2.6a26.025 26.025 0 0 1-1.484 4.2c-1.484.684-2.266 1.342-2.334 1.984-.026.234.026.576.434.876A.613.613 0 0 0 8 13.42c.342 0 .692-.258 1.092-.826.292-.408.6-.966.934-1.658a18.214 18.214 0 0 1 3.45-1.1c.616.592 1.166.892 1.642.892.35 0 .65-.158.866-.466.226-.316.276-.6.15-.842-.142-.312-.508-.454-1.126-.454zM8.016 12.75c-.184-.142-.176-.234-.166-.276.026-.216.366-.6 1.2-1.076-.634 1.176-.966 1.326-1.034 1.352zm3.21-8.958c.016 0 .408.434.034 1.258-.56-.566-.068-1.242-.034-1.258zm-.81 6.258c.4-.942.766-1.992 1.042-2.958.442.792.966 1.55 1.492 2.166-.834.2-1.716.476-2.534.792zm5.042-.184c-.124.168-.382.176-.474.176-.208 0-.284-.126-.6-.366a5.56 5.56 0 0 1 .708-.042c.35 0 .408.05.458.074-.016.026-.034.076-.092.158z"
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PdfIcon;
