import styled from 'styled-components';

export const Wrapper = styled.div`
  button,
  button:hover {
    background: none;
    padding: 0;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: var(--rhino);
  padding: 0 0 8px 0;
`;

export const SKUWrapper = styled.div`
  max-height: 460px;
  width: 320px;
  overflow: scroll;
  padding: 4px 0;
  top: 0;
  right: 48px;
  margin: 0;
  background-color: var(--white);
  box-shadow: 0px 16px 32px rgba(44, 53, 86, 0.15);
  border-radius: 8px;
  border: 1px solid var(--athens-gray);

  z-index: 1;
`;

export const SKU = styled.div`
  gap: 12px;
  padding: 8px 16px;

  img {
    height: 48px;
    width: 32px;
  }
  span {
    font-size: 12px;
    line-height: 20px;
    padding: 0;
    font-weight: 400;
    color: var(--lynch);
  }
  div {
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 24px;
  }
`;
