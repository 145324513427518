import React, { ChangeEvent, SyntheticEvent } from 'react';
import { OptionsType, PPMFormSectionsEnum } from '../../types';
import { NEGATIVE_CHOICES } from '../../constants';
import ShowAsteriskIfMandatory from '../ShowAsteriskIfMandatory';
import { AttachmentType } from '../../../../@types/defined';
import { IPreProductionMeetingForm } from '../../../../@types/ppm';
import VerticalFileUpload from '../../../../shared/VerticalFileUpload';
import RadioButton from '../../../../shared/RadioButton';
import TextInputWithLabel from '../../../../shared/TextInputWithLabel/textInputWithLabel';
import * as CommonStyles from '../styles';
import * as Styles from './styles';

interface ICombinedRadioTextFileProps {
  id: keyof IPreProductionMeetingForm;
  textFieldSlug: keyof IPreProductionMeetingForm;
  attachmentSlug: keyof IPreProductionMeetingForm;
  label: string;
  options: OptionsType;
  onChangeRadio: (event: SyntheticEvent, booleanValue?: boolean) => void;
  getFieldValueFromStore: (
    field: keyof IPreProductionMeetingForm
  ) => string | Array<AttachmentType> | null;
  onChangeText: (event: ChangeEvent<HTMLInputElement>) => void;
  onFileChange: (
    field: keyof IPreProductionMeetingForm,
    newFiles: Array<AttachmentType>,
    actionType: string
  ) => void;
  section: PPMFormSectionsEnum;
}

const CombinedRadioTextFile = ({
  id,
  textFieldSlug,
  attachmentSlug,
  label,
  options,
  onChangeRadio,
  getFieldValueFromStore,
  onChangeText,
  onFileChange,
  section,
}: ICombinedRadioTextFileProps) => (
  <Styles.Wrapper className="align-center">
    <CommonStyles.Label>
      {label} <ShowAsteriskIfMandatory field={id} section={section} />
    </CommonStyles.Label>
    <Styles.InputGroup>
      <Styles.InputGroupUpper className="d-flex">
        {options.map(option => (
          <RadioButton
            key={option.id}
            dataname={id}
            datalabel={option.name}
            datavalue={option.id}
            onChange={(event: SyntheticEvent) => {
              onChangeRadio(event, option.booleanValue);
            }}
            isChecked={
              // eslint-disable-next-line no-nested-ternary
              option.booleanValue
                ? getFieldValueFromStore(id) !== null
                  ? Boolean(getFieldValueFromStore(id)) === option.booleanValue
                  : false
                : getFieldValueFromStore(id) === option.id
            }
            item={undefined}
          />
        ))}
        <Styles.TextInputWrapper>
          <TextInputWithLabel
            datalabel=""
            placeholder="Remarks"
            onChange={onChangeText}
            dataname={textFieldSlug}
            value={getFieldValueFromStore(textFieldSlug)}
            shouldAutoFocus={false}
          />
        </Styles.TextInputWrapper>
      </Styles.InputGroupUpper>
      {getFieldValueFromStore(id) !== null &&
        !NEGATIVE_CHOICES.includes(getFieldValueFromStore(id) as string) && (
          <div className="d-flex">
            <VerticalFileUpload
              labelText="Attach Document"
              maxListFilesToShow={10}
              className="forced-to-be-horizontal"
              allFiles={
                (getFieldValueFromStore(
                  attachmentSlug
                ) as Array<AttachmentType>) ?? []
              } // TODO : with a correct way - other than "as"
              onSetFile={(newFiles, actionType) =>
                onFileChange(attachmentSlug, newFiles, actionType)
              }
            />
          </div>
        )}
    </Styles.InputGroup>
  </Styles.Wrapper>
);

export default CombinedRadioTextFile;
