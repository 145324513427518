import React from "react";
import { IconPropsType } from "./types";

interface IReplyOrUndoIconProps extends IconPropsType {}

const ReplyOrUndoIcon = ({
  stroke = "var(--rhino)",
  size = 24,
  strokeWidth = 1.25,
  ...props
}: IReplyOrUndoIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 10h10a8 8 0 0 1 8 8v2M3 10l6 6m-6-6 6-6"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReplyOrUndoIcon;
