import React, { useContext } from 'react';
import { PPMFormContext } from '../index';
import Accordion from '../accessoryComponents/Accordion';
import MultipleChoiceInput from '../accessoryComponents/MultipleChoiceInput';
import PlainTextInput from '../accessoryComponents/PlainTextInput';
import { InputTypeEnum, PPMFormSectionsEnum } from '../types';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { FIELDS } from './fields';

const ProductionWorksheet = () => {
  const { getFieldValueFromStore, onChangeRadio, onChangeText } = useContext(
    PPMFormContext
  );

  return (
    <Accordion
      header={
        PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.PRODUCTION_WORKSHEET]
      }
      section={PPMFormSectionsEnum.PRODUCTION_WORKSHEET}
    >
      {FIELDS.map(field => {
        switch (field.inputType) {
          case InputTypeEnum.MULTIPLE_RADIO:
            if (field.options) {
              return (
                <MultipleChoiceInput
                  key={field.id}
                  field={field.id}
                  label={field.label}
                  options={field.options}
                  value={getFieldValueFromStore(field.id)}
                  onChangeRadio={onChangeRadio}
                  section={PPMFormSectionsEnum.PRODUCTION_WORKSHEET}
                />
              );
            }
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRODUCTION_WORKSHEET}
              />
            );

          case InputTypeEnum.PLAIN_TEXT:
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRODUCTION_WORKSHEET}
              />
            );

          default:
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.PRODUCTION_WORKSHEET}
              />
            );
        }
      })}
    </Accordion>
  );
};

export default ProductionWorksheet;
