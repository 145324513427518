import React from "react";
import { IconPropsType } from "./types";

interface ITshirtIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  "hanger-yes":
    "M9.69556 8.23202C9.41976 8.03051 9.13126 7.79908 8.82667 7.53782L3.5 9.73109L3.59533 9.95798L3.94486 10.7899L5.2 13.7773L7 13.0588V19C7 20 7 21 9 21H15C17 21 17 20 17 19V13.0588L18.9889 13.7773L20.5 9.73109L15.3622 7.53782C15.0819 7.79925 14.8149 8.03063 14.5579 8.23202M9.69556 8.23202L12.0756 7.08403M9.69556 8.23202C11.5462 9.58427 12.8249 9.58993 14.5579 8.23202M12.0756 7.08403L14.5579 8.23202M12.0756 7.08403V5.9874C13.9644 5.9874 14.0022 3 12.0756 3C11.2067 3 10.64 3.68067 10.64 4.28571",
  "hanger-no":
    "M21 6.94382L15.56 4.5C15.2632 4.7913 14.9804 5.04911 14.7083 5.27351C12.8734 6.78653 11.5196 6.78023 9.56 5.27351C9.26798 5.04897 8.96252 4.79111 8.64 4.5L3 6.94382L3.10093 7.19663L3.47103 8.1236L4.8 11.4522L6.48 10.6517V17.2715C6.48 18.3858 6.70588 19.5 8.29412 19.5H15.7059C17.4 19.5 17.4 18.3858 17.4 17.2715V10.6517L19.4 11.4522L21 6.94382Z",
});

const TshirtIcon = ({
  size = 24,
  stroke = "var(--rhino)",
  strokeWidth = 1.25,
  viewType = "hanger-yes",
  ...props
}: ITshirtIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TshirtIcon;
