// import {
//     IPreferencesReducer,
//     PreferencesActionEnum,
//     PreferencesActionType,
//   } from 'customTypes/preferences';

export interface IPreferencesReducer {
  //   rfqList: Array<string>;
  qualityList: Array<string>;
  //   poList: Array<string>;
}

export enum PreferencesActionEnum {
  SetRfqList = 'preferences/SET_RFQ_LIST',
  SetQualityList = 'preferences/SET_QUALITY_LIST',
  SetPoList = 'preferences/SET_PO_LIST',
}

export type PreferencesActionType =
  //   | {
  //       type: PreferencesActionEnum.SetRfqList;
  //       rfqList: IPreferencesReducer['rfqList'];
  //     }
  {
    type: PreferencesActionEnum.SetQualityList;
    payload: IPreferencesReducer['qualityList'];
  };
//   | {
//       type: PreferencesActionEnum.SetPoList;
//       poList: IPreferencesReducer['poList'];
//     };

const INITIAL_STATE: IPreferencesReducer = Object.freeze({
  rfqList: [],
  qualityList: [],
  poList: [],
});

function preferencesReducer(
  state = INITIAL_STATE,
  action: PreferencesActionType
): IPreferencesReducer {
  switch (action.type) {
    //   case PreferencesActionEnum.SetRfqList:
    //     return {
    //       ...state,
    //       rfqList: action.rfqList,
    //     };

    case PreferencesActionEnum.SetQualityList:
      return {
        ...state,
        qualityList: action.payload,
      };

    //   case PreferencesActionEnum.SetPoList:
    //     return {
    //       ...state,
    //       poList: action.poList,
    //     };
    default:
      return state;
  }
}

export default preferencesReducer;
