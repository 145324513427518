import React from 'react';
import { useDispatch } from 'react-redux';
import { INITIAL_TABULAR_DATA_STATE } from '../../constants';
import { useTypedSelector } from '../../../hooks';
import { fetchQualityReports } from '../../../redux/actions/thunks';
import { setSearchText, setTabularData } from '../../../redux/actions';
import useDebounce from '../../../hooks/useDebounce';
import ICONOGRAPHY from '../../../shared/iconography';
import * as Styles from './styles';

const { search: SearchIcon } = ICONOGRAPHY;

const SearchBar = () => {
  const dispatch = useDispatch();

  const section = useTypedSelector(store => store.quality.dashboard.section);
  const searchText = useTypedSelector(
    store => store.quality.dashboard.searchText
  );
  const isLoading = useTypedSelector(store => store.quality.isLoading);

  function handleSearchChange() {
    if (isLoading) {
      return;
    }
    dispatch({
      ...setTabularData(INITIAL_TABULAR_DATA_STATE),
      meta: { operation: 'replace' },
    });
    dispatch(fetchQualityReports('replace'));
  }

  const debouncedSearchChange = useDebounce(handleSearchChange, 300);

  function handleSearchValueChange(value: string) {
    dispatch(setSearchText(value));
    debouncedSearchChange.implementation();
  }

  if (
    section &&
    !['sample-inspection', 'fabric-inspection', 'final-inspection'].includes(
      section
    )
  ) {
    return null;
  }

  return (
    <Styles.Wrapper className="align-center full-flex-grow">
      <Styles.InputWrapper className="spread gap-12">
        <SearchIcon />
        <Styles.Input
          type="text"
          placeholder="Search via Order / Style"
          value={searchText}
          onChange={event => handleSearchValueChange(event.target.value)}
        />
      </Styles.InputWrapper>
    </Styles.Wrapper>
  );
};

export default SearchBar;
