import styled, { css } from 'styled-components';

function getRowCss(columnCount: number) {
  const marginRight = '12px';

  const columnCss =
    columnCount > 1
      ? css`
          width: calc(${(100 / columnCount).toFixed(2)}% - ${marginRight});
        `
      : '';

  return css`
    > * {
      ${columnCss}
      margin-bottom: 12px;
      margin-right: ${marginRight};
    }
  `;
}

const COLUMN_CSS = css`
  > * {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .files-list {
    width: 100%;
    max-width: 100%;
  }
`;

export const Wrapper = styled.div<{
  direction: 'row' | 'column';
  itemCount: number;
}>`
  display: flex;
  flex-direction: ${props => props.direction};
  flex-wrap: wrap;

  ${props =>
    props.direction === 'row' ? getRowCss(props.itemCount) : COLUMN_CSS}

  .modalWrapperHoc-wrapper {
    width: 100%;
    margin: 0;
  }
`;

export const boxCss = css`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 4px;
`;

export const textCss = css`
  margin-left: 8px;
  font-size: 14px;
  line-height: 17px;
  color: var(--rhino);
`;
