import React from 'react';

const NoActionsNeededIcon = () => (
  <svg
    width={190}
    height={160}
    viewBox="0 0 190 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4749_18758)">
      <path
        d="M6.78042 80.9519C18.2134 71.953 36.1959 79.6006 48.8881 72.4828C56.5817 68.1676 60.5514 59.3376 62.7013 50.7763C64.8512 42.215 65.7419 33.2237 69.6041 25.2844C73.4663 17.3527 81.444 10.4807 90.2126 11.4481C96.7699 12.1699 102.045 16.9535 108.011 19.7637C124.12 27.3575 143.792 20.1323 160.684 25.7605C173.107 29.9067 182.582 40.879 186.69 53.3178C190.798 65.7566 189.984 79.4393 186.314 92.0163C179.549 115.197 162.949 135.337 141.48 146.402C118.323 158.341 90.2971 159.516 65.4885 151.561C51.2146 146.985 37.9619 139.499 26.3293 130.078C17.0079 122.522 4.62283 112.525 1.30581 100.447C-0.590725 93.552 0.929574 85.5512 6.77274 80.9519H6.78042Z"
        fill="#F7F8FA"
      />
      <path
        d="M21.5533 66.5169C25.2235 67.3001 29.2239 66.4171 32.1724 64.0983C36.1037 61.0193 37.8773 55.4448 36.4415 50.6613C33.685 41.4397 20.1405 38.4912 13.5526 45.5015C6.94924 52.5271 12.9767 64.6972 21.5533 66.5169Z"
        fill="#F7F8FA"
      />
      <path
        d="M50.0092 59.3605C53.1573 59.9134 56.5127 57.8633 57.6107 54.8611C59.6531 49.2559 53.2341 42.1996 47.483 45.0943C41.5708 48.0735 43.7821 58.2625 50.0092 59.3605Z"
        fill="#F7F8FA"
      />
      <path
        d="M177.922 12.4385C179.618 12.4385 180.993 11.0634 180.993 9.36721C180.993 7.67097 179.618 6.2959 177.922 6.2959C176.225 6.2959 174.85 7.67097 174.85 9.36721C174.85 11.0634 176.225 12.4385 177.922 12.4385Z"
        fill="#E8EAEE"
      />
      <path
        d="M180.847 27.1499L174.989 23.7715V30.5361L180.847 27.1499Z"
        stroke="#E8EAEE"
        strokeWidth={0.66}
        strokeMiterlimit={10}
      />
      <path
        d="M180.97 21.1763V15.0337H174.827V21.1763H180.97Z"
        fill="#E8EAEE"
      />
      <path
        d="M165.69 152.145C166.538 152.145 167.226 151.458 167.226 150.609C167.226 149.761 166.538 149.074 165.69 149.074C164.842 149.074 164.155 149.761 164.155 150.609C164.155 151.458 164.842 152.145 165.69 152.145Z"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.896 120.088V126.231"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.825 123.16H177.968"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1751 141.472C19.8713 141.472 21.2464 140.097 21.2464 138.401C21.2464 136.705 19.8713 135.33 18.1751 135.33C16.4788 135.33 15.1038 136.705 15.1038 138.401C15.1038 140.097 16.4788 141.472 18.1751 141.472Z"
        fill="#E8EAEE"
      />
      <path
        d="M15.2498 156.184L21.1083 152.798V159.562L15.2498 156.184Z"
        stroke="#E8EAEE"
        strokeWidth={0.66}
        strokeMiterlimit={10}
      />
      <path
        d="M21.2695 150.202V144.06H15.1269V150.202H21.2695Z"
        fill="#E8EAEE"
      />
      <path
        d="M30.5525 6.52641C32.2488 6.52641 33.6238 5.15134 33.6238 3.4551C33.6238 1.75886 32.2488 0.383789 30.5525 0.383789C28.8563 0.383789 27.4812 1.75886 27.4812 3.4551C27.4812 5.15134 28.8563 6.52641 30.5525 6.52641Z"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1826 17.0225C19.0307 17.0225 19.7183 16.3349 19.7183 15.4868C19.7183 14.6387 19.0307 13.9512 18.1826 13.9512C17.3345 13.9512 16.647 14.6387 16.647 15.4868C16.647 16.3349 17.3345 17.0225 18.1826 17.0225Z"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.8088 36.3184L67.8458 42.1308"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.0186 40.2418L70.5023 33.4619"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.6651 44.0121L58.8928 43.5283"
        stroke="#FF305D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.8005 14.9727C95.8253 15.4026 94.9193 15.9708 94.1361 16.7079C90.8421 19.833 91.6637 24.0561 93.1456 26.2674C93.9672 27.4883 95.3185 28.2714 96.739 28.6784C98.1595 29.0853 99.6414 29.1544 101.108 29.2235C106.913 29.4923 112.718 28.8396 118.515 29.2466C129.487 30.0221 135.599 31.6652 144.014 34.4908C150.564 36.6945 156.929 40.3724 160.622 46.2156C164.316 52.0587 164.661 60.3513 160.085 65.5341C157.282 68.7053 152.437 70.3484 148.644 68.4826C144.851 66.6168 143.438 60.7045 146.632 57.925C150.019 54.9765 155.893 57.5564 157.39 61.7948C158.887 66.0255 157.113 70.7707 154.48 74.4025C147.608 83.8775 135.384 88.2158 123.682 88.615C111.98 89.0143 100.471 86.0428 89.0147 83.6011C77.5587 81.1594 65.6574 79.2322 54.2167 81.6969C48.4273 82.9408 42.707 85.4285 38.7757 89.8512C34.8444 94.2739 33.0554 100.9 35.4126 106.329C38.1614 112.671 46.6152 115.781 52.8193 112.733C59.0233 109.684 61.7261 101.085 58.3783 95.0341C57.2496 92.9993 55.499 91.241 53.303 90.4655C51.107 89.69 48.4657 90.0278 46.7611 91.6172C44.8108 93.437 44.4423 96.5006 45.2562 99.0421C46.0777 101.576 47.8821 103.672 49.794 105.523C56.4357 111.942 65.0047 116.357 74.0881 118.031"
        stroke="#FFA100"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M69.7883 127.398L69.8421 45.3862C69.8421 44.4188 70.6253 43.6279 71.5927 43.6126L114.23 43.1519C114.729 43.1519 115.198 43.3515 115.543 43.7124L133.403 62.6854C133.71 63.0156 133.887 63.4455 133.887 63.8986L133.994 127.398C133.994 128.404 133.157 129.218 132.144 129.172H71.4929C70.5408 129.133 69.796 128.35 69.796 127.398H69.7883Z"
        fill="white"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M114.614 45.0869L114.322 63.9985L132.405 63.7375L114.614 45.0869Z"
        fill="#E8EAEE"
      />
      <path
        d="M96.5625 80.2534C97.5421 80.2534 98.3362 79.4593 98.3362 78.4797C98.3362 77.5002 97.5421 76.7061 96.5625 76.7061C95.5829 76.7061 94.7888 77.5002 94.7888 78.4797C94.7888 79.4593 95.5829 80.2534 96.5625 80.2534Z"
        fill="#929BAA"
      />
      <path
        d="M107.228 80.0459C108.207 80.0459 109.001 79.2518 109.001 78.2722C109.001 77.2926 108.207 76.4985 107.228 76.4985C106.248 76.4985 105.454 77.2926 105.454 78.2722C105.454 79.2518 106.248 80.0459 107.228 80.0459Z"
        fill="#929BAA"
      />
      <path
        d="M98.4514 84.146C98.4514 84.146 101.845 81.3895 105.562 84.0078"
        stroke="#929BAA"
        strokeWidth={0.95}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M78.104 102.367H127.683"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M78.104 107.772H127.683"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M78.104 113.17H127.683"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M78.104 118.568H127.683"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M113.48 22.3583C112.952 21.6572 111.956 21.5172 111.254 22.0454C110.553 22.5737 110.413 23.5703 110.942 24.2713L117.545 33.0341C118.073 33.7352 119.07 33.8753 119.771 33.347C120.472 32.8187 120.612 31.8221 120.084 31.1211L113.48 22.3583Z"
        fill="white"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M108.787 25.9668C114.465 24.4984 117.877 18.7051 116.409 13.0272C114.94 7.34924 109.147 3.93675 103.469 5.40517C97.7911 6.87358 94.3786 12.6668 95.847 18.3448C97.3154 24.0227 103.109 27.4352 108.787 25.9668Z"
        fill="white"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M106.13 23.8567C110.637 23.8567 114.292 20.2025 114.292 15.6947C114.292 11.187 110.637 7.53271 106.13 7.53271C101.622 7.53271 97.9675 11.187 97.9675 15.6947C97.9675 20.2025 101.622 23.8567 106.13 23.8567Z"
        fill="white"
        stroke="#929BAA"
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4749_18758">
        <rect
          width={188.909}
          height={160}
          fill="white"
          transform="translate(0.545654)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default NoActionsNeededIcon;
