import React from "react";
import { IconPropsType } from "./types";

interface IFilesIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  default:
    "M7 21h10a2 2 0 0 0 2-2V9.414a1 1 0 0 0-.293-.707l-5.414-5.414A1 1 0 0 0 12.586 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2Z",
  "default-alternate":
    "M19 14.0769V11.6538C19 10.8276 18.6681 10.0352 18.0774 9.45094C17.4866 8.86669 16.6854 8.53846 15.85 8.53846H14.45C14.1715 8.53846 13.9045 8.42905 13.7075 8.2343C13.5106 8.03955 13.4 7.77542 13.4 7.5V6.11538C13.4 5.28913 13.0681 4.49672 12.4774 3.91248C11.8866 3.32823 11.0854 3 10.25 3H8.5M8.5 14.7692H15.5M8.5 17.5385H12M10.6 3H6.05C5.4704 3 5 3.46523 5 4.03846V19.9615C5 20.5348 5.4704 21 6.05 21H17.95C18.5296 21 19 20.5348 19 19.9615V11.3077C19 9.10436 18.115 6.99126 16.5397 5.43327C14.9644 3.87527 12.8278 3 10.6 3Z",
});

const FilesIcon = ({
  stroke = "var(--rhino)",
  size = 24,
  strokeWidth = 1.25,
  viewType = "default",
  ...props
}: IFilesIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilesIcon;
