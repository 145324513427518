import styled from 'styled-components';

export const Wrapper = styled.div`
  z-index: 100;
  background: var(--white);
  border-radius: 4px;
  max-height: 70vh;
  max-width: 40vw;
`;

export const Header = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: var(--rhino);
  padding: 24px 24px 16px 24px;
`;

export const Body = styled.div`
  overflow: auto;
`;

export const Filters = styled.div`
  padding: 24px;
`;

export const CellHeader = styled.h3.attrs(({ className }) => ({
  className: `${className} full-width`,
}))`
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  color: var(--manatee);
`;

export const CellBody = styled.div.attrs(({ className }) => ({
  className: `${className} full-dimension`,
}))`
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: left;
  color: var(--rhino);
  padding-right: 24px;

  .tooltip-wrapper {
    width: inherit;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const Action = styled.span`
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  color: var(--pacific-blue);
  cursor: pointer;
  &:hover {
    color: var(--pacific-blue);
    text-decoration: underline;
  }
`;
