import React from "react";
import Tooltip from "react-tooltip-lite";
import { GrDownload, GrShare } from "react-icons/gr";
import { ObjectType } from "../../../../@types/defined";
import { capitalizeFirstLetter, openInNewTab } from "../../../../utils";
import { TT_WHITE_CLASS, defaultValue } from "../../../../constants";
import { getFileAttributes } from "../../../../utils/files";
import * as CommonStyles from "../styles";
import * as Styles from "./styles";

interface IHistoryAndRemarksProps {
  label: string;
  fileUrl: string;
  status: string;
  skuData: ObjectType;
}

const HistoryAndRemarks = ({
  label,
  fileUrl,
  status,
  skuData,
}: IHistoryAndRemarksProps) => {
  const { backgroundColor, Icon } = getFileAttributes(fileUrl);

  const fileName = fileUrl.split("public/")[1];

  return (
    <Styles.Wrapper className="align-center">
      <CommonStyles.Label>{label}</CommonStyles.Label>
      <Styles.InputGroupWrapper className="d-flex">
        {skuData.thumbnail?.length > 0 && (
          <Styles.HistorySection className="align-center">
            <Tooltip
              content={
                <Styles.Thumbnail>
                  <img src={skuData.thumbnail} alt="" />
                </Styles.Thumbnail>
              }
              direction="bottom-left"
              arrowSize={8}
              className={TT_WHITE_CLASS}
            >
              <Styles.Thumbnail isSmall className="cursor-pointer">
                <img src={skuData.thumbnail} alt="" />
              </Styles.Thumbnail>
            </Tooltip>
          </Styles.HistorySection>
        )}

        <div>
          {fileUrl.length > 0 && (
            <Styles.HistorySection className="d-flex">
              <Styles.HistoryAttachment backgroundColor={backgroundColor}>
                <Icon />
                <Styles.HistoryAttachmentFileName>
                  <Tooltip
                    content={fileName}
                    direction="bottom-left"
                    arrowSize={8}
                    className={`${TT_WHITE_CLASS} truncated-text`}
                  >
                    {fileName}
                  </Tooltip>
                </Styles.HistoryAttachmentFileName>
                <Styles.HistoryActionButton
                  className="center"
                  onClick={() => openInNewTab(fileUrl)}
                >
                  <GrDownload size={12} color="var(--lynch)" />
                </Styles.HistoryActionButton>
                <Styles.HistoryActionButton
                  className="center"
                  onClick={() => openInNewTab(fileUrl)}
                >
                  <GrShare size={12} color="var(--lynch)" />
                </Styles.HistoryActionButton>
              </Styles.HistoryAttachment>
            </Styles.HistorySection>
          )}
          <Styles.HistorySection className="d-flex">
            <Styles.HistoryLabel>Status :</Styles.HistoryLabel>
            <Styles.HistoryStatusText isRed={false}>
              {status.length > 0 ? capitalizeFirstLetter(status) : defaultValue}
            </Styles.HistoryStatusText>
          </Styles.HistorySection>
          <Styles.HistorySection className="d-flex">
            <Styles.HistoryLabel>Style Name :</Styles.HistoryLabel>
            <Styles.HistoryStatusText isRed={false}>
              {skuData.style_name?.length > 0
                ? capitalizeFirstLetter(skuData.style_name)
                : defaultValue}
            </Styles.HistoryStatusText>
          </Styles.HistorySection>
        </div>
      </Styles.InputGroupWrapper>
    </Styles.Wrapper>
  );
};

export default HistoryAndRemarks;
