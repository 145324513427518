import React, { useContext } from 'react';
import { PPMFormContext } from '../index';
import Accordion from '../accessoryComponents/Accordion';
import CombinedRadioTextFile from '../accessoryComponents/CombinedRadioTextFile';
import PlainTextInput from '../accessoryComponents/PlainTextInput';
import {
  NON_BOOLEAN_AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  PPM_FORM_SECTION_HEADINGS,
} from '../constants';
import { PPMFormSectionsEnum } from '../types';
import { FIELDS } from './fields';

const FinishingPackingCheckpoints = () => {
  const {
    getFieldValueFromStore,
    onChangeRadio,
    onChangeText,
    onFileChange,
  } = useContext(PPMFormContext);

  return (
    <Accordion
      header={
        PPM_FORM_SECTION_HEADINGS[
          PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS
        ]
      }
      section={PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS}
    >
      {FIELDS.map(field => {
        if (field.textFieldSlug && field.attachmentSlug) {
          return (
            <CombinedRadioTextFile
              key={field.id}
              id={field.id}
              textFieldSlug={field.textFieldSlug}
              attachmentSlug={field.attachmentSlug}
              label={field.label}
              options={NON_BOOLEAN_AVAILABILITY_MULTIPLE_CHOICE_OPTIONS}
              onChangeRadio={onChangeRadio}
              onChangeText={onChangeText}
              getFieldValueFromStore={getFieldValueFromStore}
              onFileChange={onFileChange}
              section={PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS}
            />
          );
        }

        return (
          <PlainTextInput
            key={field.id}
            id={field.id}
            label={field.label}
            value={getFieldValueFromStore(field.id)}
            onChangeText={onChangeText}
            section={PPMFormSectionsEnum.FINISHING_PACKING_CHECKPOINTS}
          />
        );
      })}
    </Accordion>
  );
};

export default FinishingPackingCheckpoints;
