/* eslint-disable max-lines */
import { format } from 'date-fns';
import { replace } from 'connected-react-router';
import { Toast } from '@fashinza/fashinza-design-system';
import {
  assignQaFailure,
  assignQaRequest,
  fetchManagePpmListSuccess,
  fetchManageQaListSuccess,
  fetchQaListFailure,
  fetchQaListRequest,
  fetchQaListSuccess,
  reschedulePpmFailure,
  reschedulePpmRequest,
  reschedulePpmSuccess,
  setLoadingState,
  setPaginationInfo,
  setTabularData,
  updateTabularDataById,
} from './index';
import { ApiResponseEnum } from '../../@types/api';
import {
  IQATeamListItem,
  IQualityAssuranceListItem,
  IReschedulePPMItem,
  OperationType,
} from '../../@types/base';
import {
  convertArrayToObject,
  isEmptyObject,
  removeDuplicatesFromArray,
  removeFalsyKeys,
} from '../../utils';
import {
  assignQaApi,
  createManageQaItem,
  getManagePpmList,
  getManageQaList,
  getQaList,
  getQualityReportsApi,
} from '../../api';
import {
  QUALITY_REPORT_FILTER_SLUG_MAP,
  SAMPLING_INSPECTION_FILTER_TO_SLUG,
  SAMPLING_INSPECTION_SLUGS,
} from '../../components/Dashboard/constants';
import {
  INITIAL_TABULAR_DATA_STATE,
  QARoutesEnum,
} from '../../components/constants';
import { ObjectType } from '../../@types/defined';
import apiResponseHandler from '../../services/apiResponseHandler';
import { getNextPageCount } from '../../components/helpers';
import { ThunkActionType } from '../types';

export const onFillPpmForm =
  (data: Array<string | number>, closeLoader: () => void): ThunkActionType =>
  async (dispatch, getState) => {
    const selectedStyles = getState().purchaseOrder.selectedTna;
    const selectedStylesData = getState().purchaseOrder.tnas.TNA_DATA;
    const processStepIds = selectedStyles.map(
      (item: string | number) =>
        selectedStylesData[Number(item)].PROCESS_STEPS?.pre_production_meeting
          ?.id
    );
    const uniqueProcessStepIds = removeDuplicatesFromArray([
      ...processStepIds,
      ...data,
    ]);
    const apiData = { process_step: uniqueProcessStepIds, type: 'ppm' };

    try {
      const res = await createManageQaItem(apiData);

      if (res.status && res.data.id) {
        dispatch(
          replace(
            `/quality/${QARoutesEnum.PPM}/${res.data.id}/${QARoutesEnum.EDIT}`
          )
        );
        closeLoader();
      } else {
        throw new Error(res.msg);
      }
    } catch (error) {
      closeLoader();
      if (error instanceof Error) {
        Toast.ERROR(error.toString());
      }
    }
  };

export const resetManageQaList = (): ThunkActionType => dispatch => {
  dispatch({
    ...fetchManageQaListSuccess([]),
    meta: {
      operation: 'replace',
    },
  });
  dispatch(
    setPaginationInfo({
      key: 'qualityAssuranceListPaginationInfo',
      count: 0,
      totalPages: -1,
    })
  );
};

export const resetManagePpmList = (): ThunkActionType => dispatch => {
  dispatch({
    ...fetchManagePpmListSuccess([]),
    meta: { operation: 'replace' },
  });
  dispatch(
    setPaginationInfo({
      key: 'ppmListPaginationInfo',
      count: 0,
      totalPages: -1,
    })
  );
};

export const fetchQaList = (): ThunkActionType => async dispatch => {
  dispatch(fetchQaListRequest());

  const promise = getQaList();
  const response = await apiResponseHandler<Array<IQATeamListItem>>(promise);
  if (response.type === ApiResponseEnum.Success) {
    dispatch(fetchQaListSuccess(response.data));
  } else if (response.type === ApiResponseEnum.Failure) {
    dispatch(fetchQaListFailure());
    Toast.ERROR(response.error);
  }
};

export const assignQa =
  (
    id: string | number,
    assigneeId: number | string,
    onSuccessCb?: () => void
  ): ThunkActionType =>
  async dispatch => {
    dispatch(assignQaRequest());
    const apiData = { assignee: assigneeId };
    const promise = assignQaApi(id, apiData);
    const response = await apiResponseHandler<IQualityAssuranceListItem>(
      promise
    );
    if (response.type === ApiResponseEnum.Success) {
      dispatch(
        updateTabularDataById({
          id,
          data: response.data,
        })
      );

      if (onSuccessCb) {
        onSuccessCb();
      }
    } else if (response.type === ApiResponseEnum.Failure) {
      dispatch(assignQaFailure());
      Toast.ERROR(response.error);
    }
  };

export const reschedulePpm =
  (
    data: {
      processSteps: Array<string | number>;
      date: Date;
      endDate: Date;
      note: string;
      scheduledTime: string;
      type: string;
    },
    onSuccessCb: () => void
  ): ThunkActionType =>
  async dispatch => {
    dispatch(reschedulePpmRequest());
    const apiData = {
      is_rescheduling: true,
      process_step: data.processSteps,
      date: data.date,
      end_date: data.endDate,
      note: data.note,
      scheduled_time: data.scheduledTime,
      type: data.type,
    };
    const promise = createManageQaItem(apiData);
    const response = await apiResponseHandler<IReschedulePPMItem>(promise);
    if (response.type === ApiResponseEnum.Success) {
      dispatch(reschedulePpmSuccess(response.data));
      if (onSuccessCb) {
        onSuccessCb();
      }
    } else if (response.type === ApiResponseEnum.Failure) {
      Toast.ERROR(response.error);
      dispatch(reschedulePpmFailure());
    }
  };

export const fetchQualityReports =
  (operation: OperationType): ThunkActionType =>
  async (dispatch, getState) => {
    const { ids, totalPages } = getState().quality.tabularData;
    const { searchText } = getState().quality.dashboard;

    const newPage = getNextPageCount(ids.length, totalPages);

    if (newPage === -1) {
      return;
    }

    const {
      section,
      subSection,
      dateFilters: { endDate, startDate },
      entityFilters: { brand, inspector, supplier },
      sortConfig,
      columnFilters,
    } = getState().quality.dashboard;

    let slug: string;

    if (section === 'sample-inspection' && subSection) {
      slug =
        subSection === 'all-samples'
          ? SAMPLING_INSPECTION_SLUGS.join(',')
          : SAMPLING_INSPECTION_FILTER_TO_SLUG[subSection];
    } else {
      slug =
        QUALITY_REPORT_FILTER_SLUG_MAP[
          section as keyof typeof QUALITY_REPORT_FILTER_SLUG_MAP
        ];
    }

    const query = new URLSearchParams(
      removeFalsyKeys({
        end_date: endDate ? format(endDate, `yyyy-MM-d`) : null,
        start_date: startDate ? format(startDate, `yyyy-MM-d`) : null,
        brand,
        inspector,
        supplier,
        slug,
        search: searchText,
        ...(searchText.length > 0 ? {} : { sort: JSON.stringify(sortConfig) }),
        ...columnFilters,
      })
    ).toString();

    try {
      dispatch(setLoadingState(true));
      const promise = getQualityReportsApi(query, newPage);
      const response = await apiResponseHandler<{
        count: number;
        next: string;
        previous: string | null;
        results: Array<ObjectType>;
        total_pages: number;
      }>(promise);

      if (response.type === ApiResponseEnum.Failure) {
        throw new Error(response.error);
      }

      const newIds = response.data.results.map(item => item.id);
      const newData = convertArrayToObject(response.data.results, 'id');

      dispatch({
        ...setTabularData({
          ids: newIds,
          data: newData,
          count: response.data.count,
          totalPages: response.data.total_pages,
        }),
        meta: { operation },
      });
      dispatch(setLoadingState(false));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setLoadingState(false));
        Toast.ERROR(error.message);
      }
    }
  };

export const fetchManageQaList =
  (operation: OperationType): ThunkActionType =>
  async (dispatch, getState) => {
    const { ids, totalPages } = getState().quality.tabularData;

    const newPage = getNextPageCount(ids.length, totalPages);

    if (newPage === -1) {
      return;
    }

    try {
      dispatch(setLoadingState(true));
      const promise = getManageQaList(newPage);
      const response = await apiResponseHandler<{
        count: number;
        next: string;
        previous: string | null;
        results: Array<ObjectType>;
        total_pages: number;
      }>(promise);

      if (response.type === ApiResponseEnum.Failure) {
        throw new Error(response.error);
      }

      const newIds = response.data.results.map(item => item.id);
      const newData = convertArrayToObject(response.data.results, 'id');

      dispatch({
        ...setTabularData({
          ids: newIds,
          data: newData,
          count: response.data.count,
          totalPages: response.data.total_pages,
        }),
        meta: { operation },
      });
      dispatch(setLoadingState(false));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setLoadingState(false));
        Toast.ERROR(error.message);
      }
    }
  };

export const fetchManagePpmList =
  (operation: OperationType): ThunkActionType =>
  async (dispatch, getState) => {
    const { ids, totalPages } = getState().quality.tabularData;

    const newPage = getNextPageCount(ids.length, totalPages);

    if (newPage === -1) {
      return;
    }

    try {
      dispatch(setLoadingState(true));
      const promise = getManagePpmList(newPage);
      const response = await apiResponseHandler<{
        count: number;
        next: string;
        previous: string | null;
        results: Array<ObjectType>;
        total_pages: number;
      }>(promise);

      if (response.type === ApiResponseEnum.Failure) {
        throw new Error(response.error);
      }

      const newIds = response.data.results.map(item => item.id);
      const newData = convertArrayToObject(response.data.results, 'id');

      dispatch({
        ...setTabularData({
          ids: newIds,
          data: newData,
          count: response.data.count,
          totalPages: response.data.total_pages,
        }),
        meta: { operation },
      });
      dispatch(setLoadingState(false));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setLoadingState(false));
        Toast.ERROR(error.message);
      }
    }
  };

export const fetchTableData =
  (operation: OperationType): ThunkActionType =>
  async (dispatch, getState) => {
    const {
      section,
      subSection,
      dateFilters: { endDate, startDate },
      entityFilters: { brand, inspector, supplier },
    } = getState().quality.dashboard;

    if (
      isEmptyObject(
        removeFalsyKeys({
          section,
          subSection,
          endDate,
          startDate,
          brand,
          inspector,
          supplier,
        })
      )
    ) {
      return;
    }

    if (operation === 'replace') {
      dispatch({
        ...setTabularData(INITIAL_TABULAR_DATA_STATE),
        meta: { operation },
      });
    }

    switch (section) {
      case 'fabric-inspection':
      case 'final-inspection':
      case 'sample-inspection': {
        dispatch(fetchQualityReports(operation));
        break;
      }

      case 'manage-qa': {
        dispatch(fetchManageQaList(operation));
        break;
      }

      case 'pre-production-meeting': {
        dispatch(fetchManagePpmList(operation));
        break;
      }

      default: {
        break;
      }
    }
  };
