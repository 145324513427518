const CONFIG = {
  TOKEN: process.env.TOKEN,
  MIXPANEL_TOKEN: process.env.MIXPANEL_TOKEN,
  IS_MIXPANEL_LOGGING_ENABLED: process.env.IS_MIXPANEL_LOGGING_ENABLED,
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN,
  IS_REDUX_ENABLED: process.env.IS_REDUX_ENABLED,
  SERVER_URL: process.env.SERVER_URL,
  SERVER_PORT: process.env.SERVER_PORT,
  IS_DEV_MODE: process.env.IS_DEV_MODE,
  PUBLIC_URL: process.env.PUBLIC_URL,
  GTM_ID: process.env.GTM_ID,
  SERVER_URL_FULL: process.env.SERVER_URL_FULL,
};

export default CONFIG;
