import React, { CSSProperties, useEffect } from 'react';
import { numberWithCommas } from '../../../../utils';
import useBoolean from '../../../../hooks/useBoolean';
import * as Styles from './styles';
import InfoTooltip from '../../../../shared/InfoTooltip';
import ICONOGRAPHY from '../../../../shared/iconography';

const {
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  tshirt: TShirtIcon,
} = ICONOGRAPHY;

interface IStatisticCardProps {
  topText: string;
  fact: number;
  gridRow?: CSSProperties['gridRow'];
  gridColumn?: CSSProperties['gridColumn'];
  topSubText?: string;
  factPrefix?: string;
  factSuffix?: string;
  centerText?: string;
  bottomFact?: number;
  bottomFactSuffix?: string;
  bottomFactDescription?: string;
  infoHeading?: string;
  infoDescription?: string;
  revertComparisonSpecificity?: boolean;
}

const StatisticCard = ({
  topText,
  fact,
  infoHeading,
  infoDescription,
  gridRow,
  gridColumn,
  topSubText,
  factPrefix,
  factSuffix,
  centerText,
  bottomFact,
  bottomFactSuffix,
  bottomFactDescription,
  revertComparisonSpecificity = false,
}: IStatisticCardProps) => {
  const [isBottomFactGreen, bottomFactColorChangeActions] = useBoolean();

  useEffect(() => {
    if (!bottomFact) {
      return;
    }

    if (fact >= bottomFact) {
      if (revertComparisonSpecificity) {
        bottomFactColorChangeActions.off();
      } else {
        bottomFactColorChangeActions.on();
      }

      return;
    }

    if (revertComparisonSpecificity) {
      bottomFactColorChangeActions.on();
    } else {
      bottomFactColorChangeActions.off();
    }

    // bottomFactColorChangeActions.off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fact, bottomFact]);

  return (
    <Styles.Wrapper
      className="v-d-flex"
      style={{
        gridRow,
        gridColumn,
      }}
    >
      <Styles.TopText className="align-center">
        {topText}
        {infoHeading && (
          <InfoTooltip
            maxWidth={320}
            heading={infoHeading}
            subHeading={infoDescription}
          />
        )}
      </Styles.TopText>
      {topSubText && <Styles.TopSubText>{topSubText}</Styles.TopSubText>}

      <div className="align-flex-end  full-flex">
        <Styles.Fact className="v-end-align full-flex">
          {factPrefix}
          {numberWithCommas(fact)}
          {factSuffix}
        </Styles.Fact>
        {centerText && (
          <Styles.SideFact className="align-center gap-10">
            <TShirtIcon size={20} viewType="hanger-no" />
            {centerText}
          </Styles.SideFact>
        )}
      </div>

      {bottomFact !== undefined && (
        <Styles.BottomFactContainer className="d-flex">
          <Styles.BottomFact
            className="align-center "
            isGreen={isBottomFactGreen}
          >
            {isBottomFactGreen ? (
              <ArrowUp size={15} fill="var(--deep-sea)" />
            ) : (
              <ArrowDown size={15} fill="var(--red-dirt)" />
            )}
            {numberWithCommas(bottomFact)}
            {bottomFactSuffix}
          </Styles.BottomFact>

          {bottomFactDescription && (
            <Styles.BottomFactDescription>
              {bottomFactDescription}
            </Styles.BottomFactDescription>
          )}
        </Styles.BottomFactContainer>
      )}
    </Styles.Wrapper>
  );
};

export default StatisticCard;
