import React, { useRef, useEffect, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import './collaboratorColumn.scss';
import { TT_WHITE_CLASS } from '../../../constants';
import { swallowEvent, titleCase } from '../../../utils';
import { buildUserName, getNameInitials } from '../../../utils/strings';

interface ICollaboratorColumnProps {
  data: Array<any>;
  disableAddAction: boolean;
  ovalwidth: number;
  fontSizeInitials: number;
  onOpenCollaboratorModal: (
    id: string,
    e: React.MouseEvent<HTMLDivElement>
  ) => void;
  availwidth: number;
}

const CollaboratorColumn: React.FC<ICollaboratorColumnProps> = ({
  data,
  disableAddAction,
  ovalwidth,
  fontSizeInitials,
  onOpenCollaboratorModal,
  availwidth,
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  const [availWidth, setAvailWidth] = useState(availwidth);

  useEffect(() => {
    if (!availWidth && myRef.current) {
      const { width } = myRef.current.getBoundingClientRect();
      setAvailWidth(width);
    }
  }, [availWidth]);

  const onClickAdd = (e: React.MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget.dataset;
    if (onOpenCollaboratorModal) onOpenCollaboratorModal(id!, e);
    swallowEvent(e);
  };

  const boxStyle = {
    height: ovalwidth,
    width: ovalwidth,
    minWidth: ovalwidth,
  };

  const boxTextStyle = {
    fontSize: fontSizeInitials,
  };

  const totalBall = data.length;
  let space = Math.floor((availWidth - ovalwidth * 2) / ovalwidth);
  const hiddenBall = totalBall - space;

  if (hiddenBall === 1) space += 1;

  const allNameMarkup = (
    <div>
      {data.map((item, idx) => {
        if (idx + 1 <= space) return '';
        return <p key={item.id}>{titleCase(buildUserName(item))}</p>;
      })}
    </div>
  );

  const collaboratorsShown = Math.min(data.length, space);

  return (
    <div
      ref={myRef}
      className="collaboratorColumn-wrapper pos-r flex-start-center"
    >
      <div className="d-flex">
        {data.map((item, idx) => {
          if (idx + 1 > space) return '';
          const name = titleCase(buildUserName(item));
          const initials = getNameInitials(name);
          return (
            <Tooltip
              key={item.id}
              content={<p>{name} </p>}
              direction="bottom-left"
              arrowSize={8}
              className={['name box center', TT_WHITE_CLASS].join(' ')}
              styles={{
                ...boxStyle,
                '--collab-index': idx,
              }}
            >
              <div className="collaboratorColumn-initials" style={boxTextStyle}>
                {initials}
              </div>
            </Tooltip>
          );
        })}
      </div>
      {hiddenBall > 1 && (
        <Tooltip
          key="hidden-ball"
          content={allNameMarkup}
          direction="bottom-left"
          arrowSize={8}
          className={['hiddenuser box center', TT_WHITE_CLASS].join(' ')}
          styles={{
            ...boxStyle,
            '--collab-index': collaboratorsShown,
          }}
        >
          <div className="collaboratorColumn-initials" style={boxTextStyle}>
            +{hiddenBall}
          </div>
        </Tooltip>
      )}

      {!disableAddAction && (
        <div
          className={`addmore box center ${
            totalBall === 0 ? 'single-box' : ''
          }`}
          onClick={onClickAdd}
          role="presentation"
          style={{
            ...boxStyle,
            ...{
              '--collab-index': collaboratorsShown + (hiddenBall > 1 ? 1 : 0),
            },
          }}
        >
          <div className="collaboratorColumn-initials" style={boxTextStyle}>
            +
          </div>
        </div>
      )}
    </div>
  );
};

export default CollaboratorColumn;
