import React from "react";
import animationData from "../../../../lotties/sampleInspection.json";
import StatisticCard from "../StatisticCard";
import useAnalyticsData from "./useAnalyticsData";
import ComparisonStatistics from "./ComparisonStatistics";
import OverlayLoader from "../OverlayLoader";
import * as Styles from "./styles";

const SampleInspectionAnalytics = () => {
  const {
    total_created: totalCreated,
    total_approved: totalApproved,
    current_month_total_created: currentMonthTotalCreated,
    current_month_approval_percentage: currentMonthApprovalPercentage,
    past_month_total: pastMonthTotal,
    previous_month_approval_percentage: previousMonthApprovalPercentage,
    interval_based_comparison_data: intervalBasedComparisonData,
    isLoading,
  } = useAnalyticsData();

  return (
    <Styles.Wrapper className="pos-r">
      <OverlayLoader isLoading={isLoading} animationData={animationData} />
      <ComparisonStatistics
        data={intervalBasedComparisonData}
        created={totalCreated}
        approved={totalApproved}
      />
      <StatisticCard
        topText="Created this month"
        topSubText="Reports created this month"
        fact={currentMonthTotalCreated}
        bottomFact={pastMonthTotal}
        gridRow="1/2"
        gridColumn="3/4"
      />
      <StatisticCard
        topText="Approval Percentage"
        topSubText="Samples approved in 1st attempt"
        fact={currentMonthApprovalPercentage}
        factSuffix="%"
        bottomFact={previousMonthApprovalPercentage}
        bottomFactSuffix="%"
        bottomFactDescription="Average"
        gridRow="1/2"
        gridColumn="4/5"
      />
    </Styles.Wrapper>
  );
};

export default SampleInspectionAnalytics;
