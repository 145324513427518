import styled, { css } from 'styled-components';
import { boxCss, textCss } from '../styles';

export const AbsoluteCrossWrapper = styled.div`
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  background-color: var(--athens-gray);
`;

export const AbsoluteCrossReferenceWrapper = styled.div`
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0.5rem;

  + ${AbsoluteCrossWrapper} {
    visibility: hidden;
  }
`;

export const LeftWrapper = styled.div`
  &:hover ${AbsoluteCrossWrapper} {
    visibility: visible;
  }
`;

export const RightWrapper = styled.div<{ shouldShow: boolean }>`
  visibility: ${props => (props.shouldShow ? 'visible' : 'hidden')};
  padding-right: 4px;

  > * {
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    padding: 0;
    background-color: transparent;
    &:hover {
      background-color: rgba(44, 53, 86, 0.1);
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

function getBackgroundStyles(backgroundColor: string) {
  return css`
    background-color: ${backgroundColor};
    border-radius: 4px;
  `;
}

export const Wrapper = styled.div<{
  backgroundColor: string;
  showBackgroundDirectly: boolean;
  showRightSideControls: boolean;
}>`
  transition: background-color 180ms ease-in-out;

  ${props =>
    props.showBackgroundDirectly && getBackgroundStyles(props.backgroundColor)}

  &:hover {
    ${props =>
      props.showRightSideControls && getBackgroundStyles(props.backgroundColor)}

    ${RightWrapper} {
      visibility: visible;
    }
  }
`;

export const IconWrapper = styled.div<{ backgroundColor: string }>`
  ${boxCss};
  background-color: ${props => props.backgroundColor};
`;

export const FileName = styled.p`
  ${textCss};
`;
