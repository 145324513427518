import { COMMON_MULTIPLE_CHOICE_OPTIONS } from '../constants';
import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'shell_fabric',
    label: 'Shell Fabric',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'lining_fabric',
    label: 'Lining Fabric',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'interlining_fabric',
    label: 'Interlining Fabric',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'trims_fabric',
    label: 'Trims Fabric',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'screen_print',
    label: 'Screen Print',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'embroidery',
    label: 'Embroidery',
    inputType: InputTypeEnum.MULTIPLE_RADIO,
    options: COMMON_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'other_material_field',
    label: 'Remarks',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'fabric_inhouse_date',
    label: 'Fabric Inhouse Date',
    inputType: InputTypeEnum.DATE,
  },
];
