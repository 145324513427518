import React from 'react';
import styled from 'styled-components';
import { IPieChartData, ITooltipPosition } from './types';

interface ITooltipDisplayProps {
  payload: IPieChartData;
  position: ITooltipPosition;
}

const Wrapper = styled.div`
  min-width: 170px;
  background-color: var(--white);
  border: 1px solid var(--athens-gray);
  box-shadow: 0 8px 8px -4px rgba(12, 16, 33, 0.03),
    0 20px 24px -4px rgba(12, 16, 33, 0.08);
  border-radius: 8px;
  padding: 12px;
  pointer-events: none;
`;

const VerticalLine = styled.div`
  width: 4px;
  margin-right: 12px;
  border-radius: 4px;
`;

const Heading = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: var(--rhino);
  margin-bottom: 8px;
`;

const SubText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: var(--rhino);
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TooltipDisplay = ({ payload, position }: ITooltipDisplayProps) => (
  <Wrapper
    className="d-flex pos-f"
    style={{
      top: position.y,
      left: position.x,
    }}
  >
    <VerticalLine
      style={{
        backgroundColor: payload.color,
      }}
    />
    <div>
      <Heading>{payload.name}</Heading>
      {payload.subTexts.map(eachSubText => (
        <SubText key={eachSubText}>{eachSubText}</SubText>
      ))}
    </div>
  </Wrapper>
);

export default TooltipDisplay;
