import React, { ReactNode } from 'react';
import { Button } from '@fashinza/fashinza-design-system';
import DetermineFileType from '../DetermineFileType';
import UploadButton from '../UploadButton';
import { FileControlsType, IFileOutputProps } from '../types';
import * as Styles from './styles';
import ModalHoc from '../../ModalHoc';
import CrossBlack from '../../iconography/CrossBlack';

interface IFilesModalProps {
  filesToDisplay: Array<IFileOutputProps>;
  onClose(): void;
  onDelete: ((linkToDelete: IFileOutputProps['link']) => void) | null;
  headerName?: ReactNode;
  canUpload?: boolean;
  controlsType?: FileControlsType;
  isMobileView?: boolean;
}

const FilesModal = ({
  filesToDisplay,
  onClose,
  headerName,
  onDelete,
  canUpload = false,
  controlsType,
  isMobileView = false,
}: IFilesModalProps) => (
  <ModalHoc onClose={onClose}>
    <Styles.Wrapper>
      {headerName && (
        <Styles.Header className="center">
          {headerName}
          <Styles.CrossWrapper className="v-center" onClick={onClose}>
            <CrossBlack height="20" width="20" />
          </Styles.CrossWrapper>
        </Styles.Header>
      )}
      <Styles.Body>
        {filesToDisplay.map((eachFile, index) => (
          <Styles.EachFileColumn
            isMobileView={isMobileView}
            key={`${eachFile.link}-${index}`}
          >
            <DetermineFileType
              key={`${eachFile.link}-${index}`}
              fileName={eachFile.name}
              fileLink={eachFile.link}
              onDelete={
                typeof onDelete !== 'undefined' && onDelete !== null
                  ? () => onDelete(eachFile.link)
                  : null
              }
              showText
              controlsType={controlsType || 'hover'}
              className="files-modal"
            />
          </Styles.EachFileColumn>
        ))}
        {canUpload && (
          <Styles.EachFileColumn isMobileView={isMobileView}>
            <UploadButton labelText="Add another file" />
          </Styles.EachFileColumn>
        )}
      </Styles.Body>
      <Styles.Footer>
        <Button variant="outline" sizeVariant="small" onClick={onClose}>
          Close
        </Button>
      </Styles.Footer>
    </Styles.Wrapper>
  </ModalHoc>
);

export default FilesModal;
