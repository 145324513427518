/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable max-lines */
import React, {
  ChangeEvent,
  Context,
  createContext,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { Spinner, Toast } from '@fashinza/fashinza-design-system';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { v1 as uuidv1 } from 'uuid';
import { AttachmentType, ObjectType } from '../../@types/defined';
import { isArray, isString } from '../../utils/assertion';
import Material from './Material';
import Trim from './Trim';
import ProductionWorksheet from './ProductionWorksheet';
import SewingCheckPoints from './SewingCheckPoints';
import PendingIssuesAndApprovals from './PendingIssuesAndApprovals';
import Others from './Others';
import ProductionPlanning from './ProductionPlanning';
import PreProductionPreparation from './PreProductionPreparation';
import CuttingCheckpoints from './CuttingCheckpoints';
import FinishingPackingCheckpoints from './FinishingPackingCheckpoints';
import ManageAttendees from './ManageAttendees';
import SampleDetails from './SampleDetails';
import SubmitForm from './SubmitForm';
import {
  INewAttendee,
  NewAttendeesType,
  OtherTestReportsType,
  PPMFormSectionsEnum,
  SectionAccordionType,
  SectionLoadersType,
} from './types';
import {
  INITIAL_SECTION_LOADERS,
  PPM_FORM_SECTION_MANDATORY_FIELDS,
  PPM_FORM_SECTION_HEADINGS,
  PRODUCTION_PLANNING_DEPENDENT_FIELDS,
  PPM_FORM_SECTION_FIELDS,
  PRODUCTION_PLANNING_INDEPENDENT_FIELDS,
  INITIAL_SECTION_ACCORDION_STATES,
} from './constants';
import { PPM_FORM_NAVIGATION_ITEMS } from '../constants';
import PPMFormMobileView from './MobileView';
import VerticalNavigationPanel from '../VerticalNavigationPanel';
import { RightSideContent } from '../styles';
import { useTypedSelector } from '../../hooks';
import { getPpmFormById, updatePpm } from '../../redux/actions/thenables';
import { IPreProductionMeetingForm } from '../../@types/ppm';
import { onChangePpmStaging, setBulkPpmStaging } from '../../redux/actions';
import { ApiResponseEnum } from '../../@types/api';
import { useIsMobile } from '../../hooks/useIsMobile';
import useBoolean from '../../hooks/useBoolean';
import { IAttendees } from '../../@types/base';
import { EMAIL_REGEX_IMPROVED } from '../../constants';

const EMAIL_REGEX = new RegExp(EMAIL_REGEX_IMPROVED);

interface IPPMFormContext {
  stagingValues: IPreProductionMeetingForm;
  getFieldValueFromStore: (
    field: keyof IPreProductionMeetingForm
  ) => string | null | any | boolean;
  onChange: (
    key: string,
    value: string | boolean | NewAttendeesType | OtherTestReportsType
  ) => void;
  onChangeRadio: (event: SyntheticEvent, booleanValue?: boolean) => void;
  onChangeText: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeDay: (field: string, date: Date | null) => void;
  onFileChange: (
    field: keyof IPreProductionMeetingForm,
    newFiles: Array<AttachmentType>,
    actionType: string
  ) => void;
  updatePpmForm: (section: PPMFormSectionsEnum, onSuccess?: () => void) => void;
  checkSectionCompleteness: (section: PPMFormSectionsEnum) => boolean;
  sectionLoaders: SectionLoadersType;
  inCompleteFields: Array<{ slug: string; displayText: string }>;
  attendeesContext: {
    newAttendees: NewAttendeesType;
    attendees: IAttendees;
    setAttendees: (attendees: IAttendees) => void;
    onChangeNewAttendee: (
      identifier: string,
      field: keyof INewAttendee,
      value: string
    ) => void;
    deleteNewAttendee: (identifier: string) => void;
    addNewAttendee: () => void;
    getIsAttendeesSubmitButtonDisabled: () => boolean;
    onChangeExistingAttendees: (data: {
      action: 'remove' | 'add';
      userid: string;
    }) => void;
  };
  sectionAccordions: SectionAccordionType;
  openAccordion: (section: PPMFormSectionsEnum) => void;
  closeAccordion: (section: PPMFormSectionsEnum) => void;
  toggleAccordion: (section: PPMFormSectionsEnum) => void;
}

export const PPMFormContext: Context<IPPMFormContext> = createContext(
  {} as IPPMFormContext
);

type PPMFormParamsType = {
  ppmId: string;
};

const PPMForm = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { ppmId } = useParams<PPMFormParamsType>();

  const [inCompleteFields, setInCompleteFields] = useState<
    Array<{ slug: string; displayText: string }>
  >([]);

  const stagingValues = useTypedSelector(store => store.quality.ppm_staging);

  const [isFetchingData, fetchingDataActions] = useBoolean(true);

  const [sectionLoaders, setSectionLoaders] = useState<SectionLoadersType>(
    INITIAL_SECTION_LOADERS
  );

  const [sectionAccordions, setSectionAccordions] =
    useState<SectionAccordionType>(INITIAL_SECTION_ACCORDION_STATES);

  function openAccordion(section: PPMFormSectionsEnum) {
    setSectionAccordions(prevData => {
      const newData = { ...prevData };
      for (const key of Object.keys(newData)) {
        newData[key as PPMFormSectionsEnum].isOpen = false;
      }
      newData[section].isOpen = true;
      return newData;
    });
  }

  function closeAccordion(section: PPMFormSectionsEnum) {
    setSectionAccordions(prevData => {
      const newData = { ...prevData };
      newData[section].isOpen = false;
      return newData;
    });
  }

  function toggleAccordion(section: PPMFormSectionsEnum) {
    if (sectionAccordions[section].isOpen) {
      closeAccordion(section);
      return;
    }

    openAccordion(section);
  }

  function getFieldValueFromStore(
    field: keyof IPreProductionMeetingForm
  ): string | null | any | boolean {
    return stagingValues[field];
  }

  function onChange(
    key: string,
    value: string | boolean | null | NewAttendeesType | OtherTestReportsType
  ) {
    dispatch(onChangePpmStaging({ key, value }));
  }

  function onChangeRadio(event: SyntheticEvent, booleanValue?: boolean) {
    const { name } = (event.currentTarget as HTMLInputElement).dataset;
    const { value } = event.target as HTMLInputElement;

    if (name && booleanValue !== null && booleanValue !== undefined) {
      onChange(name, booleanValue);
      return;
    }

    if (name) {
      onChange(name, value);
    }
  }

  function onChangeText(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const { name } = event.currentTarget.dataset;
    const { value } = event.target;
    if (name) {
      onChange(name, value);
    }
  }

  function onChangeDay(field: string, date: Date | null) {
    onChange(
      field,
      date ? String(date instanceof Date ? date.toISOString() : '') : null
    );
  }

  function onFileChange(
    field: keyof IPreProductionMeetingForm,
    newFiles: Array<AttachmentType>,
    actionType: string
  ) {
    if (actionType === 'success' || actionType === 'delete') {
      dispatch(onChangePpmStaging({ key: field, value: newFiles }));
    }
  }

  function getApiData(section: PPMFormSectionsEnum) {
    const fields = PPM_FORM_SECTION_FIELDS[section];

    const data: ObjectType = {};

    if (section === PPMFormSectionsEnum.SUBMIT_FORM) {
      data.is_submitted = true;
      return data;
    }

    fields.forEach((field: keyof IPreProductionMeetingForm) => {
      data[field] = stagingValues[field];
    });

    if (
      section === PPMFormSectionsEnum.MANAGE_ATTENDEES &&
      data.attendees_list
    ) {
      data.attendees = data.attendees_list.map(
        (attendee: ObjectType) => attendee.id
      );
      delete data.attendees_list;
    }

    // * HANDLING OF SPECIAL CASES
    if (data.new_attendees) {
      const allAttendees = data.new_attendees;

      if (
        allAttendees.length === 1 &&
        !allAttendees[0].email &&
        !allAttendees[0].mobile &&
        !allAttendees[0].name &&
        !allAttendees[0].role
      ) {
        delete data.new_attendees;
      }
    }

    return data;
  }

  async function updatePpmForm(
    section: PPMFormSectionsEnum,
    onSuccess?: () => void
  ) {
    if (!ppmId) {
      return;
    }

    setSectionLoaders({
      ...sectionLoaders,
      [section]: true,
    });

    const data = getApiData(section);
    const response = await updatePpm(ppmId, data);

    if (response.type === ApiResponseEnum.Success) {
      dispatch(setBulkPpmStaging(response.data));
      if (onSuccess) {
        onSuccess();
      }
      Toast.SUCCESS('Saved');
      if (section === PPMFormSectionsEnum.SUBMIT_FORM) {
        if (isMobile) {
          dispatch(replace(`/purchaseorder/${response.data.order_id}`));
        } else {
          dispatch(replace(`/quality?section=pre-production-meeting`));
        }
      }
    } else {
      Toast.ERROR(response.error);
    }

    setSectionLoaders({
      ...sectionLoaders,
      [section]: false,
    });

    const nextSection = sectionAccordions[section].next;

    if (nextSection) {
      openAccordion(nextSection);
    } else {
      closeAccordion(section);
    }
  }

  function getProductionPlanningFields(): Array<
    Partial<keyof IPreProductionMeetingForm>
  > {
    let fieldsToConsider = [...PRODUCTION_PLANNING_INDEPENDENT_FIELDS];

    for (const key of Object.keys(PRODUCTION_PLANNING_DEPENDENT_FIELDS)) {
      const val = getFieldValueFromStore(
        key as keyof IPreProductionMeetingForm
      );

      if (val) {
        fieldsToConsider = [
          ...fieldsToConsider,
          key as keyof IPreProductionMeetingForm,
        ];
      } else {
        fieldsToConsider = [
          ...fieldsToConsider,
          ...PRODUCTION_PLANNING_DEPENDENT_FIELDS[key],
        ];
      }
    }

    return fieldsToConsider;
  }

  function checkSectionCompleteness(section: PPMFormSectionsEnum): boolean {
    if (section === PPMFormSectionsEnum.SUBMIT_FORM) {
      return false;
    }

    let fields: Array<Partial<keyof IPreProductionMeetingForm>> = [];

    if (section === PPMFormSectionsEnum.PRODUCTION_PLANNING) {
      fields = getProductionPlanningFields();
    } else {
      fields = [...PPM_FORM_SECTION_FIELDS[section]];
    }

    for (const field of fields) {
      const val = getFieldValueFromStore(field);

      if (
        val === null ||
        (isString(val) && val.length === 0) ||
        (isArray(val) && val.length === 0)
      ) {
        return false;
      }
    }

    return true;
  }

  function checkOverallCompleteness() {
    setInCompleteFields([]);

    for (const key in PPM_FORM_SECTION_MANDATORY_FIELDS) {
      if (key !== PPMFormSectionsEnum.SUBMIT_FORM) {
        let fields: Array<Partial<keyof IPreProductionMeetingForm>> = [];

        if (key === PPMFormSectionsEnum.PRODUCTION_PLANNING) {
          fields = getProductionPlanningFields();
        } else {
          fields = [
            ...PPM_FORM_SECTION_MANDATORY_FIELDS[key as PPMFormSectionsEnum],
          ];
        }

        for (const field of fields) {
          const val = getFieldValueFromStore(field);

          if (val === null) {
            setInCompleteFields(prevValues => [
              ...prevValues,
              {
                slug: key,
                displayText: PPM_FORM_SECTION_HEADINGS[key],
              },
            ]);
            break;
          }
        }
      }
    }
  }

  const newAttendees: NewAttendeesType =
    getFieldValueFromStore('new_attendees');

  const [attendees, setAttendees] = useState<IAttendees>({
    brand_pocs: [],
    supplier_pocs: [],
    user_pocs: [],
  });

  function onChangeNewAttendee(
    identifier: string,
    field: keyof INewAttendee,
    value: string
  ) {
    const allAttendees = [...newAttendees];

    const indexToUpdate = allAttendees.findIndex(
      (item: INewAttendee) => item.identifier === identifier
    );

    if (indexToUpdate === -1) {
      return;
    }

    allAttendees[indexToUpdate][field] = value;

    onChange('new_attendees', [...allAttendees]);
  }

  function deleteNewAttendee(identifier: string) {
    const allAttendees = [...newAttendees];

    const indexToDelete = allAttendees.findIndex(
      (item: INewAttendee) => item.identifier === identifier
    );

    if (indexToDelete === -1) {
      return;
    }

    allAttendees.splice(indexToDelete, 1);

    onChange('new_attendees', [...allAttendees]);
  }

  function addNewAttendee() {
    const newAttendee: INewAttendee = {
      identifier: uuidv1(),
      email: null,
      mobile: null,
      name: null,
      role: null,
    };

    onChange('new_attendees', [...newAttendees, newAttendee]);
  }

  function getIsAttendeesSubmitButtonDisabled(): boolean {
    const allAttendees = [...newAttendees];

    if (allAttendees.length === 1) {
      if (
        (allAttendees[0].email === null ||
          allAttendees[0].email.length === 0) &&
        (allAttendees[0].mobile === null ||
          allAttendees[0].mobile.length === 0) &&
        (allAttendees[0].name === null || allAttendees[0].name.length === 0) &&
        (allAttendees[0].role === null || allAttendees[0].role.length === 0)
      ) {
        return false;
      }
    }

    for (const attendee of allAttendees) {
      if (
        !attendee.email ||
        !attendee.mobile ||
        !attendee.name ||
        !attendee.role
      ) {
        return true;
      }

      const isEmailValid =
        attendee.email !== null && EMAIL_REGEX.test(attendee.email);

      if (!isEmailValid) {
        return true;
      }
    }

    return false;
  }

  function onChangeExistingAttendees(data: {
    action: 'remove' | 'add';
    userid: string;
  }) {
    if (data.action === 'add') {
      let foundUser;

      // eslint-disable-next-line guard-for-in
      for (const group in attendees) {
        const pocs = [...attendees[group as keyof IAttendees]];
        const foundIndex = pocs.findIndex(
          poc => poc.id === Number(data.userid)
        );
        if (foundIndex !== -1) {
          foundUser = pocs[foundIndex];
          break;
        }
      }

      if (!foundUser) {
        return;
      }

      onChange('attendees_list', [
        ...getFieldValueFromStore('attendees_list'),
        foundUser,
      ]);
    }

    if (data.action === 'remove') {
      const existingAttendeesList = getFieldValueFromStore('attendees_list');

      if (!existingAttendeesList || !Array.isArray(existingAttendeesList)) {
        return;
      }

      const updatedAttendeesList = existingAttendeesList.filter(
        user => user.id !== Number(data.userid)
      );

      onChange('attendees_list', [...updatedAttendeesList]);
    }
  }

  useEffect(() => {
    async function getPpmForm(_ppmId: string) {
      fetchingDataActions.on();
      const response = await getPpmFormById(_ppmId);
      if (response.type === ApiResponseEnum.Success) {
        dispatch(setBulkPpmStaging(response.data));
      } else {
        Toast.ERROR(response.error);
        if (isMobile) {
          dispatch(replace(`/purchaseorder`));
        } else {
          dispatch(replace(`/quality?section=pre-production-meeting`));
        }
      }
      fetchingDataActions.off();
    }
    if (ppmId) {
      onChange('id', ppmId);
      getPpmForm(ppmId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ppmId]);

  if (!ppmId) {
    dispatch(replace(`/quality?section=pre-production-meeting`));
  }

  useEffect(() => {
    checkOverallCompleteness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stagingValues]);

  return (
    <PPMFormContext.Provider
      value={{
        stagingValues,
        getFieldValueFromStore,
        onChange,
        onChangeRadio,
        onChangeText,
        onChangeDay,
        onFileChange,
        updatePpmForm,
        checkSectionCompleteness,
        sectionLoaders,
        inCompleteFields,
        attendeesContext: {
          attendees,
          addNewAttendee,
          deleteNewAttendee,
          onChangeNewAttendee,
          getIsAttendeesSubmitButtonDisabled,
          newAttendees,
          onChangeExistingAttendees,
          setAttendees,
        },
        sectionAccordions,
        openAccordion,
        closeAccordion,
        toggleAccordion,
      }}
    >
      <div className="full-dimension">
        {isFetchingData ? (
          <Spinner />
        ) : (
          <>
            {isMobile ? (
              <PPMFormMobileView />
            ) : (
              <div className="d-flex">
                <VerticalNavigationPanel
                  navigationItems={PPM_FORM_NAVIGATION_ITEMS}
                  changeRoutesOnTabChange={false}
                />
                <RightSideContent className="full-flex-grow has-scroll">
                  <Material />
                  <Trim />
                  <SampleDetails />
                  <ProductionWorksheet />
                  <ProductionPlanning />
                  <PreProductionPreparation />
                  <CuttingCheckpoints />
                  <SewingCheckPoints />
                  <FinishingPackingCheckpoints />
                  <PendingIssuesAndApprovals />
                  <Others />
                  <ManageAttendees />
                  <SubmitForm />
                </RightSideContent>
              </div>
            )}
          </>
        )}
      </div>
    </PPMFormContext.Provider>
  );
};

export default PPMForm;
