import React, { ChangeEvent, useContext, useEffect } from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { GrAdd } from 'react-icons/gr';
import { Button, Toast } from '@fashinza/fashinza-design-system';
import Accordion from '../accessoryComponents/Accordion';
import { PPMFormContext } from '../index';
import { INewAttendee, PPMFormSectionsEnum } from '../types';
import * as Styles from './styles';
import * as CommonStyles from '../accessoryComponents/styles';
import {
  NEW_ATTENDEES_ROLE_OPTIONS,
  PPM_FORM_SECTION_HEADINGS,
} from '../constants';
import { getPpmAttendees } from '../../../redux/actions/thenables';
import { ApiResponseEnum } from '../../../@types/api';
import SimpleDropdown from '../../../shared/SimpleDropdown/simpleDropdown';
import TextInputWithLabel from '../../../shared/TextInputWithLabel/textInputWithLabel';
import ManageCollaborators from '../../../shared/ManageCollaborators/ManageCollaborators';

const ManageAttendees = () => {
  const {
    getFieldValueFromStore,
    updatePpmForm,
    attendeesContext: {
      attendees,
      newAttendees,
      setAttendees,
      addNewAttendee,
      deleteNewAttendee,
      getIsAttendeesSubmitButtonDisabled,
      onChangeExistingAttendees,
      onChangeNewAttendee,
    },
  } = useContext(PPMFormContext);
  const ppmId = getFieldValueFromStore('id');

  useEffect(() => {
    if (!ppmId) return;
    async function getAttendees() {
      const response = await getPpmAttendees(ppmId);
      if (response.type === ApiResponseEnum.Success) {
        setAttendees(response.data);
      }
    }
    getAttendees();
  }, [ppmId]);

  useEffect(() => {
    if (newAttendees.length === 0) {
      addNewAttendee();
    }
  }, []);

  return (
    <Accordion
      header={PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.MANAGE_ATTENDEES]}
      section={PPMFormSectionsEnum.MANAGE_ATTENDEES}
      footer={
        <Button
          sizeVariant="large"
          variant="outline"
          onClick={
            getIsAttendeesSubmitButtonDisabled()
              ? () => Toast.WARN('Fill all the fields correctly')
              : () => updatePpmForm(PPMFormSectionsEnum.MANAGE_ATTENDEES)
          }
        >
          Save & Proceed
        </Button>
      }
    >
      <div>
        <Styles.Section className="d-flex">
          <CommonStyles.Label className="flex-start-center">
            Add Existing Attendees
          </CommonStyles.Label>
          <ManageCollaborators
            id={String(getFieldValueFromStore('id'))}
            is_edit_collaborators_allowed
            collaborators={getFieldValueFromStore('attendees_list') ?? []}
            users_list={attendees.user_pocs}
            onClickUser={onChangeExistingAttendees}
            brand_pocs={attendees.brand_pocs}
            supplier_pocs={attendees.supplier_pocs}
            fontSizeInitials={12}
            ovalwidth={32}
            availwidth={500}
          />
        </Styles.Section>
        <Styles.Section>
          <Styles.NewAttendeesLabel>New Attendees</Styles.NewAttendeesLabel>
          {newAttendees.map((item: INewAttendee) => (
            <Styles.NewAttendeesItem key={item.identifier}>
              <Styles.NewAttendeesItemUpper>
                <TextInputWithLabel
                  datalabel=""
                  dataname="name"
                  placeholder="Name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeNewAttendee(item.identifier, 'name', e.target.value)
                  }
                  value={item.name}
                  shouldAutoFocus={false}
                />
                <TextInputWithLabel
                  datalabel=""
                  dataname="email"
                  placeholder="Email"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeNewAttendee(
                      item.identifier,
                      'email',
                      e.target.value
                    )
                  }
                  value={item.email}
                  shouldAutoFocus={false}
                />
                <TextInputWithLabel
                  isNumber
                  datalabel=""
                  dataname="mobile"
                  placeholder="Mobile No."
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (Number.isNaN(Number(e.target.value))) return;

                    onChangeNewAttendee(
                      item.identifier,
                      'mobile',
                      e.target.value
                    );
                  }}
                  value={item.mobile}
                  shouldAutoFocus={false}
                />

                <SimpleDropdown
                  labelPlaceholder="Role"
                  options={NEW_ATTENDEES_ROLE_OPTIONS}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChangeNewAttendee(item.identifier, 'role', e.target.value)
                  }
                  value={item.role}
                  dataname="role"
                  dropdownClass="dropdownClass"
                  dropdownPlaceholderClass="dropdownPlaceholderClass"
                  dropdownBoxClass="dropdownBoxClass"
                  dropDownItemClass="dropDownItemClass"
                  dropDownItemActiveClass="dropDownItemActiveClass"
                />
              </Styles.NewAttendeesItemUpper>
              {newAttendees.length > 1 && (
                <CommonStyles.DeleteButton
                  onClick={() => deleteNewAttendee(item.identifier)}
                >
                  <BsTrashFill size={12} color="var(--lynch)" />
                </CommonStyles.DeleteButton>
              )}
            </Styles.NewAttendeesItem>
          ))}
          <Styles.AddNewButton
            className="flex-start-center"
            onClick={addNewAttendee}
          >
            <GrAdd size={12} color="var(--lynch)" />
            Add New Attendee
          </Styles.AddNewButton>
        </Styles.Section>
      </div>
    </Accordion>
  );
};

export default ManageAttendees;
