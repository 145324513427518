import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 24px;
  /* border-bottom: 1px solid var(--athens-gray); */
`;

export const Left = styled.div`
  gap: 12px;
`;

export const Right = styled.div`
  gap: 12px;
`;

export const Pill = styled.span<{ isActive: boolean }>`
  border-radius: 40px;
  border: 1px solid
    ${props => (props.isActive ? 'var(--all-ports)' : 'var(--athens-gray)')};
  color: ${props => (props.isActive ? 'var(--white)' : 'var(--rhino)')};
  background: ${props =>
    props.isActive ? 'var(--all-ports)' : 'var(--white)'};
  font-weight: 500;
  font-size: 14px;
  padding: 0 12px;
  height: 32px;
`;

export const DateFilterItem = styled.div<{ isSelected: boolean }>`
  width: 100%;
  color: ${props => (props.isSelected ? 'var(--all-ports)' : 'var(--rhino)')};
  font-weight: ${props => (props.isSelected ? 600 : 400)};
`;

export const EntityFilterTriggerWrapper = styled.div`
  [data-type='red-dot'] {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  button {
    width: 40px;
    padding: 0;
  }
`;
