import React, { ChangeEvent } from 'react';
import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { Toast } from '@fashinza/fashinza-design-system';
import { FiAlertCircle, FiCheck, FiX } from 'react-icons/fi';
import config from '../../../config';
import { openInNewTab } from '../../../utils';
import { useTypedSelector } from '../../../hooks';
import { updatePpmResult } from '../../../redux/actions/thenables';
import * as Styles from '../styles';
import { onChangePpmStaging } from '../../../redux/actions';
import { getAuthToken } from '../../../services/auth';
import useBoolean from '../../../hooks/useBoolean';
import { ApiResponseEnum } from '../../../@types/api';

type ReviewPPMParamsType = {
  ppmId: string;
};

const ReviewPPM = () => {
  const dispatch = useDispatch();

  const { ppmId } = useParams<ReviewPPMParamsType>();

  const [isIframeLoading, iframeLoadingActions] = useBoolean(true);

  const reason = useTypedSelector(store => store.quality.ppm_staging.reason);

  function onChangeReason(event: ChangeEvent<HTMLTextAreaElement>) {
    dispatch(onChangePpmStaging({ key: 'reason', value: event.target.value }));
  }

  async function reviewPpmForm(action: 'accepted' | 'rejected') {
    if (!ppmId) {
      return;
    }

    const data = {
      reason,
      result: action,
    };

    const response = await updatePpmResult(ppmId, data);

    if (response.type === ApiResponseEnum.Success) {
      Toast.SUCCESS(`PPM successfully ${action}`);
      if (response.data.pdf && action === 'accepted') {
        openInNewTab(response.data.pdf);
        dispatch(replace(`/quality?section=pre-production-meeting`));
      }
    } else {
      Toast.ERROR(response.error);
    }
  }

  if (!ppmId) {
    dispatch(replace(`/quality?section=pre-production-meeting`));
  }

  const query = {
    Token: getAuthToken(),
  };

  return (
    <Styles.Wrapper className="d-flex full-dimension">
      <Styles.Iframe
        title="ppm-report-preview"
        style={{
          visibility: isIframeLoading ? 'hidden' : 'visible',
        }}
        className=""
        frameBorder="0"
        onLoad={() => {
          iframeLoadingActions.off();
        }}
        src={`${
          config.SERVER_URL_FULL
        }/quality/ppm_form/${ppmId}/report/?${qs.stringify(query)}`}
      />
      <Styles.RightPanel className="v-d-flex">
        <Styles.Warning className="d-flex">
          <Styles.WarningSection>
            <FiAlertCircle size={14} color="var(--rhino)" />
          </Styles.WarningSection>
          <Styles.WarningSection className="v-d-flex">
            <Styles.WarningHeading>
              This action is irreversible
            </Styles.WarningHeading>
            <Styles.WarningText>
              Make sure you are approving/rejecting correctly
            </Styles.WarningText>
          </Styles.WarningSection>
        </Styles.Warning>
        <Styles.TextArea
          placeholder="Reason (if rejected)"
          value={reason ?? ''}
          onChange={onChangeReason}
        />
        <Styles.AcceptButton
          className="center"
          onClick={() => reviewPpmForm('accepted')}
        >
          Approve <FiCheck size={20} color="var(--white)" />
        </Styles.AcceptButton>
        <Styles.RejectButton
          className="center"
          onClick={() => reviewPpmForm('rejected')}
        >
          Reject <FiX size={20} color="var(--red-dirt)" />
        </Styles.RejectButton>
      </Styles.RightPanel>
    </Styles.Wrapper>
  );
};

export default ReviewPPM;
