import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";

export const Wrapper = styled.div`
  padding: 12px 0;

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TextAreaWrapper = styled.div`
  width: 320px;

  ${device.mediumViewport} {
    width: 100%;
  }
`;

export const TextArea = styled.textarea`
  border: 1px solid var(--mischka);
  border-radius: 4px;
  min-height: 100px;
  max-height: 200px;
  max-width: 100%;
  min-width: 100%;
  overflow: auto;
  padding: 16px;

  &:focus {
    border: 1px solid var(--mischka);
  }
`;
