import React from 'react';
import CellContent from './CellContent';
import HeaderCell from './HeaderCell';
import { SECTION_WISE_FIELDS, getMultiGridSettings } from '../../constants';
import { useTypedSelector } from '../../../hooks';
import { IGridCellNode } from '../../../shared/TableLayout/types';
import GridCell from '../../../shared/TableLayout/GridCell';

interface ITableCellProps {
  listIds: Array<number>;
  gridProps: IGridCellNode;
  lastItemRef: (node: any) => void;
}

const TableCell = ({ listIds, gridProps, lastItemRef }: ITableCellProps) => {
  const { columnIndex, rowIndex } = gridProps;

  const listFields = useTypedSelector(store => store.preferences.qualityList);
  const section = useTypedSelector(store => store.quality.dashboard.section);
  const data = useTypedSelector(
    store => store.quality.tabularData.data[listIds[rowIndex - 1]]
  );

  if (!section) {
    return null;
  }

  const field = SECTION_WISE_FIELDS[section][listFields[columnIndex]];

  if (rowIndex === 0) {
    return (
      <GridCell
        gridProps={gridProps}
        totalRowsCount={listIds.length}
        totalColumnsCount={listFields.length}
        fixedColumnCount={getMultiGridSettings(section).fixedColumnCount}
        cellNode={<HeaderCell columnIndex={columnIndex} />}
      />
    );
  }

  if (data && field) {
    let cellNode = <CellContent data={data} identifier={field.value} />;

    if (columnIndex === 0 && rowIndex === listIds.length - 3) {
      cellNode = (
        <div ref={lastItemRef}>
          <CellContent data={data} identifier={field.value} />
        </div>
      );
    }

    return (
      <GridCell
        gridProps={gridProps}
        totalRowsCount={listIds.length}
        totalColumnsCount={listFields.length}
        fixedColumnCount={getMultiGridSettings(section).fixedColumnCount}
        cellNode={cellNode}
      />
    );
  }

  return null;
};

export default TableCell;
