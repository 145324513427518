import React from "react";
import { IconPropsType } from "../types";

interface IVideoFileIconProps extends IconPropsType {}

const VideoFileIcon = (props: IVideoFileIconProps) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H36C38.2091 0.5 40 2.29086 40 4.5V36.5C40 38.7091 38.2091 40.5 36 40.5H4C1.79086 40.5 0 38.7091 0 36.5V4.5Z"
      fill="var(--early-dawn)"
    />
    <path
      d="M23.333 19.5L29.1663 16.3333V24.6666L23.333 21.5V19.5Z"
      fill="var(--kournikova)"
      stroke="var(--web-orange)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.6663 14.6667H12.4997C11.5792 14.6667 10.833 15.4129 10.833 16.3334V24.6667C10.833 25.5872 11.5792 26.3334 12.4997 26.3334H21.6663C22.5868 26.3334 23.333 25.5872 23.333 24.6667V16.3334C23.333 15.4129 22.5868 14.6667 21.6663 14.6667Z"
      fill="var(--kournikova)"
      stroke="var(--web-orange)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VideoFileIcon;
