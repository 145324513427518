import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Tooltip from 'react-tooltip-lite';
import ICONOGRAPHY from '../iconography';
import { ObjectType } from '../../@types/defined';
import { buildUserName, getNameInitials } from '../../utils/strings';
import * as Styles from './styles';

export const USER_BG = {
  BRAND: '#B3D4FF',
  SUPPLIER: '#C0B6F2',
  INTERNAL: '#FFBDAD',
};

export const matchquery = (search: string, list: Array<ICollaborator>) => {
  if (search) {
    const newlist: Array<ICollaborator> = [];
    for (let i = 0; i < list.length; i += 1) {
      const option = { ...list[i] };
      const name = (option.first_name || '') + (option.last_name || '');
      const email = option.email || '';
      if (
        name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        email.toLowerCase().indexOf(search.toLowerCase()) > -1
      ) {
        newlist.push(option);
      }
    }
    return newlist;
  }
  return list;
};

const { close: CloseIcon } = ICONOGRAPHY;

const selectedSvg = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="check copy 3">
      <circle
        id="Oval"
        cx="7"
        cy="7"
        r="6"
        fill="var(--white)"
        stroke="var(--ocean-green)"
        strokeWidth="1.6"
      />
      <path
        id="Path"
        d="M9.15472 4.91608C8.0956 5.97749 7.13082 7.02463 6.10115 8.0701L4.7987 7.0288C4.67908 6.93311 4.51749 6.90849 4.3748 6.96424C4.23212 7.01999 4.13001 7.14762 4.10695 7.29906C4.08388 7.4505 4.14336 7.60275 4.26298 7.69844L5.87013 8.98416C6.04143 9.12114 6.2885 9.10669 6.44267 8.95068C7.59434 7.79653 8.63006 6.65861 9.7641 5.5221C9.89202 5.39858 9.93038 5.20889 9.86051 5.04537C9.79064 4.88184 9.62705 4.77844 9.44937 4.7855C9.3379 4.78904 9.23221 4.83588 9.15472 4.91608Z"
        fill="var(--ocean-green)"
        stroke="var(--ocean-green)"
        strokeWidth="0.8"
      />
    </g>
  </svg>
);

interface ICollaborator {
  country_code: string;
  email: string;
  first_name: string;
  id: number;
  is_admin: boolean;
  is_brand: boolean;
  is_supplier: boolean;
  last_name: string;
  phone_number: string;
  username: string;
}

const RenderBlock = ({
  heading = '',
  userList = [],
  onAdd,
  tagUsers,
}: {
  heading: string;
  userList: Array<ICollaborator>;
  onAdd: (event: any) => void;
  tagUsers: Array<ICollaborator>;
}) => (
  <div className="taggedCollaborators-user-block-item">
    <div className="taggedCollaborators-user-block-item-head">{heading}</div>
    <div className="taggedCollaborators-user-block-item-content">
      {userList.length === 0 && (
        <div className="user-block-empty">No user found</div>
      )}
      {userList.length > 0 &&
        userList.map(x => {
          let namecardClass = 'user-block-item';
          const isSelected = tagUsers.filter(y => y.id == x.id).length;
          if (isSelected) {
            namecardClass += ' selected';
          }

          let bgColor = '';
          if (x.is_brand) {
            bgColor = USER_BG.BRAND;
          } else if (x.is_supplier) {
            bgColor = USER_BG.SUPPLIER;
          } else {
            bgColor = USER_BG.INTERNAL;
          }

          const reqProps = {
            onClick: onAdd,
            'data-name': x.id,
            'data-action': isSelected ? 'remove' : 'add',
          };
          return (
            <Tooltip
              key={x.id}
              content={buildUserName(x, 'Anonymous User')}
              direction="top"
              arrowSize={5}
              className="tooltip-white"
            >
              {!!isSelected && (
                <div className="user-block-item-selected" {...reqProps}>
                  {selectedSvg}
                </div>
              )}
              <div
                className={namecardClass}
                {...reqProps}
                style={{ backgroundColor: bgColor }}
              >
                {getNameInitials(`${x.first_name} ${x.last_name}`)}
              </div>
            </Tooltip>
          );
        })}
    </div>
  </div>
);

interface ITaggedCollaboratorsProps {
  users: Array<ICollaborator>;
  brand_pocs: Array<ICollaborator>;
  supplier_pocs: Array<ICollaborator>;
  suggested_users: Array<ICollaborator>;
  tag_users: Array<ICollaborator>;
  onAdd: (event: any) => void;
  onClose: () => void;
  bigHeading: boolean;
}

const TaggedCollaborators = ({
  users: initialUsers = [],
  brand_pocs: initialBrandPocs = [],
  supplier_pocs: initialSupplierPocs = [],
  suggested_users: initialSuggestedUsers = [],
  tag_users: initialTagUsers = [],
  onAdd,
  onClose,
  bigHeading,
}: ITaggedCollaboratorsProps) => {
  const inputRef = useRef<null | HTMLInputElement>(null);

  const [search, setSearch] = useState('');
  const [internalUsers, setInternalUsers] = useState<Array<ICollaborator>>([]);
  const [brandPocs, setBrandPocs] = useState<Array<ICollaborator>>([]);
  const [supplierPocs, setSupplierPocs] = useState<Array<ICollaborator>>([]);
  const [suggestedUsers, setSuggestedUsers] = useState<Array<ICollaborator>>(
    []
  );
  const [tagUsers, setTagUsers] = useState<Array<ICollaborator>>([]);

  const setInitialUsers = () => {
    setTagUsers([...initialTagUsers]);
    setSuggestedUsers([...initialSuggestedUsers]);
    setBrandPocs([...initialBrandPocs]);
    setSupplierPocs([...initialSupplierPocs]);
    setInternalUsers([...initialUsers]);
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
    setInitialUsers();
  }, []);

  useEffect(() => {
    setTagUsers([...initialTagUsers]);
  }, [initialTagUsers]);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearch = event.target.value || '';
    setSearch(newSearch);
    setSuggestedUsers(matchquery(newSearch, initialSuggestedUsers));
    setBrandPocs(matchquery(newSearch, initialBrandPocs));
    setSupplierPocs(matchquery(newSearch, initialSupplierPocs));
    setInternalUsers(matchquery(newSearch, initialUsers));
  };

  const styleProp: ObjectType = {};
  if (bigHeading) {
    styleProp.fontSize = '20px';
  }

  const basicProps = {
    tagUsers,
    onAdd,
  };

  return (
    <Styles.Wrapper>
      <Styles.CloseWrapper onClick={onClose}>
        <CloseIcon size={18} />
      </Styles.CloseWrapper>

      <Styles.HeaderWrapper style={styleProp}>Users</Styles.HeaderWrapper>

      <Styles.InputWrapper>
        <input
          ref={inputRef}
          type="text"
          placeholder="Search users"
          onChange={onChangeInput}
          value={search}
        />
      </Styles.InputWrapper>

      <Styles.Body>
        <Styles.UserBlockWrapper>
          {tagUsers.length > 0 && (
            <RenderBlock
              heading="TAG USERS"
              userList={tagUsers}
              {...basicProps}
            />
          )}
          {suggestedUsers.length > 0 && (
            <RenderBlock
              heading="SUGGESTED USERS"
              userList={suggestedUsers}
              {...basicProps}
            />
          )}
          {brandPocs.length > 0 && (
            <RenderBlock
              heading="BRAND USERS"
              userList={brandPocs}
              {...basicProps}
            />
          )}
          {supplierPocs.length > 0 && (
            <RenderBlock
              heading="SUPPLIERS USERS"
              userList={supplierPocs}
              {...basicProps}
            />
          )}
          {internalUsers.length > 0 && (
            <RenderBlock
              heading="INTERNAL USERS"
              userList={internalUsers}
              {...basicProps}
            />
          )}
          {!tagUsers.length &&
            !suggestedUsers.length &&
            !brandPocs.length &&
            !supplierPocs.length &&
            !internalUsers.length && (
              <div className="taggedCollaborators-empty-block">
                No user found
              </div>
            )}
        </Styles.UserBlockWrapper>
      </Styles.Body>
    </Styles.Wrapper>
  );
};

export default TaggedCollaborators;
