import {
  REQUIREMENT_MULTIPLE_CHOICE_OPTIONS,
  AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  APPAREL_TEST_OPTIONS,
  PASS_FAIL_CHOICE_OPTIONS,
  DONE_NOT_DONE_CHOICE_OPTIONS,
} from '../constants';
import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'is_fabric_inspection_required',
    attachmentSlug: 'fabric_inspection_document',
    label: 'Fabric Inspection',
    inputType: InputTypeEnum.COMBINED_RADIO_FILE,
    options: DONE_NOT_DONE_CHOICE_OPTIONS,
  },
  {
    id: 'is_fabric_relaxation_required',
    attachmentSlug: 'fabric_relaxation_document',
    label: 'Fabric Relaxation',
    inputType: InputTypeEnum.COMBINED_RADIO_FILE,
    options: REQUIREMENT_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'is_shadelot_identification_approval_required',
    attachmentSlug: 'shadelot_identification_approval_document',
    label: 'Shade Lot Identification & Approval',
    inputType: InputTypeEnum.COMBINED_RADIO_FILE,
    options: REQUIREMENT_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'is_fabric_shrinkage_check_required',
    attachmentSlug: 'fabric_shrinkage_check_document',
    label: 'Fabric Shrinkage Check',
    inputType: InputTypeEnum.COMBINED_RADIO_FILE,
    options: REQUIREMENT_MULTIPLE_CHOICE_OPTIONS,
  },
  {
    id: 'is_approved_techpack_required',
    attachmentSlug: 'approved_techpack_document',
    label: 'Approved Techpack',
    inputType: InputTypeEnum.COMBINED_RADIO_FILE,
    options: AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  },

  {
    id: 'is_fpt_report_required',
    attachmentSlug: 'fpt_report_document',
    label: 'FPT Report',
    inputType: InputTypeEnum.COMBINED_RADIO_FILE,
    options: PASS_FAIL_CHOICE_OPTIONS,
  },
  {
    id: 'is_gpt_report_required',
    attachmentSlug: 'gpt_report_document',
    label: 'GPT Report',
    inputType: InputTypeEnum.COMBINED_RADIO_FILE,
    options: PASS_FAIL_CHOICE_OPTIONS,
  },
  {
    id: 'other_preproduction_planning',
    label: 'Other Test Reports',
    inputType: InputTypeEnum.COMBINED_DROPDOWN_FILE,
    options: APPAREL_TEST_OPTIONS,
  },
  {
    id: 'is_trim_card_approval_required',
    attachmentSlug: 'trim_card_approval_document',
    label: 'Trim Card Approval',
    inputType: InputTypeEnum.COMBINED_RADIO_FILE,
    options: AVAILABILITY_MULTIPLE_CHOICE_OPTIONS,
  },
];
