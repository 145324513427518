import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
`;

export const WrapperOverlay = styled.div`
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  background-color: rgba(25, 25, 25, 0.5);
  &.transparent {
    background-color: rgba(255, 255, 255, 0);
  }
`;

export const ChildrenHocWrapper = styled(motion.div)`
  position: relative;
  z-index: 10;
  width: 60vw;
  max-height: 90vh;
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 12px 30px 0 rgba(9, 12, 11, 0.2);

  > div {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const HeaderWrapper = styled.div`
  > div {
    &:first-child {
      min-height: 64px;
    }
    &:last-child {
    }
  }
`;

export const HeaderLeft = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: var(--rhino);
`;

export const HeaderRight = styled.div`
  right: -8px;
  top: 14px;
`;

export const CloseButton = styled.button`
  width: 48px;
  height: 48px;
  padding: 0;
  margin: 0;
  &:hover {
    background: var(--wash-me);
    border-radius: 50%;
  }
`;

export const FooterWrapper = styled.div`
  min-height: 64px;
  box-shadow: 0px -4px 12px rgba(44, 53, 86, 0.05);
`;

export const FooterLeft = styled.div``;

export const FooterRight = styled.div``;
export const ContentWrapper = styled.div`
  overflow: auto;
`;
