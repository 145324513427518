import React from 'react';
import { useRowSelect, useTable, Column, useFlexLayout } from 'react-table';
import EmptyView from './EmptyView';
import * as Styles from './tableStyles';
import * as CommonStyles from './styles';

interface ITableProps {
  data: Array<any>;
  columns: Array<Column>;
  isLoading: boolean;
  className?: string;
  emptyViewText?: string;
  lastItemRef?: (node: HTMLTableRowElement) => void;
}

const Table = ({
  className = '',
  columns,
  data,
  isLoading = false,
  emptyViewText = 'No data present',
  lastItemRef,
}: ITableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useFlexLayout, useRowSelect);

  const isTableEmpty = rows.length === 0;

  if (isTableEmpty && !isLoading) {
    return <EmptyView text={emptyViewText} />;
  }

  return (
    <Styles.Table {...getTableProps()} className={`${className} full-width`}>
      <thead>
        {headerGroups.map(headerGroup => (
          <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()} key={column.id}>
                {column.render('Header')}
              </th>
            ))}
          </Styles.THeadRow>
        ))}
      </thead>
      {isLoading && isTableEmpty ? (
        <Styles.SpinnerContainer isLarge className="center">
          <CommonStyles.Spinner size={40} fill="var(--watermelon)" />
        </Styles.SpinnerContainer>
      ) : (
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            if (index === rows.length - 4) {
              // third last row of the table; putting intersection observer here
              return (
                <Styles.TableRow {...row.getRowProps()} ref={lastItemRef}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </Styles.TableRow>
              );
            }
            return (
              <Styles.TableRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </Styles.TableRow>
            );
          })}
        </tbody>
      )}

      {isLoading && !isTableEmpty && (
        <Styles.SpinnerContainer isLarge={false} className="center">
          <CommonStyles.Spinner size={40} fill="var(--watermelon)" />
        </Styles.SpinnerContainer>
      )}
    </Styles.Table>
  );
};

export default Table;
