import React from "react";
import { IconPropsType } from "../types";

interface IPdfFileIconProps extends IconPropsType {}

const PdfFileIcon = (props: IPdfFileIconProps) => (
  <svg
    width={40}
    height={41}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.5} width={40} height={40} rx={4} fill="var(--bubbles)" />
    <path
      d="M12.8929 23.9342C12.0779 23.0937 12.9597 21.9386 15.3527 20.7121L16.8584 19.9404L17.445 18.6167C17.7676 17.8887 18.2491 16.7008 18.515 15.9769L18.9984 14.6608L18.6653 13.6868C18.2557 12.4892 18.1096 10.6896 18.3695 10.0424C18.7215 9.16587 19.8747 9.2558 20.3312 10.1954C20.6877 10.9293 20.6513 12.2582 20.2286 13.9342L19.8821 15.3083L20.1873 15.8426C20.3552 16.1365 20.8455 16.8344 21.2769 17.3934L22.0878 18.4336L23.0967 18.2978C26.302 17.8664 27.3998 18.5996 27.3998 19.6508C27.3998 20.9775 24.883 21.087 22.7695 19.556C22.294 19.2115 21.9674 18.8693 21.9674 18.8693C21.9674 18.8693 20.6434 19.1473 19.9915 19.3285C19.3186 19.5156 18.9829 19.6327 17.9972 19.9759C17.9972 19.9759 17.6513 20.4939 17.426 20.8704C16.5876 22.2714 15.6086 23.4324 14.9095 23.855C14.1267 24.3281 13.3061 24.3603 12.8929 23.9342ZM14.1719 23.463C14.63 23.171 15.5573 22.0399 16.1991 20.9901L16.459 20.5651L15.2757 21.1787C13.4481 22.1264 12.6121 23.0196 13.0468 23.5599C13.2911 23.8634 13.5832 23.8382 14.1719 23.463ZM26.0431 20.0263C26.4912 19.7026 26.4261 19.0503 25.9195 18.7873C25.5253 18.5827 25.2075 18.5406 24.1834 18.5562C23.554 18.6005 22.542 18.7313 22.3705 18.7711C22.3705 18.7711 22.9264 19.1674 23.1733 19.313C23.5019 19.5066 24.3004 19.8659 24.8833 20.0499C25.4584 20.2315 25.791 20.2123 26.0431 20.0263V20.0263ZM21.2698 17.9802C20.9987 17.6862 20.5375 17.0726 20.2451 16.6166C19.8627 16.0993 19.6709 15.7344 19.6709 15.7344C19.6709 15.7344 19.3914 16.6617 19.1621 17.2196L18.4467 19.0435L18.2392 19.4572C18.2392 19.4572 19.342 19.0843 19.9031 18.9331C20.4973 18.773 21.7034 18.5284 21.7034 18.5284L21.2698 17.9802ZM19.7316 11.6181C19.8008 11.0194 19.8301 10.4217 19.6436 10.1202C19.1263 9.53697 18.502 10.0233 18.6077 11.41C18.6433 11.8764 18.7557 12.6738 18.9059 13.1653L19.179 14.0589L19.3712 13.3859C19.477 13.0157 19.6391 12.2202 19.7316 11.6181Z"
      fill="var(--pacific-blue)"
    />
    <path
      d="M14.2783 27.8266H15.6014C16.0241 27.8266 16.3673 27.8683 16.6308 27.9518C16.8944 28.0325 17.1162 28.2142 17.2963 28.4968C17.4763 28.7767 17.5664 29.1145 17.5664 29.5102C17.5664 29.8736 17.4933 30.1858 17.3472 30.4469C17.201 30.708 17.004 30.8964 16.7561 31.0121C16.5108 31.1279 16.1324 31.1858 15.6209 31.1858H15.163V33.3377H14.2783V27.8266ZM15.163 28.5332V30.455H15.6014C15.9928 30.455 16.2629 30.3796 16.4116 30.2289C16.563 30.0781 16.6387 29.8332 16.6387 29.4941C16.6387 29.241 16.5891 29.0365 16.4899 28.8804C16.3908 28.7216 16.2812 28.6247 16.1611 28.5897C16.0437 28.552 15.8571 28.5331 15.6014 28.5331L15.163 28.5332Z"
      fill="var(--pacific-blue)"
    />
    <path
      d="M18.2432 27.8266H19.4449C20.0268 27.8266 20.4913 27.9329 20.8384 28.1455C21.188 28.3582 21.4516 28.6745 21.6291 29.0944C21.8091 29.5142 21.8991 29.9799 21.8991 30.4913C21.8991 31.0296 21.8183 31.5101 21.6565 31.9327C21.4973 32.3526 21.252 32.6917 20.9206 32.9501C20.5918 33.2085 20.122 33.3377 19.5114 33.3377H18.2432V27.8266ZM19.1278 28.5574V32.6069H19.4957C20.0098 32.6069 20.383 32.4239 20.6152 32.0578C20.8475 31.6891 20.9636 31.1979 20.9636 30.5842C20.9636 29.233 20.4743 28.5574 19.4957 28.5574H19.1278Z"
      fill="var(--pacific-blue)"
    />
    <path
      d="M22.75 27.8266H25.717V28.5574H23.6346V30.2047H25.3021V30.9354H23.6346V33.3377H22.75V27.8266Z"
      fill="var(--pacific-blue)"
    />
  </svg>
);

export default PdfFileIcon;
