import { FaSpinner } from 'react-icons/fa';
import styled from 'styled-components';

export const Table = styled.table`
  border: 1px solid var(--athens-gray);
  border-collapse: collapse;
  background-color: var(--white);
  // min-height: 70vh;
`;

export const THeadRow = styled.tr`
  padding: 16px 24px;
  border-bottom: 1px solid var(--athens-gray);
`;

export const SpinnerContainer = styled.div<{ isLarge: boolean }>`
  height: ${props => (props.isLarge ? '50vh' : '20vh')};
  width: 100%;
`;

export const Spinner = styled(FaSpinner)`
  animation: rotate360 1s linear infinite;
  color: var(--watermelon);
`;

export const TableRow = styled.tr`
  padding: 14px 24px;
  &:hover {
    background-color: var(--wash-me);
  }
`;
