import React, {
  ChangeEvent,
  DependencyList,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import ICONOGRAPHY from '../iconography';
import useDebounce from '../../hooks/useDebounce';
import { SAME_WIDTH_MODIFIER } from '../../constants/popper';
import useBoolean from '../../hooks/useBoolean';
import { useCustomPopper } from '../../hooks/useCustomPopper';
import ClickOutside from '../ClickOutside';
import { swallowEvent } from '../../utils';
import TextInput from '../textInput/textInput';
import { ObjectType } from '../../@types/defined';
import { isUndefined } from '../../utils/assertion';
import * as Styles from './styles';

const { add: AddIcon } = ICONOGRAPHY;

const SAME_WIDTH_POPPER_OPTIONS = Object.freeze({
  strategy: 'fixed',
  placement: 'bottom-start',
  modifiers: [SAME_WIDTH_MODIFIER],
});

export interface IOption {
  id: string | number;
  name: ReactNode;
}

export interface IDropdownBehavior {
  addOption?: {
    text: ReactNode;
    handleClick?: (searchedText: string) => void;
  };
}

interface IDropdownWithSearchProps<T> {
  options: Array<T>;
  onSelectOption(newOption: T): void;
  optionName?: string;
  onDropdownOpen?: () => void;
  onDropdownClose?: () => void;
  queryCallback?: (newValue: string) => void;
  unsetQueryCallback?: (newValue: string | null) => void;
  preSelected?: T | null;
  isDisabled?: boolean;
  isOpenByDefault?: boolean;
  hasError?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  searchPlaceholder?: string;
  zIndex?: number;
  displayJsx?: ReactNode;
  behaviour?: IDropdownBehavior;
  searchDependencies?: DependencyList;
  dropdownContainerStyles?: ObjectType;
}

const DropdownWithSearch = <T extends IOption>({
  optionName = 'dropdown-option-with-search',
  options,
  onSelectOption,
  onDropdownOpen,
  onDropdownClose,
  queryCallback,
  unsetQueryCallback,
  preSelected,
  isDisabled = false,
  isOpenByDefault = false,
  hasError = false,
  showSearch = false,
  placeholder = 'Select',
  searchPlaceholder = 'Search',
  zIndex,
  displayJsx = null,
  behaviour = {},
  searchDependencies,
  dropdownContainerStyles = {},
}: IDropdownWithSearchProps<T>) => {
  const [isOpen, openActions] = useBoolean(!isDisabled && isOpenByDefault);
  const [selected, setSelected] = useState<T | null>(preSelected ?? null);
  const [search, setSearch] = useState('');
  const [hasExactMatch, exactMatchActions] = useBoolean();
  const popperAttributes = useCustomPopper(SAME_WIDTH_POPPER_OPTIONS);

  function handleDebounce(debouncedValue: string) {
    if (debouncedValue.length > 2) {
      queryCallback?.(debouncedValue);
    } else {
      unsetQueryCallback?.(debouncedValue);
    }
  }

  const debouncedSearchChange = useDebounce(
    handleDebounce,
    300,
    searchDependencies
  );

  function handleWrapperClick() {
    if (!isDisabled) {
      if (isOpen) {
        onDropdownClose?.();
      } else {
        onDropdownOpen?.();
      }
      openActions.toggle();
    }
  }

  function handleSearchChange(value: string) {
    setSearch(value);
    debouncedSearchChange.implementation(value);
  }

  function handleClear() {
    setSearch('');
    unsetQueryCallback?.(null);
  }

  function handleClose() {
    setSearch('');
    unsetQueryCallback?.(null);
    openActions.off();
  }

  function handleSelect(newSelected: T) {
    setSelected(newSelected);
    onSelectOption(newSelected);
    handleClose();
  }

  const inputJsx =
    selected === null ? (
      <Styles.Placeholder>{placeholder}</Styles.Placeholder>
    ) : (
      <Styles.Value>{selected.name}</Styles.Value>
    );
  useEffect(() => {
    if (preSelected) {
      setSelected(preSelected);
    }
  }, [preSelected]);

  useEffect(() => {
    if (search && behaviour.addOption) {
      let hasFoundMatch = false;
      options.forEach(item => {
        if (item.name === search) {
          hasFoundMatch = true;
        }
      });
      if (hasFoundMatch && !hasExactMatch) {
        exactMatchActions.on();
      } else if (!hasFoundMatch && hasExactMatch) {
        exactMatchActions.off();
      }
    }
  }, [options.length, search]);

  return (
    <Styles.Wrapper
      role="presentation"
      className="cursor-pointer"
      onClick={handleWrapperClick}
      isDisabled={isDisabled}
      hasError={hasError}
      tabIndex={isDisabled ? -1 : 0}
      {...popperAttributes.reference}
    >
      {displayJsx || inputJsx}
      {isOpen && (
        <ClickOutside onClose={handleClose}>
          <Styles.DropdownWrapper
            className="v-d-flex no-scroll"
            {...popperAttributes.fixed}
            style={{
              ...popperAttributes.fixed.style,
              zIndex: zIndex ?? popperAttributes.fixed.style.zIndex,
            }}
            onClick={swallowEvent}
          >
            {showSearch && (
              <Styles.InputWrapper>
                <TextInput
                  placeholder={searchPlaceholder}
                  value={search}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleSearchChange(event.target.value)
                  }
                  onClear={handleClear}
                />
              </Styles.InputWrapper>
            )}
            <div
              className="full-flex has-y-scroll"
              style={{ ...dropdownContainerStyles }}
            >
              {options.map(eachOption => (
                <Styles.EachOption
                  key={`Option ${eachOption.id}`}
                  className="truncated-text cursor-pointer"
                  onClick={() => {
                    handleSelect(eachOption);
                  }}
                  data-option-name={optionName}
                >
                  {eachOption.name}
                </Styles.EachOption>
              ))}
              {search &&
                (options.length === 0 || !hasExactMatch) &&
                !isUndefined(behaviour.addOption) && (
                  <Styles.AddNewOptionDropdown className="d-flex space-between">
                    <Styles.SearchedText>{search}</Styles.SearchedText>
                    <div
                      role="presentation"
                      className="align-center"
                      onClick={() => behaviour.addOption?.handleClick?.(search)}
                    >
                      <AddIcon stroke="var(--all-ports)" size="0.8em" />
                      &nbsp;
                      <Styles.AddNewOptionText>
                        {behaviour.addOption.text}
                      </Styles.AddNewOptionText>
                    </div>
                  </Styles.AddNewOptionDropdown>
                )}
            </div>
          </Styles.DropdownWrapper>
        </ClickOutside>
      )}
    </Styles.Wrapper>
  );
};

export default DropdownWithSearch;
