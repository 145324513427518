import { FieldsType, InputTypeEnum } from '../types';

export const FIELDS: FieldsType = [
  {
    id: 'stitch_density',
    label: 'Stitch Density (SPI)',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'needle_size',
    label: 'Needle Size',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'machine_folders_attachments',
    label: 'Machine Folders/ Attachments',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'seam_allowance',
    label: 'Seam Allowance',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
  {
    id: 'sewing_checkpoint_others',
    label: 'Remarks',
    inputType: InputTypeEnum.PLAIN_TEXT,
  },
];
