import { ObjectType } from '../@types/defined';
import { RestMethodsEnum } from '../@types/api';
import { callAdminApi } from '../services/api';

// *─── Generic ─────────────────────────────────────────────────────────────────

export function getManageQaList(page: number | string) {
  return callAdminApi(
    RestMethodsEnum.GET,
    `/quality/quality_assurance/?page=${page}`
  );
}

export function getQaList() {
  return callAdminApi(RestMethodsEnum.GET, '/quality/qa_team_list/');
}

export function assignQaApi(id: number | string, data: ObjectType) {
  return callAdminApi(
    RestMethodsEnum.PUT,
    `/quality/quality_assurance/${id}/`,
    data
  );
}

export function getQualityReportsApi(query: string, page: number | string) {
  return callAdminApi(
    RestMethodsEnum.GET,
    `/quality/reports/?${query}&page=${page}`
  );
}

// *─── Pre-production Meeting ──────────────────────────────────────────────────

export function createPpmApi(data: ObjectType) {
  return callAdminApi(RestMethodsEnum.POST, `/quality/ppm_form/`, data);
}

export function getPpmApi(ppmId: number | string) {
  return callAdminApi(RestMethodsEnum.GET, `/quality/ppm_form/${ppmId}/`);
}

export function updatePpmApi(ppmId: number | string, data: ObjectType) {
  return callAdminApi(RestMethodsEnum.PUT, `/quality/ppm_form/${ppmId}/`, data);
}

export function getManagePpmList(page: number | string) {
  return callAdminApi(RestMethodsEnum.GET, `/quality/ppm_form/?page=${page}`);
}

export function createManageQaItem(data: ObjectType) {
  return callAdminApi(
    RestMethodsEnum.POST,
    '/quality/quality_assurance/',
    data
  );
}

export function updatePpmResultApi(ppmId: number | string, data: ObjectType) {
  return callAdminApi(
    RestMethodsEnum.PUT,
    `/quality/ppm_form/${ppmId}/accept/`,
    data
  );
}

export function getAttendeesApi(ppmId: number | string) {
  return callAdminApi(
    RestMethodsEnum.GET,
    `/quality/ppm_form/${ppmId}/attendees_list/`
  );
}

// *─── Final Inspection ────────────────────────────────────────────────────────

export const getFinalInspectionApi = (
  processStepId: string | number,
  query: string
) =>
  callAdminApi(
    RestMethodsEnum.GET,
    `/processstep/${processStepId}/quality/final_inspection/?${query}`
  );

export const createFinalInspectionApi = (
  processStepId: number | string,
  data: ObjectType
) =>
  callAdminApi(
    RestMethodsEnum.POST,
    `/processstep/${processStepId}/quality/final_inspection/`,
    data
  );

export const updateFinalInspectionApi = (
  qualityReportId: number | string,
  data: ObjectType
) =>
  callAdminApi(
    RestMethodsEnum.PUT,
    `/quality_report/${qualityReportId}/`,
    data
  );

export const getFinalInspectionReportHtmlApi = (
  processStepId: number | string
) =>
  callAdminApi(
    RestMethodsEnum.GET,
    `/processstep/${processStepId}/quality/final_inspection/report/`
  );

export const reviewFinalInspectionReportApi = (
  processStepId: number | string,
  data: ObjectType
) =>
  callAdminApi(
    RestMethodsEnum.PUT,
    `/processstep/${processStepId}/quality/final_inspection/review/`,
    data
  );

export const getRelatedTnaDataForReportCreationApi = (orderIds: string) =>
  callAdminApi(
    RestMethodsEnum.GET,
    `/quality/final_inspection/check_clubbed_reports/?tna_ids=${orderIds}`
  );

export const getFinalInspectionReportUrlApi = (
  reportId: number,
  slug: string
) =>
  callAdminApi(
    RestMethodsEnum.GET,
    `/quality/reports/${reportId}/document?slug=${slug}`
  );

export const getFinalInspectionAnalyticsApi = (query: string) =>
  callAdminApi(
    RestMethodsEnum.GET,
    `/quality/analytics/final-inspection/?${query}`
  );

export const getSampleInspectionAnalyticsApi = (query: string) =>
  callAdminApi(
    RestMethodsEnum.GET,
    `/quality/analytics/sample-inspections/?${query}`
  );

export const getPreviousStepReportApi = (processStepId: number) =>
  callAdminApi(
    RestMethodsEnum.GET,
    `/quality/previous_step_report/${processStepId}/`
  );

export const getReportDetailsApi = (reportId: number) =>
  callAdminApi(RestMethodsEnum.GET, `/quality/review/${reportId}/`);

export const setReportDetailsApi = (reportId: number, data: ObjectType) =>
  callAdminApi(RestMethodsEnum.PUT, `/quality/review/${reportId}/`, data);
