import { useTypedSelector } from '.';
import { isTraktezEnvironment } from '../utils/environments';

function useIsSupplierOsEnabled() {
  const linkedSupplier = useTypedSelector(store => store.user.linked_supplier);
  const isSupplierOs = linkedSupplier?.is_supplier_os && isTraktezEnvironment;
  return isSupplierOs;
}

export default useIsSupplierOsEnabled;
