import styled, { css } from "styled-components";
import Toggle from "../Toggle";

export const PreferenceWrapper = styled.div<{ isActive: boolean }>`
  height: 40px;
  width: 40px;
  border: 1px solid var(--loblolly);
  border-radius: 8px;

  ${(props) =>
    props.isActive &&
    css`
      box-shadow: 0 1px 2px rgba(12, 16, 33, 0.05), 0 0 0 4px var(--athens-gray);
    `}
`;

export const DropdownWrapper = styled.div`
  max-height: 512px;
  width: 360px;
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  z-index: 3;
  background-color: var(--white);
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
  text-align: left;

  > * {
    padding: 16px;
  }
`;

export const Label = styled.p`
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--rhino);
`;

export const EachOption = styled.div`
  cursor: default;
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 8px 12px;
`;

export const OptionLabel = styled.p<{ isDisabled: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => (props.isDisabled ? "var(--manatee)" : "var(--rhino)")};
  margin-left: 12px;
`;

const TOGGLE_THUMB_SIZE = 12;

export const ToggleWrapper = styled(Toggle)`
  .react-toggle-track {
    height: 16px;
    width: 32px;
  }

  .react-toggle-thumb {
    height: ${TOGGLE_THUMB_SIZE}px;
    width: ${TOGGLE_THUMB_SIZE}px;
    top: 2px;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: calc(100% - ${TOGGLE_THUMB_SIZE + 2}px);
  }
`;

export const ButtonsWrapper = styled.div`
  box-shadow: 0 -4px 12px rgba(44, 53, 86, 0.05);

  button {
    flex: 1;
  }
`;

export const SectionArea = styled.div`
  margin-bottom: 24px;

  &:first-of-type {
    margin-top: 8px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SectionHeader = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--lynch);
  text-transform: uppercase;
  margin-bottom: 12px;
`;
