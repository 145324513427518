import React, { useContext } from 'react';
import { PPMFormContext } from '../index';
import Accordion from '../accessoryComponents/Accordion';
import PlainTextInput from '../accessoryComponents/PlainTextInput';
import TextAreaInput from '../accessoryComponents/TextAreaInput';
import { InputTypeEnum, PPMFormSectionsEnum } from '../types';
import { PPM_FORM_SECTION_HEADINGS } from '../constants';
import { FIELDS } from './fields';

const Others = () => {
  const { getFieldValueFromStore, onChangeText } = useContext(PPMFormContext);

  return (
    <Accordion
      header={PPM_FORM_SECTION_HEADINGS[PPMFormSectionsEnum.OTHERS]}
      section={PPMFormSectionsEnum.OTHERS}
    >
      {FIELDS.map(field => {
        switch (field.inputType) {
          case InputTypeEnum.TEXT_AREA:
            return (
              <TextAreaInput
                key={field.id}
                field={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                placeholder={field.placeholder}
                onChangeText={onChangeText}
              />
            );

          default:
            return (
              <PlainTextInput
                key={field.id}
                id={field.id}
                label={field.label}
                value={getFieldValueFromStore(field.id)}
                onChangeText={onChangeText}
                section={PPMFormSectionsEnum.OTHERS}
              />
            );
        }
      })}
    </Accordion>
  );
};

export default Others;
