import styled from "styled-components";
import { device } from "../../../../constants/mediaQueries";

export const Wrapper = styled.div`
  padding: 12px 0;
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--athens-gray);
  }

  ${device.mediumViewport} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const InputGroup = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${device.mediumViewport} {
    width: 100%;
  }

  .radiobutton-wrapper {
    font-weight: 400;
    font-size: 14px;
    color: var(--rhino);

    ${device.mediumViewport} {
      padding-left: 12px;
    }
  }

  .radiobutton-label {
    border: 1px solid var(--rhino);
  }

  .forced-to-be-horizontal {
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    align-items: flex-start;

    .files-list {
      width: fit-content;
    }

    & > div:last-child {
      display: flex;
      flex-direction: row-reverse;
      gap: 16px;
    }
  }
`;

export const InputGroupUpper = styled.div`
  gap: 24px;

  ${device.mediumViewport} {
    flex-direction: column;
  }
`;

export const TextInputWrapper = styled.div`
  width: 320px;

  ${device.mediumViewport} {
    width: 100%;
  }

  .TextInputWithLabel-wrapper input {
    margin-top: 0;
    height: 40px;
  }
`;
