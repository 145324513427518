import React from "react";
import { IconPropsType } from "./types";

interface IAddIconProps extends IconPropsType {}

const PATH_DRAWN = Object.freeze({
  default: "M12 4V12M12 12V20M12 12H20M12 12L4 12",
  circle:
    "M12 9V12M12 12V15M12 12H15M12 12H9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z",
});

const AddIcon = ({
  size = 24,
  stroke = "var(--rhino)",
  strokeWidth = 1.25,
  viewType = "default",
  ...props
}: IAddIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={PATH_DRAWN[viewType as keyof typeof PATH_DRAWN]}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AddIcon;
