import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  /* height: 12.5vh; */
  box-shadow: 0px 4px 12px rgba(44, 53, 86, 0.05);
  flex: 0 1 auto;
  /* position: sticky;
  top: 0; */
`;

export const H1 = styled.h1`
  font-family: Lora;
  font-weight: 600;
  font-size: 24px;
  color: var(--rhino);
  padding: 14px 24px;
  flex: 1;
`;

export const Nav = styled.nav`
  padding: 0 24px;
`;

export const Ul = styled.ul`
  gap: 32px;
`;

export const Tab = styled.li<{ isActive: boolean }>`
  font-weight: 600;
  font-size: 14px;
  padding: 8px 0px;
  margin: 0;
  white-space: nowrap;
  color: var(--rhino);

  ${props =>
    props.isActive &&
    css`
      border-bottom: 2px solid var(--wild-watermelon);
      color: var(--wild-watermelon);
    `}
`;
