/* eslint-disable react/no-unstable-nested-components */
import React, { CSSProperties } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from '@fashinza/fashinza-design-system';
import TableCell from './TableCell';
import { SECTION_WISE_FIELDS, getMultiGridSettings } from '../../constants';
import EmptyView from '../../EmptyView';
import { usePaginatedTable } from '../../usePaginatedTable';
import * as Styles from './styles';
import { useTypedSelector } from '../../../hooks';
import { fetchTableData } from '../../../redux/actions/thunks';
import VirtualizedGrid from '../../../shared/TableLayout/VirtualizedGrid';

interface ITableProps {
  height: CSSProperties['height'];
}

const Table = ({ height }: ITableProps) => {
  const dispatch = useDispatch();

  const ids = useTypedSelector(store => store.quality.tabularData.ids);

  const isLoading = useTypedSelector(store => store.quality.isLoading);
  const section = useTypedSelector(store => store.quality.dashboard.section);
  const preferences = useTypedSelector(store => store.preferences.qualityList);

  const selectedFilterKeys: Array<string> = [];

  function fetchNext() {
    if (isLoading) {
      return;
    }

    dispatch(fetchTableData('append'));
  }

  const lastItemRef = usePaginatedTable(fetchNext);

  if (preferences.length === 0 || !section) {
    return null;
  }

  return (
    <Styles.TableWrapper className="pos-r " style={{ height }}>
      <VirtualizedGrid
        className="quality-table"
        key={ids[0] ?? ids.length}
        getGridCellNode={gridProps => (
          <TableCell
            listIds={ids}
            gridProps={gridProps}
            lastItemRef={lastItemRef}
          />
        )}
        columnCount={preferences.length}
        recomputeDependency={[preferences.length]}
        listLength={ids.length}
        getColumnWidth={index =>
          SECTION_WISE_FIELDS[section][preferences[index]]?.width
        }
        heightDependencies={[selectedFilterKeys.length]}
        fixedColumnCount={getMultiGridSettings(section).fixedColumnCount}
        commonGridClassName="remove-scrollbar"
      />
      {!isLoading && ids.length === 0 && <EmptyView text="No Reports Found!" />}
      {isLoading && (
        <Styles.LoaderWrapper className="pos-a center">
          <Spinner height={42} width={42} stroke="var(--wild-watermelon)" />
        </Styles.LoaderWrapper>
      )}
    </Styles.TableWrapper>
  );
};

export default Table;
