import styled from 'styled-components';

export const Wrapper = styled.div``;

export const InputWrapper = styled.div`
  width: 300px;
  height: 40px;
  background: var(--white);
  border: 1px solid var(--athens-gray);
  border-radius: 8px;
  padding-left: 12px;
`;

export const Input = styled.input`
  height: 38px;
  border-radius: inherit;
  padding: 0;
`;
